import React, { useEffect, useCallback } from "react"
import PortalWrapper from "../../components/layout/PortalWrapper"
// import BannerGradient from '../../components/layout/BannerGradient'
import CardSubscription from "../../components/cards/CardSubscription"
import MenuCard from "../../components/cards/Menu"
import { useState } from "react"
import api from "../../../core/api"
import { Fade, Grow, Paper, CircularProgress } from "@material-ui/core"
import { Formik } from "formik"
import { useConfirmation } from "../../../core/useConfirmation"
import { useTranslation } from "../../../components/IntlContext/IntlContext"
import { useSnackbar } from "notistack"
import SectionMain from "../../../layout/SectionMain"
import moment from "moment"
import Title from "../../../components/checkout-1.5.4/forms/Title"
import Loader from "../../../components/Loader"
import { Helmet } from "react-helmet"
import { CustomerService } from "../../../core/CustomerService"
import InfiniteScroll from "react-infinite-scroll-component"

/**@returns {import("../../../locale/locale").TranslationFunction} */

const month = {
	1: "Janeiro",
	2: "Fevereiro",
	3: "Março",
	4: "Abril",
	5: "Maio",
	6: "Junho",
	7: "Julho",
	8: "Agosto",
	9: "Setembro",
	10: "Outubro",
	11: "Novembro",
	12: "Dezembro",
}

const SubscriptionsPage = ({ history, match }) => {
	const [state, setState] = useState({ loading: true, subscriptions: [] })
	const [subscription, setSubscription] = useState("")
	const [id, setId] = useState(match.params.id || "")
	const [anchorEl, setAnchorEl] = useState(null)
	const [confirm, showConfirm] = useConfirmation()
	const { enqueueSnackbar } = useSnackbar()
	const [totalItems, setTotalItems] = useState(0)
	const [totalPages, setTotalPages] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)


	const momentDate = (arrayDate) =>
		moment()
			.year(arrayDate[0])
			.month(arrayDate[1] - 1)
			.date(arrayDate[2])

	const t = useTranslation()

	const handleSubscription = (subscription) => {
		setSubscription(subscription)
	}

	const handleToggle = useCallback((e, subscription) => {
		e.stopPropagation()

		setAnchorEl(e.currentTarget)
		setId({ subscription: subscription, id: e.currentTarget.id })
	}, [])

	const handleClose = (id) => {
		setAnchorEl(null)
	}

	const onCancelSubscription = async (subscription_id, plan_name) => {
		const response = await showConfirm(
			t("payment.labels.message.confirm"),
			t("subscriptions.label.message.confirm", plan_name),
			"portal"
		)

		if (response) {
			try {
				await api
					.delete(`/subscriptions/${subscription_id}`)
					.then((res) => {
						if (res.status === 200) {
							enqueueSnackbar(t.messages["cancel.subscription"], {
								variant: "success",
							})
						}
					})
			} catch (e) {
				enqueueSnackbar(t.errors["cancel_subscription.server_error"], {
					variant: "error",
				})

				console.log({ e })
			}
		}
	}
	const renderMapCards = (array) => {
		if (array.length === 0) {
			return (
				<SectionMain
					style={{
						height: "50vh",
					}}
					direction="column"
					noPadding
					position="center"
					alignItems="center"
				>
					<Title bold align="center">
						{t.titles["subscription.not_found"]}
					</Title>
				</SectionMain>
			)
		}

		return array.map((subscriptionByCreateDate) => {
			const [date] = Object.keys(subscriptionByCreateDate)
			const dateArray = (date || "").split("-")
			return (
				<SectionMain noPadding>
					<SectionMain direction="column" noPadding>
						<Title color="#001d7f">
							{date &&
								`${momentDate(dateArray).format("DD")} de ${month[moment(date).format("M")]}
								 de ${momentDate(dateArray).format("YYYY")}`}
						</Title>
					</SectionMain>

					<SectionMain>
						{subscriptionByCreateDate[
							Object.keys(subscriptionByCreateDate)
						].map((subscription) => (
							<Card subscription={subscription} />
						))}
					</SectionMain>
				</SectionMain>
			)
		})
	}

	const Card = useCallback(
		({ subscription, type, formikProps }) => {
			if (subscription)
				return (
					<CardSubscription
						history={history}
						type={type}
						subscription={subscription}
						handleToggle={handleToggle}
						handleSubscription={handleSubscription}
						onCancelSubscription={onCancelSubscription}
					/>
				)

			return ""
		},
		[handleToggle]
	)

	const fetchSubscriptions = async () => {
		try {
			const data = await CustomerService.Subscriptions.get({
				filters: {
					groupBy: "createDate",
					page: currentPage,
					count: 10
				}
			})
			setTotalItems(data.items.length)
			setTotalPages(data.total_pages)
			setState({ ...state, subscriptions: data.items, loading: false })


		} catch (e) {
			console.log({ e })
		}
	}

	const fetchMoreData = async () => {
		const data = await CustomerService.Subscriptions.get({
			filters: {
				groupBy: "createDate",
				page: currentPage + 1,
				count: 10
			}
		})

		function mergeObjectsWithSameKey(array1, array2) {
			let result = [...array1];

			array2.forEach(item2 => {
				const item1Index = array1.findIndex(item1 => Object.keys(item1)[0] === Object.keys(item2)[0]);

				if (item1Index >= 0) {
					const key = Object.keys(item2)[0];
					result[item1Index][key] = [...result[item1Index][key], ...item2[key]];
				} else {
					result.push(item2);
				}
			});

			return result;
		}
		const arr = mergeObjectsWithSameKey(state.subscriptions, data.items)

		let count = arr.reduce((total, item) => {
			let key = Object.keys(item)[0];
			return total + item[key].length;
		}, 0);

		setTotalItems(count)
		setCurrentPage(currentPage + 1)
		setState({ ...state, subscriptions: arr, loading: false })

	}
	const fetchSubscription = async () => {
		const data = await CustomerService.Subscriptions.GetById({ id })
		setSubscription(data)
	}

	useEffect(() => {
		fetchSubscriptions()
		id && fetchSubscription()
	}, [])

	return (
		<PortalWrapper history={history}>
			<Helmet>
				<title>Portal do assinante</title>
			</Helmet>
			<Formik initialValues={{ search: "" }}>
				{(formikProps) => (
					<SectionMain noPadding>
						{confirm}
						<MenuCard
							history={history}
							id={id}
							anchorRef={anchorEl}
							onClose={handleClose}
							onCancelSubscription={onCancelSubscription}
							handleSubscription={handleSubscription}
						/>

						{state.loading ? (
							<div
								className="flex-col center-a center-b"
								style={{
									width: "100vw",
									height: "80vh",
								}}
							>
								<Loader />
							</div>
						) : (
							<React.Fragment>
								<Fade in={subscription}>
									<SectionMain noPadding>
										<Card
											formikProps={formikProps}
											subscription={subscription}
											type="details"
										/>
									</SectionMain>
								</Fade>
								<Fade in={!subscription} style={{ justifyContent: "center" }}>
									<SectionMain noPadding>
										{!subscription ? (
											<InfiniteScroll
												dataLength={totalItems}
												next={fetchMoreData}
												style={{ width: "100%" }}

												hasMore={currentPage < totalPages}
												loader={<div className="flex-row center-a" style={{ height: "100px", width: "100%", boxSizing: "border-box" }}>
													<Loader size={80} style={{ height: 80 }} />
												</div>}
												endMessage={
													<>
														{state.subscriptions.length > 0 ? (
															<p style={{ textAlign: "center" }}>
																<b>{t("@@ Sem mais resultados")}</b>
															</p>
														) : (
															<></>
														)}
													</>
												}
											>
												{renderMapCards(state.subscriptions)}
											</InfiniteScroll>
										) : (
											<SectionMain
												style={{
													height: "50vh",
												}}
												direction="column"
												noPadding
												position="center"
												alignItems="center"
											>
												<Title bold align="center">
													{
														t.titles[
														"subscription.not_found"
														]
													}
												</Title>
											</SectionMain>
										)}
									</SectionMain>
								</Fade>
							</React.Fragment>
						)}
					</SectionMain>
				)}
			</Formik>
		</PortalWrapper>
	)
}

export default SubscriptionsPage
