import React from 'react'
import SectionMain from '../../../../layout/SectionMain'
import { TitleSmall } from '../../../../components/checkout-1.5.4/forms/Title'
import { Avatar, ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import { primary, backgroundColor } from '../../../../layout/color'
import Collapse from '@material-ui/core/Collapse'
import Grid from 'antd/lib/card/Grid'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { toCurrency } from '../../../../utils/commonMasks'
import useLocalDateFunction from '../../../../utils/useLocalDateFunction'
import { useTranslation } from '../../../../components/IntlContext/IntlContext'

const freqQtyLabels = {
	DAILY: 'dias',
	MONTHLY: 'meses',
	YEARLY: 'anos'
}

const freqLabels = {
	DAILY: 'dia',
	MONTHLY: 'mês',
	YEARLY: 'ano'
}

const PricingOptionListItem = ({ pricing_option, defaultOpen = true }) => {
	const renderDate = useLocalDateFunction('DATETIME')
	const t = useTranslation()
	return (
		<SectionMain
			direction="column"
			style={{
				backgroundColor: '#fff',
				// boxShadow: '12px 13px 17px -8px rgba(0,0,0,0.10)',
				margin: '10px 0',
				// padding: 15,
				opacity: defaultOpen ? 1 : 0.5,
				border: '1px dashed #DCDCDC'
			}}
		>
			<ListItem style={{ flexDirection: 'column' }}>
				<SectionMain miniPadding direction="row">
					<TitleSmall>Código:&nbsp;{pricing_option.pricing_code}</TitleSmall>
				</SectionMain>
				<SectionMain miniPadding>
					<TitleSmall>
						Valor:&nbsp;{`${toCurrency(
							pricing_option.amount,
							pricing_option.currency
						)}${pricing_option.count !== 1
							? ` por ${pricing_option.count} ${freqQtyLabels[pricing_option.frequency]}`
							: `/${freqLabels[pricing_option.frequency]}`}`}
					</TitleSmall>
				</SectionMain>
				<SectionMain miniPadding direction="row">
					<TitleSmall>Data do Pagamento: {renderDate(pricing_option.last_sale.billing_date)}</TitleSmall>
				</SectionMain>
				<SectionMain miniPadding>
					<TitleSmall>
						Situação:&nbsp;
						<span
							style={{
								textTransform: 'uppercase',
								fontSize: 14,
								fontWeight: '500',
								color: pricing_option.last_sale.status === 'paid' ? '#3DDB47' : '#FF3F3F'
							}}
						>
							{t(`subscriptions.status.${pricing_option.last_sale.status}`)}
						</span>
					</TitleSmall>
				</SectionMain>
				{/* {defaultOpen ? <ExpandLess /> : <ExpandMore />} */}
			</ListItem>
			{/* <Collapse in={defaultOpen}>{children}</Collapse> */}
		</SectionMain>
	)
}

export default PricingOptionListItem
