import React from 'react'
import { BottomNavigation, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import DateRangeIcon from '@material-ui/icons/DateRange'
import OfflinePinIcon from '@material-ui/icons/OfflinePinOutlined'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined'
import { useTranslation } from '../../../../../components/IntlContext/IntlContext'

const useStyles = makeStyles({
	root: {
		background: '#fff',
		position: 'fixed',
		bottom: 0,
		width: '100%',
		borderTop: 'solid 1px #0000001a',
		zIndex: 9999999
	}
})

const BottomMenuWrapper = ({ children }) => {
	const t = useTranslation()
	const history = useHistory()
	const classes = useStyles()

	const handleNavigation = (route) => {
		history.push(route)
	}

	const showLabel = (link) => history.location.pathname === link

	const navButtons = [
		{ label: t.navigation_menu.my_subscriptions, link: '/', icon: <DateRangeIcon /> },
		{ label: t.navigation_menu.my_payment_means, link: '/payments', icon: <OfflinePinIcon /> },
		{ label: t.navigation_menu.my_cards, link: '/payment-means', icon: <CreditCardIcon /> },
		{ label: t.navigation_menu.profile, link: '/profile', icon: <AccountCircleIcon /> }
	]

	return (
		<BottomNavigation className={classes.root}>
			{navButtons.map((button) =>
				children({
					label: button.label,
					link: button.link,
					icon: button.icon,
					showLabel,
					handleNavigation
				})
			)}
		</BottomNavigation>
	)
}

export default BottomMenuWrapper
