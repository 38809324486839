import axios from "axios"

/** @type {import("axios").AxiosInstance} */
export const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/v1`,
})

window.api = api

function getMode() {
    return +localStorage.getItem("test") ? "SANDBOX" : "LIVE"
}

api.interceptors.request.use((request) => {
    const token = localStorage.getItem("token")

    request.headers = {
        ...request.headers,
        // ...api.req.headers,
        Authorization: token && `Bearer ${token}`,
    }
    // }
    return request
})

api.interceptors.response.use(
    (res) => res,
    (error) => {
        Object.defineProperty(error, "message", {
            get() {
                return (
                    error.response?.data.message ??
                    "@@ Tente novamente mais tarde"
                )
            },
        })
        return Promise.reject(error)
    }
)

export class CustomerService {

    static async getCompanyConfig() {
        const { data } = await api.get("/company-config")
        return data
    }

    static async getMe() {
        const { data } = await api.get("/me")
        return data
    }

    static Subscriptions = class Subscriptions {

        static async get({ filters }) {
            const { data } = await api.get("/subscriptions", { params: filters })
            return data
        }

        static async GetById({ id }) {
            const { data } = await api.get(`/subscriptions/${id}`)
            return data
        }

        static async getPaymentMeans({ filters }) {
            const { data } = await api.get(`/payment-means`, { params: filters })
            return data
        }

        static async getSales({ filters }) {
            const { data } = await api.get(`/sales`, { params: filters })
            return data
        }

        static async getSalesBySubscriptionId({ subscriptionId, filters }) {
            const { data } = await api.get(`/subscriptions/${subscriptionId}/sales`, { params: filters })
            return data
        }

    }

}