import React, { useEffect } from "react"
import { useState } from "react"
import api, { updateFunctionApi } from "../../../core/api"
import { Formik } from "formik"
import WrapperLayout from "../../../layout/WrapperLayout"
import SectionMain from "../../../layout/SectionMain"
import Title, {
	TitleSmall,
} from "../../../components/checkout-1.5.4/forms/Title"
import {
	Paper,
	List,
	ListItem,
	ListItemIcon,
	Avatar,
	Hidden,
	Button,
	CircularProgress,
} from "@material-ui/core"
import CreditCardIcon from "@material-ui/icons/CreditCard"
import StepTitle from "../../../components/checkout-1.5.4/forms/StepTitle"
import RenderInputsV2 from "../../../components/checkout-1.5.4/forms/RenderInputsV2"
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn"
import { toCurrency } from "../../../utils/commonMasks"
import { backgroundColor } from "../../../layout/color"
import jwt from "jsonwebtoken"
import RRule from "rrule"
import { useConfirmation } from "../../../core/useConfirmation"
import { useTranslation } from "../../../components/IntlContext/IntlContext"
import { string, object, mixed, array, number } from "yup" // for only what you need
import NotFound from "../../../components/checkout-1.5.4/NotFound"
import { useSnackbar } from "notistack"
import SuccessPage from "../../../components/checkout-1.5.4/SuccessPage"
import SuccessMessage from "../../../components/checkout-1.5.4/SuccessMessage"
import Loader from "../../../components/Loader"
import RenderInputsV3 from "../../../components/checkout-1.5.4/forms/RenderInputsV3"
import RenderInputsV4 from "../../../components/checkout-1.5.4/forms/RenderInputsV4"
import AppContext, { useAppContext } from "../../../components/AppContextCheckout"
import { handleLocaleChange } from "../../../locale/handleLocaleChange"
import { CheckoutService } from "../../../core/CheckoutService"
import RenderPayzenForm from "../../../components/checkout-1.5.4/forms/PayzenForm"

const execImmediate = (f) => f()

const rruleFreqLabels = {
	arrayFreqLabelsFull: ["ano", "mês", "semana", "dia"],
	arrayFreqLabels: ["anual", "mensal", "semanal", "diário"],
}

const langList = {
	"en": 'en-US',
	"es": 'es-ES',
	"fr": 'fr-FR',
	"pt-br": 'pt-BR'
}

const ChangePaymentMethodPage = ({ match }) => {
	const { payzenForm, setAppState } = useAppContext(AppContext);
	const [details, setDetails] = useState({
		company: {},
		company_id: "",
		subscription_id: "",
		customer_id: "",
		name: "",
		email: "",
		amount: 0,
		rrule: { freq: "" },
		trial_rrule: { interval: 0, freq: "" },
		is_on_trial: false,
		plan_name: "",
	})
	let language = { BRA: "pt-BR", FRA: "fr" };
	const [checkoutData, setCheckoutData] = useState({})

	const t = useTranslation()
	const { enqueueSnackbar } = useSnackbar()
	const [confirm, showConfirm] = useConfirmation()
	const [loading, setLoading] = useState(false)

	const [success, setSuccess] = useState(false)
	const [messageError, setMessageError] = useState("loading")
	const paymentInputs = ["payment"]
	const [company, setCompany] = useState({
		style: { primary_color: "", url_logo_white: "" },
	})

	const fetchPaymentMean = async (hash) => {
		const payload = jwt.decode(hash)

		try {
			const data = payload ? await CheckoutService.GetPaymentUpdateInfo_OLD({
				subscription_id: payload.subscription_id,
				token: payload.token,
				companyId: payload.company_id,
			}) : await CheckoutService.GetPaymentUpdateInfo({
				hash
			})

			if (!payload && data) setCheckoutData(data)

			const rrule = RRule.fromString(data.plan.recurrence.rrule)
			const trial_rrule = data.trial_rrule ? RRule.fromString(data.trial_rrule) : ""
			setDetails({
				...data,
				...data.customer,
				amount: data.plan.amount,
				plan_name: data.plan.name,
				trial_rrule: trial_rrule.origOptions,
				rrule: rrule.origOptions,
				company: {}
			})
			setCompany(data.company)
			// handleLocaleChange(language[data.company.country], setAppState);

			if (data.use_form_token) {
				setAppState({
					payzenForm: { form_token: data.form_token.form_token, public_key: data.form_token.public_key },
				});
			}

			if (data.language !== null) {
				handleLocaleChange(langList[data.language])
			}


			return setMessageError("")
			// await updateFunctionApi
			// 	.get(
			// 		`/change-payment?subscriptionId=${payload.subscription_id}`,
			// 		{
			// 			headers: {
			// 				token: payload.token,
			// 				companyId: payload.company_id,
			// 			},
			// 		}
			// 	)
			// 	.then((res) => {
			// 		const trial_rrule = res.data.trial_rrule ? RRule.fromString(res.data.trial_rrule) : ""
			// 		const rrule = RRule.fromString(res.data.rrule)
			// 		setDetails({
			// 			...res.data,
			// 			trial_rrule: trial_rrule.origOptions,
			// 			rrule: rrule.origOptions,
			// 			company: {}
			// 		})
			// 		setMessageError("")
			// 	})
		} catch (e) {
			console.log(e)
			if (e.response) {
				switch (e.response.status) {
					case 401:
						setMessageError(t.errors.expired_page)
						break

					case 403:
						setMessageError(
							t.errors.link_already_used
						)
						break

					case 406:
						setMessageError(
							t.errors.payment_refused
						)
						break

					case 409:
						setMessageError(t.errors.page_deactivated)
						break

					default:
						setMessageError(
							t.errors.page_unavailable
						)
						break
				}
			} else {
				setMessageError(
					t.errors.page_unavailable
				)
			}
		}
	}

	const onSubmitForm = async (form) => {
		const [MM, YY] = form.cc_expiry_date.split("/")

		const expiry_date = `${MM}/20${YY}`

		const formDataPayment = {
			customer_id: details.customer_id,
			subscription_id: details.subscription_id,
			// payment_mean: {
			// 	type: "credit_card",
			// 	number: form.payment_mean.card_number,
			// 	expiry_date: execImmediate(() => {
			// 		try {
			// 			const [
			// 				,
			// 				month,
			// 				year,
			// 			] = form.payment_mean.expiry_date.match(
			// 				/(\d\d)\/(\d\d)/
			// 			)
			// 			const currYearFirstTwoDigs = new Date()
			// 				.getFullYear()
			// 				.toString()
			// 				.match(/^\d\d/)[0]
			// 			return `${month}/${currYearFirstTwoDigs}${year}`
			// 		} catch (e) {
			// 			return form.payment_mean.expiry_date
			// 		}
			// 	}),
			// 	cvv: form.payment_mean.cvv,
			// 	holder: form.payment_mean.customer_name,
			// 	brand: form.payment_mean.brand,
			// },
			payment_mean: {
				number: form.cc_number,
				holder: form.cc_cardholder,
				expiry_date,
				cvv: form.cvv,
				brand: form.cc_brand,
				type: "credit_card"
			},
			payment_method: "credit_card",
		}


		const response = true

		if (response) {
			setLoading(true)
			try {
				const qs = new URLSearchParams(window.location.search)
				const hash = qs.get("hash") || match.params.id
				const payload = jwt.decode(hash)
				payload ? await updateFunctionApi
					.post("/change-payment-method/old_hash", formDataPayment, {
						headers: {
							Hash: payload.token,
						},
					})
					.then((res) => {
						if (res.status === 200) {
							setSuccess({
								checkoutInfo: details,
								done: true,
								checkoutResponse: {},
								userInfo: {
									first_name: details.first_name,
									last_name: details.last_name,
									email: details.email,
									// totalPaid: {
									// 	amount: form.coupon.coupon_id
									// 		? form.coupon.total_amount
									// 		: defaultValues.total,
									// 	currency: plan.currency,
									// },
								},
							})
							setLoading(false)
						}
					})
					: (
						await updateFunctionApi.post(`/change-payment-method/${hash}`, formDataPayment, {
							headers: {
								'Authorization': checkoutData.session_token,
							}
						}).then((res) => {
							if (res.status === 200) {
								setSuccess({
									checkoutInfo: details,
									done: true,
									checkoutResponse: {},
									userInfo: {
										first_name: details.first_name,
										last_name: details.last_name,
										email: details.email,
										// totalPaid: {
										// 	amount: form.coupon.coupon_id
										// 		? form.coupon.total_amount
										// 		: defaultValues.total,
										// 	currency: plan.currency,
										// },
									},
								})
								setLoading(false)
							}
						})
					)
			} catch (e) {
				setLoading(false)

				if (e.response.status === 409) {
					enqueueSnackbar(
						"Este cartão já esta sendo utilizado na assinatura.",
						{
							variant: "error",
						}
					)

					setSuccess(false)
				} else {
					if (e.response.status === 406) {
						enqueueSnackbar(
							"Verifique os dados do cartão e tente novamente.",
							{
								variant: "error",
							}
						)
						setSuccess(false)
					} else {
						enqueueSnackbar(
							"Verifique os dados do cartão, por favor tente novamente.",
							{
								variant: "error",
							}
						)
						setSuccess(false)
					}
				}
			}
		}
	}

	const CCInitialValues = () => {
		let initialValues = {
			cc_number: "",
			cc_cardholder: "",
			cc_expiry_date: "",
			cvv: "",
			brand: "",
			type: "",
			// installments: checkoutConfig.plan.installments && checkoutConfig.plan.installments[0]

		}
		return initialValues
	}

	const validationSchema = (values) => {
		const errors = {}

		if (!values.cc_number) {
			errors.cc_number = "Campo Obrigatório"
		}
		if (!values.cc_cardholder) {
			errors.cc_cardholder = "Campo Obrigatório"
		}
		if (!values.cc_expiry_date) {
			errors.cc_expiry_date = "Campo Obrigatório"
		} else {
			const [MM, YY] = values.cc_expiry_date.split("/")
			if (MM > 12) {
				errors.cc_expiry_date = "Insira uma data válida"
			}
			const cardDate = new Date(parseInt(`20${YY}`), (parseInt(MM) - 1))
			const now = new Date()
			if (cardDate < now) {
				errors.cc_expiry_date = "Insira uma data válida"
			}
		}
		if (!values.cvv) {
			errors.cvv = "Campo Obrigatório"
		}

		return errors;
	}

	useEffect(() => {
		const qs = new URLSearchParams(window.location.search)
		const hash = qs.get("hash") || match.params.id;
		if (hash) {
			fetchPaymentMean(hash)
		}
		// fetchCompanyConfig()
	}, [])

	const SubscriptionDetails = ({ padding = "10px 20px", hiddenButton }) => (
		<SectionMain
			xs={12}
			sm={6}
			md={6}
			lg={6}
			xl={6}
			style={{ padding, width: "100%" }}
			direction="column"
		>
			<Paper
				style={{
					boxShadow: "12px 13px 17px -8px rgba(0,0,0,0.10)",
					padding: "10px 15px",
					width: "100%",
					border: "2px dashed #DCDCDC",
				}}
			>
				<style>
					{`@media only screen and (min-width: 1024px) {
							#form-input-section {
								width:90% !important ;
								
							}}
							`}
				</style>
				<SectionMain noPadding>DETALHES DA ASSINATURA</SectionMain>

				<SectionMain direction="column" miniPadding>
					<TitleSmall>Assinante</TitleSmall>
					<TitleSmall bold>{details.first_name + " " + details.last_name}</TitleSmall>
				</SectionMain>
				<SectionMain direction="column" miniPadding>
					<TitleSmall>E-mail</TitleSmall>
					<TitleSmall bold style={{ textTransform: "lowercase" }}>
						{details.email}
					</TitleSmall>
				</SectionMain>
				<SectionMain direction="column" miniPadding>
					<TitleSmall>Plano</TitleSmall>
					<TitleSmall bold>{details.plan_name}</TitleSmall>
				</SectionMain>
				<SectionMain miniPadding>
					<div
						className="flex-row center-a center-b"
						style={{
							height: 1,
							border: "1px dashed #DCDCDC",
							width: "100%",
						}}
					/>
				</SectionMain>

				{details.is_on_trial && (
					<SectionMain position="space-between" miniPadding>
						<TitleSmall>Trial</TitleSmall>
						<Title>{`${toCurrency(details.trial_amount)}`}</Title>
					</SectionMain>
				)}

				{/* {details.is_on_trial && (
					<SectionMain position="flex-end" miniPadding>
						<TitleSmall align="right">
							{`Após isso, será cobrado o valor de ${toCurrency(details.trial_amount)} por ${freqLabels[
								details.trial_frequency
							]}.`}
						</TitleSmall>
					</SectionMain>
				)} */}
				<SectionMain position="space-between" miniPadding>
					<TitleSmall>Valor da assinatura</TitleSmall>
					<Title>{`${toCurrency(details.amount, details.currency)}/${rruleFreqLabels.arrayFreqLabelsFull[details.rrule.freq]
						}`}</Title>
				</SectionMain>
			</Paper>
			<div
				className="flex-row flex-end"
				style={{ display: hiddenButton ? "none" : "flex", marginBottom: 20 }}
			>
				{!details.use_form_token &&
					<Button
						// fullWidth
						size="small"
						disabled={loading}
						type="submit"
						variant="contained"
						color="primary"
						style={{
							width: 165,
							marginTop: 20,
							color: "#fff",
							alignItems: "center",
							backgroundColor: company.style.primary_color
								? company.style.primary_color
								: backgroundColor,
						}}
					>
						{loading && (
							<CircularProgress
								size={24}
								color="inherit"
								style={{ marginRight: 8 }}
							/>
						)}
						Concluir atualização
					</Button>
				}
			</div>
			<TitleSmall>{t(`@@ *Ao confirmar a atualização, você aceita que seus dados sejam armazenados para futuros pagamentos recorrentes de acordo com o plano assinado.`)}</TitleSmall>
		</SectionMain>
	)

	const PaymentForm = ({ formikProps }) => (
		<SectionMain xs={12} sm={6} md={6} lg={6} xl={6} noPadding>
			<StepTitle
				noArrow
				iconColor={company.style.primary_color}
				hidden={false}
				defaultOpen={true}
				stepNumber={<CreditCardIcon style={{ fontSize: 18 }} />}
				stepTitle="NOVO MEIO DE PAGAMENTO"
			>
				<SectionMain noPadding={!details.use_form_token}>
					{details.use_form_token
						? <div className="flex-row center-a" style={{ width: '100%' }}>
							<div id="form-payzen">
								<div className="kr-smart-form" kr-card-form-expanded />
							</div>
							<RenderPayzenForm
								gatewayConfig={
									details.gateway_config
								}
								setSuccess={
									(clientAnswer) => setSuccess({
										done: true,
										checkoutResponse: {},
										userInfo: {
											name: details.first_name + " " + details.last_name,
											email: details.email,
											// totalPaid: {
											// 	amount: form.coupon.coupon_id
											// 		? form.coupon.total_amount
											// 		: defaultValues.total,
											// 	currency: plan.currency,
											// },
										},
									})
								}
								subscriptionId={
									details.subscription_id
								}
								couponId={
									null
								}
							/>
						</div>
						: <RenderInputsV4
							formikprops={formikProps}
							checkoutConfig={{ ...details }}
							rows={[
								['cc_number'],
								['cc_expiry_date', 'cvv'],
								['cc_cardholder'],
							]} />}
				</SectionMain>
			</StepTitle>
		</SectionMain>
	)

	if (messageError) {
		return <NotFound company={company} messageError={messageError} />
	}

	if (success) {
		return (
			<SuccessPage
				userInfo={success.userInfo}
				logo={company && company.style.url_logo_default}
				primaryColor={company && company.style.primary_color}
				updatePayment
			/>
		)
	}

	return (
		<Formik
			validate={values => validationSchema(values)}
			initialValues={CCInitialValues()}
			onSubmit={onSubmitForm}
		>
			{(formikProps) => {
				if (messageError === "loading") {
					return (
						<div
							className="flex-col center-a center-b"
							style={{
								width: "100vw",
								height: "100vh",
							}}
						>
							<Loader />
						</div>
					)
				}
				return (
					<WrapperLayout
						primary_color={company.style.primary_color}
						logo_white_url={company.style.url_logo_white}
						company={company}
					>
						{confirm}
						<form
							autocomplete="new-password"
							action="javascript:void(0)"
							onSubmit={formikProps.handleSubmit}
						>
							<SectionMain position="center">
								<Title align="center">
									ATUALIZAR MEIO DE PAGAMENTO
								</Title>
							</SectionMain>
							<SectionMain position="space-between" noPadding>
								<div className="flex-row xs-wrap">
									<Hidden only={["sm", "md", "lg", "xl"]}>
										<SubscriptionDetails
											padding="0"
											hiddenButton
										/>
									</Hidden>
									<PaymentForm formikProps={formikProps} />
									<Hidden only={["xs"]}>
										<SubscriptionDetails />
									</Hidden>
									{!details.use_form_token &&
										<Hidden only={["sm", "md", "lg", "xl"]}>
											<SectionMain miniPadding>
												<Button
													fullWidth
													size="large"
													disabled={loading}
													type="submit"
													variant="contained"
													color="primary"
													style={{
														marginTop: 20,
														color: "#fff",
														alignItems: "center",
														backgroundColor: company.style
															.primary_color
															? company.style
																.primary_color
															: backgroundColor,
													}}
												>
													{loading && (
														<CircularProgress
															size={24}
															color="inherit"
															style={{ marginRight: 8 }}
														/>
													)}
													Concluir atualização
												</Button>
											</SectionMain>
										</Hidden>
									}
								</div>
							</SectionMain>
						</form>
					</WrapperLayout>
				)
			}}
		</Formik>
	)
}

export default ChangePaymentMethodPage
