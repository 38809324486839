import swal from 'sweetalert';


const useMsgBox = () => {
    const err = ({ title = "", text = "" }) => {
        swal(title, text, "error");
    }

    const ok = ({ title = "", text = "" }) => {
        swal(title, text, "success");
    }

    const warn = ({ title = "", text = "" }) => {
        swal(title, text, "warning");
    }

    const info = ({ title = "", text = "" }) => {
        swal(title, text, "info");
    }

    return {
        err,
        ok,
        warn,
        info
    }
}

export default useMsgBox