import React from 'react'
import BottomMenuWrapper from './BottomMenuWrapper'
import BottomMenuUI from './BottomMenuUI'

const BottomMenu = () => {
	return (
		<BottomMenuWrapper>
			{({ link, label, icon, showLabel, handleNavigation }) => (
				<BottomMenuUI
					link={link}
					label={label}
					icon={icon}
					showLabel={showLabel}
					handleNavigation={handleNavigation}
				/>
			)}
		</BottomMenuWrapper>
	)
}

export default BottomMenu
