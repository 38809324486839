import React from 'react'
import SectionMain from '../../../../layout/SectionMain'
import { makeStyles, Hidden } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { TitleSmall } from '../../../../components/checkout-1.5.4/forms/Title'
import { useTranslation } from '../../../../components/IntlContext/IntlContext'

const useStyles = makeStyles({
	container: {
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	steper: {
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	track: {
		justifyContent: 'center',
		alignItems: 'center',
		background: '#7b7b7b',
		height: 2,
		top: 13,
		width: '96.5%',
		position: 'relative'
	},
	icon: {
		borderRadius: '100%',
		background: '#c4c4c4',
		height: 16,
		width: 16,
		zIndex: 150
	},
	arrow: {
		fontSize: 24,
		color: '#7b7b7b',
		zIndex: 150
	}
})

const Stepers = ({ firstCharge, currentCharge, nextCharge }) => {
	const classes = useStyles()
	const t = useTranslation()
	return (
		<SectionMain noPadding position="center">
			<SectionMain noPadding className={classes.track} />
			<SectionMain noPadding className={classes.steper}>
				<div className={classes.icon} />
				<div className={classes.icon} />
				<ArrowForwardIcon className={classes.arrow} />
			</SectionMain>
			<SectionMain miniPadding className={classes.container}>
				<Hidden only="xs">
					<TitleSmall>
						{firstCharge} <br /> {`${t.titles.initial_charge} ${t.generics.charge}`}
					</TitleSmall>
					<TitleSmall align="center">
						{currentCharge} <br /> {`${t.titles.current_charge} ${t.generics.charge}`}
					</TitleSmall>
					<TitleSmall bold align="right">
						{nextCharge} <br /> {`${t.titles.next_charge} ${t.generics.payment}`}
					</TitleSmall>
				</Hidden>
				<Hidden only={[ 'xl', 'lg', 'md', 'sm' ]}>
					<TitleSmall fontSize={12}>
						{firstCharge} <br /> {t.titles.initial_charge} <br /> {t.generics.charge}
					</TitleSmall>
					<TitleSmall fontSize={12} align="center">
						{currentCharge} <br /> {t.titles.current_charge} <br /> {t.generics.charge}
					</TitleSmall>
					<TitleSmall fontSize={12} bold align="right">
						{nextCharge} <br /> {t.titles.next_charge} <br /> {t.generics.payment}
					</TitleSmall>
				</Hidden>
			</SectionMain>
		</SectionMain>
	)
}

export default Stepers
