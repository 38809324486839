import React from "react"
import { createMuiTheme, ThemeProvider, Typography } from "@material-ui/core"
import { primary } from "../../../../layout/color"

const theme = createMuiTheme({
	typography: {
		fontFamily: ["Titillium Web"],
	},
})

const Title = ({
	className,
	children,
	fontSize = 16,
	color = primary,
	bold,
	letterSpacing = 2,
	align,
	shadow,
	style,
	...props
}) => {
	return (
		// <ThemeProvider theme={theme}>
		<Typography
			id="font-title"
			align={align}
			style={{
				textShadow: shadow && "4px 4px 10px rgba(0, 0, 0, 0.29)",
				letterSpacing: bold ? 1 : letterSpacing,
				fontWeight: bold ? "800" : "600",
				fontSize,
				color,
				...style,
			}}
			{...props}
			className={className}
		>
			{children}
		</Typography>
		// </ThemeProvider>
	)
}

export const TitleSmall = ({
	className,
	children,
	color = primary,
	bold,
	fontSize = 14,
	letterSpacing = 1,
	align,
	shadow,
	style,
	...props
}) => {
	return (
		<ThemeProvider theme={theme}>
			<Typography
				className={className}
				align={align}
				style={{
					textShadow: shadow && "4px 4px 10px rgba(0, 0, 0, 0.29)",
					letterSpacing: bold ? 1 : letterSpacing,
					fontWeight: bold ? "800" : "500",
					fontSize,
					color,
					...style,
				}}
				{...props}
			>
				{children}
			</Typography>
		</ThemeProvider>
	)
}

export default Title
