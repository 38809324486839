import React, { useEffect } from "react"
import { makeStyles, Dialog, DialogContent, Button } from "@material-ui/core"
import SectionMain from "../../../layout/SectionMain"
import { barcode } from "react-icons-kit/fa"
import Icon from "react-icons-kit"
import Title, { TitleSmall } from "../forms/Title"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import { useTranslation } from "../../IntlContext/IntlContext"
import GetAppIcon from "@material-ui/icons/GetApp"
import { useSnackbar } from "notistack"
import { billService } from "../../../core/api"
import { saveAs } from "file-saver"
import toCurrency from "../../../core/toCurrency"
import CompactTable from "../../TableRenderer"
import useLocalDateFunction from "../../../utils/useLocalDateFunction"

const useStyles = makeStyles({
	root: {
		// height: "100vh",
		justifyContent: "center",
		alignItems: "center",

	},
	backdrop: { background: "none" },
	container: {
		justifyContent: "center",
	},
	dialog: {
		background: "none",
	},
	logo: {
		backgroundImage: ({ logo }) => `url(${logo})`,
		backgroundSize: "contain",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		width: "100%",
		height: "10vw",
		maxHeight: 100,
	},
	icon: {
		borderRadius: "100%",
		height: 120,
		width: 120,
		paddingTop: 10,
		color: "#fff",
		background: ({ color }) => color,
		alignItems: "center",
		justifyContent: "center",
	},
	code: {
		border: '1px solid rgba(0,0,0,0.19)',
		borderRadius: '15px',
		padding: "5px 10px",
		textAlign: "center",
		width: '100%',
		fontSize: "16px"
	},
	button: {
		border: '1px solid rgba(0,0,0,0.19)',
		borderRadius: '15px',
		textTransform: "none",
		color: ({ color }) => color
	},
})

const SuccessBoleto = (props) => {

	const {
		primaryColor,
		logo,
		boleto = { link: "", payment_number: "" },
		userInfo,
		checkoutInfo,
		isProduct
	} = props

	const classes = useStyles({ color: primaryColor, logo })
	const t = useTranslation()
	const renderDate = useLocalDateFunction('DATE')
	const { enqueueSnackbar } = useSnackbar()

	const copyCheckoutLinkToClipboard = () => {
		try {
			document.getElementById("boleto-code").select()
			document.execCommand("copy")

			enqueueSnackbar(t.checkout.code_copied, {
				options: {
					autoHideDuration: 1000,
				},
				variant: "success",
			})
		} catch (e) {
			console.log({ e })
		}
	}

	const getBoleto = async () => {
		const qs = new URLSearchParams(window.location.search)
		const hash = qs.get("hash")
		try {
			await saveAs(
				`${process.env.REACT_APP_BILL_SERVICE_URL}/checkout/boleto/print?hash=${hash}&protocolo=${boleto.protocolo}`
			)
			//
		} catch (e) {
			console.log({ e })
		}
	}

	const renderCheckoutSuccessInfo = () => {
		const lines = checkoutInfo ? checkoutInfo.checkout_config?.checkout_success_instructions?.split("\n") : []
		return lines.map(line => (
			<span>
				{line}
				<br />
			</span>
		))
	}

	const renderFrequency = (pricing_option) => {
		if (pricing_option?.interval < 2) {
			return t.frequency[pricing_option?.frequency.toUpperCase()]
		} else {
			return t.generics.each + " " + pricing_option.interval + " " + t.frequency_quantity[pricing_option?.frequency.toUpperCase()]
		}
	}

	const renderTrialAmount = (success_info) => {
		if (success_info.amount === 0) {
			if (checkoutInfo?.plan?.trial.interval > 1) {
				return t("^titles.gratuitous", checkoutInfo?.plan?.trial.interval + " " + t.frequency_quantity[checkoutInfo?.plan?.trial.interval_unit])
			} else {
				return t("^titles.gratuitous", (checkoutInfo?.plan?.trial.interval + " " + t.frequency_interval_unit[checkoutInfo?.plan?.trial.interval_unit]))
			}
		} else {
			return toCurrency(success_info.amount) + " " + t("^titles.trial", (checkoutInfo?.plan?.trial.interval + " " + t.frequency_interval_unit[checkoutInfo?.plan?.trial.interval_unit]))

		}
	}

	const tableData = [
		{
			label: `${isProduct ?
				t.products.name
				: t.plans.name}:`,
			value: boleto.plan?.name || boleto.product?.name,
			boldValue: true,
			boldLabel: true,
		},
		(userInfo.name || userInfo.first_name)
			? {
				label: `${t.titles.customer}:`,
				value: userInfo.name || userInfo.first_name + " " + userInfo.last_name,
				boldValue: true,
				boldLabel: true
			}
			: null,
		(userInfo.email)
			? {
				label: `${t.checkout.email}:`,
				value: userInfo.email,
				boldValue: true,
				boldLabel: true
			}
			: null,

		(boleto.subscription?.code)
			? {
				label: `${t.generics.subscription}:`,
				value: boleto.subscription.code,
				boldValue: true,
				boldLabel: true
			}
			: null,
		(boleto?.pricing_option)
			? {
				label: `${t.label.frequency}:`,
				value: renderFrequency(boleto.pricing_option),
				boldValue: true,
				boldLabel: true,
				valueStyle: { textTransform: 'capitalize' }
			}
			: null,

		(boleto?.amount)
			? {
				label: `${t("^currencies.amount")}:`,
				value: toCurrency(boleto?.amount),
				boldValue: true,
				boldLabel: true,
				valueStyle: { textTransform: 'capitalize' }
			}
			: null,

		(checkoutInfo?.plan?.trial.interval > 0)
			? {
				label: `${t("^currencies.amount")}:`,
				value: renderTrialAmount(boleto),
				boldValue: true,
				boldLabel: true,
				valueStyle: { textTransform: 'capitalize' }
			}
			: null,
	]


	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div className={classes.root}>
			<div style={{ width: '100%', backgroundColor: primaryColor, padding: 5, marginBottom: 40 }}>
				<div className={classes.logo} />
			</div>

			<div className='flex-row center-a'>
				<div className='flex-col center-b' style={{ borderRadius: 25, border: '1px solid rgba(0,0,0,0.19)', width: '80vw', padding: '30px 15px', maxWidth: '1440px', margin: 40, marginTop: 0 }}>
					<div className='flex-col center-b' style={{ width: 'fit-content' }}>
						<SectionMain position="center">
							<SectionMain noPadding className={classes.icon}>
								<Icon size={72} icon={barcode} />
							</SectionMain>
						</SectionMain>
						<SectionMain position="center" direction="column" alignItems="center">
							<Title align="center">
								<span style={{ fontSize: 18 }}>
									{(userInfo.totalPaid.amount > 0)
										? isProduct
											? t.products.pay_boleto
											: t.messages["success_submit_boleto.subscription"]
										: "Assinatura concluída com sucesso!"}
								</span>
							</Title>
						</SectionMain>
						<br />
						<div style={{ paddingBottom: 15 }}>
							<Title align="center">
								<h3 style={{ margin: 0 }}>
									{t('^currencies.amount')}:
								</h3>
								<h2 style={{ margin: 0 }}>
									{toCurrency(boleto.amount, "BRL")}
								</h2>
							</Title>
						</div>

					</div>
					{userInfo.totalPaid.amount > 0 &&
						<div className='flex-col center-b' style={{ width: '80%' }}>
							<SectionMain position="center" direction="column" alignItems="center">
								<input className={classes.code} value={boleto.payment_number} id="boleto-code">

								</input>
							</SectionMain>
							<div style={{ marginBottom: '15px' }}>
								<Button
									onClick={copyCheckoutLinkToClipboard}
									startIcon={<FileCopyIcon htmlColor={primaryColor} />}
									className={classes.button}
									variant="text"
									color="primary"
								>
									{t.checkout.copy_code}
								</Button>
							</div>
							<div style={{ marginBottom: '15px' }}>
								<a target="_blank" href={boleto.link} style={{ textDecoration: 'none' }}>
									<Button
										// onClick={getBoleto}
										className={classes.button}
										color="primary"
										variant="text"
										startIcon={<GetAppIcon htmlColor={primaryColor} />}
									>
										{t.checkout.download_boleto}
									</Button>
								</a>
							</div>
							<br />

						</div>
					}
					{checkoutInfo.plan?.trial.interval > 0 &&
						<>
							<h3 style={{ margin: 0 }}>
								{t.generics.from_day} <b>{renderDate(checkoutInfo.plan.trial.next_charge)}</b>, {t(
									"^messages.trial_interval.charge",
									toCurrency(
										checkoutInfo.plan.amount,
										checkoutInfo.plan.currency
									),
									t.frequency_interval_unit[
									checkoutInfo.plan.recurrence.frequency
									]
								)}
							</h3>
							<br />
						</>
					}

					<div className='flex-col center-b' style={{ width: 'fit-content' }}>

						<CompactTable data={tableData} noCommas noTitle tableStyle={{ width: '100%', margin: '15px 0 0 0' }} rowStyle={{ color: "rgba(0, 0, 0, 0.8)" }} />

						<SectionMain position="center" direction="column" alignItems="center">
							<span style={{ fontSize: 18, maxWidth: 300, textAlign: 'center' }}>
								{t('^messages.send_invoice.subscription', "userInfo.email")}
							</span>
						</SectionMain>

						{checkoutInfo && checkoutInfo.checkout_config.checkout_success_instructions &&
							<div style={{ padding: 15 }}>
								<SectionMain position="center" direction="column" alignItems="center">
									<span style={{ fontSize: 18, whiteSpace: 'pre-wrap' }}>
										{renderCheckoutSuccessInfo()}
									</span>
								</SectionMain>
							</div>
						}
					</div>
				</div>
			</div>

		</div >
	)
}

export default SuccessBoleto
