import React from "react"
import Col from "antd/lib/col"

const spans = {
	lg: 24,
	md: 12,
	sm: 6,
	xs: 3
}

const makeColFromSpan = size => ({
	children,
	offset,
	offXs,
	offSm,
	offMd,
	offLg,
	offXl,
	spanSize,
	textHeight,
	fixedCol,
	fixedMobile,
	color,
	colStyle,
	propPadding,
	pull,
	push,
	id,
	...props
}) => (
	<Col
		id={id}
		offset={offset}
		pull={pull}
		push={push}
		xs={{ offset: offXs, span: fixedCol || 24 }}
		sm={{ offset: offSm, span: fixedCol || 24 }}
		md={{ offset: offMd, span: fixedCol || 24 }}
		lg={{ offset: offLg, span: spanSize || spans[size] }}
		xl={{ offset: offXl, span: spanSize || spans[size] }}
		style={{
			backgroundColor: color,
			paddingTop: propPadding && "1.2em",
			...colStyle
		}}>
		<span style={{ height: textHeight }} {...props}>
			{children}
		</span>
	</Col>
)

export const Lg = makeColFromSpan("lg")
export const Md = makeColFromSpan("md")
export const Sm = makeColFromSpan("sm")
export const Xs = makeColFromSpan("xs")
