import React from 'react'
import api from '../../../../core/api'
import ListSalesWrapper from './ListSalesWrapper'
import ListSalesUI from './ListSalesUI'

export const ListSales = ({
	subscriptionId = null
}) => {
	return (
		<ListSalesWrapper subscriptionId={subscriptionId}>{({ sale }) => <ListSalesUI sale={sale} />}</ListSalesWrapper>
	)
}
