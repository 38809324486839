import React, { useState, useEffect } from 'react'
import api from '../../../../../core/api'
import SectionMain from '../../../../../layout/SectionMain'

const CardSubscriptionWrapper = ({
	subscription,
	type = 'default',
	handleToggle,
	handleSubscription,
	formikProps,
	history,
	onCancelSubscritption,
	children
}) => {
	const [ sales, setSales ] = useState([])

	const fetchSales = async () => {
		try {
			await api.get(`/subscriptions/${subscription.subscription_id}/sales`).then((res) => {
				const sales = res.data.items
				setSales(sales)
			})
		} catch (e) {
			console.log({ e })
		}
	}

	useEffect(() => {
		if (type === 'details') {
			fetchSales()
		}
	}, [])

	return (
		<SectionMain noPadding>
			{children({
				subscription,
				type,
				handleToggle,
				handleSubscription,
				formikProps,
				history,
				sales,
				onCancelSubscritption
			})}
		</SectionMain>
	)
}

export default CardSubscriptionWrapper
