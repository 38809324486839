import React from 'react'

const CheckoutContext = React.createContext({
    checkout: null,
    shippingAddress: false,
    onSubmit() {},
    setCheckoutState() {}
})

export const useCheckoutContext = () => React.useContext(CheckoutContext)

export default CheckoutContext