import React, { useState, useEffect } from 'react'
import { TitleSmall } from '../../../components/checkout-1.5.4/forms/Title'
import SectionMain from '../../../layout/SectionMain'
import api from '../../../core/api'
import Loader from '../../../components/Loader'
import ParticlesBg from 'particles-bg'
import { lightOrDark } from '../../../utils/lightOrDark'
import { Dialog, DialogTitle, DialogContent, Button, CircularProgress, Link, makeStyles } from '@material-ui/core'
import TextInput from '../../../components/checkout-1.5.4/forms/TextInput'
import { Formik } from 'formik'
import { string, object } from 'yup' // for only what you need
import { useSnackbar } from 'notistack'
import { useTranslation } from '../../../components/IntlContext/IntlContext'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { CheckoutService } from '../../../core/CheckoutService'

const useStyles = makeStyles(() => ({
	root: {
		marginTop: -100
	}
}))

/**@returns {import("../../../locale/locale").TranslationFunction} */

const ForgotPage = ({ history }) => {
	const [company, setCompany] = useState('')
	const [response, setResponse] = useState('')
	const [showPassword, setShowPassword] = useState(false)
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const t = useTranslation()

	const fetchCompanyConfig = async () => {
		try {
			await api.get('/company-config').then((res) => {
				const company = res.data
				setCompany(company)
			})
		} catch (e) {
			if (e.message.includes('Network')) {
				setResponse(t.errors['get_company_config.network_error'])
			}
			console.log({ e })
		}
	}

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword)
	}

	const handleMouseDownPassword = (event) => {
		event.preventDefault()
	}

	const validationSchema = () =>
		object().shape({
			email: string().email(t.forms_validation.email_valid).required(t.forms_validation.email_required)
		})

	const onSubmit = async (values) => {
		const form = { email: values.email.toLowerCase() }

		try {
			await api.post(`/login/forgot`, form).then((res) => {
				if (res.status === 200) {
					enqueueSnackbar(
						<span>
							{t.messages['send_confirm.forgot']}
							<strong>&nbsp;{form.email}</strong>,&nbsp;
							{t.messages['send_confirm_email.forgot']}
						</span>,
						{
							variant: 'success',
							autoHideDuration: 50000
						}
					)
					setResponse(res)
				} else {
					throw res
				}
			})
		} catch (e) {
			console.log({ e })

			if (e.response.status === 401) {
				enqueueSnackbar(t.errors['login.invalid_user_error'], {
					variant: 'error'
				})
			} else {
				enqueueSnackbar(t.errors['login.server_error'], {
					variant: 'error'
				})
			}
		}
	}

	useEffect(() => {
		fetchCompanyConfig()
	}, [])

	if (!company) {
		return (
			<div
				className="flex-col center-a center-b"
				style={{
					width: '100vw',
					height: '100vh'
				}}
			>
				<Loader />
				{response && <h3 style={{ color: 'red', textAlign: 'center' }}>{response}</h3>}
			</div>
		)
	}

	return (
		<SectionMain style={{ overflow: 'hidden', height: '100vh' }} noPadding position="center">
			<style>
				{`#banner {
	opacity: 0.5;
	background: ${company.style.primary_color}; /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, ${company.style.primary_color}, ${company.style
						.primary_color}66); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, ${company.style.primary_color}, ${company.style.primary_color}66);
}
.MuiBackdrop-root{
    background-color: transparent !important;
}
`}
			</style>
			<Formik
				initialValues={{ email: '' }}
				validationSchema={validationSchema}
				onSubmit={(values) => onSubmit(values)}
			>
				{(formikProps) => (
					<Dialog open={true} PaperProps={{ elevation: 3 }} style={{ borderRadius: 12 }} className={classes.root}>
						{response.status === 200 ? (
							<SectionMain
								position="center"
								alignItems="center"
								direction="column"
								noPadding
								style={{ padding: 20 }}
							>
								<SectionMain noPadding position="center">
									<CheckCircleIcon
										style={{
											fontSize: 150,
											color: 'rgba(0, 230, 64, 0.8)'
										}}
									/>
								</SectionMain>
								<SectionMain position="center">
									<TitleSmall bold align="center" color="#282828">
										{t.messages['send_confirm_email.forgot']}
									</TitleSmall>
								</SectionMain>
								<Link href="/login"> {t.navigation_menu['go_login']}</Link>
							</SectionMain>
						) : (
							<form action="javascript:void(0)" onSubmit={formikProps.handleSubmit}>
								<DialogTitle>
									<SectionMain position="center" noPadding>
										<div
											style={{
												backgroundImage: `url(${company.style.url_logo_default})`,
												backgroundSize: 'contain',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												width: '100%',
												height: '10vw',
												maxHeight: 100,
												marginBottom: 10
											}}
										/>
										<TitleSmall align="center" color={company.style.primary_color} fontSize={18}>
											{t.titles.customer_portal}
										</TitleSmall>
									</SectionMain>
								</DialogTitle>
								<DialogContent>
									<SectionMain position="center" noPadding>
										<SectionMain position="center" noPadding>
											<TitleSmall color="#282828">
												{t.messages['forgot_message.forgot']}
											</TitleSmall>
										</SectionMain>
										<SectionMain>
											<TextInput
												label="*E-mail"
												errorMessage={formikProps.touched.email && formikProps.errors.email}
												value={formikProps.values.email}
												autoComplete="email"
												onChange={(e) => formikProps.setFieldValue('email', e.target.value)}
												autoFocus
												helperText={
													formikProps.touched.email && (
														<span style={{ color: 'red' }}>{formikProps.errors.email}</span>
													)
												}
											/>
										</SectionMain>
										<SectionMain>
											<Button
												fullWidth
												type="submit"
												variant="contained"
												color="primary"
												disabled={formikProps.isSubmitting}
												style={{
													color:
														company.style.primary_color &&
															lightOrDark(company.style.primary_color) === 'light'
															? '#282828'
															: '#fff',
													alignItems: 'center',
													backgroundColor: company.style.primary_color && company.style.primary_color
												}}
											>
												{formikProps.isSubmitting && (
													<CircularProgress
														size={24}
														color="inherit"
														style={{ marginRight: 8 }}
													/>
												)}
												{t.generics.send}
											</Button>
										</SectionMain>
									</SectionMain>
								</DialogContent>
							</form>
						)}
					</Dialog>
				)}
			</Formik>
		</SectionMain>
	)
}

export default ForgotPage
