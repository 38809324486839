import React, { useState } from "react";
import useMsgBox from "../components/SweetAlert";
import { CheckoutService } from "../core/CheckoutService";
import { Button, TextField } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { TitleSmall } from "../components/checkout-1.5.4/forms/Title";
import Loader from "../components/Loader";
import { useTranslation } from "../components/IntlContext/IntlContext";
import TextInput from "../components/checkout-1.5.4/forms/TextInput";
import AppContext, { useAppContext } from "../components/AppContextCheckout";
import { TrackGoogleAnalyticsEvent } from "../utils/google-analytics";

const CouponForm = ({
    coupon = {},
    setCoupon = () => null,
    totalAmount = 0,
    setTotalAmount = () => null,
    couponLoading = false,
    setCouponLoading = () => null,
    checkoutConfig,
    productHash,
    quantity,
    disabled,
    hash
}) => {

    const msg = useMsgBox()
    const t = useTranslation()

    const [oldValue, setOldValue] = useState(totalAmount)
    const { setAppState, coupon: couponState } = useAppContext(AppContext);


    function isBackgroundDark(hexcolor = "#000000") {
        hexcolor = hexcolor.replace("#", "");
        var r = parseInt(hexcolor.substr(0, 2), 16);
        var g = parseInt(hexcolor.substr(2, 2), 16);
        var b = parseInt(hexcolor.substr(4, 2), 16);
        var yiq = (r * 299 + g * 587 + b * 114) / 1000;
        return yiq < 128;
    }


    const onCouponSubmit = async () => {
        if (!coupon.value) return
        setCouponLoading(true)


        try {
            const data = productHash
                ? await CheckoutService.Products.ValidateCoupon({ code: coupon.value, hash: productHash, quantity })
                : await CheckoutService.ValidateCoupon({ code: coupon.value, hash })
            setCoupon({
                id: data.coupon_id,
                code: coupon.value,
                discount_amount: data.discount_amount,
                total_amount: data.total_amount,
                tax_amount: data.tax_amount,
                next_charge: data.next_charge
            })

            setAppState({ coupon: { ...couponState, id: data.coupon_id } })
            TrackGoogleAnalyticsEvent("Checkout", "add_coupon", coupon.value);

            setTotalAmount(data.total_amount)
        } catch (error) {
            TrackGoogleAnalyticsEvent("Checkout", "add_coupon_error", coupon.value);
            msg.err({ title: 'Erro', text: (t(`^errors.coupons.${error.response.data.parameter.split('.')[1]}`) || t.errors['coupons.generic_error']) })
        }

        setCouponLoading(false)

    }

    const cancelCoupon = async () => {

        setCoupon({
            id: null,
            code: null,
            value: "",
            discount_amount: 0,
            total_amount: 0,
            tax_amount: 0,
            next_charge: null
        })

        TrackGoogleAnalyticsEvent("Checkout", "remove_coupon", coupon.code);

        setTotalAmount(oldValue)

        setAppState({ coupon: { ...couponState, id: null } })


        return
    }

    return (
        <div className="flex-col" style={{
            width: "100%",
            opacity: !couponState.enabled ? 0.4 : 1,
            pointerEvents: !couponState.enabled && 'none'
        }}>
            <span style={{ margin: '10px 0 10px 0', textTransform: "uppercase", fontWeight: "bold" }}>
                {t.forms.discount_coupon}:
            </span>
            {coupon.code
                ? <div className="flex-row center-b" style={{ justifyContent: "space-between" }}>
                    <span>
                        {coupon.code}
                    </span>
                    <IconButton color="primary" component="span" onClick={() => cancelCoupon()} disabled={disabled}>
                        <CloseIcon />
                    </IconButton>

                </div>
                : (<>
                    {/* <TextField fullWidth variant="outlined" value={coupon.value} onChange={e => {
                        let value = e.target.value.toUpperCase()
                        value = value.replace(" ", "")
                        setCoupon({
                            ...coupon, value
                        })
                    }} label="Código do cupom"></TextField> */}
                    <TextInput
                        placeholder={t.titles["coupon.form"]}
                        fullWidth
                        disabled={disabled}
                        errorMessage={coupon.coupon_error}
                        value={coupon.value}
                        inputProps={{ 'data-cy': `cy-input-${'coupon'}` }}
                        onChange={e => {
                            let value = e.target.value.toUpperCase()
                            value = value.replace(" ", "")
                            setCoupon({
                                ...coupon, value
                            })
                        }}
                        rigthElement={
                            <Button
                                onClick={() => onCouponSubmit()}
                                style={{
                                    backgroundColor: checkoutConfig.company?.style.primary_color
                                        ? checkoutConfig.company?.style.primary_color
                                        : '#fff',
                                    color: isBackgroundDark(checkoutConfig.company?.style.primary_color || '#fff') ? 'white' : 'black'
                                }}
                                variant="contained"
                                color="primary"
                                disabled={disabled}
                                size="small"
                            >
                                {couponLoading &&
                                    <Loader size={20} white />
                                }
                                {t.buttons["checkout.validate"]}
                            </Button>
                        }
                    />
                </>)}
            {!productHash &&
                <TitleSmall>
                    {t.messages["coupon.discount"]}
                </TitleSmall>
            }
        </div >
    )
}

export default CouponForm