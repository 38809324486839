/* eslint-disable */
/**
 *
 * @param {"DATE" | "DATETIME" | "BIRTHDAY"} format
 */
export default function useLocalDateFunction(format = 'DATETIME') {
	// const { user } = useContext(AppContext)

	const timezone = {
		name_identifier: 'E. South America Standard Time',
		display_name: '(UTC-03:00) Brasilia',
		standard_name: 'E. South America Standard Time',
		utc_offset: '-03:00:00'
	}
	const locale = 'pt-br'

	const { utc_offset } = timezone

	const offset = +utc_offset.match(/^[\+|\-]?\d+/)[0] * 1000 * 60 * 60

	const localeFuncCfg = { timeZone: 'UTC' }

	return function(date) {
		const computed_date = new Date(+new Date(date) + offset)
		// console.log(utc_offset.match(/^[\+|\-]?\d+/))
		// console.log(`${date} --> ${computed_date.toUTCString()}`)
		switch (format) {
			case 'DATETIME':
				return computed_date.toLocaleString(locale, localeFuncCfg)
			case 'DATE':
				return computed_date.toLocaleDateString(locale, localeFuncCfg)
			case 'BIRTHDAY':
				return new Date(date).toLocaleDateString(locale, localeFuncCfg)
			default:
				throw new Error('Invalid date format')
		}
	}
}
