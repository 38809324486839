const getStatusColor = (status) => {
	switch (status) {
		case "active":
			return "#009621"
		case "expired":
		case "pending":
		case "suspended":
		case "paused":
			return "#9E9E9E"
		case "trial":
		case "created":
			return "#35C2FF"
		case "paid":
			return "#3DDB47"
		case "unpaid":
		case "canceled":
		case "pending_cancellation":
			return "#FF3F3F"
		case "past_due":
		case "waiting_payment":
		case "review":
			return "#FCE041"
		default:
			return "#282828"
	}
}

export default getStatusColor
