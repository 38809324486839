import React from 'react'
import SectionMain from '../../../layout/SectionMain'

const NotFound = ({ company, messageError }) => {
	if (!company) {
		return ''
	}

	return (
		<SectionMain style={{ overflow: 'hidden', height: '60vh' }} noPadding position="center">
			<style>
				{`#banner {
                        opacity: 0.5;
                        background: ${company.style.primary_color}; /* fallback for old browsers */
                        background: -webkit-linear-gradient(to right, ${company.style.primary_color}, ${company.style
					.primary_color}66); /* Chrome 10-25, Safari 5.1-6 */
                        background: linear-gradient(to right, ${company.style.primary_color}, ${company.style
					.primary_color}66);
                            }
                        .MuiBackdrop-root{
                        background-color: transparent !important;
                            }
                `}
			</style>
			<SectionMain position="center" noPadding style={{ paddingTop: 100 }}>
				<div
					style={{
						backgroundImage: `url(${company.style.url_logo_default})`,
						backgroundSize: 'contain',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						width: '100%',
						height: '10vw',
						maxHeight: 100
					}}
				/>
				<SectionMain position="center" xs={10}>
					<div className="flex-col center-a center-b">
						<h2 style={{ color: company.style.primary_color, textAlign: 'center' }}>{messageError}</h2>
					</div>
				</SectionMain>
			</SectionMain>
		</SectionMain>
	)

	return <div />
}

export default NotFound
