import React from 'react'
import { Avatar, IconButton, Menu, MenuItem, makeStyles } from '@material-ui/core'
import { TitleSmall } from '../../../components/checkout-1.5.4/forms/Title'
import Title from 'antd/lib/skeleton/Title'
import { useTranslation } from '../../../components/IntlContext/IntlContext'
import { useHistory } from 'react-router-dom'

/**@returns {import("../../../locale/locale").TranslationFunction} */

const useStyles = makeStyles({
	avatar: {
		width: 35,
		height: 35
	}
})

const UserAvatar = ({ user, noName }) => {
	const classes = useStyles()
	const [ anchorEl, setAnchorEl ] = React.useState(null)
	const t = useTranslation()
	const history = useHistory()

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	if (user)
		return (
			<div className="flex-row center-b">
				<TitleSmall
					color="#282828"
					style={{ paddingRight: 5, display: noName ? 'none' : 'flex', textTransform: 'capitalize' }}
				>{`${user.first_name}`}</TitleSmall>

				<IconButton size="small" onClick={handleClick}>
					<Avatar className={classes.avatar}>{user.first_name[0].toUpperCase()}</Avatar>
				</IconButton>
				<Menu anchorEl={anchorEl} id="simple-menu" keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
					<MenuItem
						onClick={() => {
							handleClose()
							history.push('/profile')
						}}
					>
						<TitleSmall>{t.navigation_menu.profile}</TitleSmall>
					</MenuItem>
					<MenuItem
						onClick={() => {
							window.localStorage.setItem('token', '')
							handleClose()
							window.location = '/login'
						}}
					>
						<TitleSmall>{t.generics['exit']}</TitleSmall>
					</MenuItem>
				</Menu>
			</div>
		)
	return ''
}

export default UserAvatar
