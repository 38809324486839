import React from "react"
import { makeStyles, Paper } from "@material-ui/core"
import SectionMain from "../../../../layout/SectionMain"

const ContainerUI = ({ children, position, alignItems, ...props }) => {
	return (
		<Paper
			style={{
				display: "flex",
				width: "100%",
				padding: 20,
				minHeight: 72,
				justifyContent: position,
				alignItems,
			}}
			{...props}
		>
			<SectionMain noPadding>{children}</SectionMain>
		</Paper>
	)
}

export default ContainerUI
