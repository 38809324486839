import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from '../components/IntlContext/IntlContext';
import FormAccordion from '../components/Accordion';
import { Formik } from 'formik';
import { FormikValidationsCustomer, FormikValidationsCCPayment, FormikValidationsCustomerFR } from '../components/checkout-1.5.4/forms/RenderInputsV4/FormikValidations';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { AiOutlineBarcode } from 'react-icons/ai'
import RenderInputsV4 from '../components/checkout-1.5.4/forms/RenderInputsV4';
import { TextField, Button, FormControlLabel, Checkbox, Select, FormLabel, FormControl, InputLabel, Box, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { CheckoutService } from '../core/CheckoutService';
import useMsgBox from '../components/SweetAlert';
import Loader from '../components/Loader';
import PersonIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import SectionMain from '../layout/SectionMain';
import toCurrency from '../core/toCurrency';
import TestForm from '../core/randomize';
import AppContext, { useAppContext } from '../components/AppContextCheckout';
import RenderPayzenForm from '../components/checkout-1.5.4/forms/PayzenForm';
import { billService } from '../core/api';
import logoSEPA from '../assets/sepa-logo.png'
import Typography from '@material-ui/core/Typography';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import ReCaptcha from 'react-google-recaptcha'


let _ = require('lodash');



const FRForm = ({
    checkoutConfig,
    setSuccessPage = () => null,
    totalAmount = 0,
    couponId = null
}) => {
    const t = useTranslation()
    const msg = useMsgBox()
    const [formStep, setFormStep] = useState('customer')
    const [customer, setCustomer] = useState({})
    const [loadingState, setLoadingState] = useState({
        customer: false,
        payment: false
    })
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [sddInputs, setSddInputs] = useState([]);
    const { setAppState, payzenForm, coupon } = useAppContext(AppContext);
    const [showIFrame, setShowIFrame] = useState(false)

    const [captchaChecked, setCaptchaChecked] = useState(false)
    const isSubscriptionCustomEnabled = checkoutConfig.checkout_config.subscription_custom_fields?.filter(field => field.is_enabled === true).length > 0

    const refCaptcha = useRef()

    const fetchSddForm = async (subscription_id) => {
        const qs = new URLSearchParams(window.location.search);
        const hash = qs.get("hash");

        try {
            billService
                .get(
                    `/checkout/sdd/form?hash=${hash}&subscriptionId=${subscription_id}&isSHA256=true`
                )
                .then((res) => {
                    setSddInputs(res.data);
                });
        } catch (e) {
            setSddInputs([]);
            console.log({ e });
        }
    };


    const onCustomerSubmit = async (form) => {
        setIsSubmitting(true)

        setLoadingState({ ...loadingState, customer: true })
        setAppState({
            coupon: {
                ...coupon,
                enabled: false
            }
        })

        const other_documents = []

        let customerCustomFieldsValues = checkoutConfig.checkout_config.customer_custom_fields ? [
            ...checkoutConfig.checkout_config.customer_custom_fields?.map(field => field.is_enabled && form[`customer_${field.key}`] ? {
                key: field.key,
                value: (field.type === 'date') ? form[`customer_${field.key}`].format("YYYY-MM-DD[T00:00:00Z]") : form[`customer_${field.key}`]
            }
                : null)
        ] : []

        let subscriptionCustomFieldsValues = checkoutConfig.checkout_config.subscription_custom_fields ? [
            ...checkoutConfig.checkout_config.subscription_custom_fields?.map(field => field.is_enabled && form[`subscription_${field.key}`] ? {
                key: field.key,
                value: (field.type === 'date') ? form[`subscription_${field.key}`].format("YYYY-MM-DD[T00:00:00Z]") : form[`subscription_${field.key}`]
            }
                : null)
        ] : []


        customerCustomFieldsValues = customerCustomFieldsValues?.filter(v => v !== null)
        subscriptionCustomFieldsValues = subscriptionCustomFieldsValues?.filter(v => v !== null)

        const customer = {
            first_name: form.first_name,
            last_name: form.last_name,
            email: form.email,
            mobile_number: form.mobile_phone,
            gender: "",
            document: checkoutConfig.checkout_config.document
                ? {
                    type: form.document_type_fr,
                    number: form.document_number.match(/\d+/g).join("")
                } : null,
            billing_address: checkoutConfig.checkout_config.checkout_fields.billing_address ? {
                postal_code: form.billing_address_postal_code,
                street: form.billing_address_street1,
                street2: form.billing_address_street2,
                number: form.billing_address_number,
                state: form.billing_address_state,
                district: form.billing_address_district,
                city: form.billing_address_city,
                country_code: form.billing_address_country_code,
                complement: form.billing_address_complement,
            } : null,
            shipping_address: checkoutConfig.checkout_config.checkout_fields.shipping_address ? [{
                postal_code: form.shipping_address_postal_code,
                street: form.shipping_address_street1,
                street2: form.shipping_address_street2,
                number: form.shipping_address_number,
                state: form.shipping_address_state,
                district: form.shipping_address_district,
                city: form.shipping_address_city,
                country_code: form.shipping_address_country_code,
                complement: form.shipping_address_complement,
                is_main: false
            }] : null,
            other_documents,
            custom_fields: customerCustomFieldsValues
        }
        const subscription = {
            custom_fields: subscriptionCustomFieldsValues
        }

        const qs = new URLSearchParams(window.location.search);
        const hash = qs.get("hash");

        try {
            const recaptchaToken = ['develop', 'homolog'].includes(process.env.REACT_APP_ENVIRONMENT) ? null : await refCaptcha.current.executeAsync()
            const response = await CheckoutService.Subscribe({ customer, subscription, hash, coupon_id: coupon.id, session_token: checkoutConfig.session_token, recaptchaToken })
            setCustomer({ ...response, ...customer })
            if (checkoutConfig.use_form_token) {
                setAppState({
                    payzenForm: { form_token: response.form_token, public_key: response.public_key },
                });
            }
            if (checkoutConfig.plan.payment_methods.includes("sdd")) fetchSddForm(response.subscription_id);

        } catch (e) {
            msg.err({ title: "Erro", text: t.errors[e.response.data.parameter] || t.errors['generic.post'] })
            setLoadingState({ ...loadingState, customer: false })
            setAppState({
                coupon: {
                    ...coupon,
                    enabled: false
                }
            })
            setIsSubmitting(false)
            return
        }


        setIsSubmitting(false)
        setFormStep('payment')
        setExpanded({
            ...expanded,
            customer: false,
            billing: false,
            shipping: false,
            additional_info: false,
            payment: true,
            credit_card: true,
        });

        setLoadingState({ ...loadingState, customer: false })

    }


    const onCCPaymentSubmit = async (form) => {
        setIsSubmitting(true)
        setLoadingState({ ...loadingState, payment: true })

        const [MM, YY] = form.cc_expiry_date.split("/")

        const expiry_date = `${MM}/20${YY}`

        const payment = {
            payment_mean: {
                number: form.cc_number,
                holder: form.cc_cardholder,
                expiry_date,
                cvv: form.cvv,
                brand: form.cc_brand,
                type: "credit_card"
            },
            installment: checkoutConfig.plan.installments?.length > 0 ? form.installments : null,
            customer: {
                customer_id: customer.customer_id
            },
            subscription_id: customer.subscription_id
        }

        const qs = new URLSearchParams(window.location.search);
        const hash = qs.get("hash");

        try {
            await CheckoutService.Pay({ payment, hash, session_token: checkoutConfig.session_token })
            return setSuccessPage({ type: "credit_card", customer, installment: form.installments })
        } catch (e) {
            console.log(e)
            msg.err({ title: "Erro", text: (t(`^errors.${e.response.data.parameter}`) || t.errors['generic.post']) })
            setIsSubmitting(false)
            setLoadingState({ ...loadingState, payment: false })

        }
        setLoadingState({ ...loadingState, payment: false })
        setIsSubmitting(false)
    }



    function isBackgroundDark(hexcolor = "#000000") {
        hexcolor = hexcolor.replace("#", "");
        var r = parseInt(hexcolor.substr(0, 2), 16);
        var g = parseInt(hexcolor.substr(2, 2), 16);
        var b = parseInt(hexcolor.substr(4, 2), 16);
        var yiq = (r * 299 + g * 587 + b * 114) / 1000;
        return yiq < 128;
    }

    const disabledInputsCustomer = () => {
        if (!checkoutConfig.customer) return {}
        let initialValues = {
            first_name: Boolean(checkoutConfig.customer.first_name),
            last_name: Boolean(checkoutConfig.customer.last_name),
            mobile_phone: Boolean(checkoutConfig.customer.mobile_number),
            email: Boolean(checkoutConfig.customer.email),
            country_code: Boolean(checkoutConfig.customer.country_code),
            document_type_fr: Boolean(checkoutConfig.customer.document.type),
            document_number: Boolean(checkoutConfig.customer.document.number),
            billing_address_postal_code: Boolean(checkoutConfig.customer.billing_address?.postal_code),
            billing_address_street1: Boolean(checkoutConfig.customer.billing_address?.street),
            billing_address_number: Boolean(checkoutConfig.customer.billing_address?.number),
            billing_address_state: Boolean(checkoutConfig.customer.billing_address?.state),
            billing_address_district: Boolean(checkoutConfig.customer.billing_address?.district),
            billing_address_city: Boolean(checkoutConfig.customer.billing_address?.city),
            billing_address_country_code: Boolean(checkoutConfig.customer.billing_address?.country_code),
            billing_address_complement: Boolean(checkoutConfig.customer.billing_address?.complement),
            shipping_address_postal_code: Boolean(checkoutConfig.customer.shipping_address[0]?.postal_code),
            shipping_address_street1: Boolean(checkoutConfig.customer.shipping_address[0]?.street),
            shipping_address_number: Boolean(checkoutConfig.customer.shipping_address[0]?.number),
            shipping_address_state: Boolean(checkoutConfig.customer.shipping_address[0]?.state),
            shipping_address_district: Boolean(checkoutConfig.customer.shipping_address[0]?.district),
            shipping_address_city: Boolean(checkoutConfig.customer.shipping_address[0]?.city),
            shipping_address_country_code: Boolean(checkoutConfig.customer.shipping_address[0]?.country_code),
            shipping_address_complement: Boolean(checkoutConfig.customer.shipping_address[0]?.complement),
        }
        return initialValues
    }

    const customerInitialValues = () => {

        if (checkoutConfig.customer) {
            let initialValues = {
                first_name: checkoutConfig.customer.first_name,
                last_name: checkoutConfig.customer.last_name,
                mobile_phone: checkoutConfig.customer.mobile_number || "",
                email: checkoutConfig.customer.email,
                country_code: checkoutConfig.customer.country_code,
                document_type_fr: checkoutConfig.customer.document.type,
                document_number: checkoutConfig.customer.document.number,
                billing_address_postal_code: checkoutConfig.customer.billing_address?.postal_code,
                billing_address_street1: checkoutConfig.customer.billing_address?.street,
                billing_address_number: checkoutConfig.customer.billing_address?.number,
                billing_address_state: checkoutConfig.customer.billing_address?.state,
                billing_address_district: checkoutConfig.customer.billing_address?.district,
                billing_address_city: checkoutConfig.customer.billing_address?.city,
                billing_address_country_code: checkoutConfig.customer.billing_address?.country_code,
                billing_address_complement: checkoutConfig.customer.billing_address?.complement,
                shipping_address_postal_code: checkoutConfig.customer.shipping_address[0]?.postal_code,
                shipping_address_street1: checkoutConfig.customer.shipping_address[0]?.street,
                shipping_address_number: checkoutConfig.customer.shipping_address[0]?.number,
                shipping_address_state: checkoutConfig.customer.shipping_address[0]?.state,
                shipping_address_district: checkoutConfig.customer.shipping_address[0]?.district,
                shipping_address_city: checkoutConfig.customer.shipping_address[0]?.city,
                shipping_address_country_code: checkoutConfig.customer.shipping_address[0]?.country_code,
                shipping_address_complement: checkoutConfig.customer.shipping_address[0]?.complement,
            }
            return initialValues
        }

        let initialValues = {
            first_name: "",
            last_name: "",
            mobile_phone: "",
            email: "",
            country_code: "FRA",
            document_type_fr: 'CNI',
            document_number: "",
            billing_address_postal_code: "",
            billing_address_street1: "",
            billing_address_number: "",
            billing_address_state: "",
            billing_address_district: "",
            billing_address_city: "",
            billing_address_country_code: "",
            billing_address_complement: "",
            shipping_address_postal_code: "",
            shipping_address_street1: "",
            shipping_address_number: "",
            shipping_address_state: "",
            shipping_address_district: "",
            shipping_address_city: "",
            shipping_address_country_code: "",
            shipping_address_complement: "",
        }
        return initialValues
    }

    const CCInitialValues = () => {
        let initialValues = {
            cc_number: "",
            cc_cardholder: "",
            cc_expiry_date: "",
            cvv: "",
            brand: "",
            type: "",
            installments: checkoutConfig.plan.installments && checkoutConfig.plan.installments[0]

        }
        return initialValues
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <div className="fade-in">
                            {children}
                        </div>
                    </Box>
                )}
            </div>
        );
    }

    const [value, setValue] = React.useState(0);

    const checkForErrors = async (form, handleSubmit, formikprops, type) => {
        const values = type === 'credit_card'
            ? CCInitialValues()
            : customerInitialValues()
        Object.keys(values).forEach(v => {
            formikprops.setFieldTouched(v, true)
        })
        if (_.isEmpty(formikprops.errors)) return handleSubmit(form)
    }

    const [expanded, setExpanded] = useState({
        customer: true,
        billing: true,
        shipping: true,
        additional_info: true,
        payment: true,
        credit_card: true,
    })

    const handleExpand = (panel) => {
        if (panel === "credit_card") {
            return setExpanded({
                ...expanded,
                credit_card: !expanded.credit_card,
            });
        }

        return setExpanded({ ...expanded, [panel]: !expanded[panel] });
    };

    const AccordionCustomerConfig = {
        title: (<span className="flex-row center-b">
            <Avatar style={{ marginRight: 10, fontSize: 45, backgroundColor: checkoutConfig.company.style.primary_color }}>
                <PersonIcon style={{ fontSize: 35, color: isBackgroundDark(checkoutConfig.company.style.primary_color) ? 'white' : 'black' }} />
            </Avatar>
            {t.titles['customer.form']}
        </span>),
        name: 'customer',
        rowInputs: [
            ['first_name', 'last_name'],
            ['email'],
            (checkoutConfig.checkout_config?.country_code ? ['country_code'] : []),
            (checkoutConfig.checkout_config?.document ? ['document_type_fr', 'document_number'] : []),
            (checkoutConfig.checkout_config?.mobile_phone ? ['mobile_phone'] : [])
            ,
        ],
        expanded: expanded.customer,
        handleExpand: () => handleExpand('customer'),
        disabled: (formStep !== 'customer'),
        disabledInputs: disabledInputsCustomer(),
        TCenabled: (checkoutConfig.checkout_config.terms_conditions?.is_enabled && (!checkoutConfig.checkout_config?.checkout_fields.billing_address && !checkoutConfig.checkout_config?.subscription_custom_fields.length > 0)),
        loading: loadingState.customer,
        buttons: [
            ((!checkoutConfig.checkout_config?.checkout_fields.billing_address && !isSubscriptionCustomEnabled && formStep === 'customer') && {
                text: t.buttons['checkout.continue'],
                type: 'submit',
                disabled: isSubmitting
            })
        ],
        shadow: true
    }

    const AccordionAdditionalInfoConfig = {
        title: (<span className="flex-row center-b">
            <Avatar style={{ marginRight: 10, fontSize: 45, backgroundColor: checkoutConfig.company.style.primary_color }}>
                <PriorityHighIcon style={{ fontSize: 35, color: isBackgroundDark(checkoutConfig.company.style.primary_color) ? 'white' : 'black' }} />
            </Avatar>
            {t.titles['additional_info.form']}
        </span>),
        name: 'additional_info',
        rowInputs: [
            ['subscription_custom_fields']

        ],
        expanded: expanded.additional_info,
        handleExpand: () => handleExpand('additional_info'),
        disabled: (formStep !== 'customer'),
        disabledInputs: disabledInputsCustomer(),
        TCenabled: (checkoutConfig.checkout_config.terms_conditions?.is_enabled && (!checkoutConfig.checkout_config?.checkout_fields.billing_address)),
        loading: loadingState.customer,
        buttons: [
            ((!checkoutConfig.checkout_config?.checkout_fields.billing_address && formStep === 'customer') && {
                text: t.buttons['checkout.continue'],
                type: 'submit',
                disabled: isSubmitting
            })
        ],
        shadow: true
    }

    const AccordionBillingConfig = {
        title: (<span className="flex-row center-b">
            <AttachMoneyIcon style={{ paddingRight: 10, fontSize: 45 }} />
            {t.titles['billing_address.form']}
        </span>),
        name: 'billing',
        rowInputs: [
            ['billing_address_postal_code_fr'],
            ['billing_address_street1'],
            ['billing_address_street2'],
            ['billing_address_number', 'billing_address_district'],
            ['billing_address_city', 'billing_address_state'],
            ['billing_address_complement'],
        ],
        expanded: expanded.billing,
        handleExpand: () => handleExpand('billing'),
        disabled: (formStep !== 'customer'),
        TCenabled: (checkoutConfig.checkout_config.terms_conditions?.is_enabled && !checkoutConfig.checkout_config?.checkout_fields.shipping_address),
        buttons: [
            (!checkoutConfig.checkout_config?.checkout_fields.shipping_address && formStep === 'customer' && {
                text: t.buttons['checkout.continue'],
                type: 'submit',
                disabled: isSubmitting
            })
        ],
        shadow: true
    }

    const AccordionShippingConfig = {
        title: (<span className="flex-row center-b">
            <LocalShippingIcon style={{ paddingRight: 10, fontSize: 45 }} />
            {t.titles['shipping_address.form']}
        </span>),
        name: 'shipping',
        rowInputs: [
            ['shipping_address_postal_code_fr'],
            ['shipping_address_street1'],
            ['shipping_address_street2'],
            ['shipping_address_number', 'shipping_address_district'],
            ['shipping_address_city', 'shipping_address_state'],
            ['shipping_address_complement'],
        ],
        expanded: expanded.shipping,
        handleExpand: () => handleExpand('shipping'),
        disabled: (formStep !== 'customer'),
        TCenabled: (checkoutConfig.checkout_config.terms_conditions?.is_enabled),
        buttons: [
            (formStep === 'customer' && {
                text: t.buttons['checkout.continue'],
                type: 'submit',
                disabled: isSubmitting

            })
        ],
        shadow: true
    }

    function removeIframe() {
        var iframe = document.querySelector('iframe[name=sdd]');
        if (iframe) {
            iframe.remove();
        }
        return msg.warn({
            title: t.generics.attention,
            text: t.errors['sdd.session_expired']
        })
    }

    const AccordionPaymentConfig = {
        title: (<span className="flex-row center-b">
            <Avatar style={{ marginRight: 10, fontSize: 45, backgroundColor: checkoutConfig.company.style.primary_color }}>
                <AttachMoneyIcon style={{ fontSize: 35, color: isBackgroundDark(checkoutConfig.company.style.primary_color) ? 'white' : 'black' }} />
            </Avatar>
            {t.titles['payment_mean.form']}
        </span>),
        name: 'payment',
        style: {
            padding: 0
        },
        customRender: (

            <div style={{ width: '100%' }}>
                <div className="flex-row" style={{ backgroundColor: "#F6F6F6", width: '100%' }}>
                    {checkoutConfig.plan.payment_methods.map((method, index) => {
                        switch (method) {
                            case 'credit_card':
                                return (
                                    <div className={`flex-col payment-button ${value === index && "button-selected"} center-b`} style={{ opacity: isSubmitting ? "0.8" : "1", pointerEvents: isSubmitting && "none" }} role="button" onClick={() => setValue(index)}>
                                        <CreditCardIcon style={{ fontSize: 45 }} />
                                        {t.titles['payment_method.credit_card']}
                                    </div>

                                )
                            case 'sdd':
                                return (
                                    <div className={`flex-col payment-button ${value === index && "button-selected"} center-b`} style={{ opacity: isSubmitting ? "0.8" : "1", pointerEvents: isSubmitting && "none" }} role="button" onClick={() => setValue(index)}>
                                        <img src={logoSEPA} height={60} />
                                    </div>
                                )
                            default:
                                return
                        }
                    })}
                </div>


                {checkoutConfig.plan.payment_methods.map((method, index) => {
                    switch (method) {
                        case 'credit_card':
                            return (
                                <Formik
                                    initialValues={CCInitialValues()}
                                    validate={values => FormikValidationsCCPayment(values, checkoutConfig, t)}
                                    onSubmit={props => onCCPaymentSubmit(props)}
                                >
                                    {({
                                        handleSubmit,
                                        ...formikprops
                                    }) => (
                                        <Typography
                                            style={{ padding: 10 }}
                                            component="div"
                                            role="tabpanel"
                                            hidden={value !== index}
                                            id={`simple-tabpanel-${index}`}
                                            aria-labelledby={`simple-tab-${index}`}
                                        >
                                            <form onSubmit={(form) => checkForErrors(form, handleSubmit, formikprops, 'credit_card')} style={{ width: '100%' }} action="javascript:void(0)">
                                                <div className="flex-row center-a">
                                                    <div className="payment-fields">
                                                        {checkoutConfig.use_form_token ? (
                                                            <div className="flex-row center-a">
                                                                <div id="form-payzen">
                                                                    <div className='kr-smart-form' kr-card-form-expanded />
                                                                </div>
                                                                <RenderPayzenForm
                                                                    gatewayConfig={
                                                                        checkoutConfig.gateway_config
                                                                    }
                                                                    setSuccess={
                                                                        (clientAnswer) => {
                                                                            setSuccessPage({
                                                                                type: "credit_card", customer, data: clientAnswer
                                                                            })
                                                                        }
                                                                    }
                                                                    subscriptionId={
                                                                        customer.subscription_id
                                                                    }
                                                                    couponId={
                                                                        couponId
                                                                    }
                                                                    setIsSubmitting={setIsSubmitting}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {checkoutConfig.plan.installments?.length > 0 &&
                                                                    <div key={`row-`} className="flex-row xs-wrap">
                                                                        <div key={`input`} className="flex-col" style={{ width: '100%', margin: 10 }}>
                                                                            <FormControl variant="outlined" >
                                                                                <InputLabel id="demo-simple-select-required-label">{t.forms.installments}</InputLabel>
                                                                                <Select label={t.forms.installments} defaultValue={checkoutConfig.plan.installments && checkoutConfig.plan.installments[0]} name="installments" value={formikprops.values.installments} onChange={formikprops.handleChange} fullWidth variant="outlined">
                                                                                    {checkoutConfig.plan.installments.map(installment => (
                                                                                        <MenuItem value={installment}>
                                                                                            <SectionMain position="space-between">
                                                                                                <div>
                                                                                                    {`${installment}x`}
                                                                                                </div>
                                                                                                <FormLabel component="legend">
                                                                                                    {t.generics.in}{" "}
                                                                                                    {installment}x{" "}
                                                                                                    {toCurrency(totalAmount / installment, "EUR")}
                                                                                                </FormLabel>
                                                                                            </SectionMain>
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <RenderInputsV4
                                                                    formikprops={formikprops}
                                                                    checkoutConfig={checkoutConfig}
                                                                    rows={[
                                                                        ['cc_number'],
                                                                        ['cc_expiry_date', 'cvv'],
                                                                        ['cc_cardholder'],
                                                                    ]} />
                                                                {checkoutConfig?.checkout_config?.captcha?.active && captchaChecked == false &&
                                                                    <div className="flex-row center-a center-b" style={{ padding: '10px' }}>
                                                                        <ReCaptcha
                                                                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                                                            onChange={value => setCaptchaChecked(value)}
                                                                        />
                                                                    </div>
                                                                }

                                                                <div style={{ padding: "0 10px 0 10px" }}>
                                                                    <Button
                                                                        style={{
                                                                            backgroundColor: checkoutConfig.company?.style.primary_color ?
                                                                                checkoutConfig.company?.style.primary_color
                                                                                : '#fff',
                                                                            color: isBackgroundDark(checkoutConfig.company?.style.primary_color || '#fff') ? 'white' : 'black',
                                                                            width: '100%',
                                                                            height: 50,
                                                                            fontSize: 15
                                                                        }}
                                                                        disabled={checkoutConfig?.checkout_config?.captcha?.active && !captchaChecked}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        size="small"
                                                                        type='submit'
                                                                    >
                                                                        {loadingState.payment &&
                                                                            <span style={{ marginRight: "10px" }}>
                                                                                <Loader white size={30} />
                                                                            </span>
                                                                        }
                                                                        {t.buttons['checkout.paid']}
                                                                    </Button>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <TestForm checkoutConfig={checkoutConfig} value={value} formikprops={formikprops} formStep={formStep} />

                                            </form>
                                        </Typography>
                                    )}
                                </Formik>
                            )
                        case 'sdd':
                            return (
                                <Typography
                                    style={{ padding: 10 }}
                                    component="div"
                                    role="tabpanel"
                                    hidden={value !== index}
                                    id={`simple-tabpanel-${index}`}
                                    aria-labelledby={`simple-tab-${index}`}
                                >
                                    <div className="flex-row center-a" style={{ width: '100%' }}>
                                        <form
                                            id="sdd-form"
                                            method="POST"
                                            action="https://secure.payzen.eu/vads-payment/"
                                            target="sdd"
                                            autoComplete="new-password"
                                            onSubmit={() => {
                                                setIsSubmitting(true)
                                                setShowIFrame(true)
                                            }}
                                            className="flex-col center-b"
                                            style={{ width: '100%' }}
                                        >
                                            {sddInputs.map(
                                                ({ name, value, }) => {
                                                    return (
                                                        <input
                                                            key={name}
                                                            hidden
                                                            name={name}
                                                            value={value}
                                                        />
                                                    )
                                                }
                                            )}
                                            <div>
                                                {!showIFrame && <Button
                                                    target="sdd-form"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"

                                                >
                                                    {t.buttons["checkout.continue"]}
                                                </Button>}
                                            </div>
                                            {showIFrame && (
                                                <>
                                                    <iframe
                                                        id="sdd"
                                                        name="sdd"
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                            height: 600,
                                                            width: "100%",
                                                            display: "flex",
                                                            border: "none",
                                                        }}
                                                    />
                                                    <span style={{ display: "none" }}>
                                                        {setTimeout(() => removeIframe(), 600000)}
                                                    </span>
                                                </>
                                            )}
                                        </form>
                                    </div>
                                </Typography>
                            )
                        default:
                            return
                    }
                })}
            </div >
        ),
        expanded: expanded.payment,
        loading: loadingState.payment,
        handleExpand: () => handleExpand('payment'),
        shadow: true
    }

    useEffect(() => {
        if (checkoutConfig.customer) {
            setCustomer(checkoutConfig.customer)
        }
    }, [])

    return (
        <div className="flex-col" style={{ width: '100%' }}>
            <Formik
                initialValues={customerInitialValues()}
                validate={values => FormikValidationsCustomerFR(values, checkoutConfig, t)}
                onSubmit={props => onCustomerSubmit(props)}
            >
                {({
                    handleSubmit,
                    ...formikprops
                }) => (
                    <form onSubmit={(form) => checkForErrors(form, handleSubmit, formikprops, 'customer')} style={{ width: '100%' }} action="javascript:void(0)">
                        <FormAccordion config={AccordionCustomerConfig} checkoutConfig={checkoutConfig} formikprops={formikprops} />
                        {isSubscriptionCustomEnabled && <FormAccordion config={AccordionAdditionalInfoConfig} checkoutConfig={checkoutConfig} formikprops={formikprops} />}
                        {checkoutConfig.checkout_config?.checkout_fields.billing_address && <FormAccordion config={AccordionBillingConfig} checkoutConfig={checkoutConfig} formikprops={formikprops} />}
                        {checkoutConfig.checkout_config?.checkout_fields.shipping_address && <FormAccordion config={AccordionShippingConfig} checkoutConfig={checkoutConfig} formikprops={formikprops} />}
                        {/* <TestForm checkoutConfig={checkoutConfig} value={value} formikprops={formikprops} formStep={formStep} /> */}
                    </form>
                )}
            </Formik>
            <div className="flex-col center-a center-b" style={{ width: '100%' }}>
                <ReCaptcha
                    size="invisible"
                    ref={refCaptcha}
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                />
            </div>
            {formStep === 'payment' && <FormAccordion config={AccordionPaymentConfig} checkoutConfig={checkoutConfig} />}
        </div>
    )
}

export default FRForm