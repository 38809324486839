import React from "react"
import { Menu, MenuItem } from "@material-ui/core"
import { TitleSmall } from "../../../../components/checkout-1.5.4/forms/Title"
import { useTranslation } from "../../../../components/IntlContext/IntlContext"

/**@returns {import('../../../../locale/locale')} */

function MenuCard({
	id,
	anchorRef,
	onClose,
	handleSubscription,
	onCancelSubscription,
	history,
}) {
	const { id: menuId } = id
	const t = useTranslation()

	return (
		<Menu
			anchorEl={anchorRef}
			id={menuId}
			keepMounted
			open={Boolean(anchorRef)}
			onClose={() => onClose(null)}
		>
			<MenuItem
				onClick={() => {
					history.push(`/subscriptions/${id.subscription.subscription_id}`)
					onClose(id)
				}}
			>
				<TitleSmall>{t.titles["show_details.subscription"]}</TitleSmall>
			</MenuItem>
			{id.subscription &&
				id.subscription.payment_method === "credit_card" && (
					<MenuItem
						onClick={() => {
							history.push(
								`/edit-payment/${id.subscription.subscription_id}`
							)
							onClose(id)
						}}
					>
						<TitleSmall>
							{t.titles["change_payment_means.subscription"]}
						</TitleSmall>
					</MenuItem>
				)}

			{id.subscription && id.subscription.enable_user_cancelation && (
				<MenuItem
					onClick={() => {
						onClose(id)
						onCancelSubscription(
							id.subscription.subscription_id,
							id.subscription.plan.name
						)
					}}
				>
					<TitleSmall color="secondary">
						{t.titles["cancel.subscription"]}
					</TitleSmall>
				</MenuItem>
			)}
		</Menu>
	)
}

export default MenuCard
