import React, { useEffect, useState } from "react";
import { useTranslation } from "../components/IntlContext/IntlContext";
import SectionMain from "../layout/SectionMain";
import WrapperLayout from "../layout/WrapperLayout/index";
import BRForm from "./br";
import FRForm from "./fr";
import { TitleSmall } from "../components/checkout-1.5.4/forms/Title";
import Loader from "../components/Loader";
import { CheckoutService } from "../core/CheckoutService";
import _ from "lodash"
import SuccessPage from "../components/checkout-1.5.4/SuccessPage";
import SuccessBoleto from "../components/checkout-1.5.4/SuccessBoleto";
import SuccessBolecode from "../components/checkout-1.5.4/SuccessBolecode";
import toCurrency from "../core/toCurrency";
import RenderAmount from "../core/renderAmount";
import CouponForm from "../layout/coupon_form";
import CheckoutPage from "../checkout-new";
import { handleLocaleChange } from "../locale/handleLocaleChange";
import AppContext, { useAppContext } from "../components/AppContextCheckout";
import useLocalDateFunction from "../utils/useLocalDateFunction";
import SuccessPix from "../components/checkout-1.5.4/SuccessPix";
import getLocale from '../locale'
import { Icon } from '@iconify/react';
import { useCallback } from "react"
import { LocaleSelect } from "../components/LocaleSelect";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import formSchema from "../core/formSchema";
import InitializeGoogleAnalytics, { TrackGoogleAnalyticsEvent } from "../utils/google-analytics";
import useMsgBox from "../components/SweetAlert";
import ExitIntentModal from "../components/exit-intent-modal";
import CheckoutContext from "../components/checkouts/CheckoutContext";


const Checkout = ({ match }) => {

    const t = useTranslation()
    const renderDate = useLocalDateFunction('DATE')
    const textBG = `/images/test_mode_bg.jpg`
    let languageLabel = { BRA: "pt-BR", FRA: "fr-FR", CHL: "es-ES" };
    const [plan, setPlan] = useState(null)
    const [checkoutConfig, setCheckoutConfig] = useState(null)
    const [paymentLink, setPaymentLink] = useState(false)
    const [company, setCompany] = useState({
        style: {
            primary_color: "",
        },
    });

    const [couponLoading, setCouponLoading] = useState(false)
    const [coupon, setCoupon] = useState({
        id: null,
        code: null,
        value: "",
        discount_amount: 0,
        total_amount: 0,
        tax_amount: 0
    })

    const [totalAmount, setTotalAmount] = useState(0)
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(true)
    const [messageError, setMessageError] = useState('')
    const { setAppState, payzenForm, lang, language } = useAppContext(AppContext);


    const fetchCheckout = () => {
        setLoading(true)
        fetchCheckoutConfig()
    }

    const handleLocaleChange = useCallback(async lang => {
        const { locale } = await getLocale(lang)
        localStorage.setItem("locale", lang)
        setAppState({ language: lang, locale })
    }, [])

    const qs = new URLSearchParams(window.location.search);

    const fetchCheckoutConfig = async () => {
        setLoading(true)

        try {
            const hash = qs.get("hash") || match.params.id;
            await CheckoutService.Get({ hash })
                .then(async (checkout) => {
                    if (checkout.plan.amount === 0) return setMessageError(t.errors["hash.disabled"]);
                    if (checkout.customer && checkout.plan.payment_methods.includes('boleto')) checkout.is_payment_link = true
                    // checkInitialFormStep(checkout, checkout.company.country);
                    const list = await CheckoutService.GetDocumentTypes({ countryId: checkout.company.address.country_id, session_token: "" })
                    checkout.documents_list = list
                    checkout.checkout_config.customer_custom_fields = checkout.checkout_config.customer_custom_fields || []
                    checkout.checkout_config.subscription_custom_fields = checkout.checkout_config.subscription_custom_fields || []
                    setCheckoutConfig(checkout);
                    const date = new Date()
                    let today = date.getDay()
                    if (checkout.customer) setPaymentLink(true)

                    setCompany(checkout.company)
                    setPlan(checkout.plan)

                    if (checkout.plan.charge_only_setup) {
                        setTotalAmount(checkout.plan.setup_fee)
                    } else if (checkout.plan.trial.interval > 0) {
                        if (checkout.plan.setup_fee) {
                            setTotalAmount(checkout.plan.trial.amount + checkout.plan.setup_fee)
                        } else {
                            setTotalAmount(checkout.plan.trial.amount)
                        }

                    } else if (checkout.plan.pro_rata > 0) {
                        if (checkout.plan.setup_fee) {
                            setTotalAmount(checkout.plan.pro_rata + checkout.plan.setup_fee)
                        } else {
                            setTotalAmount(totalAmount + checkout.plan.pro_rata)
                        }

                    } else if (checkout.plan.setup_fee) {
                        setTotalAmount(checkout.plan.amount + checkout.plan.setup_fee)
                    } else {
                        setTotalAmount(checkout.plan.amount)
                    }
                    checkout.plan.payment_methods.sort((a, b) => {
                        if (a === "credit_card") {
                            return -1
                        } else return 1
                    })

                    if (checkout.use_form_token) {
                        checkout.plan.installments = null
                    }
                    handleLocaleChange((checkout.language || formSchema[checkout.company.address.country_code].language), setAppState);

                    let MEASUREMENT_ID

                    try {
                        MEASUREMENT_ID = checkout.integrations[0].analytics[0].data.measurement_id
                    } catch (e) {
                        console.log(e)
                    } finally {
                    }

                    InitializeGoogleAnalytics(MEASUREMENT_ID)

                    TrackGoogleAnalyticsEvent("Checkout", "load", checkout.plan.name);

                    return checkout
                })

        } catch (e) {

            console.log({ e })

            if (!e.response) {
                setMessageError(t.errors["hash.server_error"]);
                setLoading(false)
                return;
            }

            switch (e.response.status) {
                case 403:
                    setMessageError(t.errors["hash.used"]);
                    break;
                case 409:
                    setMessageError(t.errors["hash.disabled"]);
                    break;

                default:
                    setMessageError(t.errors["hash.server_error"]);
                    break;
            }
        }
        setLoading(false)

    }


    useEffect(() => {
        fetchCheckout()
    }, [])

    if (loading) return <div className="flex-row center-a center-b" style={{ height: '100vh' }}>
        <Loader />
    </div>

    // if (paymentLink) return (
    //     <CheckoutPage />
    // )

    if (messageError) return <SectionMain
        position="center"
        noPadding
        style={{ paddingTop: 100 }}
    >
        <div
            style={{
                backgroundImage: `url(${company.style.url_logo_default})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "10vw",
                maxHeight: 100,
            }}
        />
        <SectionMain position="center">
            <div className="flex-col center-a center-b">
                <Icon icon="gg:unavailable" color="rgba(0,0,0,0.25)" width="25rem" height="25rem" />
                <h2
                    style={{
                        color: 'rgba(0,0,0,0.50)',
                        textAlign: "center",
                    }}
                >

                    {messageError
                        ? messageError
                        : t.errors["generic"]}
                </h2>
                {t.errors["hash.server_error"] === messageError &&
                    < h2
                        style={{
                            color: 'rgba(0,0,0,0.50)',
                            textAlign: "center",
                        }}
                    >

                        {t.errors.page_expired}
                    </h2>
                }
            </div>
        </SectionMain>
    </SectionMain >

    if (success) {
        if (success.type === 'credit_card') return (
            <SuccessPage
                checkoutInfo={checkoutConfig}
                userInfo={{ ...success.customer, totalPaid: { amount: totalAmount, currency: plan?.currency, installment: success.installment } }}
                logo={checkoutConfig.company && checkoutConfig.company.style.url_logo_default}
                primaryColor={checkoutConfig.company && checkoutConfig.company.style.primary_color}
                success_info={success.data}
            />
        )
        if (success.type === 'boleto') return (
            <SuccessBoleto
                checkoutInfo={checkoutConfig}
                userInfo={{ ...success.customer, totalPaid: { amount: totalAmount, currency: plan?.currency } }}
                logo={checkoutConfig.company && checkoutConfig.company.style.url_logo_default}
                primaryColor={company && company.style.primary_color}
                boleto={success.boleto}
            />
        )
        if (success.type === 'bolecode') return (
            <SuccessBolecode
                checkoutInfo={checkoutConfig}
                userInfo={{ ...success.customer, totalPaid: { amount: totalAmount, currency: plan?.currency } }}
                logo={checkoutConfig.company && checkoutConfig.company.style.url_logo_default}
                primaryColor={company && company.style.primary_color}
                boleto={success.bolecode}
            />
        )
        if (success.type === 'pix') return (
            <SuccessPix
                logo={company && company.style.url_logo_default}
                primaryColor={company && company.style.primary_color}
                pixData={success.pix}
                checkoutInfo={checkoutConfig}
                userInfo={{ ...success.customer, totalPaid: { amount: totalAmount, currency: plan?.currency } }}
            />
        )
    }

    if (checkoutConfig) return (
        <>
            <CheckoutContext.Provider value={{ checkout: checkoutConfig, shippingAddress: false, onSubmit: () => { }, setCheckoutState: () => { } }}>
                <ExitIntentModal disabled={(!checkoutConfig.coupon_enabled || !checkoutConfig.checkout_config.exit_intent_modal || !checkoutConfig.checkout_config.exit_intent_modal.is_enabled)} />
                <LocaleSelect />
                {process.env.REACT_APP_ENVIRONMENT === 'sandbox' && (
                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            bottom: 0,
                            height: "100vh",
                            width: "100%",
                            backgroundImage: `url(${textBG})`,
                            opacity: 0.1,
                            pointerEvents: "none",
                            zIndex: 9999
                        }}
                    />
                )}
                <WrapperLayout
                    company={company}
                    primary_color={company.style.primary_color}
                    logo_white_url={company.style.url_logo_white}
                    url_logo_default={company.style.url_logo_default}
                    customFooterInfo={checkoutConfig?.checkout_config?.footer_informations}
                    checkout={checkoutConfig}
                >
                    <div className="flex-row center-a" >
                        <TitleSmall>
                            {t(
                                "^titles.subscription",
                                checkoutConfig.plan?.name
                            )}
                        </TitleSmall>
                    </div>
                    <div className="flex-row xs-wrap-reverse">
                        <div className="flex-row padded-all-md" style={{ width: '100%', height: 'fit-content' }}>
                            <RenderForm checkoutConfig={checkoutConfig} country={checkoutConfig?.company.address.country_code} setSuccess={setSuccess} coupon={coupon} totalAmount={totalAmount} />
                        </div>
                        <div className="plan-summary fade-in">
                            <SectionMain>
                                <span style={{ marginBottom: '10px', textTransform: "uppercase", fontWeight: "bold" }}>
                                    {(t.generics.payment)}:
                                </span>
                                <div className="payment-panel">
                                    <span className="flex-row justify-sb">
                                        <span>{checkoutConfig.plan?.name}{plan.tax && " (TTC)"}:</span>
                                        <span>
                                            <span style={{ textDecoration: (plan.charge_only_setup || plan.trial.interval > 0 || plan.pro_rata > 0) ? "line-through" : "none" }}>
                                                {toCurrency(plan.amount, plan.currency)}
                                            </span>
                                        </span>
                                    </span>

                                    {plan.setup_fee > 0 && (
                                        <span className="flex-row justify-sb">
                                            <span>{t.titles["summary.initial_amount"]}</span>
                                            <span>
                                                {toCurrency(plan.setup_fee, plan.currency)}
                                            </span>
                                        </span>
                                    )}

                                    {plan.pro_rata > 0 && (
                                        <span className="flex-row justify-sb">
                                            <span>{"Pró-rata"}</span>
                                            <span style={{ textDecoration: (plan.charge_only_setup) ? "line-through" : "none" }}>
                                                {toCurrency(plan.pro_rata, plan.currency)}
                                            </span>
                                        </span>
                                    )}

                                    {plan.trial.interval > 0 && (
                                        <span className="flex-row justify-sb">
                                            <span>Trial</span>
                                            <span style={{ textDecoration: (plan.charge_only_setup) ? "line-through" : "none" }}>
                                                {toCurrency(plan.trial.amount, plan.currency)}
                                            </span>
                                        </span>
                                    )}

                                    {coupon.code && (
                                        <span className="flex-row justify-sb">
                                            <span>{t.titles["discount_value.details"]}:</span>
                                            <span>-{toCurrency(coupon.discount_amount, plan.currency)}</span>
                                        </span>
                                    )}

                                    <div className="flex-col center-a" style={{ alignItems: "flex-end" }}>
                                        <span>
                                            <span style={{ fontSize: 16, fontWeight: 'bold', textTransform: "uppercase" }}>
                                                {t.titles["total.details"]}{plan.tax && " (TTC)"}:
                                            </span>
                                            <span style={{ fontSize: 16, fontWeight: 'bold', textTransform: "uppercase", paddingLeft: 5 }}>
                                                {toCurrency(totalAmount, plan.currency)}
                                            </span>
                                        </span>
                                        {plan.tax &&
                                            <span className="flex-row flex-end" style={{ opacity: '0.5', fontWeight: "bold", fontSize: 12 }}>
                                                {t("^messages.tax.included",
                                                    plan.tax.tax_rate,
                                                    toCurrency((coupon.id ? coupon.tax_amount : plan.tax.tax_amount), plan.currency)
                                                )}
                                            </span>
                                        }
                                        {(plan.recurrence.count !== 1 && coupon.code) &&
                                            <span className="flex-row flex-end" style={{ opacity: '0.5' }}>{t.generics.from_day}&nbsp;<b>{renderDate(coupon.next_charge)}</b>, {t(
                                                "^messages.trial_interval.charge",
                                                toCurrency(
                                                    plan.amount,
                                                    plan.currency
                                                ),
                                                t.frequency_interval_unit[
                                                plan.recurrence.frequency
                                                ]
                                            )}</span>
                                        }
                                        {checkoutConfig.plan?.installments && (
                                            <div className="flex-row flex-end">
                                                {t.generics.in_up_to} {Math.max.apply(null, checkoutConfig.plan?.installments)}x
                                            </div>
                                        )}
                                    </div>
                                </div>


                                {checkoutConfig.coupon_enabled && (
                                    <CouponForm
                                        coupon={coupon}
                                        setCoupon={setCoupon}
                                        totalAmount={totalAmount}
                                        setTotalAmount={setTotalAmount}
                                        couponLoading={couponLoading}
                                        setCouponLoading={setCouponLoading}
                                        checkoutConfig={checkoutConfig}
                                        hash={qs.get("hash") || match.params.id}
                                    />
                                )}
                                <span style={{ margin: '10px 0 10px 0', textTransform: "uppercase", fontWeight: "bold" }}>
                                    {(t.generics.details)}:

                                </span>

                                <div className="flex-col desc-panel">
                                    <span style={{ fontWeight: "bold", margin: 0 }}>
                                        {plan.name}
                                    </span>
                                    <span>
                                        {plan.description}
                                    </span>
                                    <span className="flex-col" style={{ alignItems: 'flex-end' }}>
                                        <RenderPricingInfo plan={plan} />
                                    </span>
                                </div>

                            </SectionMain>
                        </div>
                    </div>
                </WrapperLayout>
            </CheckoutContext.Provider>

        </>
    )

    return null
}

export default Checkout


const RenderPricingInfo = ({ plan }) => {
    const renderDate = useLocalDateFunction('DATE')
    const t = useTranslation()
    return (
        <>
            <>
                <span>
                    {plan.trial.interval > 0 ?
                        <span className="flex-col" style={{ alignItems: 'flex-end' }}>
                            {!((plan.charge_only_setup && ((plan.trial && plan.trial.interval_unit == 'MONTHLY' && plan.trial.interval == 1) || (plan.trial && plan.trial.interval_unit == "DAILY")))) &&
                                <>
                                    {plan.trial.amount > 0
                                        ? `${toCurrency(
                                            plan.trial.amount,
                                            plan.currency
                                        )} ${t(
                                            "^titles.trial",
                                            `${plan.trial.interval
                                            } ${plan.trial.interval !==
                                                1
                                                ? t.frequency_quantity[plan.trial.interval_unit]
                                                : t.frequency_interval_unit[plan.trial.interval_unit]
                                            }`
                                        )}`
                                        : t(
                                            "^titles.gratuitous",
                                            `${plan.trial.interval
                                            } ${plan.trial.interval !==
                                                1
                                                ? t.frequency_quantity[plan.trial.interval_unit]
                                                : t.frequency_interval_unit[plan.trial.interval_unit]
                                            }`
                                        )
                                    }
                                </>
                            }

                            {(plan.trial.interval > 0 && plan.trial.amount === 0 && plan.setup_fee > 0) &&
                                <span>
                                    ({t.summary.charge_setup_only})
                                </span>
                            }
                            <span>
                                {plan.recurrence.interval > 1
                                    ? <span className="flex-col" style={{ alignItems: "flex-end" }}>
                                        {t.generics.then}, {toCurrency(plan.amount, plan.currency)}
                                        {plan.recurrence.count !== 1 &&
                                            <>
                                                &nbsp;{t.generics.each} {plan.recurrence.interval} {t.frequency_quantity[plan.recurrence.frequency]}
                                            </>
                                        }
                                        {plan.setup_fee > 0 &&
                                            <span>
                                                {toCurrency(plan.setup_fee, plan.currency)} {t.summary.of_setup}
                                            </span>
                                        }
                                    </span>
                                    : <span>
                                        {t.generics.from_day} <b>{renderDate(plan.trial.next_charge)}</b>, {t(
                                            "^messages.trial_interval.charge",
                                            toCurrency(
                                                plan.amount,
                                                plan.currency
                                            ),
                                            t.frequency_interval_unit[
                                            plan.recurrence.frequency
                                            ]
                                        )}
                                    </span>}
                            </span>
                        </span>
                        : plan.recurrence.interval > 1
                            ? <span className="flex-col" style={{ alignItems: "flex-end" }}>
                                {toCurrency(plan.amount, plan.currency)} {plan.recurrence.count !== 1 &&
                                    <>
                                        {t.generics.each} {plan.recurrence.interval} {t.frequency_quantity[plan.recurrence.frequency]}
                                    </>
                                }
                                {plan.setup_fee > 0 &&
                                    <span>
                                        {toCurrency(plan.setup_fee, plan.currency)} {t.summary.of_setup}
                                    </span>
                                }
                            </span>
                            : <span className="flex-col" style={{ alignItems: "flex-end" }}>
                                {toCurrency(plan.amount, plan.currency)}{plan.recurrence.count !== 1 &&
                                    <>
                                        /{t.frequency_interval_unit[plan.recurrence.frequency]}
                                    </>
                                }
                                {plan.setup_fee > 0 && <>
                                    <span>
                                        {toCurrency(plan.setup_fee, plan?.currency)} {t.summary.of_setup}
                                    </span>
                                </>
                                }
                            </span>
                    }
                </span>
            </>

        </>
    )
}


const RenderForm = (props) => {
    switch (props.country) {
        case 'FRA':
            return <FRForm checkoutConfig={props.checkoutConfig} setSuccessPage={props.setSuccess} couponId={props.coupon.id} totalAmount={props.totalAmount} />
        default:
            return <BRForm checkoutConfig={props.checkoutConfig} setSuccessPage={props.setSuccess} couponId={props.coupon.id} totalAmount={props.totalAmount} />
    }
}