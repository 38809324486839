import React from 'react'
import SectionMain from '../../../../layout/SectionMain'
import { TitleSmall } from '../../../../components/checkout-1.5.4/forms/Title'

const ListPaymentMeanItem = ({ paymentMean, listView }) => {
	return (
		<SectionMain
			alignItems="center"
			position="space-between"
			style={{
				backgroundColor: listView ? 'transparent' : '#fff',
				boxShadow: listView ? 'none' : '12px 13px 17px -8px rgba(0,0,0,0.10)',
				margin: '10px 0',
				padding: listView ? 5 : 15,
				// opacity: defaultOpen ? 1 : 0.5,
				border: listView ? 'none' : '1px dashed #DCDCDC'
			}}
		>
			<TitleSmall>
				{paymentMean.holder}
				<span style={{ display: 'flex', alignItems: 'bottom' }}>{paymentMean.card.number}</span>
			</TitleSmall>
			<TitleSmall>{paymentMean.card.expiry}</TitleSmall>
			<img
				src={`https://veripagassets.blob.core.windows.net/brands/${paymentMean.card.brand.toLowerCase()}.png`}
				width="40px"
				height="23px"
				alt=""
				style={{ marginRight: 10 }}
			/>
		</SectionMain>
	)
}

export default ListPaymentMeanItem
