import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Button, makeStyles } from '@material-ui/core'
import { useAppContext } from '../../../../../components/AppContextCheckout'

const useStyles = makeStyles({
	root: { color: ({ link, primary }) => (link === window.location.pathname ? primary : '#282828') }
})

const TopMenuUI = ({ label, link, icon }) => {
	const { company } = useAppContext()
	const classes = useStyles({ link, primary: company.style.primary_color })

	return (
		<Button className={classes.root} variant="text" component={RouterLink} to={link} startIcon={icon}>
			{label}
		</Button>
	)
}

export default TopMenuUI
