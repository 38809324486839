import React, { useState } from "react"
import { Formik } from "formik"

import RenderInputsV4 from "../checkout-1.5.4/forms/RenderInputsV4"
import { useTranslation } from "../IntlContext/IntlContext"
import { FormikValidationsCustom } from "../checkout-1.5.4/forms/RenderInputsV4/FormikValidations"
import _ from 'lodash'
import Loader from "../Loader"
import toCurrency from "../../core/toCurrency"
import { Button, Checkbox, FormControl, FormLabel, InputLabel, MenuItem, Select } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import PaymentMethodsList from "../PaymentMethods"
import SectionMain from "../../layout/SectionMain"
import useMsgBox from "../SweetAlert"
import AppContext, { useAppContext } from "../AppContextCheckout"
import { ThemeProvider } from "@emotion/react"
import swal from "@sweetalert/with-react"
import TestForm from "../../core/randomize"

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const ProductMultiStep = ({
    checkout,
    quantity,
    totalAmount,
    onCustomerSubmit = () => null,
    onPaymentSubmit = () => null,
    loadingState = {}
}) => {
    const t = useTranslation()
    const { setAppState, payzenForm, coupon } = useAppContext(AppContext);
    const msgBox = useMsgBox()
    const [selectedMethod, setSelectedMethod] = useState(checkout.checkout_config.payment_methods.includes('credit_card')
        ? 'credit_card'
        : checkout.checkout_config.payment_methods[0])




    function getSteps() {
        if (checkout.checkout_config.billing_address_enabled || checkout.checkout_config.shipping_address_enabled) {
            return [t.titles["customer.form"], t.generics.addresses, t.generics.payment];
        }
        return [t.titles["customer.form"], t.generics.payment];

    }

    const onCustomerStepSubmmit = async (form) => {

        const result = await onCustomerSubmit(form)
        if (result) return setActiveStep(activeStep + 1)
        return
    }

    function getStepContent(stepIndex) {
        if (checkout.checkout_config.billing_address_enabled || checkout.checkout_config.shipping_address_enabled) {
            switch (stepIndex) {
                case 0:
                    return <RenderForm selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod} type={'customer'} checkout={checkout} customerInitialValues={customerInitialValues} CCInitialValues={CCInitialValues} onSubmit={() => setActiveStep(1)} />
                case 1:
                    return <RenderForm selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod} type={'address'} checkout={checkout} customerInitialValues={customerInitialValues} CCInitialValues={CCInitialValues} onSubmit={(form) => onCustomerStepSubmmit(form)} loadingState={loadingState} />
                case 2:
                    return <RenderForm totalAmount={totalAmount} selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod} type={'payment'} checkout={checkout} customerInitialValues={customerInitialValues} CCInitialValues={CCInitialValues} onSubmit={onPaymentSubmit} loadingState={loadingState} />
                default:
                    return 'Unknown stepIndex';
            }
        }
        switch (stepIndex) {
            case 0:
                return <RenderForm selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod} type={'customer'} checkout={checkout} customerInitialValues={customerInitialValues} CCInitialValues={CCInitialValues} onSubmit={(form) => onCustomerStepSubmmit(form)} loadingState={loadingState} />
            case 1:
                return <RenderForm totalAmount={totalAmount} selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod} type={'payment'} checkout={checkout} customerInitialValues={customerInitialValues} CCInitialValues={CCInitialValues} onSubmit={onPaymentSubmit} loadingState={loadingState} />
            default:
                return 'Unknown stepIndex';
        }
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const disabledInputsCustomer = () => {
        if (!checkout.customer) return {}
        let initialValues = {
            first_name: Boolean(checkout.customer.first_name),
            last_name: Boolean(checkout.customer.last_name),
            mobile_phone: Boolean(checkout.customer.mobile_number),
            email: Boolean(checkout.customer.email),
            country_code: Boolean(checkout.customer.country_code),
            document_type: Boolean(checkout.customer.document.type),
            document_number: Boolean(checkout.customer.document.number),
            billing_address_postal_code: Boolean(checkout.customer.billing_address?.postal_code),
            billing_address_street1: Boolean(checkout.customer.billing_address?.street),
            billing_address_number: Boolean(checkout.customer.billing_address?.number),
            billing_address_state: Boolean(checkout.customer.billing_address?.state),
            billing_address_district: Boolean(checkout.customer.billing_address?.district),
            billing_address_city: Boolean(checkout.customer.billing_address?.city),
            billing_address_country: Boolean(checkout.customer.billing_address?.country),
            billing_address_complement: Boolean(checkout.customer.billing_address?.complement),
            shipping_address_postal_code: Boolean(checkout.customer.shipping_address[0]?.postal_code),
            shipping_address_street1: Boolean(checkout.customer.shipping_address[0]?.street),
            shipping_address_number: Boolean(checkout.customer.shipping_address[0]?.number),
            shipping_address_state: Boolean(checkout.customer.shipping_address[0]?.state),
            shipping_address_district: Boolean(checkout.customer.shipping_address[0]?.district),
            shipping_address_city: Boolean(checkout.customer.shipping_address[0]?.city),
            shipping_address_country: Boolean(checkout.customer.shipping_address[0]?.country),
            shipping_address_complement: Boolean(checkout.customer.shipping_address[0]?.complement),
        }
        return initialValues
    }

    const customerInitialValues = () => {
        if (checkout.customer) {
            let initialValues = {
                first_name: checkout.customer.first_name,
                last_name: checkout.customer.last_name,
                mobile_phone: checkout.customer.mobile_number || "",
                email: checkout.customer.email,
                country_code: checkout.customer.country_code,
                document_type: checkout.customer.document.type,
                document_number: checkout.customer.document.number,
                billing_address_postal_code: checkout.customer.billing_address?.postal_code,
                billing_address_street1: checkout.customer.billing_address?.street,
                billing_address_number: checkout.customer.billing_address?.number,
                billing_address_state: checkout.customer.billing_address?.state,
                billing_address_district: checkout.customer.billing_address?.district,
                billing_address_city: checkout.customer.billing_address?.city,
                billing_address_country: checkout.customer.billing_address?.country,
                billing_address_complement: checkout.customer.billing_address?.complement,
                shipping_address_postal_code: checkout.customer.shipping_address[0]?.postal_code,
                shipping_address_street1: checkout.customer.shipping_address[0]?.street,
                shipping_address_number: checkout.customer.shipping_address[0]?.number,
                shipping_address_state: checkout.customer.shipping_address[0]?.state,
                shipping_address_district: checkout.customer.shipping_address[0]?.district,
                shipping_address_city: checkout.customer.shipping_address[0]?.city,
                shipping_address_country: checkout.customer.shipping_address[0]?.country,
                shipping_address_complement: checkout.customer.shipping_address[0]?.complement,
            }
            return initialValues
        }
        let initialValues = {
            first_name: "",
            last_name: "",
            mobile_phone: "",
            email: "",
            country_code: "BRA",
            document_type: checkout.checkout_config.prioritize_b2b ? "CNPJ" : "CPF",
            document_number: "",
            billing_address_postal_code: "",
            billing_address_street1: "",
            billing_address_number: "",
            billing_address_state: "",
            billing_address_district: "",
            billing_address_city: "",
            billing_address_country: "",
            billing_address_complement: "",
            shipping_address_postal_code: "",
            shipping_address_street1: "",
            shipping_address_number: "",
            shipping_address_state: "",
            shipping_address_district: "",
            shipping_address_city: "",
            shipping_address_country: "",
            shipping_address_complement: "",
        }
        return initialValues
    }

    const CCInitialValues = () => {
        let initialValues = {
            cc_number: "",
            cc_cardholder: "",
            cc_expiry_date: "",
            cvv: "",
            brand: "",
            type: "",
            installments: checkout.installments && checkout.installments[0]

        }
        return initialValues
    }

    return (
        <div className='form-container'>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {getStepContent(activeStep)}
        </div>
    )
}

const TCbox = ({ theme, TCAgreed, setTCAgreed, checkout, showTCmodal }) => {
    const t = useTranslation()

    return (
        <div className='flex-row center-b'>
            <ThemeProvider theme={theme}>
                <Checkbox checked={TCAgreed} onChange={() => setTCAgreed(!TCAgreed)} color="primary" />
            </ThemeProvider>
            <span>
                {checkout.checkout_config.terms_conditions.type === 'link' &&
                    <>
                        {t.checkout.terms_conditions_1}{" "}
                        <a style={{ color: 'black' }} target={'_blank'} href={checkout.checkout_config.terms_conditions.data}>
                            {t.checkout.terms_conditions_2}
                        </a>
                    </>
                }
                {checkout.checkout_config.terms_conditions.type === 'text' &&
                    <>
                        {t.checkout.terms_conditions_1}{" "}
                        <a onClick={() => showTCmodal(t)} style={{ textDecoration: "underline", cursor: 'pointer' }}>
                            {t.checkout.terms_conditions_2}
                        </a>
                    </>
                }
                {checkout.checkout_config.terms_conditions.type === 'accept' &&
                    <>
                        {t.checkout.terms_conditions_full}
                    </>
                }
            </span>
        </div>
    )
}


const RenderForm = ({ checkout,
    customerInitialValues,
    CCInitialValues,
    type,
    onSubmit = () => null,
    selectedMethod = "",
    setSelectedMethod = () => null,
    totalAmount = 0,
    loadingState = {}
}) => {
    const t = useTranslation()
    const checkForErrors = async (form, handleSubmit, formikprops, type) => {
        // const customer = customerInitialValues()
        const payment = CCInitialValues()

        const values = { ...payment }
        Object.keys(values).forEach(v => {
            formikprops.setFieldTouched(v, true)
        })
        if (_.isEmpty(formikprops.errors)) return handleSubmit(form)
    }
    const customValidationConfig = {
        customer: true,
        billing_address: checkout.checkout_config.billing_address || ['boleto', 'bolecode'].includes(selectedMethod),
        shipping_address: checkout.checkout_config.shipping_address,
        document: checkout.checkout_config.document || ['pix', 'boleto', 'bolecode'].includes(selectedMethod),
        cc_payment: selectedMethod === 'credit_card'
    }

    const theme = createTheme({
        palette: {
            primary: { main: checkout.company?.style.primary_color },
        },
    });

    const [TCAgreed, setTCAgreed] = useState(!checkout.checkout_config.terms_conditions.is_enabled)

    const showTCmodal = async (t) => {
        const lines = checkout.checkout_config.terms_conditions.data.split("\n")

        const result = await swal({
            buttons: {
                cancel: t.generics.cancel,
                accept: {
                    text: t.generics.accept,
                    value: true,
                },
            },
            content: (
                <div>
                    <div style={{ width: '100%', height: '50vh', backgroundColor: "#f2f2f2", overflowY: 'auto', textAlign: 'left', padding: 5 }}>
                        {lines.map(line => (
                            <span>
                                {line}
                                <br />
                            </span>
                        ))}
                    </div>
                </div>
            ),

        })
        if (result) setTCAgreed(true)
    }


    switch (type) {
        case 'customer':
            return (
                <Formik
                    initialValues={customerInitialValues()}
                    validate={values => FormikValidationsCustom(values, checkout, t, { customer: true })}
                    onSubmit={props => onSubmit(props)}
                >
                    {({
                        handleSubmit,
                        ...formikprops
                    }) => (
                        <form onSubmit={(form) => handleSubmit(form)} style={{ width: '100%' }} action="javascript:void(0)">
                            <>
                                <span style={{ padding: 10 }}>
                                    {t.titles["customer.form"]}:
                                </span>
                                <RenderInputsV4 formikprops={formikprops} checkoutConfig={checkout} rows={[
                                    (formikprops.values["document_type"] !== "CNPJ" ? ['first_name', 'last_name'] : ['first_name']),
                                    (checkout.checkout_config?.birth_date ? ['email', 'birth_date'] : ['email']),
                                    (checkout.checkout_config?.country_code ? ['country_code'] : []),
                                    (checkout.checkout_config?.document_enabled ? ['document_type', 'document_number'] : []),
                                    (checkout.checkout_config?.mobile_phone ? ['mobile_phone'] : [])
                                ]} />
                            </>

                            {checkout.checkout_config.terms_conditions.is_enabled && <TCbox theme={theme} setTCAgreed={setTCAgreed} TCAgreed={TCAgreed} showTCmodal={showTCmodal} checkout={checkout} />}

                            <div className='flex-row' style={{ width: 'auto', padding: 10 }}>
                                <Button
                                    style={{
                                        width: '100%',
                                        fontSize: '1rem'
                                    }}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    type='submit'
                                    disabled={loadingState.customer || !TCAgreed}
                                >
                                    {loadingState.customer &&
                                        <span style={{ marginRight: "10px" }}>
                                            <Loader white size={30} />
                                        </span>
                                    }
                                    {t.buttons['checkout.continue']}
                                </Button>
                            </div>
                            <TestForm checkoutConfig={checkout} value={0} formikprops={formikprops} formStep={'one_step'} />

                        </form>
                    )}
                </Formik>
            )
        case 'address':
            return (
                <Formik
                    // initialValues={customerInitialValues()}
                    validate={values => FormikValidationsCustom(values, checkout, t, {
                        billing_address: checkout.checkout_config.billing_address_enabled,
                        shipping_address: checkout.checkout_config.shipping_address_enabled,
                    })}
                    onSubmit={props => onSubmit(props)}
                >
                    {({
                        handleSubmit,
                        ...formikprops
                    }) => (
                        <form onSubmit={(form) => handleSubmit(form)} style={{ width: '100%' }} action="javascript:void(0)">
                            <>
                                {checkout.checkout_config.billing_address_enabled &&
                                    <>
                                        <span style={{ padding: 10 }}>
                                            {t.titles['billing_address.form']}:
                                        </span>
                                        <RenderInputsV4 formikprops={formikprops} checkoutConfig={checkout} rows={[
                                            ['billing_address_postal_code'],
                                            ['billing_address_street1'],
                                            ['billing_address_number', 'billing_address_district'],
                                            ['billing_address_city', 'billing_address_state_br'],
                                            ['billing_address_complement'],
                                        ]} />
                                    </>
                                }

                                {checkout.checkout_config.shipping_address_enabled &&
                                    <>
                                        <span style={{ padding: 10 }}>
                                            {t.titles['shipping_address.form']}:
                                        </span>
                                        <RenderInputsV4 formikprops={formikprops} checkoutConfig={checkout} rows={[
                                            ['shipping_address_postal_code'],
                                            ['shipping_address_street1'],
                                            ['shipping_address_number', 'shipping_address_district'],
                                            ['shipping_address_city', 'shipping_address_state_br'],
                                            ['shipping_address_complement'],
                                        ]} />
                                    </>
                                }

                                <div className='flex-row' style={{ width: 'auto', padding: 10 }}>
                                    <Button
                                        style={{
                                            width: '100%',
                                            fontSize: '1rem'
                                        }}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        type='submit'
                                        disabled={loadingState.customer}
                                    >
                                        {loadingState.customer &&
                                            <span style={{ marginRight: "10px" }}>
                                                <Loader white size={30} />
                                            </span>
                                        }
                                        {t.buttons['checkout.continue']}
                                    </Button>
                                </div>
                            </>
                            <TestForm checkoutConfig={checkout} value={0} formikprops={formikprops} formStep={'one_step'} />
                        </form>
                    )}
                </Formik>
            )
        case 'payment':
            return (
                <Formik
                    validate={values => {
                        if (totalAmount > 0) {
                            return FormikValidationsCustom(values, checkout, t, {
                                billing_address: ['boleto'].includes(selectedMethod),
                                document: ['pix', 'boleto'].includes(selectedMethod),
                                cc_payment: selectedMethod === 'credit_card'
                            })
                        } else {
                            return true
                        }
                    }}
                    onSubmit={props => onSubmit({ ...props, type: selectedMethod })}
                >
                    {({
                        handleSubmit,
                        ...formikprops
                    }) => (
                        <form onSubmit={(form) => handleSubmit(form)} style={{ width: '100%' }} action="javascript:void(0)">
                            {totalAmount > 0
                                ? <>
                                    <span style={{ padding: 10 }}>
                                        {t.generics.payment}:
                                    </span>
                                    <PaymentMethodsList
                                        selectedMethod={selectedMethod}
                                        onSelect={(method) => setSelectedMethod(method)}
                                        availablMethods={checkout.checkout_config.payment_methods}
                                        formikprops={formikprops}
                                        totalAmount={totalAmount}
                                        checkout={checkout} />

                                    <div className='flex-row' style={{ width: 'auto', padding: 10 }}>
                                        <Button
                                            style={{
                                                width: '100%',
                                                fontSize: '1rem'
                                            }}
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            type='submit'
                                            disabled={loadingState.payment}
                                        >
                                            {loadingState.payment &&
                                                <span style={{ marginRight: "10px" }}>
                                                    <Loader white size={30} />
                                                </span>
                                            }
                                            {t.buttons['checkout.paid']} {toCurrency(totalAmount, checkout.product.currency)}
                                        </Button>
                                    </div>
                                </>
                                : <div className='flex-row' style={{ width: 'auto', padding: 10 }}>
                                    <Button
                                        style={{
                                            width: '100%',
                                            fontSize: '1rem'
                                        }}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        type='submit'
                                        disabled={loadingState.payment}
                                    >
                                        {loadingState.payment &&
                                            <span style={{ marginRight: "10px" }}>
                                                <Loader white size={30} />
                                            </span>
                                        }
                                        {t("@@ Concluir venda")}
                                    </Button>
                                </div>}
                            <TestForm checkoutConfig={checkout} value={0} formikprops={formikprops} formStep={'one_step'} />
                        </form>
                    )}
                </Formik>
            )

        default:
            return null
    }
}

export default ProductMultiStep