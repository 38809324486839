import React from 'react'
import { Button } from '@material-ui/core'

const ButtonCard = ({ children, style, textDecorationLine = 'underline', color = 'primary', ...props }) => {
	return (
		<Button
			size="small"
			variant="text"
			color={color}
			style={{
				textTransform: 'capitalize',
				textDecorationLine,
				fontSize: 14,
				maxHeight: 40,
				textAlign: 'end',
				...style
			}}
			{...props}
		>
			{children}
		</Button>
	)
}

export default ButtonCard
