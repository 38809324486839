import React from "react"
import SectionMain from "../../../../../layout/SectionMain"
import { useTranslation } from "../../../../../components/IntlContext/IntlContext"
import { makeStyles, AppBar } from "@material-ui/core"
import { useAppContext } from "../../../../../components/AppContextCheckout"
import DateRangeIcon from "@material-ui/icons/DateRange"
import OfflinePinIcon from "@material-ui/icons/OfflinePinOutlined"
import CreditCardIcon from "@material-ui/icons/CreditCard"
import UserAvatar from "../../../UserAvatar"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles({
	appBar: {
		backgroundColor: "#fff",
		justifyContent: "center",
		flexDirection: "row",
		height: 65,
		padding: "0 20px",
	},
	container: {
		maxWidth: 1600,
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "space-between",
	},

	menu: {
		alignItems: "center",
		justifyContent: "space-between",
		maxWidth: 500,
		paddingLeft: 20,
	},
	logo: {
		// maxWidth: 100,
		maxHeight: 45,
		marginRight: 8,
	},
	avatar: {
		justifyContent: "space-between",
		alignItems: "center",
		flexDirection: "row",
	},
})

const TopMenuWrapper = ({ children }) => {
	const classes = useStyles()
	const t = useTranslation()
	const { company, user } = useAppContext()
	const history = useHistory()
	const navButtons = [
		{
			label: t.navigation_menu.my_subscriptions,
			link: "/",
			icon: <DateRangeIcon />,
		},
		{
			label: t.navigation_menu.my_payment_means,
			link: "/payments",
			icon: <OfflinePinIcon />,
		},
		{
			label: t.navigation_menu.my_cards,
			link: "/payment-means",
			icon: <CreditCardIcon />,
		},
	]

	return (
		<AppBar className={classes.appBar}>
			<SectionMain className={classes.container}>
				<SectionMain noPadding item xs={10} alignItems="center">
					<img
						alt="Logo"
						className={classes.logo}
						src={company.style.url_logo_default}
						// width="200px"
						onClick={() => history.push("/")}
					/>
					<SectionMain noPadding className={classes.menu}>
						{navButtons.map(({ label, link, icon }) =>
							children({ label, link, icon })
						)}
					</SectionMain>
				</SectionMain>
				<SectionMain item xs={2} position="flex-end">
					<div className="flex-row center-b">
						{/* <i
							className={`flag flag-24 flag-br`}
							style={{ marginRight: 5 }}
						/> */}
						<UserAvatar user={user} />
					</div>
				</SectionMain>
			</SectionMain>
		</AppBar>
	)
}

export default TopMenuWrapper
