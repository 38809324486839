import React from 'react'
import { Animated } from 'react-web-animation'

const AnimatedView = ({ children, styleDiv, animate = {
	keyframes: [
		{ transform: 'none', opacity: '0', offset: 0 },
		{ transform: 'none', opacity: '0.5', offset: 0.3 },
		{ transform: 'none', opacity: '1', offset: 1 },
	],
	timing: {
		duration: 700,
		iterations: 1,
		easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
	},
} }) => {
	return (
		<Animated.div
			keyframes={animate.keyframes}
			timing={animate.timing}
			style={{
				width: '100%',
				...styleDiv
			}}
		>
			{children}
		</Animated.div>
	)
}

export default AnimatedView
