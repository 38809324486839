import React, { useState, useEffect } from "react"
import { AppBar, makeStyles, Hidden, FormControl, MenuItem, Select, InputLabel } from "@material-ui/core"
import SectionMain from "../SectionMain"
import { TitleSmall } from "../../components/checkout-1.5.4/forms/Title"
import pciLogo from "../../assets/pci-logo.png"
import cycloPay from "../../assets/cyclopay-blue-logo2.svg"
import cyclopayWhite from "../../assets/cyclopay-white-logo2.png"
import { useCallback } from "react"
import mask from "../../core/mask"
import { Icon } from '@iconify/react';
import getLocale from '../../locale'
import { useAppContext } from "../../components/AppContextCheckout"

const useStyles = makeStyles((theme) => ({
	root: {
		position: "fixed",
		backgroundColor: ({ primary_color }) => primary_color,
		padding: "10px",
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	logo: {
		backgroundImage: ({ logo_white_url }) => `url(${logo_white_url})`,
		backgroundSize: "contain",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		width: "100%",
		height: "10vw",
		maxHeight: 60,
	},
	wrapper: {
		margin: "50px 50px 0px",
		padding: "50px 20px",
		width: "100%",
		maxWidth: 1280,
		minHeight: ({ height }) => height,
	},
	footerContainer: {
		height: 200,
		backgroundColor: "#eee",
		justifyContent: "center",
		alignItems: "center",
	},
	footer: {
		maxWidth: 1280,
		padding: "0px 20px",
	},
	localeSelect: {
		padding: 10,
		backgroundColor: 'white'
	},
	logosFooter: {
		display: "flex",
		alignItems: "center",
		margin: 10,
		// borderRadius: "5px",
		// border: ({ primary_color }) => `2px dashed ${primary_color}`,
		// backgroundColor: ({ primary_color }) => primary_color,
	},
}))

const WrapperLayout = ({
	children,
	primary_color,
	logo_white_url,
	company,
	customFooterInfo,
	checkout
}) => {
	const [height, setHeight] = useState(window.innerHeight - 300)
	const classes = useStyles({ primary_color, logo_white_url, height })
	const { lang, setAppState, language } = useAppContext()

	// window.document.title = `${company.name} - Checkout`
	window.document.title = `Checkout`



	function isBackgroundDark(hexcolor = "#000000") {
		hexcolor = hexcolor.replace("#", "");
		var r = parseInt(hexcolor.substr(0, 2), 16);
		var g = parseInt(hexcolor.substr(2, 2), 16);
		var b = parseInt(hexcolor.substr(4, 2), 16);
		var yiq = (r * 299 + g * 587 + b * 114) / 1000;
		return yiq < 128;
	}

	const getHeightWindow = useCallback(() => {
		window.addEventListener("resize", (e) => {
			setHeight(e.target.innerHeight - 250)
		})
	}, [])

	useEffect(() => {
		getHeightWindow()
	}, [])

	if (!primary_color) {
		return ""
	}

	if (customFooterInfo && customFooterInfo.is_enabled && !customFooterInfo.hide_company_info) {
		company = {
			...company,
			site: customFooterInfo.site || company.site,
			phone: customFooterInfo.phone || company.phone,
			email: customFooterInfo.email || company.email,
			address: {
				...company.address,
				city: customFooterInfo.address?.city,
				district: customFooterInfo.address?.district,
				complement: customFooterInfo.address?.complement,
				enabled: customFooterInfo.address?.enabled,
				number: customFooterInfo.address?.number,
				postal_code: customFooterInfo.address?.postal_code,
				state: customFooterInfo.address?.state,
				street: customFooterInfo.address?.street,
			}

		}
	}
	return (
		<SectionMain noPadding position="center" xs={12} style={{ backgroundColor: company.style.background_color, height: 'fit-content', minHeight: '100%' }}>
			<AppBar color="transparent" className={classes.root}>
				<SectionMain noPadding={true}>
					{logo_white_url ? (
						<div className={classes.logo} />
					) : (
						company.name
					)}
				</SectionMain>
			</AppBar>
			<div id="wrapper" className={classes.wrapper}>
				<span className='fade-in'>
					{children}
				</span>
			</div>
			<SectionMain className={classes.footerContainer} style={{ backgroundColor: company.style.secundary_color, color: "white", marginTop: 'auto' }} id="footer">
				<SectionMain className={classes.footer}>
					{!checkout?.checkout_config?.footer_informations?.hide_company_info ? (
						<SectionMain noPadding item xs={12} xl={6} lg={6} md={6}>
							<Hidden only={["xs"]}>
								<SectionMain style={{ color: "white" }} noPadding>
									<TitleSmall style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }} className={classes.label}>{`${company.legal_name}`}</TitleSmall>
								</SectionMain>
								{company.document_type && company.document_number &&
									<TitleSmall style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
										{`${company.document_type.toUpperCase()} ${company.document_number}`}
									</TitleSmall>
								}
								{company.address?.street &&
									<SectionMain noPadding>
										<TitleSmall style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
											{`${company.address.street}${company.address.number ? (`, ${company.address.number}`) : ""}${company.address.complement ? ` - ${company.address.complement}` : ""}`}&nbsp;
										</TitleSmall>
									</SectionMain>
								}
								{company.address?.district &&
									<SectionMain noPadding>
										<TitleSmall style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }} noPadding>
											{company.address.district}
										</TitleSmall>
									</SectionMain>
								}
								{company.address?.city && company.address?.postal_code &&
									<SectionMain noPadding>
										<TitleSmall style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }} className={classes.label}>
											<span
											>{`${company.address.postal_code} - ${company.address.city}, ${company.address.state}`}</span>
										</TitleSmall>
									</SectionMain>
								}
								{company.address?.second_street && (
									<SectionMain noPadding>
										<TitleSmall style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
											{company.address.second_street}
										</TitleSmall>
									</SectionMain>
								)}
								{company.email &&
									<SectionMain noPadding>
										<TitleSmall style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
											{company.email}
										</TitleSmall>
									</SectionMain>
								}
								{company.phone && company.phone.length > 5 &&
									<SectionMain noPadding>
										<TitleSmall style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
											{company.phone}
										</TitleSmall>
									</SectionMain>
								}
								{company.site &&
									<SectionMain noPadding>
										<TitleSmall style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
											{company.site}
										</TitleSmall>
									</SectionMain>
								}
								{/* <SectionMain >
								<TitleSmall style={{ color: isBackgroundDark(company.style.secundary_color)? "white": 'black' }} className={classes.label}>
									{mask(company.tax_id, company.document_type === 'cnpj' ? "99.999.999/9999-99" :"9".repeat(40))}
								</TitleSmall>
							</SectionMain> */}
							</Hidden>

							<Hidden only={["xl", "lg", "md", "sm"]}>
								<SectionMain position="center">
									<SectionMain noPadding position="center">
										<TitleSmall style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>{`${company.legal_name}`}</TitleSmall>
									</SectionMain>
									<div className="flex-col" style={{ alignItems: 'center' }}>
										{company.document_type && company.document_number &&
											<TitleSmall style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
												{`${company.document_type.toUpperCase()} ${company.document_number}`}
											</TitleSmall>
										}
										{company.address?.street &&
											<TitleSmall className={classes.label} style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
												{`${company.address.street} ${company.address.number ? (company.address.number) : ""}`}&nbsp;
											</TitleSmall>
										}
										{company.address?.district &&
											<TitleSmall className={classes.label} style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }} noPadding>
												{company.address.district}
											</TitleSmall>
										}
										{company.address?.city && company.address?.postal_code &&

											<TitleSmall className={classes.label} tyle={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
												<span
												>{`${company.address.city} - ${company.address.postal_code}`}</span>
											</TitleSmall>
										}
										{company.address?.second_street && (
											<TitleSmall className={classes.label} style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
												{company.address.second_street}
											</TitleSmall>
										)}
										{company.email &&
											<TitleSmall className={classes.label} style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
												{company.email}
											</TitleSmall>
										}
										{company.phone && company.phone.length > 5 &&
											<TitleSmall className={classes.label} style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
												{company.phone}
											</TitleSmall>
										}
										{company.site &&
											<TitleSmall className={classes.label} style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
												{company.site}
											</TitleSmall>
										}
									</div>
								</SectionMain>
							</Hidden>
						</SectionMain>
					)
						: <></>
					}
					<SectionMain
						noPadding
						position="center"
						item
						xs={12}
						xl={checkout?.checkout_config?.footer_informations?.hide_company_info ? 12 : 6}
						lg={checkout?.checkout_config?.footer_informations?.hide_company_info ? 12 : 6}
						md={checkout?.checkout_config?.footer_informations?.hide_company_info ? 12 : 6}
					>
						<div className='flex-col'>
							<span className="flex-row">
								<span className={classes.logosFooter}>
									<img alt="pci-logo" src={pciLogo} width="40px" />
								</span>
								<span className={classes.logosFooter}>
									<img
										onClick={() => {
											window.location = "https://cyclopay.com"
										}}
										alt="ciclopay-logo"
										src={isBackgroundDark(company.style.secundary_color) ? cyclopayWhite : cycloPay}
										width="70px"
									/>
								</span>
							</span>
							{/* <span className="flex-row flex-end">
								<FormControl variant="outlined" className={classes.formControl}>
									<Select
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										value={language || lang}
										onChange={e => handleLocaleChange(e.target.value)}
										classes={{ root: classes.localeSelect }}
									>
										<MenuItem value={'pt-BR'}>
											<span className="flex-row">
												<Icon style={{ fontSize: '20px' }} icon="cif:br" />
											</span>
										</MenuItem>
										<MenuItem value={'fr-FR'}>
											<span className="flex-row">
												<Icon style={{ fontSize: '20px' }} icon="cif:fr" />
											</span>
										</MenuItem>
										<MenuItem value={'es-ES'}>
											<span className="flex-row">
												<Icon style={{ fontSize: '20px' }} icon="cif:es" />
											</span>
										</MenuItem>
									</Select>
								</FormControl>
							</span> */}
						</div>
					</SectionMain>
				</SectionMain>
			</SectionMain>
		</SectionMain>
	)
}

export default WrapperLayout
