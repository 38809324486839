export const valid_locales = ['pt-BR', 'es', 'fr', 'en', 'en-US', 'es-ES', 'fr-FR']

const locales = {
	'pt-BR': require('./pt-BR.json'),
	fr: require('./fr.json'),
	es: require('./es.json'),
	'es-ES': require('./es.json'),
	'fr-FR': require('./fr.json'),
	'en': require('./en.json'),
	'en-US': require('./en.json'),

}

export default async (lang) => {
	if (!valid_locales.includes(lang)) {
		lang = valid_locales[0]
		console.log(`Invalid language. Setting to fallback (${lang})`)
	}

	return { locale: locales[lang], language: lang }
}
