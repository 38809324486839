import React from "react"
import { Grid, makeStyles } from "@material-ui/core"
import { backgroundColor } from "../color"

const useStyles = makeStyles({
	root: {
		padding: (theme) =>
			theme.noPadding ? 0 : theme.miniPadding ? "10px 0" : "15px 0",
		justifyContent: (theme) => theme.position,
		backgroundColor: ({ debug }) => debug && debug,
		alignItems: ({ alignItems }) => alignItems && alignItems,
	},
})

const SectionMain = ({
	children,
	position,
	noPadding,
	miniPadding,
	debug,
	alignItems,
	...props
}) => {
	const classes = useStyles({
		noPadding,
		miniPadding,
		position,
		alignItems,
		debug,
	})
	return (
		<Grid className={classes.root} container {...props}>
			{children}
		</Grid>
	)
}

export default SectionMain
