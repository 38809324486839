import React, { useEffect, useContext } from 'react'
import PortalWrapper from '../../components/layout/PortalWrapper'
import { Formik } from 'formik'
import SectionMain from '../../../layout/SectionMain'
import { Paper, Hidden, CircularProgress, Button, IconButton, MenuItem } from '@material-ui/core'
import Title, { TitleSmall } from '../../../components/checkout-1.5.4/forms/Title'
import { toCurrency } from '../../../utils/commonMasks'
import { useState } from 'react'

import Loader from '../../../components/Loader'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import ListPaymentMeans from '../../components/list/ListPaymentMeans'
import RenderInputsV2 from '../../../components/checkout-1.5.4/forms/RenderInputsV2'
import { string, object, mixed, array } from 'yup' // for only what you need
import CloseIcon from '@material-ui/icons/Close'
import AppContext from '../../../components/AppContextCheckout'

import { lightOrDark } from '../../../utils/lightOrDark'
import StatusCard from '../../components/cards/StatusCard'
import api from '../../../core/api'
import { useSnackbar } from 'notistack'
import { useTranslation } from '../../../components/IntlContext/IntlContext'
import RenderInputsV3 from '../../../components/checkout-1.5.4/forms/RenderInputsV3'

const execImmediate = (f) => f()

const freqLabels = {
	DAILY: 'dia',
	MONTHLY: 'mês',
	YEARLY: 'ano'
}

const AddCreditCardPage = ({ history, match }) => {
	const t = useTranslation()
	const [ state, setState ] = useState({ loading: true })
	const { enqueueSnackbar } = useSnackbar()
	const id = match.params.id
	const { company } = useContext(AppContext)

	const validationSchema = () =>
		object().shape({
			payment_mean: object().shape({
				card_number: string().required('Preencha o campo cep.'),
				cvv: string().required('Preencha o campo rua.'),
				expiry_date: string().required('Preencha o campo número.'),
				customer_name: string().required('Preencha o campo estado.')
			})
		})

	const onFormSubmit = async (values, setFieldValue) => {
		const form = {
			...values.payment_mean,
			holder: values.payment_mean.customer_name,
			number: values.payment_mean.card_number,
			expiry_date: execImmediate(() => {
				try {
					const [ , month, year ] = values.payment_mean.expiry_date.match(/(\d\d)\/(\d\d)/)
					const currYearFirstTwoDigs = new Date().getFullYear().toString().match(/^\d\d/)[0]
					return `${month}/${currYearFirstTwoDigs}${year}`
				} catch (e) {
					return form.payment_mean.expiry_date
				}
			})
		}
		delete form.customer_name
		try {
			let response = await api.post(`/payment-means`, form)

			if (response.status === 200) {
				enqueueSnackbar('Meio de pagamento alterado com sucesso.', {
					variant: 'success'
				})
			}
			history.goBack()
		} catch (e) {
			if (e.response.status === 409) {
				enqueueSnackbar('Este cartão já esta sendo utilizado na assinatura.', {
					variant: 'error'
				})
			} else {
				if (e.response.data.message.includes('Invalid card number')) {
					enqueueSnackbar('Número de cartão invalido.', {
						variant: 'error'
					})
				} else {
					enqueueSnackbar('Erro interno, por favor tente novamente.', {
						variant: 'error'
					})
				}
			}
		}
	}

	return (
		<PortalWrapper history={history}>
			<Paper style={{ padding: '0 20px' }}>
				<Formik
					initialValues={{
						payment_mean: {
							card_number: '',
							expiry_date: '',
							cvv: '',
							customer_name: '',
							brand: ''
						}
					}}
					validationSchema={validationSchema}
					onSubmit={(values, { setFieldValue }) => onFormSubmit(values, setFieldValue)}
				>
					{(formikProps) => {
						return (
							<form action="javascript:void(0)" onSubmit={formikProps.handleSubmit}>
								<style>
									{`@media only screen and (min-width: 1024px) {
							#form-input-section {
								width:90% !important ;
								
							}}
							`}
								</style>

								<SectionMain noPadding>
									<SectionMain position="space-between" alignItems="flex-start">
										<Title style={{ paddingRight: 10 }}>
											{t.titles['add_payment_method.card']}
										</Title>
										<IconButton size="small" onClick={() => history.goBack()}>
											<CloseIcon />
										</IconButton>
									</SectionMain>

									<SectionMain noPadding item xs={8}>
										<RenderInputsV3
											portalFixLayout
											// checkoutConfig={
											//     checkout.checkout_config
											// }
											formikProps={formikProps}
											arrayInputs={[ 'payment' ]}
										/>
									</SectionMain>
									<SectionMain position="flex-end">
										<SectionMain noPadding xl={2} lg={2} md={2} sm={2}>
											<Button
												fullWidth
												type="submit"
												variant="contained"
												color="primary"
												disabled={formikProps.isSubmitting}
												style={{
													color:
														company.primary_color &&
														lightOrDark(company.primary_color) === 'light'
															? '#282828'
															: '#fff',
													alignItems: 'center',
													backgroundColor: company.primary_color && company.primary_color
												}}
											>
												{formikProps.isSubmitting && (
													<CircularProgress
														size={24}
														color="inherit"
														style={{
															marginRight: 8
														}}
													/>
												)}
												Adicionar
											</Button>
										</SectionMain>
									</SectionMain>
								</SectionMain>
							</form>
						)
					}}
				</Formik>
			</Paper>
		</PortalWrapper>
	)
}

export default AddCreditCardPage
