import React from 'react'

const RenderInputsWrapper = ({ children, arrayInputs, checkoutConfig = {} }) => {
	const renderInputsMap = (arrayInputs = []) =>
		arrayInputs.map((inputName) => {
			const inputType = inputName.match(/billing_address|shipping_address|billing_address.fr|shipping_address.fr/)
				? inputName.split('_').pop()
				: inputName

			if (checkoutConfig[`${inputName}`] === true) return children({ inputType, inputName })
		})

	return <React.Fragment>{renderInputsMap(arrayInputs)}</React.Fragment>
}

export default RenderInputsWrapper
