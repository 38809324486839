import { FormControl, makeStyles, MenuItem, Select } from "@material-ui/core";
import { useCallback } from "react";
import AppContext, { useAppContext } from "./AppContextCheckout";
import { useTranslation } from "./IntlContext/IntlContext";
import getLocale from '../locale'



const useStyles = makeStyles((theme) => ({
  localeSelect: {
    padding: 10,
    backgroundColor: 'white',

    '&:focus': {
      backgroundColor: 'white',
      borderRadius: 'inherit',
    }
  },

}))


export function LocaleSelect() {
  const { setAppState, lang, language } = useAppContext(AppContext)

  const t = useTranslation()
  const classes = useStyles()

  const handleLocaleChange = useCallback(async lang => {
    const { locale } = await getLocale(lang)
    localStorage.setItem("locale", lang)
    setAppState({ language: lang, locale })
  }, [])

  return (
    <div style={{ position: "fixed", right: '5px', top: 0, zIndex: 1101 }}>
      <FormControl variant="outlined">
        <Select
          id="select_locale"
          data-cy="select_locale"
          value={language || lang}
          onChange={e => handleLocaleChange(e.target.value)}
          classes={{ root: classes.localeSelect }}
        >
          <MenuItem value={'pt-BR'}>
            {t('@@ Português (Brasil)')}
          </MenuItem>
          <MenuItem value={'fr-FR'}>
            {t('@@ Français')}
          </MenuItem>
          <MenuItem value={'es-ES'}>
            {t('@@ Español')}
          </MenuItem>
          <MenuItem value={'en-US'}>
            {t('@@ English')}
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}