import React, { useState, useEffect } from "react"
import api from "../../../../../core/api"
import SectionMain from "../../../../../layout/SectionMain"
import { makeStyles } from "@material-ui/core"
import moment from "moment"
import { useTranslation } from "../../../../../components/IntlContext/IntlContext"
import Title from "../../../../../components/checkout-1.5.4/forms/Title"
import Loader from "../../../../../components/Loader"
import { CustomerService } from "../../../../../core/CustomerService"
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles({
	track: { background: "#7b7b7b", width: 2, padding: 0, marginTop: 5 },
	card: { marginLeft: 15 },
	icon: {
		borderRadius: "100%",
		background: "#c4c4c4",
		height: 16,
		width: 16,
		zIndex: 150,
		position: "relative",
		right: 18,
	},
})

const month = {
	1: "Janeiro",
	2: "Fevereiro",
	3: "Março",
	4: "Abril",
	5: "Maio",
	6: "Junho",
	7: "Julho",
	8: "Agosto",
	9: "Setembro",
	10: "Outubro",
	11: "Novembro",
	12: "Dezembro",
}


const ListSalesWrapper = ({ children, subscriptionId }) => {
	const classes = useStyles()
	const [sales, setSales] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [currentPage, setCurrentPage] = useState(1)
	const [totalItems, setTotalItems] = useState(0)
	const [totalPages, setTotalPages] = useState(0)
	const [hasMore, setHasMore] = useState(true)
	const momentDate = (arrayDate) =>
		moment()
			.year(arrayDate[0])
			.month(arrayDate[1] - 1)
			.date(arrayDate[2])
	const t = useTranslation()

	const fetchSales = async () => {
		setIsLoading(true)
		if (subscriptionId) {
			try {
				const data = await CustomerService.Subscriptions.getSalesBySubscriptionId({
					subscriptionId,
					filters: {
						groupBy: "createDate",
						page: currentPage,
						count: 10
					}
				})

				const sales = data.items;
				console.log(sales)
				setTotalItems(sales.length)
				setTotalPages(data.total_pages)
				setSales(sales)
			} catch (e) {
				console.log({ e })
			}
		} else {
			try {
				const data = await CustomerService.Subscriptions.getSales({
					filters: {
						groupBy: "createDate",
						page: currentPage,
						count: 10
					}
				})
				setTotalItems(data.items.length)
				setTotalPages(data.total_pages)
				setSales(data.items)

			} catch (e) {
				console.log({ e })
			}
		}
		return setIsLoading(false)
	}

	const fetchMoreData = async () => {

		if (subscriptionId) {
			const data = await CustomerService.Subscriptions.getSalesBySubscriptionId({
				subscriptionId,
				filters: {
					groupBy: "createDate",
					page: currentPage + 1,
					count: 10
				}
			})

			function mergeObjectsWithSameKey(array1, array2) {
				let result = [...array1];

				array2.forEach(item2 => {
					const item1Index = array1.findIndex(item1 => Object.keys(item1)[0] === Object.keys(item2)[0]);

					if (item1Index >= 0) {
						const key = Object.keys(item2)[0];
						result[item1Index][key] = [...result[item1Index][key], ...item2[key]];
					} else {
						result.push(item2);
					}
				});

				return result;
			}
			const arr = mergeObjectsWithSameKey(sales, data.items)

			let count = arr.reduce((total, item) => {
				let key = Object.keys(item)[0];
				return total + item[key].length;
			}, 0);

			setTotalItems(count)
			setCurrentPage(currentPage + 1)
			setSales(arr)
		} else {
			const data = await CustomerService.Subscriptions.getSales({
				filters: {
					groupBy: "createDate",
					page: currentPage + 1,
					count: 10
				}
			})

			function mergeObjectsWithSameKey(array1, array2) {
				let result = [...array1];

				array2.forEach(item2 => {
					const item1Index = array1.findIndex(item1 => Object.keys(item1)[0] === Object.keys(item2)[0]);

					if (item1Index >= 0) {
						const key = Object.keys(item2)[0];
						result[item1Index][key] = [...result[item1Index][key], ...item2[key]];
					} else {
						result.push(item2);
					}
				});

				return result;
			}
			const arr = mergeObjectsWithSameKey(sales, data.items)

			let count = arr.reduce((total, item) => {
				let key = Object.keys(item)[0];
				return total + item[key].length;
			}, 0);

			setTotalItems(count)
			setCurrentPage(currentPage + 1)
			setSales(arr)
		}
	}


	useEffect(() => {
		fetchSales()
	}, [])


	if (isLoading) return (
		<div
			className="flex-col center-a center-b"
			style={{
				width: "100vw",
				height: "80vh",
			}}
		>
			<Loader />
		</div>
	)


	if (sales.length === 0) {
		return (
			<SectionMain
				style={{
					height: "50vh",
				}}
				direction="column"
				noPadding
				position="center"
				alignItems="center"
			>
				<Title bold align="center">
					{t.titles["payment.not_found"]}
				</Title>
			</SectionMain>
		)
	}

	return (
		<SectionMain noPadding>
			<style>
				{`
					.infinite-scroll-component__outerdiv {
						width: 100%;
					}
				`}
			</style>
			<InfiniteScroll
				dataLength={totalItems}
				next={fetchMoreData}
				hasMore={currentPage < totalPages}
				loader={<div className="flex-row center-a" style={{ height: "100px", width: "100%", boxSizing: "border-box" }}>
					<Loader size={80} style={{ height: 80 }} />
				</div>}
				endMessage={
					<p style={{ textAlign: "center" }}>
						<b>{t("@@ Sem mais resultados")}</b>
					</p>
				}
			>
				<SectionMain
					noPadding
					item
					xs={1}
					position="center"
					style={{ maxWidth: 20, padding: 0, height: "100%", width: "100%" }}
				>
					<SectionMain noPadding className={classes.track} />
				</SectionMain>
				<SectionMain noPadding item xs={11}>
					{renderMapSales(sales, children, classes)}
				</SectionMain>
			</InfiniteScroll>
		</SectionMain>
	)
}

const renderMapSales = (array, children, classes) => {

	const momentDate = (arrayDate) =>
		moment()
			.year(arrayDate[0])
			.month(arrayDate[1] - 1)
			.date(arrayDate[2])


	console.log(array)


	return array.map((saleByCreateDate) => {
		const [date] = Object.keys(saleByCreateDate)
		const dateArray = (date || "").split("-")
		return (
			<SectionMain noPadding>
				<SectionMain direction="column" noPadding>
					<SectionMain alignItems="center" noPadding>
						<div className={classes.icon} />
						<Title color="#001d7f">
							{date &&
								`${momentDate(dateArray).format("DD")} de ${month[moment(date).format("M")]}
								 de ${momentDate(dateArray).format("YYYY")}`}
						</Title>
					</SectionMain>
				</SectionMain>

				<SectionMain className={classes.card}>
					{saleByCreateDate[
						Object.keys(saleByCreateDate)
					].map((sale) => children({ sale: sale }))}
				</SectionMain>
			</SectionMain>
		)
	})

}
export default ListSalesWrapper
