import React, { memo, useState } from "react"
import {
	Paper,
	Button,
	Hidden,
	IconButton,
	Menu,
	MenuItem,
	Slide,
	Dialog,
	AppBar,
	LinearProgress,
	Stepper,
	Step,
	StepLabel,
	StepConnector,
	withStyles,
} from "@material-ui/core"
import SectionMain from "../../../../../layout/SectionMain"
import Title, {
	TitleSmall,
} from "../../../../../components/checkout-1.5.4/forms/Title"
import ButtonCard from "../../../../../portal/components/cards/ButtonCard"
import StatusCard from "../../../../../portal/components/cards/StatusCard"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import { toCurrency } from "../../../../../utils/commonMasks"
import useLocalDateFunction from "../../../../../utils/useLocalDateFunction"
import { useTranslation } from "../../../../../components/IntlContext/IntlContext"
import TrendingFlatIcon from "@material-ui/icons/TrendingFlatOutlined"
import { useAppContext } from "../../../../../components/AppContextCheckout"
import Stepers from "../../../layout/Stepers"
import getStatusColor from "../../../../../utils/getStatusColor"
import { ListSales } from "../../../list/ListSales"
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"
import { barcode } from "react-icons-kit/fa"

const CardSubscriptionUI = ({
	subscription,
	type = "default",
	handleToggle,
	handleSubscription,
	history,
	onCancelSubscritption,
	sales,
}) => {
	const renderDate = useLocalDateFunction("DATE")
	const nextCharge = subscription.next_charge || ""
	const firstCharge = subscription.initial_charge_date || ""
	const currentCharge = subscription.current_charge || ""

	const t = useTranslation()
	const { company } = useAppContext()

	// MIN = Minimum expected value
	// MAX = Maximium expected value
	// Function to normalise the values (MIN / MAX could be integrated)

	const normalise = (value) =>
		((value - 0) * 100) / (subscription.pricing_options.count - 0)

	const RenderPaymentMean = ({ type }) => {
		switch (type) {
			case "credit_card":
				return (
					<TitleSmall>
						<span
							className="flex-row"
							style={{
								alignItems: "bottom",
							}}
						>
							<img
								src={`https://veripagassets.blob.core.windows.net/brands/${subscription.payment_mean.card.brand.toLowerCase()}.png`}
								width="40px"
								height="23px"
								alt=""
							/>
							<span
								style={{
									marginLeft: 10,
								}}
							>{`${t.generics.last_numbers} ${(
								subscription.payment_mean.card.number || ""
							).substring(
								subscription.payment_mean.card.number.length - 4
							)}`}</span>
						</span>
					</TitleSmall>
				)
			case "boleto":
				return (
					<SectionMain miniPadding>
						<TitleSmall bold>
							{`${t.titles["payment_mean.form"]}: `}
						</TitleSmall>
						<TitleSmall>{"Boleto"}</TitleSmall>
					</SectionMain>
				)
			case "sdd":
				return (
					<SectionMain miniPadding>
						<TitleSmall bold>
							{`${t.titles["payment_mean.form"]}: `}
						</TitleSmall>
						<TitleSmall>{"SEPA"}</TitleSmall>
					</SectionMain>
				)

			default:
				return (
					<SectionMain miniPadding>
						<TitleSmall bold>
							{t.titles["payment_method.not_found"]}
						</TitleSmall>
					</SectionMain>
				)
		}
	}

	if (type === "default") {
		return (
			<SectionMain>
				<Paper style={{ width: "100%" }}>
					<SectionMain
						noPadding
						style={{
							background: getStatusColor(subscription.status),
							height: 5,
						}}
					/>
					<SectionMain style={{ padding: "10px 15px" }}>
						<SectionMain noPadding alignItems="flex-start">
							<div className="flex-row" style={{ width: "100%" }}>
								<SectionMain noPadding>
									<SectionMain noPadding xs={6}>
										<Title>{subscription.plan.name}</Title>
									</SectionMain>
									<SectionMain
										noPadding
										position="flex-end"
										item
										xs={6}
										style={{
											marginTop: "-8px",
											position: "relative",
											flexDirection: "column-reverse",
											alignItems: "flex-end",
										}}
									>
										<StatusCard
											status={subscription.status}
										/>
										<IconButton
											id={`card-${subscription.subscription_id}`}
											size="small"
											onClick={(e) =>
												handleToggle(e, subscription)
											}
										>
											<MoreHorizIcon />
										</IconButton>
									</SectionMain>

									<SectionMain miniPadding>
										<TitleSmall bold>
											{`${toCurrency(
												subscription.plan.amount,
												subscription.plan.currency
													.alpha_code
											)}/${t.frequency_interval_unit[
											subscription.plan.frequency
											]
												}`}
										</TitleSmall>
									</SectionMain>

									{subscription.is_contract && (
										<SectionMain noPadding>
											<SectionMain
												miniPadding
												position="space-between"
											>
												<TitleSmall>
													{
														t.titles[
														"status.subscription"
														]
													}
												</TitleSmall>
												<TitleSmall>
													{subscription.is_contract && (
														<span>{`${subscription.qty_sales} ${t.generics.to} ${subscription.pricing_options.count}`}</span>
													)}
													&nbsp;{t.generics.charges}
												</TitleSmall>
											</SectionMain>
											<SectionMain
												style={{
													width: "100%",
													padding: 2,
													borderRadius: 5,
													border: `1px solid ${company.style.primary_color}63`,
												}}
											>
												<LinearProgress
													variant="determinate"
													value={normalise(
														subscription.qty_sales
													)}
													style={{
														width: "100%",
														borderRadius: 5,
													}}
												/>
											</SectionMain>
										</SectionMain>
									)}
									{subscription.next_charge && (
										<SectionMain>
											<Title id="text-truncate">
												{t.titles["summary.details"]}
											</Title>
											<Stepers
												firstCharge={renderDate(
													firstCharge
												)}
												currentCharge={renderDate(
													currentCharge
												)}
												nextCharge={renderDate(
													nextCharge
												)}
											/>
										</SectionMain>
									)}

									{subscription.last_sale && (
										<SectionMain miniPadding>
											<SectionMain noPadding>
												<Title id="text-truncate">
													{
														t.titles[
														"last_charge.subscription"
														]
													}
												</Title>
											</SectionMain>

											<div className="flex-row center-b">
												<TitleSmall>
													{toCurrency(
														subscription.last_sale
															.amount,
														subscription.plan
															.currency.alpha_code
													)}
												</TitleSmall>
												&nbsp;
												<TrendingFlatIcon
													htmlColor={getStatusColor(
														subscription.last_sale
															.status
													)}
													style={{
														fontSize: 20,
														paddingTop: 2,
													}}
												/>
												&nbsp;
												<TitleSmall>
													<span
														style={{
															textTransform:
																"uppercase",
															fontSize: 14,
															fontWeight: "500",
															color: getStatusColor(
																subscription
																	.last_sale
																	.status
															),
														}}
													>
														{
															t
																.status_subscriptions[
															subscription
																.last_sale
																.status
															]
														}
													</span>
												</TitleSmall>
											</div>
										</SectionMain>
									)}
								</SectionMain>
							</div>
						</SectionMain>
					</SectionMain>
				</Paper>
			</SectionMain>
		)
	}

	if (type === "details") {
		return (
			<SectionMain noPadding>
				<Hidden only={["md", "lg", "xl"]}>
					<SectionMain noPadding style={{ paddingBottom: 10 }}>
						<ButtonCard
							onClick={() => history.push('/')}
							textDecorationLine="none"
							startIcon={<KeyboardBackspaceIcon />}
						>
							{t.generics.goback}
						</ButtonCard>
					</SectionMain>
				</Hidden>
				<Paper style={{ width: "inherit" }}>
					<SectionMain noPadding>
						<SectionMain
							item
							xs={12}
							sm={12}
							md={9}
							lg={9}
							xl={9}
							noPadding
						>
							<SectionMain
								noPadding
								style={{
									background: getStatusColor(
										subscription.status
									),
									height: 5,
								}}
							/>

							<SectionMain style={{ padding: "10px 15px" }}>
								<SectionMain noPadding alignItems="flex-start">
									<div
										className="flex-row"
										style={{ width: "100%" }}
									>
										<SectionMain noPadding>
											<Hidden only={["xs", "sm"]}>
												<SectionMain
													noPadding
													position="space-between"
												>
													<div>

														<Title id="text-truncate">
															{subscription.plan.name}
														</Title>
														<TitleSmall>{subscription.plan.description}</TitleSmall>
													</div>
													<div>

														<StatusCard
															status={
																subscription.status
															}
														/>
													</div>
												</SectionMain>
											</Hidden>
											<Hidden only={["md", "lg", "xl"]}>
												<SectionMain noPadding xs={6}>
													<Title>
														{subscription.plan.name}
													</Title>
												</SectionMain>
												<SectionMain
													position="flex-end"
													item
													xs={6}
												>
													<StatusCard
														status={
															subscription.status
														}
													/>
												</SectionMain>
											</Hidden>
											<SectionMain
												miniPadding
												direction="column"
											>
												<Title bold>
													{`${toCurrency(
														subscription.plan
															.amount,
														subscription.plan
															.currency.alpha_code
													)}/${t
														.frequency_interval_unit[
													subscription.plan
														.frequency
													]
														}`}
												</Title>
												<RenderPaymentMean
													type={
														subscription.payment_method
													}
												/>
											</SectionMain>

											{subscription.is_contract && (
												<SectionMain noPadding>
													<SectionMain
														miniPadding
														position="space-between"
													>
														<TitleSmall>
															{
																t.titles[
																"status.subscription"
																]
															}
														</TitleSmall>
														<TitleSmall>
															{subscription.is_contract && (
																<span>{`${subscription.qty_sales} ${t.generics.to} ${subscription.pricing_options.count}`}</span>
															)}
															&nbsp;
															{t.generics.charges}
														</TitleSmall>
													</SectionMain>
													<SectionMain
														style={{
															width: "100%",
															padding: 2,
															borderRadius: 5,
															border: `1px solid ${company.style.primary_color}63`,
														}}
													>
														<LinearProgress
															variant="determinate"
															value={normalise(
																subscription.qty_sales
															)}
															style={{
																width: "100%",
																borderRadius: 5,
															}}
														/>
													</SectionMain>
												</SectionMain>
											)}
											{subscription.next_charge && (
												<SectionMain>
													<Title id="text-truncate">
														{
															t.titles[
															"summary.details"
															]
														}
													</Title>
													<Stepers
														firstCharge={renderDate(
															firstCharge
														)}
														currentCharge={renderDate(
															currentCharge
														)}
														nextCharge={renderDate(
															nextCharge
														)}
													/>
												</SectionMain>
											)}

											{subscription.last_sale && (
												<SectionMain miniPadding>
													<SectionMain noPadding>
														<Title id="text-truncate">
															{
																t.titles[
																"last_charge.subscription"
																]
															}
														</Title>
													</SectionMain>

													<div className="flex-row center-b">
														<TitleSmall>
															{toCurrency(
																subscription
																	.last_sale
																	.amount,
																subscription
																	.plan
																	.currency
																	.alpha_code
															)}
														</TitleSmall>
														&nbsp;
														<TrendingFlatIcon
															htmlColor={getStatusColor(
																subscription
																	.last_sale
																	.status
															)}
															style={{
																fontSize: 20,
																paddingTop: 2,
															}}
														/>
														&nbsp;
														<TitleSmall>
															<span
																style={{
																	textTransform:
																		"uppercase",
																	fontSize: 14,
																	fontWeight:
																		"500",
																	color:
																		subscription
																			.last_sale
																			.status ===
																			"paid"
																			? "#3DDB47"
																			: "#FF3F3F",
																}}
															>
																{
																	t
																		.status_subscriptions[
																	subscription
																		.last_sale
																		.status
																	]
																}
															</span>
														</TitleSmall>
													</div>
												</SectionMain>
											)}
										</SectionMain>
									</div>
								</SectionMain>
							</SectionMain>
						</SectionMain>

						<Hidden only={["xs", "sm"]}>
							<SectionMain
								miniPadding
								item
								xs={3}
								style={{
									borderLeft: "solid 1px #7b7b7b75",
								}}
							>
								<SectionMain
									noPadding
									style={{
										padding: "0px 15px 80%",

										flexDirection: "column",
										alignItems: "flex-end",
										justifyContent: "space-between",
									}}
								>
									{/* <ButtonCard textDecorationLine="none">{t.titles['payments.subscription']}</ButtonCard>
							<ButtonCard textDecorationLine="none">{t.titles['payments.chat']}</ButtonCard> */}
									{subscription.enable_user_cancelation && (
										<ButtonCard
											textDecorationLine="none"
											color="secondary"
											onClick={onCancelSubscritption}
										>
											{
												t.titles[
												"payments.pause_subscription"
												]
											}
										</ButtonCard>
									)}
									{subscription.payment_method === "credit_card" && (
										<ButtonCard
											textDecorationLine="none"
											onClick={() =>
												history.push(`/edit-payment/${subscription.subscription_id}`)
											}
										>
											{
												t.titles["change_payment_means.subscription"]
											}
										</ButtonCard>
									)}
									<ButtonCard
										onClick={() => history.push('/')}
										textDecorationLine="none"
										startIcon={<KeyboardBackspaceIcon />}
									>
										{t.generics.goback}
									</ButtonCard>
								</SectionMain>
							</SectionMain>
						</Hidden>
						<Hidden only={["md", "lg", "xl"]}>
							<SectionMain
								noPadding
								style={{
									padding: "0px 15px 10px",

									flexDirection: "column",
									alignItems: "flex-start",
									justifyContent: "space-between",
								}}
							>
								{subscription.enable_user_cancelation && (
									<ButtonCard
										textDecorationLine="none"
										onClick={onCancelSubscritption}
										color="secondary"
									>
										{
											t.titles[
											"payments.pause_subscription"
											]
										}
									</ButtonCard>
								)}

								{subscription.payment_method ===
									"credit_card" && (
										<ButtonCard
											textDecorationLine="none"
											onClick={() =>
												history.push(
													`/edit-payment/${subscription.subscription_id}`
												)
											}
										>
											{
												t.titles[
												"change_payment_means.subscription"
												]
											}
										</ButtonCard>
									)}
							</SectionMain>
						</Hidden>
					</SectionMain>
				</Paper>
				<Hidden only={["xs", "sm"]}>
					<SectionMain style={{ padding: 20 }}>
						<ListSales
							subscriptionId={subscription.subscription_id}
						/>
					</SectionMain>
				</Hidden>
				<Hidden only={["md", "lg", "xl"]}>
					<SectionMain>
						<ListSales
							subscriptionId={subscription.subscription_id}
						/>
					</SectionMain>
				</Hidden>
			</SectionMain>
		)
	}

	return ""
}

export default CardSubscriptionUI
