import React from 'react'
import SectionMain from '../../../../layout/SectionMain'
import { Grid, Hidden, OutlinedInput } from '@material-ui/core'
import TextInput, { TextInputPhone, TextInputMasked } from '../TextInput'
import AppContext from '../../../AppContextCheckout'
import SelectedInput from '../SelectedInput'
import MaskedInput from 'react-text-mask'
import request from 'superagent'
import { flagReValidators, getBrand } from '../../../../core/cards-utils'
import generateMask from '../../../../core/generateMask'
import { useTranslation } from '../../../IntlContext/IntlContext'
import CardBrand from '../../../../portal/components/cards/CardBrand'

/**
 * 
 * @returns {import("../locale/locale").TranslationFunction} 
 */

const RenderInputsV2 = ({ inputs = [], checkout_config = {}, formikProps, portalFixLayout }) => {
	const t = useTranslation()

	const maskType = (type, brand) => {
		switch (type) {
			case 'CNPJ':
				return generateMask('99.999.999/9999-99')

			case 'CPF':
				return generateMask('999.999.999-99')

			case 'CARD':
				return generateMask(flagReValidators[brand || 'default'].raw_mask)

			case 'CVV':
				return generateMask(flagReValidators[brand || 'default'].raw_mask_cvv)

			default:
				return []
				break
		}
	}

	/**
   * @param {React.FocusEvent<HTMLInputElement>} event
   */
	function handleFocus(event) {
		let lastDigitIndex = 0
		const { target } = event
		const { value } = target
		for (let i = value.length - 1; i >= 0; i--) {
			if (value[i].match(/\d/)) {
				lastDigitIndex = i + 1
				break
			}
		}
		setTimeout(() => {
			target.setSelectionRange(lastDigitIndex, lastDigitIndex)
		})
	}

	return inputs.map((input, i) => {
		const getviaCepUrl = (postal_code = '', handleFormikValues) => {
			if (postal_code.length === 8) {
				let next_address = request.get(`https://viacep.com.br/ws/${postal_code}/json`)
				next_address.end((err, response) => {
					if (err) {
						console.log(err)
					} else {
						const { logradouro, cep, localidade, bairro, uf } = response.body
						const address = {
							...handleFormikValues.values[`${input.name}`],
							postal_code: cep,
							street1: logradouro,
							district: bairro,
							state: uf,
							city: localidade
						}
						handleFormikValues.setFieldValue(`${input.name}`, { ...address })
					}
				})
			}
		}

		const handleBrand = async (number) => {
			const newbrand = await getBrand(number)
			formikProps.setFieldValue(`payment_mean`, {
				...formikProps.values.payment_mean,
				brand: newbrand,
				card_number: number
			})
		}

		switch (input.type) {
			case 'name':
				// if (checkout_config[`${input.name}`])
				return (
					<SectionMain noPadding position="space-between">
						<div id="form-input" style={{ width: '100%' }}>
							<Grid item xs={12}>
								<TextInput
									errorMessage={formikProps.touched.first_name && formikProps.errors.first_name}
									disabled={formikProps.values.disabled_input}
									label={t('^forms.first_name', '*')}
									value={formikProps.values['first_name']}
									onChange={(e) => formikProps.setFieldValue(`first_name`, e.target.value)}
								/>
							</Grid>
						</div>
						<div id="form-input" style={{ width: '100%' }}>
							<Grid item xs={12}>
								<TextInput
									errorMessage={formikProps.touched.last_name && formikProps.errors.last_name}
									disabled={formikProps.values.disabled_input}
									label={t('^forms.last_name', '*')}
									value={formikProps.values['last_name']}
									onChange={(e) => formikProps.setFieldValue(`last_name`, e.target.value)}
								/>
							</Grid>
						</div>
					</SectionMain>
				)

			case 'email':
				if (checkout_config[`${input.name}`])
					return (
						<SectionMain>
							<TextInput
								type="email"
								errorMessage={formikProps.touched.email && formikProps.errors.email}
								disabled={formikProps.values.disabled_input}
								label={t('^forms.email', '*')}
								value={formikProps.values['email']}
								onChange={(e) => formikProps.setFieldValue(`email`, e.target.value)}
							/>
						</SectionMain>
					)

			case 'document':
				if (checkout_config[`${input.name}`])
					return (
						<SectionMain noPadding position="space-between">
							<div id="form-input" style={{ width: '100%' }}>
								<Grid item xs={12}>
									<SelectedInput
										disabled={formikProps.values.disabled_input}
										value={formikProps.values['document'].type}
										onChange={(e) =>
											formikProps.setFieldValue(`document`, {
												type: e.target.value,
												number: formikProps.values[`${input.name}`].number
											})}
										arrayValues={[
											{ value: 'CPF', label: 'CPF' },

											{ value: 'CNPJ', label: 'CNPJ' }
										]}
										label={t('^forms.document_type', '*')}
									/>
								</Grid>
							</div>
							<div id="form-input" style={{ width: '100%' }}>
								<Grid item xs={12}>
									<MaskedInput
										// autoComplete="new-password"
										errorMessage={
											formikProps.touched[`${input.name}`] &&
											formikProps.touched[`${input.name}`].number &&
											formikProps.errors[`${input.name}`] &&
											formikProps.errors[`${input.name}`].number
										}
										disabled={formikProps.values.disabled_input}
										value={formikProps.values['document'].number}
										onFocus={handleFocus}
										onChange={(e) => {
											const value = e.target.value

											formikProps.setFieldValue(`document`, {
												number: value,
												type: formikProps.values[`${input.name}`].type
											})
										}}
										mask={() => maskType(formikProps.values[`${input.name}`].type)}
										placeholderChar={'\u2000'}
										render={(ref, props) => (
											<TextInputMasked
												type="tel"
												label={t('^forms.document_number', '*')}
												inputRef={ref}
												{...props}
											/>
										)}
									/>
								</Grid>
							</div>
						</SectionMain>
					)

				return ''
			case 'phone':
				if (checkout_config[`${input.name}`])
					return (
						<SectionMain
							xl={6}
							lg={6}
							md={6}
							style={{ paddingRight: 20, paddingTop: 20, paddingBottom: 20 }}
						>
							<TextInputPhone
								type="tel"
								errorMessage={formikProps.touched.mobile_phone && formikProps.errors.mobile_phone}
								disabled={formikProps.values.disabled_input}
								style={{ opacity: formikProps.values.disabled_input ? 0.5 : 1 }}
								label={t('^forms.document_type')}
								value={formikProps.values['mobile_phone']}
								onChange={(value) => formikProps.setFieldValue(`mobile_phone`, value)}
							/>
						</SectionMain>
					)
				return ''
			case 'country':
				if (checkout_config[`${input.name}`])
					return (
						<SectionMain>
							<SelectedInput
								// errorMessage={formikProps.touched.first_name && formikProps.errors.first_name}
								disabled={formikProps.values.disabled_input}
								value={formikProps.values['country_code']}
								onChange={(e) => formikProps.setFieldValue(`country_code`, e.target.value)}
								arrayValues={[
									{ value: 'BRA', label: 'Brasil' },
									{ value: 'FRA', label: 'França' },
									{ value: 'PER', label: 'Brasil' },
									{ value: 'ARG', label: 'Argentina' },
									{ value: 'COL', label: 'Colombia' }
								]}
								label={t('^forms.country')}
							/>
						</SectionMain>
					)
				return ''
			case 'address':
				if (checkout_config[`${input.name}`])
					return (
						<SectionMain noPadding>
							<SectionMain noPadding>
								<div id="form-input" style={{ width: '100%' }}>
									<Grid item xs={12}>
										<MaskedInput
											errorMessage={
												formikProps.touched[`${input.name}`] &&
												formikProps.touched[`${input.name}`].postal_code &&
												formikProps.errors[`${input.name}`] &&
												formikProps.errors[`${input.name}`].postal_code
											}
											disabled={formikProps.values.disabled_input}
											value={formikProps.values[`${input.name}`].postal_code}
											onFocus={handleFocus}
											onChange={(e) => {
												const value = (e.target.value.toString().match(/\d/g) || []).join('')
												getviaCepUrl(value, formikProps)

												formikProps.setFieldValue(`${input.name}`, {
													...formikProps.values[`${input.name}`],
													postal_code: e.target.value
												})
											}}
											mask={() => generateMask('99999-999')}
											placeholderChar={'\u2000'}
											autoComplete="postal-code"
											render={(ref, props) => (
												<TextInputMasked
													type="tel"
													label={t('^forms.postal_code', '*')}
													inputRef={ref}
													{...props}
												/>
											)}
										/>
									</Grid>
								</div>
							</SectionMain>
							<SectionMain>
								<TextInput
									errorMessage={
										formikProps.touched[`${input.name}`] &&
										formikProps.touched[`${input.name}`].street1 &&
										formikProps.errors[`${input.name}`] &&
										formikProps.errors[`${input.name}`].street1
									}
									disabled={formikProps.values.disabled_input}
									label={t('^forms.street', '*')}
									value={formikProps.values[`${input.name}`].street1}
									onChange={(e) =>
										formikProps.setFieldValue(`${input.name}`, {
											...formikProps.values[`${input.name}`],
											street1: e.target.value
										})}
								/>
							</SectionMain>
							<SectionMain noPadding position="space-between">
								<div id="form-input" style={{ width: '100%' }}>
									<Grid item xs={12}>
										<TextInput
											errorMessage={
												formikProps.touched[`${input.name}`] &&
												formikProps.touched[`${input.name}`].number &&
												formikProps.errors[`${input.name}`] &&
												formikProps.errors[`${input.name}`].number
											}
											disabled={formikProps.values.disabled_input}
											label={t('^forms.street_number', '*')}
											value={formikProps.values[`${input.name}`].number}
											onChange={(e) =>
												formikProps.setFieldValue(`${input.name}`, {
													...formikProps.values[`${input.name}`],
													number: e.target.value
												})}
										/>
									</Grid>
								</div>
								<div id="form-input" style={{ width: '100%' }}>
									<Grid item xs={12}>
										<TextInput
											errorMessage={
												formikProps.touched[`${input.name}`] &&
												formikProps.touched[`${input.name}`].district &&
												formikProps.errors[`${input.name}`] &&
												formikProps.errors[`${input.name}`].district
											}
											disabled={formikProps.values.disabled_input}
											label={t('^forms.district', '*')}
											value={formikProps.values[`${input.name}`].district}
											onChange={(e) =>
												formikProps.setFieldValue(`${input.name}`, {
													...formikProps.values[`${input.name}`],
													district: e.target.value
												})}
										/>
									</Grid>
								</div>
							</SectionMain>
							<SectionMain noPadding position="space-between">
								<div id="form-input" style={{ width: '100%' }}>
									<Grid item xs={12}>
										<TextInput
											errorMessage={
												formikProps.touched[`${input.name}`] &&
												formikProps.touched[`${input.name}`].city &&
												formikProps.errors[`${input.name}`] &&
												formikProps.errors[`${input.name}`].city
											}
											disabled={formikProps.values.disabled_input}
											label={t('^forms.city', '*')}
											value={formikProps.values[`${input.name}`].city}
											onChange={(e) =>
												formikProps.setFieldValue(`${input.name}`, {
													...formikProps.values[`${input.name}`],
													city: e.target.value
												})}
										/>
									</Grid>
								</div>
								<div id="form-input" style={{ width: '100%' }}>
									<Grid item xs={12}>
										<TextInput
											errorMessage={
												formikProps.touched[`${input.name}`] &&
												formikProps.touched[`${input.name}`].state &&
												formikProps.errors[`${input.name}`] &&
												formikProps.errors[`${input.name}`].state
											}
											disabled={formikProps.values.disabled_input}
											label={t('^forms.state', '*')}
											value={formikProps.values[`${input.name}`].state}
											onChange={(e) =>
												formikProps.setFieldValue(`${input.name}`, {
													...formikProps.values[`${input.name}`],
													state: e.target.value
												})}
										/>
									</Grid>
								</div>
							</SectionMain>
							<SectionMain>
								<TextInput
									value={formikProps.values[`${input.name}`].complement}
									disabled={formikProps.values.disabled_input}
									label={t('^forms.complement')}
									onChange={(e) =>
										formikProps.setFieldValue(`${input.name}`, {
											...formikProps.values[`${input.name}`],
											complement: e.target.value
										})}
								/>
							</SectionMain>
						</SectionMain>
					)

				return ''

			case 'payment':
				return (
					<SectionMain
						noPadding
						xs={12}
						sm={12}
						md={portalFixLayout ? 12 : 8}
						lg={portalFixLayout ? 12 : 8}
						xl={portalFixLayout ? 12 : 8}
					>
						<Hidden only={[ 'xs', 'sm', 'md' ]}>
							<SectionMain>
								<MaskedInput
									type="tel"
									onFocus={handleFocus}
									onBlur={() => handleBrand(formikProps.values.payment_mean.card_number)}
									errorMessage={
										formikProps.touched.payment_mean &&
										formikProps.touched.payment_mean.card_number &&
										formikProps.errors.payment_mean &&
										formikProps.errors.payment_mean.card_number
									}
									value={formikProps.values.payment_mean.card_number}
									onChange={(e) => {
										const number = (e.target.value.match(/\d/g) || []).join('')
										formikProps.setFieldValue(`payment_mean`, {
											...formikProps.values.payment_mean,
											card_number: number
										})
									}}
									mask={() => maskType('CARD', formikProps.values.payment_mean.brand)}
									placeholderChar={'\u2000'}
									render={(ref, props) => (
										<TextInputMasked
											label={t('^forms.card_number', '*')}
											autoComplete="cc-number"
											inputRef={ref}
											rigthElement={
												<div style={{ paddingBottom: '5px' }} className="flex-row center-a">
													{[ 'visa', 'mastercard', 'amex', 'elo' ].map((brand) => (
														<CardBrand
															cardBrand={formikProps.values.payment_mean.brand}
															brand={brand}
														/>
													))}
												</div>
											}
											{...props}
										/>
									)}
								/>
							</SectionMain>
						</Hidden>
						<Hidden only={[ 'lg', 'xl' ]}>
							<SectionMain>
								<div style={{ paddingBottom: '5px' }} className="flex-row center-a">
									{[ 'visa', 'mastercard', 'amex', 'elo' ].map((brand) => (
										<CardBrand cardBrand={formikProps.values.payment_mean.brand} brand={brand} />
									))}
								</div>
							</SectionMain>
							<SectionMain>
								<MaskedInput
									type="tel"
									onFocus={handleFocus}
									onBlur={() => handleBrand(formikProps.values.payment_mean.card_number)}
									errorMessage={
										formikProps.touched.payment_mean &&
										formikProps.touched.payment_mean.card_number &&
										formikProps.errors.payment_mean &&
										formikProps.errors.payment_mean.card_number
									}
									value={formikProps.values.payment_mean.card_number}
									onChange={(e) => {
										const number = (e.target.value.match(/\d/g) || []).join('')
										formikProps.setFieldValue(`payment_mean`, {
											...formikProps.values.payment_mean,
											card_number: number
										})
									}}
									mask={() => maskType('CARD', formikProps.values.payment_mean.brand)}
									placeholderChar={'\u2000'}
									// showMask
									render={(ref, props) => (
										<TextInputMasked
											{...props}
											autoComplete="cc-number"
											label={t('^forms.card_number', '*')}
											inputRef={ref}
										/>
									)}
								/>
							</SectionMain>
						</Hidden>
						<SectionMain noPadding position="space-between">
							<div id="form-input" style={{ width: '100%' }}>
								<Grid item xs={12}>
									<MaskedInput
										type="tel"
										onFocus={handleFocus}
										errorMessage={
											formikProps.touched.payment_mean &&
											formikProps.touched.payment_mean.expiry_date &&
											formikProps.errors.payment_mean &&
											formikProps.errors.payment_mean.expiry_date
										}
										value={formikProps.values.payment_mean.expiry_date}
										onChange={(e) =>
											formikProps.setFieldValue(`payment_mean`, {
												...formikProps.values.payment_mean,
												expiry_date: e.target.value
											})}
										mask={[ /[0-1]/, /[0-9]/, '/', /[2-5]/, /[0-9]/ ]}
										placeholderChar={'\u2000'}
										render={(ref, props) => (
											<TextInputMasked
												{...props}
												label={t('^forms.expiry_date', '*')}
												inputRef={ref}
											/>
										)}
									/>
								</Grid>
							</div>
							<div id="form-input" style={{ width: '100%' }}>
								<Grid item xs={12}>
									<MaskedInput
										type="tel"
										onFocus={handleFocus}
										errorMessage={
											formikProps.touched.payment_mean &&
											formikProps.touched.payment_mean.cvv &&
											formikProps.errors.payment_mean &&
											formikProps.errors.payment_mean.cvv
										}
										value={formikProps.values.payment_mean.cvv}
										onChange={(e) =>
											formikProps.setFieldValue(`payment_mean`, {
												...formikProps.values.payment_mean,
												cvv: (e.target.value.match(/\d/g) || []).join('')
											})}
										mask={() => maskType('CVV', formikProps.values.payment_mean.brand)}
										placeholderChar={'\u2000'}
										// showMask
										render={(ref, props) => (
											<TextInputMasked
												{...props}
												label={t('^forms.cvv', '*')}
												autoComplete="cc-csc"
												inputRef={ref}
											/>
										)}
									/>
								</Grid>
							</div>
						</SectionMain>
						<SectionMain>
							<TextInput
								label={t('^forms.customer_name', '*')}
								errorMessage={
									formikProps.touched.payment_mean &&
									formikProps.touched.payment_mean.customer_name &&
									formikProps.errors.payment_mean &&
									formikProps.errors.payment_mean.customer_name
								}
								value={formikProps.values.payment_mean.customer_name}
								onChange={(e) =>
									formikProps.setFieldValue(`payment_mean`, {
										...formikProps.values[`${input.name}`],
										customer_name: e.target.value
									})}
							/>
						</SectionMain>
					</SectionMain>
				)

			default:
				return ''
		}
	})
}

export default RenderInputsV2
