import React from 'react';
import './index.css';
import ExitIntent from '../../utils/ExitIntent';
import { Button } from '@material-ui/core';
import CheckoutContext from '../checkouts/CheckoutContext';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const ExitIntentModal = ({
    children,
    onExitIntent,
    onExitIntentCancel,
    disabled = false,
}) => {

    const [showModal, setShowModal] = React.useState(false);
    const [exitIntent, setExitIntent] = React.useState(false);

    const cookies = document.cookie.split(';').map(item => item.split('=')).reduce((acc, [k, v]) => (acc[k.trim().replace('"', '')] = v) && acc, {}) || {};

    const { exit_intent_show } = cookies.exit_intent_show ? cookies : { exit_intent_show: 'true' };


    if (exit_intent_show === 'false') {
        disabled = true;
    }
    const handleModalClose = () => {
        setShowModal(false);
        setExitIntent(false);
    };

    React.useEffect(() => {
        setTimeout(() => {
            const removeExitIntent = ExitIntent({
                threshold: 10,
                eventThrottle: 100,
                onExitIntent: () => {
                    setShowModal(true)
                },
            })
            return () => {
                removeExitIntent()
            }
        }, 300)
    }, [])

    return (
        <div>
            {children}
            <CheckoutContext.Consumer>
                {({ checkout }) => {
                    return <ExitIntentModalContent showModal={!disabled && showModal} handleModalClose={handleModalClose} checkout={checkout} values={checkout.checkout_config.exit_intent_modal} />
                }}
            </CheckoutContext.Consumer>
        </div>
    );

}


const ExitIntentModalContent = ({ showModal, handleModalClose = () => null, values = {}, checkout = {} }) => {
    const date = new Date();

    const hsitory = useHistory()

    const path = hsitory.location.pathname

    function isBackgroundDark(hexcolor = "#000000") {
        hexcolor = hexcolor.replace("#", "");
        var r = parseInt(hexcolor.substr(0, 2), 16);
        var g = parseInt(hexcolor.substr(2, 2), 16);
        var b = parseInt(hexcolor.substr(4, 2), 16);
        var yiq = (r * 299 + g * 587 + b * 114) / 1000;
        return yiq < 128;
    }

    if (values === null) {
        return null;
    }


    return (
        <div className={`exit-intent-modal${showModal ? "" : " no-display"}`}>
            <div className="exit-intent-modal__container">
                <div className="exit-intent-modal__content">
                    <div>
                        <h1>{values.title}</h1>
                        <h3>
                            {values.message}
                        </h3>
                    </div>
                    <div className='exit-intent-modal__buttons'>

                        {values.coupon_code &&

                            <CopyToClipboard text={values.coupon_code}
                                onCopy={() => {
                                    handleModalClose()
                                    document.cookie = `exit_intent_show=false; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=${path}`;
                                }}>
                                <Button
                                    style={{
                                        backgroundColor: checkout.company?.style.primary_color ?
                                            checkout.company?.style.primary_color
                                            : '#fff',
                                        color: isBackgroundDark(checkout.company?.style.primary_color || '#fff') ? 'white' : 'black',
                                    }}
                                    color="primary" variant="contained">Copiar cupom</Button>
                            </CopyToClipboard>}

                        <Button
                            style={{
                                backgroundColor: checkout.company?.style.primary_color ?
                                    checkout.company?.style.primary_color
                                    : '#fff',
                                color: isBackgroundDark(checkout.company?.style.primary_color || '#fff') ? 'white' : 'black',
                            }}
                            color="primary" variant="contained" onClick={() => {
                                document.cookie = `exit_intent_show=false; expires=${new Date(Date.now() + 0.5 * 60 * 1000).toUTCString()}`;
                                handleModalClose()
                            }}>Fechar este aviso</Button>
                        <Button
                            style={{
                                backgroundColor: checkout.company?.style.primary_color ?
                                    checkout.company?.style.primary_color
                                    : '#fff',
                                color: isBackgroundDark(checkout.company?.style.primary_color || '#fff') ? 'white' : 'black',
                            }}
                            color="primary" variant="contained" onClick={() => {
                                document.cookie = `exit_intent_show=false; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=${path}`;
                                handleModalClose()
                            }}>Não mostrar esse aviso novamente</Button>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExitIntentModal;