import React from "react";
import SectionMain from "../../../../../layout/SectionMain";
import { useTranslation } from "../../../../IntlContext/IntlContext";
import TextInput, { TextInputMasked, TextInputPhone } from "../../TextInput";
import SelectedInput from "../../SelectedInput";
import MaskedInput from "react-text-mask";
import generateMask from "../../../../../core/generateMask";
import { flagReValidators } from "../../../../../core/cards-utils";
import { getviaCepUrl } from "../../../../../utils/oldGetViaCepUrl";
import { Hidden } from "@material-ui/core";
import { handleBrand } from "../../../../../utils/handleBrand";
import CardBrand from "../../../../../portal/components/cards/CardBrand";
import AppContext, { useAppContext } from "../../../../AppContextCheckout";
import { validaCPF } from "../../../../../utils/validaCPFeCNPJ";
/**@returns {import("../../../../../locale/locale").TranslationFunction} */

//variavel para controlar mascara de input documento
let documentType = "CPF";

const RenderInputsUI = ({
	inputType = "",
	inputName,
	portalFixLayout,
	formikProps,
	brands = [],
}) => {
	const t = useTranslation();
	const { language } = useAppContext(AppContext);

	const getDocument = (lang) => {
		switch (lang) {
			case "pt-BR":
				return [
					{ value: "CPF", label: "CPF" },
					{ value: "CNPJ", label: "CNPJ" },
				];

			case "fr":
				return [{ value: "CNI", label: "CNI" }];

			default:
				return "br";
		}
	};

	//returns cursor to first position on click input
	function handleFocus(event) {
		let lastDigitIndex = 0;
		const { target } = event;
		const { value } = target;
		for (let i = value.length - 1; i >= 0; i--) {
			if (value[i].match(/\d/)) {
				lastDigitIndex = i + 1;
				break;
			}
		}
		setTimeout(() => {
			target.setSelectionRange(lastDigitIndex, lastDigitIndex);
		});
	}

	const maskType = (type, brand) => {
		switch (type) {
			case "CNPJ":
				return generateMask("99.999.999/9999-99");

			case "CPF":
				return generateMask("999.999.999-99");

			case "CNI":
				return generateMask("99999999999999");

			case "CARD":
				return generateMask(
					flagReValidators[brand || "default"].raw_mask
				);

			case "CVV":
				return generateMask(
					flagReValidators[brand || "default"].raw_mask_cvv
				);

			default:
				return [];
				break;
		}
	};

	switch (inputType) {
		case "first_name":
			return (
				<SectionMain xl={6} lg={6} md={6}>
					<style>
						{`@media only screen and (min-width: 1024px) {
							#form-input-section {
								width:90% !important ;
								
							}}
							`}
					</style>
					<div id="form-input-section" style={{ width: "100%" }}>
						<TextInput
							errorMessage={
								formikProps.touched.first_name &&
								formikProps.errors.first_name
							}
							disabled={formikProps.values.disabled_input}
							label={t("^forms.first_name", "*")}
							value={formikProps.values["first_name"]}
							autoComplete="first_name"
							onChange={(e) =>
								formikProps.setFieldValue(
									`first_name`,
									e.target.value
								)
							}
						/>
					</div>
				</SectionMain>
			);
		case "last_name":
			return (
				<SectionMain xl={6} lg={6} md={6}>
					<style>
						{`@media only screen and (min-width: 1024px) {
							#form-input-section-right {
								display:flex;
								justify-content:flex-end !important;
							}
						}
							`}
					</style>
					<div
						id="form-input-section-right"
						className="flex-row flex-end"
						style={{ width: "100%" }}
					>
						{/* <SectionMain> */}
						<TextInput
							id="form-input-section"
							autoComplete="off"
							errorMessage={
								formikProps.touched.last_name &&
								formikProps.errors.last_name
							}
							disabled={formikProps.values.disabled_input}
							label={t("^forms.last_name", "*")}
							value={formikProps.values["last_name"]}
							onChange={(e) =>
								formikProps.setFieldValue(
									`last_name`,
									e.target.value
								)
							}
						/>
						{/* </SectionMain> */}
					</div>
				</SectionMain>
			);
		case "email":
			return (
				<SectionMain>
					<TextInput
						type="email"
						errorMessage={
							formikProps.touched.email &&
							formikProps.errors.email
						}
						disabled={formikProps.values.disabled_input}
						label={t("^forms.email", "*")}
						autoComplete="email"
						value={formikProps.values["email"]}
						onChange={(e) =>
							formikProps.setFieldValue(`email`, e.target.value)
						}
					/>
				</SectionMain>
			);
		case "document":
			return (
				<SectionMain noPadding position="space-between">
					<SectionMain xl={6} lg={6} md={6}>
						<div id="form-input-section" style={{ width: "100%" }}>
							<SelectedInput
								disabled={formikProps.values.disabled_input}
								value={formikProps.values["document"].type}
								onChange={(e) => {
									documentType = e.target.value;
									formikProps.setFieldValue(
										`document.type`,
										e.target.value
									);
								}}
								arrayValues={getDocument(language)}
								label={t("^forms.document_type", "*")}
								errorMessage={
									formikProps.touched[`document`] &&
									formikProps.touched[`document`].number &&
									formikProps.errors[`document`] &&
									formikProps.errors[`document`].number
								}
							/>
						</div>
					</SectionMain>
					<SectionMain xl={6} lg={6} md={6}>
						<div
							id="form-input-section-right"
							style={{ width: "100%" }}
						>
							{language === "fr" ? (
								<TextInput
									errorMessage={
										formikProps.touched[`document`] &&
										formikProps.touched[`document`]
											.number &&
										formikProps.errors[`document`] &&
										formikProps.errors[`document`].number
									}
									disabled={formikProps.values.disabled_input}
									label={t("^forms.document_number", "*")}
									autoComplete="document"
									value={
										formikProps.values["document"].number
									}
									onChange={(e) => {
										const value = e.target.value;

										formikProps.setFieldValue(`document`, {
											number: value,
											type:
												formikProps.values[`document`]
													.type,
										});
									}}
								/>
							) : (
								<MaskedInput
									errorMessage={
										formikProps.touched[`document`] &&
										formikProps.touched[`document`]
											.number &&
										formikProps.errors[`document`] &&
										formikProps.errors[`document`].number
									}
									disabled={formikProps.values.disabled_input}
									autoComplete="on"
									value={
										formikProps.values["document"].number
									}
									onFocus={handleFocus}
									onChange={(e) => {
										const value = e.target.value;

										formikProps.setFieldValue(`document`, {
											number: value,
											type:
												formikProps.values[`document`]
													.type,
										});
									}}
									mask={() => {
										return maskType(documentType);
									}}
									placeholderChar={"\u2000"}
									render={(ref, props) => (
										<TextInputMasked
											id="form-input-section"
											type="tel"
											label={t(
												"^forms.document_number",
												"*"
											)}
											inputRef={ref}
											{...props}
										/>
									)}
								/>
							)}
						</div>
					</SectionMain>
				</SectionMain>
			);

		case "mobile_number":
			const phoneMasks = {
				fr: ". .. .. .. ..",
				br: "(..) .....-....",
			}
			return (
				<SectionMain xl={6} lg={6} md={6}>
					<div id="form-input-section" style={{ width: "100%" }}>
						<style>
							{`
						
							.react-tel-input .special-label {
								width: ${language === "fr" && "110px"} !important;
								color: ${formikProps.touched &&
									formikProps.touched.mobile_phone &&
									formikProps.errors &&
									formikProps.errors.mobile_phone
									? "#f44336"
									: "#0000008a"
								};
								font-size: 12px;
								top: -9px;
								left: 10px;
							}
							
							`}
						</style>
						<TextInputPhone
							type="tel"
							errorMessage={
								formikProps.touched &&
								formikProps.touched.mobile_phone &&
								formikProps.errors &&
								formikProps.errors.mobile_phone
							}
							disabled={formikProps.values.disabled_input}
							style={{
								opacity: formikProps.values.disabled_input
									? 0.5
									: 1,
							}}
							//removido por causa do bug na label
							label={`*${t("^forms.mobile_phone")}`}
							value={formikProps.values["mobile_phone"]}
							masks={phoneMasks}
							onChange={(value) => {
								formikProps.setFieldValue(
									`mobile_phone`,
									value
								);
							}}
						/>
					</div>
				</SectionMain>
			);
		case "country_code":
			return (
				<SectionMain>
					<SelectedInput
						disabled={true}
						value={formikProps.values["country_code"]}
						onChange={(e) =>
							formikProps.setFieldValue(
								`country_code`,
								e.target.value
							)
						}
						arrayValues={
							formikProps.values["country_code"] === "FRA"
								? [{ value: "FRA", label: "France" }]
								: [
									{ value: "BRA", label: "Brasil" },
									{ value: "FRA", label: "França" },
									{ value: "PER", label: "Perú" },
									{ value: "ARG", label: "Argentina" },
									{ value: "COL", label: "Colombia" },
								]
						}
						label={t("^forms.country")}
					/>
				</SectionMain>
			);

		case "address":
			return (
				<SectionMain noPadding>
					<SectionMain xl={6} lg={6} md={6}>
						<div id="form-input-section" style={{ width: "100%" }}>
							<MaskedInput
								errorMessage={
									formikProps.touched[`${inputName}`] &&
									formikProps.touched[`${inputName}`]
										.postal_code &&
									formikProps.errors[`${inputName}`] &&
									formikProps.errors[`${inputName}`]
										.postal_code
								}
								disabled={formikProps.values.disabled_input}
								value={
									formikProps.values[`${inputName}`] &&
									formikProps.values[`${inputName}`]
										.postal_code
								}
								onFocus={handleFocus}
								onChange={(e) => {
									const value = (
										e.target.value
											.toString()
											.match(/\d/g) || []
									).join("");
									getviaCepUrl(value, formikProps, inputName);
									formikProps.setFieldValue(`${inputName}`, {
										...formikProps.values[`${inputName}`],
										postal_code: e.target.value,
									});
								}}
								mask={() => generateMask("99999-999")}
								placeholderChar={"\u2000"}
								autoComplete="postal-code"
								render={(ref, props) => (
									<TextInputMasked
										type="tel"
										label={t("^forms.postal_code", "*")}
										inputRef={ref}
										{...props}
									/>
								)}
							/>
						</div>
					</SectionMain>
					<SectionMain>
						<TextInput
							errorMessage={
								formikProps.touched[`${inputName}`] &&
								formikProps.touched[`${inputName}`].street1 &&
								formikProps.errors[`${inputName}`] &&
								formikProps.errors[`${inputName}`].street1
							}
							disabled={formikProps.values.disabled_input}
							label={t("^forms.street", "*")}
							InputLabelProps={{
								shrink: Boolean(formikProps.values[`${inputName}`] && formikProps.values[`${inputName}`].street1)
							}}
							value={
								formikProps.values[`${inputName}`] &&
								formikProps.values[`${inputName}`].street1
							}
							onChange={(e) =>
								formikProps.setFieldValue(`${inputName}`, {
									...formikProps.values[`${inputName}`],
									street1: e.target.value,
								})
							}
						/>
					</SectionMain>
					<SectionMain xl={6} lg={6} md={6}>
						<div id="form-input-section" style={{ width: "100%" }}>
							<TextInput
								errorMessage={
									formikProps.touched[`${inputName}`] &&
									formikProps.touched[`${inputName}`]
										.number &&
									formikProps.errors[`${inputName}`] &&
									formikProps.errors[`${inputName}`].number
								}
								disabled={formikProps.values.disabled_input}
								label={t("^forms.street_number", "*")}
								InputLabelProps={{
									shrink: Boolean(formikProps.values[`${inputName}`] && formikProps.values[`${inputName}`].number)
								}}
								value={
									formikProps.values[`${inputName}`] &&
									formikProps.values[`${inputName}`].number
								}
								onChange={(e) =>
									formikProps.setFieldValue(`${inputName}`, {
										...formikProps.values[`${inputName}`],
										number: e.target.value,
									})
								}
							/>
						</div>
					</SectionMain>
					<SectionMain xl={6} lg={6} md={6}>
						<div
							id="form-input-section-right"
							style={{ width: "100%" }}
						>
							<TextInput
								errorMessage={
									formikProps.touched[`${inputName}`] &&
									formikProps.touched[`${inputName}`]
										.district &&
									formikProps.errors[`${inputName}`] &&
									formikProps.errors[`${inputName}`].district
								}
								disabled={formikProps.values.disabled_input}
								label={t("^forms.district", "*")}
								InputLabelProps={{
									shrink: Boolean(formikProps.values[`${inputName}`] && formikProps.values[`${inputName}`].district)
								}}
								value={
									formikProps.values[`${inputName}`] &&
									formikProps.values[`${inputName}`].district
								}
								onChange={(e) =>
									formikProps.setFieldValue(`${inputName}`, {
										...formikProps.values[`${inputName}`],
										district: e.target.value,
									})
								}
							/>
						</div>
					</SectionMain>
					<SectionMain xl={6} lg={6} md={6}>
						<div id="form-input-section" style={{ width: "100%" }}>
							<TextInput
								errorMessage={
									formikProps.touched[`${inputName}`] &&
									formikProps.touched[`${inputName}`].city &&
									formikProps.errors[`${inputName}`] &&
									formikProps.errors[`${inputName}`].city
								}
								disabled={formikProps.values.disabled_input}
								label={t("^forms.city", "*")}
								InputLabelProps={{
									shrink: Boolean(formikProps.values[`${inputName}`] && formikProps.values[`${inputName}`].city)
								}}
								value={
									formikProps.values[`${inputName}`] &&
									formikProps.values[`${inputName}`].city
								}
								onChange={(e) =>
									formikProps.setFieldValue(`${inputName}`, {
										...formikProps.values[`${inputName}`],
										city: e.target.value,
									})
								}
							/>
						</div>
					</SectionMain>
					<SectionMain xl={6} lg={6} md={6}>
						<div
							id="form-input-section-right"
							style={{ width: "100%" }}
						>
							<TextInput
								errorMessage={
									formikProps.touched[`${inputName}`] &&
									formikProps.touched[`${inputName}`].state &&
									formikProps.errors[`${inputName}`] &&
									formikProps.errors[`${inputName}`].state
								}
								disabled={formikProps.values.disabled_input}
								label={t("^forms.state", "*")}
								InputLabelProps={{
									shrink: Boolean(formikProps.values[`${inputName}`] && formikProps.values[`${inputName}`].state)
								}}
								value={
									formikProps.values[`${inputName}`] &&
									formikProps.values[`${inputName}`].state
								}
								onChange={(e) =>
									formikProps.setFieldValue(`${inputName}`, {
										...formikProps.values[`${inputName}`],
										state: e.target.value,
									})
								}
							/>
						</div>
					</SectionMain>
					<SectionMain>
						<TextInput
							InputLabelProps={{
								shrink: Boolean(formikProps.values[`${inputName}`] && formikProps.values[`${inputName}`].complement)
							}}
							value={
								formikProps.values[`${inputName}`] &&
								formikProps.values[`${inputName}`].complement
							}
							disabled={formikProps.values.disabled_input}
							label={t("^forms.complement")}
							onChange={(e) =>
								formikProps.setFieldValue(`${inputName}`, {
									...formikProps.values[`${inputName}`],
									complement: e.target.value,
								})
							}
						/>
					</SectionMain>
				</SectionMain>
			);
		case "address.fr":
			return (
				<SectionMain noPadding>
					<SectionMain>
						<TextInput
							errorMessage={
								formikProps.touched[
								`${inputName.split(".")[0]}`
								] &&
								formikProps.touched[
									`${inputName.split(".")[0]}`
								].street1 &&
								formikProps.errors[
								`${inputName.split(".")[0]}`
								] &&
								formikProps.errors[`${inputName.split(".")[0]}`]
									.street1
							}
							disabled={formikProps.values.disabled_input}
							label={t("^forms.street", "*", "ligne 1")}
							value={
								formikProps.values[`${inputName.split(".")[0]}`]
									.street1
							}
							onChange={(e) =>
								formikProps.setFieldValue(
									`${inputName.split(".")[0]}`,
									{
										...formikProps.values[
										`${inputName.split(".")[0]}`
										],
										street1: e.target.value,
									}
								)
							}
						/>
					</SectionMain>
					<SectionMain>
						<TextInput
							errorMessage={
								formikProps.touched[
								`${inputName.split(".")[0]}`
								] &&
								formikProps.touched[
									`${inputName.split(".")[0]}`
								].street2 &&
								formikProps.errors[
								`${inputName.split(".")[0]}`
								] &&
								formikProps.errors[`${inputName.split(".")[0]}`]
									.street2
							}
							disabled={formikProps.values.disabled_input}
							label={t("^forms.street", "*", "ligne 2")}
							value={
								formikProps.values[`${inputName.split(".")[0]}`]
									.street2
							}
							onChange={(e) =>
								formikProps.setFieldValue(
									`${inputName.split(".")[0]}`,
									{
										...formikProps.values[
										`${inputName.split(".")[0]}`
										],
										street2: e.target.value,
									}
								)
							}
						/>
					</SectionMain>
					<SectionMain xl={6} lg={6} md={6}>
						<div id="form-input-section" style={{ width: "100%" }}>
							<TextInput
								type="tel"
								label={t("^forms.postal_code", "*")}
								errorMessage={
									formikProps.touched[
									`${inputName.split(".")[0]}`
									] &&
									formikProps.touched[
										`${inputName.split(".")[0]}`
									].postal_code &&
									formikProps.errors[
									`${inputName.split(".")[0]}`
									] &&
									formikProps.errors[
										`${inputName.split(".")[0]}`
									].postal_code
								}
								disabled={formikProps.values.disabled_input}
								value={
									formikProps.values[
										`${inputName.split(".")[0]}`
									].postal_code
								}
								onFocus={handleFocus}
								autoComplete="postal-code"
								onChange={(e) => {
									const value = (
										e.target.value
											.toString()
											.match(/\d/g) || []
									).join("");

									formikProps.setFieldValue(
										`${inputName.split(".")[0]}`,
										{
											...formikProps.values[
											`${inputName.split(".")[0]}`
											],
											postal_code: e.target.value,
										}
									);
								}}
							/>
						</div>
					</SectionMain>
					<SectionMain xl={6} lg={6} md={6}>
						<div
							id="form-input-section-right"
							style={{ width: "100%" }}
						>
							<TextInput
								errorMessage={
									formikProps.touched[
									`${inputName.split(".")[0]}`
									] &&
									formikProps.touched[
										`${inputName.split(".")[0]}`
									].city &&
									formikProps.errors[
									`${inputName.split(".")[0]}`
									] &&
									formikProps.errors[
										`${inputName.split(".")[0]}`
									].city
								}
								disabled={formikProps.values.disabled_input}
								label={t("^forms.city", "*")}
								value={
									formikProps.values[
										`${inputName.split(".")[0]}`
									].city
								}
								onChange={(e) =>
									formikProps.setFieldValue(
										`${inputName.split(".")[0]}`,
										{
											...formikProps.values[
											`${inputName.split(".")[0]}`
											],
											city: e.target.value,
										}
									)
								}
							/>
						</div>
					</SectionMain>
					<SectionMain>
						<SelectedInput
							disabled={true}
							value={formikProps.values["country_code"]}
							onChange={(e) =>
								formikProps.setFieldValue(
									`country_code`,
									e.target.value
								)
							}
							arrayValues={
								formikProps.values["country_code"] === "FRA"
									? [{ value: "FRA", label: "France" }]
									: [
										{ value: "BRA", label: "Brasil" },
										{ value: "FRA", label: "França" },
										{ value: "PER", label: "Perú" },
										{
											value: "ARG",
											label: "Argentina",
										},
										{ value: "COL", label: "Colombia" },
									]
							}
							label={t("^forms.country")}
						/>
					</SectionMain>
				</SectionMain>
			);

		case "payment":
			return (
				<SectionMain
					noPadding
					xs={12}
					sm={12}
					md={portalFixLayout ? 12 : 8}
					lg={portalFixLayout ? 12 : 8}
					xl={portalFixLayout ? 12 : 8}
				>
					<Hidden only={["xs", "sm", "md"]}>
						<SectionMain>
							<MaskedInput
								name="cnumeric"
								type="text"
								onFocus={handleFocus}
								onBlur={() =>
									handleBrand(
										formikProps.values.payment_mean
											.card_number,
										formikProps
									)
								}
								errorMessage={
									formikProps.touched.payment_mean &&
									formikProps.touched.payment_mean
										.card_number &&
									formikProps.errors.payment_mean &&
									formikProps.errors.payment_mean.card_number
								}
								value={
									formikProps.values.payment_mean.card_number
								}
								onChange={(e) => {
									const number = (
										e.target.value.match(/\d/g) || []
									).join("");
									formikProps.setFieldValue(`payment_mean`, {
										...formikProps.values.payment_mean,
										card_number: number,
									});
								}}
								mask={() =>
									maskType(
										"CARD",
										formikProps.values.payment_mean.brand
									)
								}
								placeholderChar={"\u2000"}
								render={(ref, props) => (
									<TextInputMasked
										label={t("^forms.card_number", "*")}
										autoComplete="off"
										inputRef={ref}
										rigthElement={
											<div
												style={{ paddingBottom: "5px" }}
												className="flex-row center-a"
											>
												{brands.length > 0 && brands.map((brand) => (
													<CardBrand
														cardBrand={
															formikProps.values
																.payment_mean
																.brand
														}
														brand={brand}
													/>
												))}
											</div>
										}
										{...props}
									/>
								)}
							/>
						</SectionMain>
					</Hidden>
					<Hidden only={["lg", "xl"]}>
						<SectionMain>
							<div
								style={{ paddingBottom: "5px" }}
								className="flex-row center-a"
							>
								{["visa", "mastercard", "amex", "elo"].map(
									(brand) => (
										<CardBrand
											cardBrand={
												formikProps.values.payment_mean
													.brand
											}
											brand={brand}
										/>
									)
								)}
							</div>
						</SectionMain>
						<SectionMain>
							<MaskedInput
								type="tel"
								autoComplete="text"
								onFocus={handleFocus}
								onBlur={() =>
									handleBrand(
										formikProps.values.payment_mean
											.card_number,
										formikProps
									)
								}
								errorMessage={
									formikProps.touched.payment_mean &&
									formikProps.touched.payment_mean
										.card_number &&
									formikProps.errors.payment_mean &&
									formikProps.errors.payment_mean.card_number
								}
								value={
									formikProps.values.payment_mean.card_number
								}
								onChange={(e) => {
									const number = (
										e.target.value.match(/\d/g) || []
									).join("");
									formikProps.setFieldValue(`payment_mean`, {
										...formikProps.values.payment_mean,
										card_number: number,
									});
								}}
								mask={() =>
									maskType(
										"CARD",
										formikProps.values.payment_mean.brand
									)
								}
								placeholderChar={"\u2000"}
								// showMask
								render={(ref, props) => (
									<TextInputMasked
										{...props}
										autoComplete="cc-number"
										label={t("^forms.card_number", "*")}
										inputRef={ref}
									/>
								)}
							/>
						</SectionMain>
					</Hidden>
					<SectionMain xl={6} lg={6} md={6}>
						<div id="form-input-section" style={{ width: "100%" }}>
							<MaskedInput
								type="tel"
								onFocus={handleFocus}
								errorMessage={
									formikProps.touched.payment_mean &&
									formikProps.touched.payment_mean
										.expiry_date &&
									formikProps.errors.payment_mean &&
									formikProps.errors.payment_mean.expiry_date
								}
								value={
									formikProps.values.payment_mean.expiry_date
								}
								onChange={(e) =>
									formikProps.setFieldValue(`payment_mean`, {
										...formikProps.values.payment_mean,
										expiry_date: e.target.value,
									})
								}
								mask={[/[0-1]/, /[0-9]/, "/", /[2-5]/, /[0-9]/]}
								placeholderChar={"\u2000"}
								placeholder="MM/YY"
								render={(ref, props) => (
									<TextInputMasked
										{...props}
										label={t("^forms.expiry_date", "*")}
										inputRef={ref}
										autoComplete="off"
									/>
								)}
							/>
						</div>
					</SectionMain>
					<SectionMain xl={6} lg={6} md={6}>
						<div
							id="form-input-section-right"
							style={{ width: "100%" }}
						>
							<MaskedInput
								type="tel"
								onFocus={handleFocus}
								errorMessage={
									formikProps.touched.payment_mean &&
									formikProps.touched.payment_mean.cvv &&
									formikProps.errors.payment_mean &&
									formikProps.errors.payment_mean.cvv
								}
								value={formikProps.values.payment_mean.cvv}
								onChange={(e) =>
									formikProps.setFieldValue(`payment_mean`, {
										...formikProps.values.payment_mean,
										cvv: (
											e.target.value.match(/\d/g) || []
										).join(""),
									})
								}
								mask={() =>
									maskType(
										"CVV",
										formikProps.values.payment_mean.brand
									)
								}
								placeholderChar={"\u2000"}
								// showMask
								render={(ref, props) => (
									<TextInputMasked
										{...props}
										label={t("^forms.cvv", "*")}
										autoComplete="off"
										inputRef={ref}
									/>
								)}
							/>
						</div>
					</SectionMain>
					<SectionMain>
						<TextInput
							label={t("^forms.customer_name", "*")}
							errorMessage={
								formikProps.touched.payment_mean &&
								formikProps.touched.payment_mean
									.customer_name &&
								formikProps.errors.payment_mean &&
								formikProps.errors.payment_mean.customer_name
							}
							value={
								formikProps.values.payment_mean.customer_name
							}
							autoComplete="cc-name"
							onChange={(e) =>
								formikProps.setFieldValue(`payment_mean`, {
									...formikProps.values[`payment_mean`],
									customer_name: e.target.value,
								})
							}
						/>
					</SectionMain>
				</SectionMain>
			);

		default:
			return "";
	}
};

export default RenderInputsUI;
