import React, { useState } from 'react'
import RenderInputsV4 from './checkout-1.5.4/forms/RenderInputsV4'
import { useTranslation } from './IntlContext/IntlContext'
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { Button, Checkbox } from '@material-ui/core';
import { createTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Loader from './Loader';
import swal from '@sweetalert/with-react'
import ReCaptcha from 'react-google-recaptcha'


const Accordion = withStyles({
    root: {
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const FormAccordion = ({ config = {}, formikprops, checkoutConfig }) => {

    const theme = createTheme({
        palette: {
            primary: { main: checkoutConfig.company?.style.primary_color },
        },
    });

    function isBackgroundDark(hexcolor = "#000000") {
        hexcolor = hexcolor.replace("#", "");
        var r = parseInt(hexcolor.substr(0, 2), 16);
        var g = parseInt(hexcolor.substr(2, 2), 16);
        var b = parseInt(hexcolor.substr(4, 2), 16);
        var yiq = (r * 299 + g * 587 + b * 114) / 1000;
        return yiq < 128;
    }

    const [TCAgreed, setTCAgreed] = useState(false)

    const showTCmodal = async (t) => {
        const lines = checkoutConfig.checkout_config.terms_conditions.data.split("\n")

        const result = await swal({
            buttons: {
                cancel: t.generics.cancel,
                accept: {
                    text: t.generics.accept,
                    value: true,
                },
            },
            content: (
                <div>
                    <div style={{ width: '100%', height: '50vh', backgroundColor: "#f2f2f2", overflowY: 'auto', textAlign: 'left', padding: 5 }}>
                        {lines.map(line => (
                            <span>
                                {line}
                                <br />
                            </span>
                        ))}
                    </div>
                </div>
            ),

        })
        if (result) setTCAgreed(true)
    }

    const [captchaChecked, setCaptchaChecked] = useState(false)

    const AccordionSummary = withStyles({
        root: {
            border: config.borderOnly ? '2px solid black' : '0px',
            borderBottom: config.borderOnly ? '2px solid black' : '1px solid rgba(0, 0, 0, .125)',
            borderRadius: config.borderOnly ? '5px' : '0px',
            marginBottom: -1,
            minHeight: 56,
            fontWeight: 700,
            '&$expanded': {
                minHeight: 56,
            },
        },
        content: {
            '&$expanded': {
                margin: '12px 0',
            },
        },
        expanded: {},
    })(MuiAccordionSummary);

    const t = useTranslation()

    return (
        <div className="fade-in">
            <div style={{ marginBottom: config.borderOnly ? '10px' : 0, boxShadow: config.shadow ? '0px 10px 10px #888888' : '0px', backgroundColor: "transparent" }}>
                <Accordion square key={`accordion-${config.name}`} expanded={config.expanded} onChange={() => config.handleExpand()}>
                    <AccordionSummary
                        aria-controls={`panel${config.name}-content`}
                        id={`panel${config.name}-header`}
                        style={{
                            opacity: (config.disabled || config.loading) ? 0.8 : 1,
                            borderRadius: config.borderOnly ? config.expanded ? '5px 5px 0 0' : '5px' : '0px'
                        }}
                    >
                        <span>{config.title}</span>
                    </AccordionSummary>
                    <MuiAccordionDetails style={{
                        border: config.borderOnly ? '2px solid black' : '0px',
                        borderRadius: config.borderOnly ? '0 0 5px 5px' : '0px',
                        borderTop: 'none',
                        ...config.style
                    }}>
                        <div className="flex-col" style={{ width: "100%", opacity: (config.disabled || config.loading) ? 0.8 : 1, pointerEvents: (config.disabled || config.loading) && 'none' }}>
                            {config.inputTitle &&
                                <span style={{ padding: 10 }}>
                                    {config.inputTitle}
                                </span>
                            }
                            {config.customRender
                                ? config.customRender
                                : <RenderInputsV4
                                    rows={config.flexRowInputs ? config.flexRowInputs(formikprops) : config.rowInputs}
                                    formikprops={formikprops}
                                    checkoutConfig={checkoutConfig}
                                    disabledInputs={config.disabledInputs}
                                />}
                            {config.captchaEnabled &&
                                <div className='flex-row center-a center-b' style={{ marginBottom: '16px' }}>
                                    <ReCaptcha
                                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                        onChange={value => setCaptchaChecked(value)}
                                    />
                                </div>
                            }
                            {config.TCenabled &&
                                <div className='flex-row flex-end center-b'>
                                    <ThemeProvider theme={theme}>
                                        <Checkbox checked={TCAgreed} onChange={() => setTCAgreed(!TCAgreed)} color="primary" />
                                    </ThemeProvider>
                                    <span>
                                        {checkoutConfig.checkout_config.terms_conditions.type === 'link' &&
                                            <>
                                                {t.checkout.terms_conditions_1}{" "}
                                                <a style={{ color: 'black' }} target={'_blank'} href={checkoutConfig.checkout_config.terms_conditions.data}>
                                                    {t.checkout.terms_conditions_2}
                                                </a>
                                            </>
                                        }
                                        {checkoutConfig.checkout_config.terms_conditions.type === 'text' &&
                                            <>
                                                {t.checkout.terms_conditions_1}{" "}
                                                <a onClick={() => showTCmodal(t)} style={{ textDecoration: "underline", cursor: 'pointer' }}>
                                                    {t.checkout.terms_conditions_2}
                                                </a>
                                            </>
                                        }
                                        {checkoutConfig.checkout_config.terms_conditions.type === 'accept' &&
                                            <>
                                                {t.checkout.terms_conditions_full}
                                            </>
                                        }
                                    </span>
                                </div>
                            }
                        </div>
                    </MuiAccordionDetails>
                </Accordion>
            </div>
            {
                config.buttons &&
                <div className="flex-row flex-end">
                    {config.buttons.map(button => {

                        if (!button) return null

                        return (<Button
                            onClick={button.onClick}
                            style={{
                                backgroundColor: checkoutConfig.company?.style.primary_color ?
                                    checkoutConfig.company?.style.primary_color
                                    : '#fff',
                                color: isBackgroundDark(checkoutConfig.company?.style.primary_color || '#fff') ? 'white' : 'black',
                                width: (button.fullWidth && '100%'),
                                marginTop: 20,
                                ...button.style
                            }}
                            variant="contained"
                            color="primary"
                            size="small"
                            type={button.type}
                            disabled={button.disabled || (config.TCenabled && !TCAgreed) || (config.captchaEnabled && !captchaChecked)}
                        >
                            {config.loading &&
                                <Loader size={20} white={isBackgroundDark(checkoutConfig.company?.style.primary_color || '#fff')} />
                            }
                            &nbsp;
                            {
                                button.text
                            }
                        </Button>)
                    })}
                </div>
            }
        </div >
    )

}

export default FormAccordion