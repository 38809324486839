import React from 'react'
import { FormControlLabel, makeStyles, Radio } from '@material-ui/core'
import clsx from 'clsx'
import Icon from 'react-icons-kit'
import logoSEPA from '../../../../assets/sepa-logo.png'
import { Icon as Iconify } from '@iconify/react';


const useStyles = makeStyles({
	root: {
		border: '1px solid #bcbcbc',
		padding: '10px 20px',
		borderRadius: '4px',
		margin: '0 13px',
		minWidth: 181
	},
	radio: {
		'&:hover': {
			backgroundColor: 'transparent'
		}
	},
	icon: {
		borderRadius: '50%',
		width: 16,
		height: 16,
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#f5f8fa',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2
		},
		'input:hover ~ &': {
			backgroundColor: '#ebf1f5'
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(206,217,224,.5)'
		}
	},
	checkedIcon: {
		backgroundColor: ({ colorIcon }) => colorIcon,
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
			content: '""'
		},
		'input:hover ~ &': {
			backgroundColor: ({ colorIcon }) => colorIcon
		}
	}
})

const PaymentMeanInput = ({ icon, value, label = "", colorIcon, noLeftMargin, ...props }) => {
	const classes = useStyles({ noLeftMargin, colorIcon })
	console.log(label)
	const StyledRadio = (props) => {
		return (
			<div className="flex-row center-b">
				<Radio
					className={classes.radio}
					disableRipple
					color="default"
					checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
					icon={<span className={classes.icon} />}
					{...props}
				/>
				<div style={{ color: colorIcon }}>
					{label?.match('SEPA') ? <img src={logoSEPA} width="100px" />
						: label?.match('Pix')
							? <Iconify icon="ic:baseline-pix" style={{ fontSize: '45px' }} />
							: <Icon size={28} icon={icon} />}
				</div>
			</div>
		)
	}

	return (
		<FormControlLabel
			className={classes.root}
			labelPlacement="start"
			value={value}
			control={<StyledRadio />}
			label={label}
			{...props}
		/>
	)
}

export default PaymentMeanInput
