import React from "react"
import SectionMain from "../../../../layout/SectionMain"
import { makeStyles, Avatar } from "@material-ui/core"
import Title from "../../../../components/checkout-1.5.4/forms/Title"

const useStyles = makeStyles({
	root: {
		alignItems: "center",
	},
	icon: {
		width: 50,
		height: 50,
		border: ({ primaryColor }) => `3px solid ${primaryColor}`,
		borderRadius: "100%",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "center",
		background: "transparent",
		color: ({ primaryColor }) => primaryColor,
	},
	label: {
		paddingLeft: 20,
	},
})

const IconLabel = ({ icon, label, primaryColor = "#000" }) => {
	const classes = useStyles({ primaryColor })
	return (
		<SectionMain noPadding className={classes.root}>
			<Avatar className={classes.icon}>{icon}</Avatar>
			<Title color={primaryColor} fontSize={18} className={classes.label}>
				{label}
			</Title>
		</SectionMain>
	)
}

export default IconLabel
