import formSchema from "../../../../core/formSchema";
import { CNPJValidation, CPFValidation } from "../../../../utils/validation";
import luhn from 'luhn'
import moment from "moment";

export const FormikValidationsCustomer = (values, checkoutConfig, t, useBillingAsShipping) => {
    const errors = {};

    const country = values.country_code || checkoutConfig.company.address.country_code

    // Campos Vazios
    if (!values.first_name) {
        errors.first_name = t.generics.field_required;
    } else if ((values.first_name || "").length > 60) {
        errors.first_name = t("@@ O campo Nome não pode ter mais de 60 caracteres");
    }

    if (!values.last_name) {
        if (values.document_type !== formSchema[country].b2bDocument) { errors.last_name = t.generics.field_required; }
    } else if ((values.last_name || "").length > 60) {
        errors.last_name = t("@@ O campo Sobrenome não pode ter mais de 60 caracteres");
    }

    if (checkoutConfig.checkout_config?.checkout_fields.mobile_number) {
        if (!values.mobile_phone || values.mobile_phone.length < 3) {
            errors.mobile_phone = t.forms_validation.invalid_phone;
        }
    }

    if (checkoutConfig.checkout_config?.country_code) {
        if (!values.country_code) {
            errors.country_code = t.generics.field_required;
        }
    }

    if (checkoutConfig.checkout_config?.checkout_fields.birth_date) {
        if (!values.birth_date) {
            errors.birth_date = t.generics.field_required
        } else if (!values.birth_date.isValid()) {
            errors.birth_date = t.generics.field_required
        } else if (values.birth_date > moment(new Date())) {
            errors.birth_date = t.generics.valid_date
        } else if (values.birth_date < moment(new Date("1900-01-01"))) {
            errors.birth_date = t.generics.valid_date
        } else {
            const twelveYearsAgo = moment().subtract(12, 'years');
            if (values.birth_date > twelveYearsAgo) {
                errors.birth_date = t.forms_validation.minimum_age;
            }
        }
    }


    checkoutConfig.checkout_config.customer_custom_fields?.forEach(field => {

        if (field.is_enabled && field.is_required && !values[`customer_${field.key}`]) {
            errors[`customer_${field.key}`] = t.generics.field_required;
        }

        if (field.type === 'date' && field.is_enabled && Boolean(values[`customer_${field.key}`])) {
            if (!values[`customer_${field.key}`].isValid()) {
                errors[`customer_${field.key}`] = t.generics.field_required
            } else if (values[`customer_${field.key}`] > moment("2100-01-01")) {
                errors[`customer_${field.key}`] = t.generics.valid_date
            } else if (values[`customer_${field.key}`] < moment("1900-01-01")) {
                errors[`customer_${field.key}`] = t.generics.valid_date
            }
        }

    });

    checkoutConfig.checkout_config.subscription_custom_fields?.forEach(field => {

        if (field.is_enabled && field.is_required && !values[`subscription_${field.key}`]) {
            errors[`subscription_${field.key}`] = t.generics.field_required;
        }

        if (field.type === 'date' && field.is_enabled && Boolean(values[`subscription_${field.key}`])) {
            if (!values[`subscription_${field.key}`].isValid()) {
                errors[`subscription_${field.key}`] = t.generics.field_required;
            } else if (values[`subscription_${field.key}`] > moment("2100-01-01")) {
                errors[`subscription_${field.key}`] = t.generics.valid_date;
            } else if (values[`subscription_${field.key}`] < moment("1900-01-01")) {
                errors[`subscription_${field.key}`] = t.generics.valid_date;
            }
        }

    });

    if (checkoutConfig.checkout_config?.checkout_fields.document) {
        if (!values.document_type) {
            errors.document_type = t.generics.field_required;
        }
        if (!values.document_number) {
            errors.document_number = t.generics.field_required;
        } else if (values.document_type === 'CPF') {
            if (!CPFValidation(values.document_number)) {
                errors.document_number = t.forms_validation.invalid_document;
            }
        } else if (values.document_type === 'CNPJ') {
            if (!CNPJValidation(values.document_number)) {
                errors.document_number = t.forms_validation.invalid_document;
            }
        }
    }

    if (checkoutConfig.checkout_config?.checkout_fields.shipping_address && !useBillingAsShipping) {
        switch (country) {
            case 'BRA':
                if (!values.shipping_address_postal_code) {
                    errors.shipping_address_postal_code = t.generics.field_required;
                } else if (values.shipping_address_postal_code.length < 8) {
                    errors.shipping_address_postal_code = t.forms_validation.invalid_cep;
                }

                if (!values.shipping_address_street1) {
                    errors.shipping_address_street1 = t.generics.field_required;
                }
                if (!values.shipping_address_district) {
                    errors.shipping_address_district = t.generics.field_required;
                }
                if (!values.shipping_address_state) {
                    errors.shipping_address_state = t.generics.field_required;
                } else if (values.shipping_address_state.length > 2) {
                    errors.shipping_address_state = t.forms_validation.invalid_state;
                }
                if (!values.shipping_address_city) {
                    errors.shipping_address_city = t.generics.field_required;
                }

                if (!values.shipping_address_number) {
                    errors.shipping_address_number = t.generics.field_required;
                }
                break;

            case 'FRA':
                if (!values.shipping_address_street1) {
                    errors.shipping_address_street1 = t.generics.field_required;
                }

                break

            case 'COL':
            case 'ARG':
            case 'PER':
            case 'CHL':
                if (!values.shipping_address_street1) {
                    errors.shipping_address_street1 = t.generics.field_required;
                }
                if (!values.shipping_address_postal_code) {
                    errors.shipping_address_postal_code = t.generics.field_required;
                }
                if (!values.shipping_address_state) {
                    errors.shipping_address_state = t.generics.field_required;
                }
                if (!values.shipping_address_city) {
                    errors.shipping_address_city = t.generics.field_required;
                }

                break;
            default:
                break;
        }
    }


    if (checkoutConfig.checkout_config?.checkout_fields.billing_address) {
        switch (country) {
            case 'BRA':
                if (!values.billing_address_postal_code) {
                    errors.billing_address_postal_code = t.generics.field_required;
                } else if (values.billing_address_postal_code.length < 8) {
                    errors.billing_address_postal_code = t.forms_validation.invalid_cep;
                }

                if (!values.billing_address_street1) {
                    errors.billing_address_street1 = t.generics.field_required;
                }
                if (!values.billing_address_district) {
                    errors.billing_address_district = t.generics.field_required;
                }
                if (!values.billing_address_state) {
                    errors.billing_address_state = t.generics.field_required;
                } else if (values.billing_address_state.length > 2) {
                    errors.billing_address_state = t.forms_validation.invalid_state;
                }
                if (!values.billing_address_city) {
                    errors.billing_address_city = t.generics.field_required;
                }

                if (!values.billing_address_number) {
                    errors.billing_address_number = t.generics.field_required;
                }
                break;

            case 'FRA':
                if (!values.billing_address_street1) {
                    errors.billing_address_street1 = t.generics.field_required;
                }

                break

            case 'COL':
            case 'ARG':
            case 'PER':
            case 'CHL':
                if (!values.billing_address_street1) {
                    errors.billing_address_street1 = t.generics.field_required;
                }
                if (!values.billing_address_postal_code) {
                    errors.billing_address_postal_code = t.generics.field_required;
                }
                if (!values.billing_address_state) {
                    errors.billing_address_state = t.generics.field_required;
                }
                if (!values.billing_address_city) {
                    errors.billing_address_city = t.generics.field_required;
                }

                break;
            default:
                break;
        }
    }

    // Validações de valor
    if (!values.email) {
        errors.email = t.generics.field_required;
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
        errors.email = t.forms_validation.invalid_email;
    }

    return errors;
}


export const FormikValidationsCustomerFR = (values, checkoutConfig, t) => {
    const errors = {};

    // Campos Vazios
    if (!values.first_name) {
        errors.first_name = t.generics.field_required;
    }
    if (!values.last_name) {
        errors.last_name = t.generics.field_required;
    }
    if (checkoutConfig.checkout_config?.checkout_fields.mobile_number) {
        if (!values.mobile_phone || values.mobile_phone.length < 3) {
            errors.mobile_phone = t.forms_validation.invalid_phone;
        }
    }

    if (checkoutConfig.checkout_config?.country_code) {
        if (!values.country_code) {
            errors.country_code = t.generics.field_required;
        }
    }

    if (checkoutConfig.checkout_config?.checkout_fields.document) {
        if (!values.document_type_fr) {
            errors.document_type_fr = t.generics.field_required;
        }
        if (!values.document_number) {
            errors.document_number = t.generics.field_required;
        }
    }

    checkoutConfig.checkout_config.customer_custom_fields?.forEach(field => {

        if (field.is_enabled && field.is_required && !values[`customer_${field.key}`]) {
            errors[`customer_${field.key}`] = t.generics.field_required;
        }

    });

    checkoutConfig.checkout_config.subscription_custom_fields?.forEach(field => {

        if (field.is_enabled && field.is_required && !values[`subscription_${field.key}`]) {
            errors[`subscription_${field.key}`] = t.generics.field_required;
        }

    });

    if (checkoutConfig.checkout_config?.checkout_fields.billing_address) {
        if (!values.billing_address_postal_code) {
            errors.billing_address_postal_code = t.generics.field_required;
        }

        if (!values.billing_address_street1) {
            errors.billing_address_street1 = t.generics.field_required;
        }
        if (!values.billing_address_district) {
            errors.billing_address_district = t.generics.field_required;
        }
        if (!values.billing_address_state) {
            errors.billing_address_state = t.generics.field_required;
        }
        if (!values.billing_address_city) {
            errors.billing_address_city = t.generics.field_required;
        }

        if (!values.billing_address_number) {
            errors.billing_address_number = t.generics.field_required;
        }

    }


    if (checkoutConfig.checkout_config?.checkout_fields.shipping_address && !values.use_billing_as_shipping) {

        if (!values.shipping_address_postal_code) {
            errors.shipping_address_postal_code = t.generics.field_required;
        } else if (values.shipping_address_postal_code.length < 8) {
            errors.shipping_address_postal_code = t.forms_validation.invalid_cep;
        }

        if (!values.shipping_address_street1) {
            errors.shipping_address_street1 = t.generics.field_required;
        }
        if (!values.shipping_address_district) {
            errors.shipping_address_district = t.generics.field_required;
        }
        if (!values.shipping_address_state) {
            errors.shipping_address_state = t.generics.field_required;
        }
        if (!values.shipping_address_city) {
            errors.shipping_address_city = t.generics.field_required;
        }

        if (!values.shipping_address_number) {
            errors.shipping_address_number = t.generics.field_required;
        }
    }

    // Validações de valor
    if (!values.email) {
        errors.email = t.generics.field_required;
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
        errors.email = t.forms_validation.invalid_email;
    }

    return errors;
}

export const FormikValidationsCCPayment = (values, checkoutConfig, t) => {
    const errors = {}

    if (!values.cc_number) {
        errors.cc_number = t.generics.field_required
    } else {
        const isValid = luhn.validate(values.cc_number)
        if (!isValid) {
            errors.cc_number = t("@@ Cartão inválido")
        }
    }
    if (!values.cc_cardholder) {
        errors.cc_cardholder = t.generics.field_required
    }
    if (!values.cc_expiry_date) {
        errors.cc_expiry_date = t.generics.field_required
    } else {
        const [MM, YY] = values.cc_expiry_date.split("/")
        if (MM > 12) {
            errors.cc_expiry_date = t.generics.valid_date
        }
        const cardDate = new Date(parseInt(`20${YY}`), (parseInt(MM) - 1))
        const now = new Date()
        if (cardDate < now) {
            errors.cc_expiry_date = t.generics.valid_date
        }
    }
    if (!values.cvv) {
        errors.cvv = t.generics.field_required
    }

    return errors;

}

export const FormikValidationsBoletoPayment = (values, checkoutConfig, t) => {
    const errors = {};

    // Campos Vazios
    if (!checkoutConfig.checkout_config?.checkout_fields.document) {
        if (!values.document_type) {
            errors.document_type = t.generics.field_required;
        }
        if (!values.document_number) {
            errors.document_number = t.generics.field_required;
        } else if (values.document_type === 'CPF') {
            if (!CPFValidation(values.document_number)) {
                errors.document_number = 'CPF inválido';
            }
        } else if (values.document_type === 'CNPJ') {
            if (!CNPJValidation(values.document_number)) {
                errors.document_number = 'CNPJ inválido';
            }
        }
    }

    if (!checkoutConfig.checkout_config?.checkout_fields.billing_address) {
        if (!values.billing_address_postal_code) {
            errors.billing_address_postal_code = t.generics.field_required;
        } else if (values.billing_address_postal_code.length < 8) {
            errors.billing_address_postal_code = t.forms_validation.invalid_cep;
        }

        if (!values.billing_address_street1) {
            errors.billing_address_street1 = t.generics.field_required;
        }
        if (!values.billing_address_district) {
            errors.billing_address_district = t.generics.field_required;
        }
        if (!values.billing_address_state) {
            errors.billing_address_state = t.generics.field_required;
        }
        if (!values.billing_address_city) {
            errors.billing_address_city = t.generics.field_required;
        }

        if (!values.billing_address_number) {
            errors.billing_address_number = t.generics.field_required;
        }

    }

    return errors;
}


export const FormikValidationsPixPayment = (values, checkoutConfig, t) => {
    const errors = {};

    // Campos Vazios
    if (!checkoutConfig.checkout_config?.checkout_fields.document) {
        if (!values.document_type) {
            errors.document_type = t.generics.field_required;
        }
        if (!values.document_number) {
            errors.document_number = t.generics.field_required;
        } else if (values.document_type === 'CPF') {
            if (!CPFValidation(values.document_number)) {
                errors.document_number = 'CPF inválido';
            }
        } else if (values.document_type === 'CNPJ') {
            if (!CNPJValidation(values.document_number)) {
                errors.document_number = 'CNPJ inválido';
            }
        }
    }

    return errors;
}

export const FormikValidationsCustom = (values, checkoutConfig, t, fields = {
    customer: false,
    billing_address: false,
    shipping_address: false,
    cc_payment: false,
    document: false,
}) => {

    const errors = {}

    if (fields.customer) {
        if (!values.first_name) {
            errors.first_name = t.generics.field_required;
        }
        if (!values.last_name) {

            if (values.document_type !== "CNPJ") { errors.last_name = t.generics.field_required; }

        }
        if (checkoutConfig.checkout_config?.mobile_phone) {
            if (!values.mobile_phone || values.mobile_phone.length < 3) {
                errors.mobile_phone = 'Telefone inválido';
            }
        }

        if (checkoutConfig.checkout_config?.country_code) {
            if (!values.country_code) {
                errors.country_code = t.generics.field_required;
            }
        }

        if (values.mobile_phone && values.mobile_phone.length < 3) {
            errors.mobile_phone = 'Telefone inválido';
        }

        if (checkoutConfig.checkout_config?.document) {
            if (!values.document_type) {
                errors.document_type = t.generics.field_required;
            }
            if (!values.document_number) {
                errors.document_number = t.generics.field_required;
            } else if (values.document_type === 'CPF') {
                if (!CPFValidation(values.document_number)) {
                    errors.document_number = 'Documento inválido';
                }
            } else if (values.document_type === 'CNPJ') {
                if (!CNPJValidation(values.document_number)) {
                    errors.document_number = 'Documento inválido';
                }
            }
        }
    }

    if (fields.document) {
        if (!values.document_type) {
            errors.document_type = t.generics.field_required;
        }
        if (!values.document_number) {
            errors.document_number = t.generics.field_required;
        } else if (values.document_type === 'CPF') {
            if (!CPFValidation(values.document_number)) {
                errors.document_number = 'Documento inválido';
            }
        } else if (values.document_type === 'CNPJ') {
            if (!CNPJValidation(values.document_number)) {
                errors.document_number = 'Documento inválido';
            }
        }
    }

    if (fields.billing_address) {
        if (!values.billing_address_postal_code) {
            errors.billing_address_postal_code = t.generics.field_required;
        } else if (values.billing_address_postal_code.length < 8) {
            errors.billing_address_postal_code = 'CEP inválido';
        }

        if (!values.billing_address_street1) {
            errors.billing_address_street1 = t.generics.field_required;
        }
        if (!values.billing_address_district) {
            errors.billing_address_district = t.generics.field_required;
        }
        if (!values.billing_address_state) {
            errors.billing_address_state = t.generics.field_required;
        } else if (values.billing_address_state.length > 2) {
            errors.billing_address_state = 'Estado inválido';
        }
        if (!values.billing_address_city) {
            errors.billing_address_city = t.generics.field_required;
        }

        if (!values.billing_address_number) {
            errors.billing_address_number = t.generics.field_required;
        }
    }


    if (fields.shipping_address) {

        if (!values.shipping_address_postal_code) {
            errors.shipping_address_postal_code = t.generics.field_required;
        } else if (values.shipping_address_postal_code.length < 8) {
            errors.shipping_address_postal_code = 'CEP inválido';
        }

        if (!values.shipping_address_street1) {
            errors.shipping_address_street1 = t.generics.field_required;
        }
        if (!values.shipping_address_district) {
            errors.shipping_address_district = t.generics.field_required;
        }
        if (!values.shipping_address_state) {
            errors.shipping_address_state = t.generics.field_required;
        } else if (values.billing_address_state.length > 2) {
            errors.billing_address_state = 'Estado inválido';
        }
        if (!values.shipping_address_city) {
            errors.shipping_address_city = t.generics.field_required;
        }

        if (!values.shipping_address_number) {
            errors.shipping_address_number = t.generics.field_required;
        }
    }


    // Validações de valor
    if (!values.email) {
        errors.email = t.generics.field_required;
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
        errors.email = 'Endereço de email inválido';
    }

    if (fields.cc_payment) {
        if (!values.cc_number) {
            errors.cc_number = t.generics.field_required
        } else {
            const isValid = luhn.validate(values.cc_number)
            if (!isValid) {
                errors.cc_number = t("@@ Cartão inválido")
            }
        }
        if (!values.cc_cardholder) {
            errors.cc_cardholder = t.generics.field_required
        }
        if (!values.cc_expiry_date) {
            errors.cc_expiry_date = t.generics.field_required
        } else {
            const [MM, YY] = values.cc_expiry_date.split("/")
            if (MM > 12) {
                errors.cc_expiry_date = "Insira uma data válida"
            }
            const cardDate = new Date(parseInt(`20${YY}`), (parseInt(MM) - 1))
            const now = new Date()
            if (cardDate < now) {
                errors.cc_expiry_date = "Insira uma data válida"
            }
        }
        if (!values.cvv) {
            errors.cvv = t.generics.field_required
        }
    }
    return errors
}
