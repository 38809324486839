import React from 'react'
import { BottomNavigationAction } from '@material-ui/core'
import { useAppContext } from '../../../../../components/AppContextCheckout'

const BottomMenuUI = ({ label, link, icon, showLabel, handleNavigation }) => {
	const { company } = useAppContext()
	return (
		<BottomNavigationAction
			style={{ color: window.location.pathname === link ? company.style.primary_color : '#282828' }}
			key={label}
			onClick={() => handleNavigation(link)}
			showLabel={true}
			label={label}
			icon={icon}
		/>
	)
}

export default BottomMenuUI
