import React, { useEffect, useState } from 'react'
import confetti from 'canvas-confetti'
import { Container, Button, Dialog, makeStyles, FormLabel } from '@material-ui/core'
import { basic_elaboration_mail_check } from 'react-icons-kit/linea/basic_elaboration_mail_check'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { ic_done } from 'react-icons-kit/md/ic_done'
// import Icon from 'react-icons-kit'
import SectionMain from '../../../layout/SectionMain'
import Title, { TitleSmall } from '../forms/Title'
import toCurrency from '../../../core/toCurrency'
import { useTranslation } from '../../../components/IntlContext/IntlContext'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router"
import { Icon } from '@iconify/react';
import CompactTable from '../../TableRenderer'
import useLocalDateFunction from '../../../utils/useLocalDateFunction'
import './index.css'
import AnimatedView from '../AnimatedView'

const useStyles = makeStyles({
	root: {
		// height: "100vh",
		justifyContent: 'center',
		alignItems: 'center',
	},
	container: {
		border: '3px dashed #DCDCDC',
		maxWidth: 400,
		padding: 20
	},
	logo: {
		backgroundImage: ({ logo }) => `url(${logo})`,
		backgroundSize: 'contain',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		width: '100%',
		height: '10vw',
		maxHeight: 100
	},
	icon: {
		borderRadius: '100%',
		height: 120,
		width: 120,
		paddingTop: 0,
		color: '#fff',
		background: ({ color }) => color,
		alignItems: 'center',
		justifyContent: 'center'
	}
})

const SuccessPage = (props) => {
	const { userInfo = {
		name: '',
		email: '',
		totalPaid: { amount: '', currency: 'BRL' }
	},
		logo,
		primaryColor,
		updatePayment,
		checkoutInfo,
		successMessage,
		contact_email,
		success_info,
		isProduct
	} = props
	const t = useTranslation()
	const renderDate = useLocalDateFunction('DATE')
	const classes = useStyles({ color: primaryColor, logo })

	const [isRedirecting, setIsRedirecting] = useState(false)

	const renderFrequency = (pricing_option) => {
		if (pricing_option?.interval < 2) {
			return t.frequency[pricing_option?.frequency.toUpperCase()]
		} else {
			return t.generics.each + " " + pricing_option.interval + " " + t.frequency_quantity[pricing_option?.frequency.toUpperCase()]
		}
	}

	const renderTrialAmount = (success_info) => {
		if (success_info.amount === 0) {
			if (checkoutInfo?.plan?.trial.interval > 1) {
				return t("^titles.gratuitous", checkoutInfo?.plan?.trial.interval + " " + t.frequency_quantity[checkoutInfo?.plan?.trial.interval_unit])
			} else {
				return t("^titles.gratuitous", (checkoutInfo?.plan?.trial.interval + " " + t.frequency_interval_unit[checkoutInfo?.plan?.trial.interval_unit]))
			}
		} else {
			return toCurrency(success_info.amount) + " " + t("^titles.trial", (checkoutInfo?.plan?.trial.interval + " " + t.frequency_interval_unit[checkoutInfo?.plan?.trial.interval_unit]))

		}
	}

	const delayRedirect = () => {
		const link = checkoutInfo?.redirect_url_on_success || checkoutInfo?.plan?.redirect_url_on_success || checkoutInfo?.checkout_config.redirect_url_on_success
		if (Boolean(link)) {
			setIsRedirecting(true)
			return window.setTimeout(() => window.location.href = link, 8000);
		}
		return
	}

	const Row = ({ label, value }) => (
		<div
			className="flex-row"
			style={{
				width: '100%',
				padding: '8px 0',
				justifyContent: 'space-between'
			}}
		>
			<div className="flex-col center-b">{label}</div>
			<div className="flex-col center-b" style={{ textAlign: 'right' }}>
				{value}
			</div>
		</div>
	)

	const renderCheckoutSuccessInfo = () => {
		const lines = checkoutInfo ? checkoutInfo.checkout_config?.checkout_success_instructions?.split("\n") : []
		return lines.map(line => (
			<span>
				{line}
				<br />
			</span>
		))
	}

	useEffect(() => {
		delayRedirect()
	}, [checkoutInfo])

	console.log(success_info)

	const tableData = [
		(success_info?.plan || success_info?.product)
			? {
				label: `${isProduct ?
					t.products.name
					: t.plans.name}:`,
				value: success_info.plan?.name || success_info.product?.name,
				boldValue: true,
				boldLabel: true,
			}
			: null,
		,
		(userInfo.name || userInfo.first_name)
			? {
				label: `${isProduct ? t.titles.buyer : t.titles.customer}:`,
				value: userInfo.name || userInfo.first_name + " " + userInfo.last_name, boldValue: true,
				boldValue: true,
				boldLabel: true
			}
			: null,
		(userInfo.name || userInfo.first_name)
			? {
				label: `${t.checkout.email}:`,
				value: userInfo.email,
				boldValue: true,
				boldLabel: true
			}
			: null,

		(success_info?.subscription)
			? {
				label: `${t.generics.subscription}:`,
				value: success_info.subscription.code,
				boldValue: true,
				boldLabel: true
			}
			: null,
		(success_info?.payment_number)
			? {
				label: `${t.titles['payment_method.credit_card']}:`,
				value: t.generics.last_numbers + " " + success_info.payment_number.slice(-4),
				boldValue: true,
				boldLabel: true
			}
			: null,
		(success_info?.pricing_option)
			? {
				label: `${t.label.frequency}:`,
				value: renderFrequency(success_info.pricing_option),
				boldValue: true,
				boldLabel: true,
				valueStyle: { textTransform: 'capitalize' }
			}
			: null,

		(success_info?.amount)
			? {
				label: `${t("^currencies.amount")}:`,
				value: toCurrency(success_info?.amount),
				boldValue: true,
				boldLabel: true,
				valueStyle: { textTransform: 'capitalize' }
			}
			: null,

		(checkoutInfo?.plan?.trial.interval > 0)
			? {
				label: `${t("^currencies.amount")}:`,
				value: renderTrialAmount(success_info),
				boldValue: true,
				boldLabel: true,
				valueStyle: { textTransform: 'capitalize' }
			}
			: null,
	]


	return (
		<AnimatedView>
			<div className={classes.root}>
				<div style={{ width: '100%', backgroundColor: primaryColor, padding: 5, marginBottom: 40 }}>
					<div className={classes.logo} />
				</div>

				<div className='flex-row center-a'>
					<div className='flex-row center-a' style={{ borderRadius: 25, border: '1px solid rgba(0,0,0,0.19)', width: '80vw', padding: '30px 15px', maxWidth: '1440px' }}>
						<div className='flex-col center-b' style={{ width: 'fit-content', overflow: "hidden" }}>
							<AnimatedView animate={{
								keyframes: [
									{
										transform: 'scale3d(3, 3, 3) translate3d(0, 5vh, 0)',
										opacity: '0',
										offset: 0,
									},
									{
										transform: 'scale3d(3, 3, 3) translate3d(0, 5vh, 0)',
										opacity: '1',
										offset: 0.1,
									},
									{
										transform: 'scale3d(2, 2, 2) translate3d(0, 5vh, 0)',
										opacity: '1',
										offset: 0.5,
									},
									{
										transform: 'scale3d(1, 1, 1) translate3d(0, 0, 0)',
										opacity: '1',
										offset: 1,
									},
								],
								timing: {
									duration: 2000,
									iterations: 1,
									easing: 'cubic-bezier(1,0,.79,1.3)',
								}
							}
							}>
								<div class="success-animation">
									<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
								</div>
							</AnimatedView>
							<AnimatedView animate={{
								keyframes: [
									{
										transform: 'none',
										opacity: '0',
										offset: 0,
									},
									{
										transform: 'none',
										opacity: '0',
										offset: 0.3,
									},
									{
										transform: 'none',
										opacity: '0',
										offset: 0.5,
									},
									{
										transform: 'none',
										opacity: '0',
										offset: 0.7,
									},
									{
										transform: 'none',
										opacity: '1',
										offset: 1,
									},
								],
								timing: {
									duration: 2500,
									iterations: 1,
									easing: 'cubic-bezier(0,0,1,1)',
								}
							}}>

								<SectionMain position="center" direction="column" alignItems="center">
									<Title align="center">
										<span style={{ fontSize: 18 }}>
											{updatePayment ? (
												t.messages['success_update_payment_mean.subscription']
											) : (
												(userInfo.totalPaid.amount > 0) ? t.messages['success_submit_payment.subscription'] : "Assinatura concluída com sucesso!"
											)}
										</span>
									</Title>
								</SectionMain>
								<br />
								{(userInfo.totalPaid?.amount > 0) && (
									<>
										< div style={{ paddingBottom: 15 }}>
											<Title align="center">
												<h3 style={{ margin: 0 }}>

													{t('^currencies.amount')}:
												</h3>
												<h2 style={{ margin: 0 }}>
													{toCurrency(userInfo.totalPaid.amount, userInfo.totalPaid.currency)}
													{checkoutInfo?.plan?.enabled_installment && userInfo.totalPaid.installment && (
														<span style={{ fontSize: '1rem' }}>
															{" "}{t.generics.in} {userInfo.totalPaid.installment}x
														</span>
													)}
												</h2>
											</Title>
											<br />

											{checkoutInfo.plan?.trial.interval > 0 &&
												<span>
													<h3 style={{ margin: 0 }}>
														{t.generics.from_day} <b>{renderDate(checkoutInfo.plan.trial.next_charge)}</b>, {t(
															"^messages.trial_interval.charge",
															toCurrency(
																checkoutInfo.plan.amount,
																checkoutInfo.plan.currency
															),
															t.frequency_interval_unit[
															checkoutInfo.plan.recurrence.frequency
															]
														)}
													</h3>
												</span>
											}
										</div>
										<br /></>)
								}

								<CompactTable data={tableData} noCommas noTitle tableStyle={{ width: '100%', margin: 0 }} rowStyle={{ color: "rgba(0, 0, 0, 0.8)" }} />

								<SectionMain position="center" direction="column" alignItems="center">
									<span style={{ fontSize: 18, maxWidth: 300, textAlign: 'center' }}>
										{updatePayment ? (
											t.messages['success_update_payment_mean.subscription_2']
										) : (
											t('^messages.send_invoice.subscription', userInfo.email)
										)}
									</span>
									{/* <br /> <br />
					<Link to={"cyclopay.com"}>
						clique aqui acesse os seus dados no portal do assinante
					</Link> */}
								</SectionMain>
								{checkoutInfo && checkoutInfo.checkout_config.checkout_success_instructions &&
									<div style={{ padding: 15 }}>
										<SectionMain position="center" direction="column" alignItems="center">
											<span style={{ fontSize: 18, whiteSpace: 'pre-wrap' }}>
												{renderCheckoutSuccessInfo()}
											</span>
										</SectionMain>
									</div>
								}
							</AnimatedView>

						</div>
					</div>
				</div>

				{
					isRedirecting &&
					<AnimatedView animate={{
						keyframes: [
							{
								transform: 'none',
								opacity: '0',
								offset: 0,
							},
							{
								transform: 'none',
								opacity: '0',
								offset: 0.3,
							},
							{
								transform: 'none',
								opacity: '0',
								offset: 0.5,
							},
							{
								transform: 'none',
								opacity: '0',
								offset: 0.7,
							},
							{
								transform: 'none',
								opacity: '1',
								offset: 1,
							},
						],
						timing: {
							duration: 2500,
							iterations: 1,
							easing: 'cubic-bezier(0,0,1,1)',
						}
					}}>
						<SectionMain position="center" direction="column" alignItems="center">
							<Title align="center">
								<span style={{ fontSize: 12, whiteSpace: 'pre-wrap' }}>
									{t.checkout.redirect_msg}...
								</span>
							</Title>
							<Title align="center">
								<span style={{ fontSize: 12, textDecoration: "underline", whiteSpace: 'pre-wrap', cursor: 'pointer' }} role="button" onClick={() => window.location.href = "https://www.google.com.br"}>
									{t.checkout.if_not_redirected_msg}
								</span>
							</Title>
						</SectionMain>
					</AnimatedView>
				}

			</div >

		</AnimatedView >
	)
}

export default SuccessPage