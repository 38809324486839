import React from 'react'
import TopMenuUI from './TopMenuUI'
import TopMenuWrapper from './TopMenuWrapper'

const TopMenu = () => {
	return (
		<TopMenuWrapper>
			{({ label, link, icon }) => <TopMenuUI label={label} link={link} icon={icon} />}
		</TopMenuWrapper>
	)
}

export default TopMenu
