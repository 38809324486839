import axios from "axios"

/** @type {import("../../node_modules/axios/index").AxiosInstance} */
export const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_NEW_URL}/v1`,
})

window.api = api

function getMode() {
    return +localStorage.getItem("test") ? "SANDBOX" : "LIVE"
}

api.interceptors.request.use((config) => {
    config.headers.mode = getMode()
    config.headers['Content-Type'] = 'application/json'
    // config.headers['Authorization'] = localStorage.getItem("token") || null
    return config
})

api.interceptors.response.use(
    (res) => res,
    (error) => {
        Object.defineProperty(error, "message", {
            get() {
                return (
                    this.response?.data?.errors?.[0]?.message ??
                    "@@ Tente novamente mais tarde"
                )
            },
        })
        return Promise.reject(error)
    }
)

export class CheckoutService {

    static Products = class Products {
        static async Pay({ form, session_token, hash, recaptchaToken }) {
            const { data } = await api.post(`/product-checkout/pay/${hash}`, form, {
                headers: {
                    'Authorization': session_token,
                    'Recaptcha-Token': recaptchaToken || "",

                }
            })
            return data
        }
        static async Subscribe({ customer, coupon_id, session_token, hash, recaptchaToken, form }) {
            const { data } = await api.post(`product-checkout/sale/${hash}`, { ...form }, {
                headers: {
                    'Authorization': session_token,
                    'Recaptcha-Token': recaptchaToken || "",
                }
            })
            return data
        }
        static async ValidateCoupon({ code, hash, quantity }) {
            const { data } = await api.get(`/product-checkout/coupon?code=${code}&hash=${hash}&quantity=${quantity}`)
            return data
        }
    }
    static async Subscribe({ customer, subscription, coupon_id, session_token, recaptchaToken }) {
        const { data } = await api.post(`/checkout/subscribe`, { customer, subscription, coupon_id }, {
            headers: {
                'Authorization': session_token,
                'Recaptcha-Token': recaptchaToken || "",
            }
        })
        return data
    }

    static async GetDocumentTypes({ countryId, session_token }) {
        const { data } = await api.get(`/commons/documents?countryId=${countryId}`)
        return data
    }

    static async Pay({ payment, session_token }) {
        const { data } = await api.post(`/checkout/pay`, payment, {
            headers: {
                'Authorization': session_token
            }
        })
        return data
    }

    static async Get({ hash }) {
        const { data } = await api.post(`/checkout/${hash}`)
        localStorage.setItem("token", data.session_token)
        return data
    }

    static async GetProduct({ hash }) {
        const { data } = await api.post(`/product-checkout/${hash}`)
        localStorage.setItem("token", data.session_token)
        return data
    }

    static async GetPaymentUpdateInfo_OLD({ subscription_id, token, company_id }) {
        const { data } = await api.get(`/checkout/payment-mean/old_hash`, {
            headers: {
                "Hash": token,
            }
        })
        return data
    }

    static async GetPaymentUpdateInfo({ hash }) {
        const { data } = await api.get(`/checkout/payment-mean/${hash}`)
        return data
    }

    static async UpdatePaymentMean({ session_token, form }) {
        const { data } = await api.post(`/update-payment-mean`, form, {
            headers: {
                'Authorization': session_token
            }
        })
        return data
    }

    static async PayBoleto({ payment, session_token }) {
        const { data } = await api.post(`/checkout/pay`, payment, {
            headers: {
                'Authorization': session_token
            }
        })
        return data
    }

    static async ValidateCoupon({ code, hash }) {
        const { data } = await api.get(`/checkout/coupon?code=${code}&hash=${hash}`)
        return data
    }

    static async GetFormToken() {
        const { data } = await api.post('/payment-means/form-token')
        return data
    }

    static async SendForgotToken({ email }) {
        const { data } = await api.post(`/login/forgot`, { email })
        return data
    }

    static async AddCard(form) {
        const { data } = await api.post(`/me/payments/cards`, form)
        return data
    }

    static async onAddShippingAddress(form) {
        const { data } = await api.post(`/me/shipping-address`, form)
        return data
    }

    static async GetMe() {
        const { data } = await api.get(`/me`)
        return data
    }


    static Subscriptions = class Subscriptions {
        static async GetById({ id }) {
            const { data } = await api.get(`/subscriptions/${id}`)
            return data
        }
    }


}