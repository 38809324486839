import React from 'react'
import SectionMain from '../../../../../layout/SectionMain'
import { makeStyles, Paper } from '@material-ui/core'
import getStatusColor from '../../../../../utils/getStatusColor'
import StatusCard from '../../../cards/StatusCard'
import Title, { TitleSmall } from '../../../../../components/checkout-1.5.4/forms/Title'
import toCurrency from '../../../../../core/toCurrency'
import { useTranslation } from '../../../../../components/IntlContext/IntlContext'
import { creditCard, barcode, euro } from "react-icons-kit/fa";
import Icon from 'react-icons-kit'



const useStyles = makeStyles({
	container: { padding: '1px 1px', width: '100%' },
	statusBar: {
		backgroundColor: ({ statusColor }) => statusColor,
		height: 5
	},
	cardContainer: { padding: '10px 10px', justifyContent: 'space-between' }
})

const ListSalesUI = ({ sale }) => {
	const classes = useStyles({ statusColor: getStatusColor(sale.status) })
	const t = useTranslation()
	return (
		<SectionMain>
			<Paper className={classes.container}>
				<SectionMain noPadding className={classes.statusBar} />
				<SectionMain className={classes.cardContainer} position="space-between">
					<Title>{sale.sale_code}</Title>
					<StatusCard status={sale.status} />
				</SectionMain>
				<SectionMain className={classes.cardContainer} position="space-between">
					<TitleSmall bold>{toCurrency(sale.total_amount, sale.currency?.alpha_code)}</TitleSmall>

					{sale.payment_mean && sale.payment_mean.boleto && (
						<TitleSmall>
							<span className="flex-row" style={{ alignItems: 'bottom' }}>
								<Icon icon={barcode} />
								<span style={{ marginLeft: 10 }}>{`Boleto bancário`}</span>
							</span>
						</TitleSmall>
					)}
					{sale.payment_mean && sale.payment_mean.card && (
						<TitleSmall>
							<span className="flex-row" style={{ alignItems: 'bottom' }}>
								<img
									src={`https://veripagassets.blob.core.windows.net/brands/${sale.payment_mean.card.brand.toLowerCase()}.png`}
									width="40px"
									height="23px"
									alt=""
								/>
								<span style={{ marginLeft: 10 }}>{`${t.generics.last_numbers} ${(sale.payment_mean.card
									.number || '')
									.substring(sale.payment_mean.card.number.length - 4)}`}</span>
							</span>
						</TitleSmall>
					)}

					{sale.payment_mean && sale.payment_mean.boleto && (
						<a href={sale.payment_mean.boleto.link} target='_blank'>
							<TitleSmall>
								{"Baixar boleto"}
							</TitleSmall>
						</a>
					)}
				</SectionMain>
			</Paper>
		</SectionMain>
	)
}

export default ListSalesUI
