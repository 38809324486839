import React, { useEffect, useContext } from "react"
import { Lg, Md } from "../components/grids/Cols"
import api, { billService } from "../core/api"

import useReduxState from "../core/useReduxState"
import mask from "../core/mask"
import toCurrency from "../core/toCurrency"
import { useTranslation } from "../components/IntlContext/IntlContext"
import AppContext from "../components/AppContextCheckout"

const InvoiceOnlyPage = ({ match }) => {
	const { user, company } = useContext(AppContext)
	const [getState, setState] = useReduxState({ invoice: {} })

	useEffect(() => {
		fetchInvoice()
	}, [])

	let path = match.params.id

	const fetchInvoice = async () => {
		const { data: invoice } = await api.get(`/invoices/${path}`)
		setState({
			invoice: {
				...invoice,
				customer: { ...user },
				company: { ...company }
			}
		})
		document.title = `Fatura - ${path}`
	}

	const formatDocumentNumber = (number, type) => {
		switch (type) {
			case "CNPJ":
				return mask(number, "99.999.999/9999-99")
			case "CPF":
				return mask(number, "999.999.999-99")
		}
		return number
	}

	const invoice_status = {
		review: "invoices.status.review",
		pending: "invoices.status.pending",
		paid: "invoices.status.paid",
		canceled: "invoices.status.canceled",
		scheduled: "invoices.status.scheduled",
		created: "invoices.status.created",
		suspended: "invoices.status.suspended",
		blocked: "invoices.status.blocked",
		unpaid: "invoices.status.unpaid"
	}

	const invoice_color = {
		review: "#000",
		pending: "#000",
		paid: "green",
		canceled: "red",
		scheduled: "blue",
		created: "#000",
		suspended: "orange",
		blocked: "red",
		unpaid: "blue"
	}

	const style = {
		invoice: {
			backgroundColor: "#fff",
			width: "70vmin",
			height: "100vh"
		},
		header: {
			padding: "20px 45px 40px 45px",
			backgroundColor: "#d0d0d0"
		},

		text: {
			textTransform: "uppercase",
			fontSize: "25px"
		},
		fatura_number: { fontSize: "30px" },
		contact_sub: {
			justifyContent: "space-between",
			fontSize: "12px"
		},

		textDados: {
			textTransform: "uppercase",
			fontSize: "12px",
			// marginTop: "10px",
			fontWeight: "500"
		},
		nome: {
			textTransform: "uppercase",
			marginTop: "5px",
			fontSize: "20px",
			marginBottom: "10px",
			color: "#757575",
			fontWeight: "bold"
		},
		title: {
			fontWeight: "bold",
			marginTop: "1.2em"
		}
	}
	const { invoice } = getState()

	const LabelIcon = ({ label, icon }) => (
		<div className="flex-row center-b">
			{icon && (
				<i
					className={`fas fa-${icon}`}
					style={{ fontSize: "large", marginRight: "10px" }}
				/>
			)}
			<div style={style.textDados}>{label}</div>
		</div>
	)

	const t = useTranslation()
	return (
		<>
			<style jsx>{`
				@media print {
					.invoice {
						margin: 0 0 !important;
						page-break-after: always !important;
						width: 100% !important;
						height: 100vh !important;
					}
				}
			`}</style>
			{invoice.company && (
				<Lg
					colStyle={{ width: "100%", backgroundColor: "#f8f8f8" }}
					className="flex-row center-a">
					{/* <pre>{JSON.stringify(invoice, null, 4)}</pre> */}
					<div className="flex-col  invoice" style={style.invoice}>
						<div style={style.header}>
							<Lg>
								<div>
									<img
										height="80px"
										src={
											invoice.company.style
												.url_logo_default
										}
									/>
								</div>
								<Md>
									<div style={style.text}>
										{invoice.company.company_name}
									</div>

									<div>
										{invoice.company.street1}{" "}
										{invoice.company.street2
											? `, ${invoice.company.street2} -`
											: ""}
										{invoice.company.city
											? `, ${invoice.company.city} - `
											: ""}
										{invoice.company.state
											? `${invoice.company.state}, `
											: ""}
										{invoice.company.postal_code
											? `${invoice.company.postal_code} `
											: ""}
										{invoice.company.number
											? `- ${invoice.company.number}`
											: ""}
									</div>
									<div>
										{formatDocumentNumber(
											invoice.company.number,
											invoice.company.document_type
										)}
									</div>
								</Md>
								<Md>
									<div
										style={style.fatura_number}
										className="flex-row flex-end">
										{invoice.invoice_code}
									</div>
									<div style={style.contact_sub}>
										<div>
											<div className="flex-row flex-end">
												{t(
													"invoices.fields.billing_date"
												)}
												:{" "}
												{new Date(
													invoice.billing_date
												).toLocaleDateString()}
											</div>
											{/* <div className="flex-row flex-end">
												{t("subscriptions.fields.next_charge")}:{" "}
												{new Date(
													invoice.next_charge
												).toLocaleDateString()}
											</div> */}
										</div>
									</div>
								</Md>
							</Lg>
						</div>
						<Lg colStyle={{ padding: "20px 45px 40px 45px" }}>
							<Md spanSize={16}>
								<Lg>
									<div style={style.textDados}>
										{t("invoices.label.for")}:
									</div>
								</Lg>
								<Lg>
									<div style={style.nome}>
										{`${invoice.customer.first_name} ${invoice.customer.last_name}`}
									</div>
								</Lg>
								<Lg>
									<Lg>
										<LabelIcon
											label={
												<div>
													{invoice.company.street1}{" "}
													{invoice.company.street2
														? `, ${invoice.company.street2} -`
														: ""}
													{invoice.company.city
														? `, ${invoice.company.city} - `
														: ""}
													{invoice.company.state
														? `${invoice.company.state}, `
														: ""}
													{invoice.company.postal_code
														? `${invoice.company.postal_code} `
														: ""}
													{invoice.company.number
														? `- ${invoice.company.number}`
														: ""}
												</div>
											}
										/>
									</Lg>
									<Lg>
										{invoice.customer.document && (
											<LabelIcon
												label={formatDocumentNumber(
													invoice.customer.document
														.number,
													invoice.customer.document
														.type
												)}
											/>
										)}
									</Lg>
									{invoice.customer.email && (
										<Lg>
											<LabelIcon
												label={invoice.customer.email}
											/>
										</Lg>
									)}
									{invoice.customer.mobile_number && (
										<Lg propPadding>
											<LabelIcon
												icon="phone"
												label={
													invoice.customer
														.mobile_number
												}
											/>
										</Lg>
									)}
								</Lg>
							</Md>
							<Md spanSize={8}>
								<Lg className="flex-row flex-end">
									<div style={style.textDados}>
										{t("invoices.label.payment_status")}
									</div>
								</Lg>
								<Lg>
									<div className="flex-row flex-end">
										<span
											style={{
												textTransform: "uppercase",
												color:
													invoice_color[
														invoice.status
													],
												fontWeight: "bold",
												fontSize: "1.2em",
												borderRadius: "3px"
											}}>
											{t(invoice_status[invoice.status])}
										</span>
									</div>
								</Lg>
							</Md>

							{/* <Lg propPadding>
								<div className="flex-row center-b">
									<div style={{ marginRight: "5px" }}>
										<img
											height="28px"
											src={`https://veripagassets.blob.core.windows.net/brands/${}.png`}
										/>
									</div>
									<div style={style.textDados}>
										**** **** **** 0014
									</div>
								</div>
							</Lg> */}
							<Lg>
								<hr
									style={{
										height: "1px",
										backgroundColor: "#7e7e7e"
									}}
								/>
							</Lg>
							<Lg propPadding>
								<div className="flex-row center-a center-b">
									<img src="https://d1oco4z2z1fhwp.cloudfront.net/templates/default/60/ecommerce-template_order-confirmed-icon.jpg" />
								</div>
								<div
									className="flex-row center-a center-b"
									style={style.title}>
									{t("invoices.label.check_data")}
								</div>
							</Lg>
							<Lg>
								<Md fixedCol={12}>
									<Lg>
										<div style={style.title}>
											{" "}
											{t(
												"invoices.label.description"
											)}{" "}
										</div>
										<div style={style.textDados}>
											{invoice.plan.name}
										</div>
									</Lg>
								</Md>
								<Md fixedCol={12}>
									<Lg>
										<div
											className="flex-row flex-end"
											style={style.title}>
											Valor
										</div>
										<div
											className="flex-row flex-end"
											style={style.textDados}>
											{toCurrency(
												invoice.plan.payment_definitions
													.amount,
												invoice.plan.payment_definitions
													.currency
											)}
										</div>
									</Lg>
								</Md>
								<Lg>
									<hr
										style={{
											height: "1px",
											backgroundColor: "#7e7e7e"
										}}
									/>
								</Lg>
							</Lg>
							<Lg>
								<Md fixedCol={12}>
									<Lg>
										<div style={style.textDados}>
											{t("invoices.label.subtotal")}
										</div>
										<div style={style.textDados}>
											{t("invoices.label.tax")}
										</div>
									</Lg>
								</Md>
								<Md fixedCol={12}>
									<Lg>
										<div
											className="flex-row flex-end"
											style={style.textDados}>
											{toCurrency(
												invoice.amount,
												invoice.plan.payment_definitions
													.currency
											)}
										</div>
										<div
											className="flex-row flex-end"
											style={style.textDados}>
											{toCurrency(
												invoice.tax_amount,
												invoice.plan.payment_definitions
													.currency
											)}
										</div>
									</Lg>
								</Md>
							</Lg>
							<Lg>
								<Md fixedCol={12}>
									<h2 style={{ fontWeight: "bold" }}>
										Total
									</h2>
								</Md>
								<Md fixedCol={12} className="flex-row flex-end">
									<h2 style={{ fontWeight: "bold" }}>
										{toCurrency(
											invoice.total_amount,
											invoice.plan.payment_definitions
												.currency
										)}
									</h2>
								</Md>
							</Lg>
						</Lg>
					</div>
				</Lg>
			)}
		</>
	)
}

export default InvoiceOnlyPage
