import React from "react"
import SectionMain from "../../../../layout/SectionMain"
import ListPaymentMeanItem from "../ListPaymentMeanItem"
import { List, Hidden } from "@material-ui/core"
import Cards from "react-credit-cards"

const ListPaymentMeans = ({ arrayCards = [] }) => {
	return (
		<SectionMain
			style={{ justifyContent: "center" }}
		>
			<Hidden only={["xs"]}>
				{arrayCards.map((payment_mean) => (
					<SectionMain
						position="center"
						noPadding
						xs={12}
						sm={6}
						md={6}
						lg={6}
						xl={6}
						style={{ padding: 20 }}
					>
						<Cards
							expiry={payment_mean.card.expiry}
							name={payment_mean.holder || "---"}
							number={
								(
									payment_mean.card.brand || ""
								).toLowerCase() === "amex"
									? payment_mean.card.number.substring(1)
									: payment_mean.card.number
							}
							issuer={(
								payment_mean.card.brand || ""
							).toLowerCase()}
							preview
						/>
					</SectionMain>
				))}
			</Hidden>

			<Hidden only={["xl", "lg", "md", "sm"]}>
				<List style={{ width: "100%" }}>
					<SectionMain noPadding>
						{arrayCards.map((payment_mean) => (
							<ListPaymentMeanItem paymentMean={payment_mean} />
						))}
					</SectionMain>
				</List>
			</Hidden>
		</SectionMain>
	)
}

export default ListPaymentMeans
