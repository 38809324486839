import React from 'react'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(error) {
		console.log('FRONTEND ERROR', error.message)
		// Atualiza o state para que a próxima renderização mostre a UI alternativa.
		return { hasError: true }
	}

	// componentDidCatch(error, errorInfo) {
	// 	// Você também pode registrar o erro em um serviço de relatórios de erro
	// 	logErrorToMyService(error, errorInfo)
	// }

	render() {
		if (this.state.hasError) {
			// Você pode renderizar qualquer UI alternativa
			return this.props.errorPage || ''
		}

		return this.props.children
	}
}

export default ErrorBoundary
