import React, { useContext, useState, useEffect, useCallback } from "react"
import PortalWrapper from "../../components/layout/PortalWrapper"
import ContainerUI from "../../components/layout/Container"
import IconLabel from "../../components/layout/IconLabel"
import PersonIcon from "@material-ui/icons/PersonOutlineOutlined"
import StarIcon from "@material-ui/icons/Star"
import StarBorderIcon from "@material-ui/icons/StarBorder"
import { useTranslation } from "../../../components/IntlContext/IntlContext"
import SectionMain from "../../../layout/SectionMain"
import AppContext from "../../../components/AppContextCheckout"
import MapOutlinedIcon from "@material-ui/icons/MapOutlined"
import Title, {
	TitleSmall,
} from "../../../components/checkout-1.5.4/forms/Title"
import {
	makeStyles,
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	DialogActions,
	CircularProgress,
} from "@material-ui/core"
import { Formik } from "formik"
import { string, object, mixed, array } from "yup" // for only what you need
import api from "../../../core/api"
import { useSnackbar } from "notistack"
import RenderInputsV3 from "../../../components/checkout-1.5.4/forms/RenderInputsV3"
import Loader from "../../../components/Loader"

const useStyles = makeStyles({
	title: {
		fontWeight: "400",
		color: "#858585",
	},
	label: {
		display: "flex",
		fontWeight: "600",
		textTransform: ({ toUpperCase }) => toUpperCase && "capitalize",
		width: 320,
	},
	textHover: {
		"&:hover": {
			color: ({ onHover }) => onHover && "#fff",
		},
	},
	addressCard: {
		transition: "0.3s",
		"&:hover": {
			background: ({ primaryColor }) => `${primaryColor}`,
		},
	},
	modal: {
		maxHeight: window.innerHeight < 375 && window.innerHeight - 50,
	},
})

const ProfilePage = ({ history }) => {
	const { user, company } = useContext(AppContext)
	const classes = useStyles()
	const { style } = company
	const t = useTranslation()
	const { enqueueSnackbar } = useSnackbar()
	const [open, setOpen] = useState("")
	const [confirm, setConfirm] = useState(false)
	const [loading, setLoading] = useState(true)
	const [validationSchema, setValidationSchema] = useState(() => void 0)
	const [defaultValues, setDefaultValues] = useState({
		disabled_input: false,
		billing_address: {
			postal_code: "",
			street1: "",
			number: "",
			state: "",
			district: "",
			city: "",
			// country: string().required('Preencha o campo país.')
		},
		shipping_address: {
			postal_code: "",
			street1: "",
			number: "",
			state: "",
			district: "",
			city: "",
			// country: string().required('Preencha o campo país.')
		},
	})

	const ProfileRow = ({ title, value = "---", toUpperCase }) => {
		const classes = useStyles({ toUpperCase })
		return (
			<SectionMain direction="column">
				<Title fontSize={18} className={classes.title}>
					<span className={classes.title}>{t(title)}</span>
				</Title>
				<Title className={classes.label} fontSize={13}>
					{value}
				</Title>
			</SectionMain>
		)
	}

	const RenderSelectedAddress = ({ typeAddress, formikProps }) => {
		switch (typeAddress) {
			case "billing_address":
				return (
					<RenderInputsV3
						checkoutConfig={{
							billing_address: true,
						}}
						formikProps={formikProps}
						arrayInputs={["billing_address"]}
					/>
				)

			case "shipping_address":
				return (
					<RenderInputsV3
						checkoutConfig={{
							shipping_address: true,
						}}
						formikProps={formikProps}
						arrayInputs={["shipping_address"]}
					/>
				)

			default:
				return "Tem certeza que deseja remover este endereço?"
		}
	}

	const AddressCard = ({ address, billing, shipping, onClick }) => {
		const classes = useStyles({
			primaryColor: style.primary_color,
		})

		if (!address.postal_code) {
			return (
				<ContainerUI className={classes.addressCard} id="textHover">
					<Title
						align="center"
						style={{
							width: "100%",
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<SectionMain
							noPadding
							alignItems="center"
							direction="column"
							position="center"
						>
							<span className={classes.label}>
								{billing &&
									"Clique para cadastrar um endereço de cobrança"}
								{shipping &&
									"Clique para cadastrar um endereço de entrega"}
							</span>
						</SectionMain>
					</Title>
				</ContainerUI>
			)
		}

		return (
			<ContainerUI className={classes.addressCard} id="textHover">
				<SectionMain
					direction="column"
					noPadding
					item
					xs={11}
					onClick={onClick}
				>
					<Title>
						<span
							className={classes.label}
						>{`${address.street1}, ${address.number}`}</span>
					</Title>
					<Title className={classes.label}>
						<span
							className={classes.label}
						>{`${address.city}, ${address.state} - ${address.postal_code}`}</span>
					</Title>
					<Title className={classes.label}>
						<span className={classes.label}>
							{address.district}
						</span>
					</Title>
					{!address.street1.match(address.street2) && (
						<Title className={classes.label}>
							<span className={classes.label}>
								{address.street2}
							</span>
						</Title>
					)}
				</SectionMain>
				{shipping && (
					<SectionMain
						item
						xs={1}
						direction="column"
						noPadding
						alignItems="center"
						position="center"
						id="textHover"
					>
						{address.is_main ? (
							<Title>
								<StarIcon htmlColor="#FFCA0C" />
							</Title>
						) : (
							<Title>
								<StarBorderIcon
									onClick={() =>
										handleFavoriteAddress(
											address.address_id
										)
									}
								/>
							</Title>
						)}
					</SectionMain>
				)}
			</ContainerUI>
		)
	}

	const onChangeShippingAddress = (address, formikProps) => {
		formikProps.setFieldValue("shipping_address", address)
		setOpen("shipping_address")
	}

	const onFormSubmit = async (values, setFieldValue) => {
		const form = {
			billing_address: {
				values: {
					...values.billing_address,
					address_country_code: company.country,
				},
				send: async (values) =>
					await api.put(`/me/billing-address`, values),
			},
			shipping_address: {
				values: {
					...values.shipping_address,
					address_country_code: company.country,
				},
				send: async (values) =>
					values.address_id
						? await api.put(
								`/me/shipping-address/${values.address_id}`,
								values
						  )
						: await api.post(`/me/shipping-address/`, values),
			},
		}

		try {
			await form[open].send(form[open].values).then((res) => {
				if (res.status === 200) {
					enqueueSnackbar(t.messages["success_address.profile"], {
						variant: "success",
					})
					setOpen("")
				}
			})
			fetchAddress()
		} catch (e) {
			if (e.response.status === 409) {
				enqueueSnackbar("Erro .", {
					variant: "error",
				})
			} else {
				enqueueSnackbar(t.errors["generic.post"], {
					variant: "error",
				})
			}
		}
	}

	const onDeleteAddress = async (ShippingAddressId, type) => {
		const form = {
			billing_address: {
				delete: async () => await api.delete(`/me/billing-address`),
			},
			shipping_address: {
				delete: async (addressId) =>
					await api.delete(`/me/shipping-address/${addressId}`),
			},
		}
		try {
			await form[type].delete(ShippingAddressId).then((res) => {
				if (res.status === 200) {
					enqueueSnackbar(t.messages["success_address.profile"], {
						variant: "success",
					})
					setOpen("")
				}
			})
			setLoading(true)

			fetchAddress()
		} catch (e) {
			if (e.response.status === 409) {
				enqueueSnackbar("Erro .", {
					variant: "error",
				})
			} else {
				enqueueSnackbar(t.errors["generic.post"], {
					variant: "error",
				})
			}
		}
	}

	const handleDeleteAddress = (addressId = "") => {
		if (confirm) {
			onDeleteAddress(addressId, confirm)
		} else {
			setConfirm(open)
			setOpen("confirm")
		}
	}

	const handleFavoriteAddress = async (addressId) => {
		setLoading(true)
		try {
			await api
				.put(`/me/shipping-address/${addressId}/main`)
				.then((res) => {
					if (res.status === 200) {
						enqueueSnackbar(t.messages["success_address.profile"], {
							variant: "success",
						})
						setOpen("")
					}

					fetchAddress()
				})
		} catch (e) {
			console.log({ e })
		}
	}

	const fetchAddress = useCallback(async () => {
		try {
			await api
				.get("/me/billing-address")
				.then((res) => {
					const billing_address = res.data
					if (billing_address.postal_code) {
						return billing_address
					}
					return defaultValues.billing_address
				})
				.then(
					async (billing_address) =>
						await api.get("/me/shipping-address").then((res) => {
							const shipping_address = res.data.items
							setDefaultValues({
								...defaultValues,
								billing_address,
								shipping_address,
							})
						})
				)
		} catch (e) {
			console.log({ e })
		}
		setConfirm(false)
		setLoading(false)
	}, [])

	useEffect(() => {
		setValidationSchema(
			object().shape({
				[open]: object().shape({
					postal_code: string().required("Preencha o campo cep."),
					street1: string().required("Preencha o campo rua."),
					number: string().required("Preencha o campo número."),
					state: string().required("Preencha o campo estado."),
					district: string().required("Preencha o campo bairro."),
					city: string().required("Preencha o campo cidade."),
					// country: string().required('Preencha o campo país.')
				}),
			})
		)
	}, [open])

	useEffect(() => {
		fetchAddress()
	}, [])

	return (
		<PortalWrapper history={history}>
			{loading ? (
				<div
					className="flex-col center-a center-b"
					style={{
						width: "100vw",
						height: "35vw",
					}}
				>
					<Loader />
				</div>
			) : (
				<Formik
					initialValues={defaultValues}
					validationSchema={() => validationSchema}
					onSubmit={(values, { setFieldValue }) =>
						onFormSubmit(values, setFieldValue)
					}
				>
					{(formikProps) => (
						<React.Fragment>
							{open && (
								<Dialog
									className={classes.modal}
									open={open}
									onClose={() => {
										setOpen("")
										setConfirm(false)
									}}
								>
									<form
										onSubmit={(e) => {
											e.preventDefault()
											formikProps.handleSubmit()
										}}
									>
										<DialogTitle>
											{t.titles[`${open}.form`]}
										</DialogTitle>
										<DialogContent>
											<SectionMain
												noPadding
												position="center"
											>
												<style>
													{`@media only screen and (min-width: 1024px) {
							#form-input-section {
								width:90% !important ;
								
							}}
							`}
												</style>

												<RenderSelectedAddress
													typeAddress={open}
													formikProps={formikProps}
												/>
											</SectionMain>
										</DialogContent>
										<DialogActions>
											{open.includes(
												"billing_address"
											) ? (
												<Button
													color="secondary"
													onClick={() => {
														setOpen("")
														setConfirm("")
													}}
												>
													{t.generics.cancel}
												</Button>
											) : (
												formikProps.values
													.shipping_address
													.address_id && (
													<Button
														color="secondary"
														onClick={() =>
															handleDeleteAddress(
																formikProps
																	.values
																	.shipping_address
																	.address_id
															)
														}
													>
														{confirm
															? t.generics.yes
															: t.generics.trash}
													</Button>
												)
											)}

											{confirm ? (
												<Button
													onClick={() => {
														setOpen("")
														setConfirm(false)
													}}
													variant="contained"
													color="primary"
													style={{
														background:
															style.primary_color,
													}}
												>
													{t.generics.no}
												</Button>
											) : (
												<Button
													disabled={
														formikProps.isSubmitting
													}
													type="submit"
													variant="contained"
													color="primary"
													style={{
														background:
															style.primary_color,
													}}
												>
													{t.generics.save}
												</Button>
											)}
										</DialogActions>
									</form>
								</Dialog>
							)}

							<ContainerUI>
								<IconLabel
									label={t.titles["profile.form"]}
									icon={<PersonIcon />}
									primaryColor={style.primary_color}
								/>
								<ProfileRow
									toUpperCase
									title={"^forms.first_name"}
									value={`${user.first_name} ${user.last_name}`}
								/>

								<ProfileRow
									title={"^forms.email"}
									value={`${user.email}`}
								/>
								<ProfileRow
									title={"^forms.mobile_phone"}
									value={user.mobile_number}
								/>
								<ProfileRow
									title={"^forms.country"}
									value={user.country_code}
								/>
							</ContainerUI>
							<SectionMain />
							<ContainerUI>
								<IconLabel
									label={t.titles["billing_address.form"]}
									icon={<MapOutlinedIcon />}
									primaryColor={"#27AE60"}
								/>
								<SectionMain
									onClick={() => setOpen("billing_address")}
								>
									<AddressCard
										address={defaultValues.billing_address}
										billing
									/>
								</SectionMain>
								<IconLabel
									label={t.titles["shipping_address.form"]}
									icon={<MapOutlinedIcon />}
									primaryColor={"#27AE60"}
								/>

								{defaultValues.shipping_address &&
									defaultValues.shipping_address.map(
										(address) => (
											<SectionMain>
												<AddressCard
													address={address}
													shipping
													onClick={() =>
														onChangeShippingAddress(
															address,
															formikProps
														)
													}
												/>
											</SectionMain>
										)
									)}
								<SectionMain
									onClick={() =>
										onChangeShippingAddress(
											{
												postal_code: "",
												street1: "",
												number: "",
												state: "",
												district: "",
												city: "",
											},
											formikProps
										)
									}
								>
									<AddressCard address={""} shipping />
								</SectionMain>
							</ContainerUI>
						</React.Fragment>
					)}
				</Formik>
			)}
		</PortalWrapper>
	)
}

export default ProfilePage
