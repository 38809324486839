import React, { useState, useEffect, useCallback } from "react";
import TextInput from "./components/checkout-1.5.4/forms/TextInput";
import WrapperLayout from "./layout/WrapperLayout";
import SectionMain from "./layout/SectionMain";
import StepTitle from "./components/checkout-1.5.4/forms/StepTitle";
import Title, { TitleSmall } from "./components/checkout-1.5.4/forms/Title";
import {
	Grid,
	Hidden,
	FormControlLabel,
	Checkbox,
	Button,
	withStyles,
	IconButton,
	CircularProgress,
	RadioGroup,
	ListItem,
	List,
	MenuItem,
	ListItemIcon,
	Radio,
	ListItemText,
	ListItemSecondaryAction,
	FormLabel,
	ThemeProvider,
	createTheme,
} from "@material-ui/core";
import { string, object } from "yup"; // for only what you need
import { Formik } from "formik";
import AnimatedView from "./components/checkout-1.5.4/AnimatedView";
import { backgroundColor } from "./layout/color";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import api, { billService, sddApiService } from "./core/api";
import toCurrency from "./core/toCurrency";
import { useConfirmation } from "./core/useConfirmation";
import { useTranslation } from "./components/IntlContext/IntlContext";
import SuccessPage from "./components/checkout-1.5.4/SuccessPage";
import RoomIcon from "@material-ui/icons/Room";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import PersonIcon from "@material-ui/icons/Person";
import ErrorCheckoutMessage from "./components/checkout-1.5.4/ErrorMessage";
import AppContext, { useAppContext } from "./components/AppContextCheckout";
import renderKRForm from "./components/checkout-1.5.4/forms/PayzenForm";
import RenderPayzenForm from "./components/checkout-1.5.4/forms/PayzenForm";
import RenderInputsV3 from "./components/checkout-1.5.4/forms/RenderInputsV3";
import RenderInputsUI from "./components/checkout-1.5.4/forms/RenderInputsV3/RenderInputsUI";
import RenderInputsWrapper from "./components/checkout-1.5.4/forms/RenderInputsV3/RenderInputsWrapper";
import { handleLocaleChange } from "./locale/handleLocaleChange";
import { useSnackbar } from "notistack";
import ErrorBoundary from "./components/ErrorBoundary";
import { creditCard, barcode, euro } from "react-icons-kit/fa";
import PaymentMeanInput from "./portal/components/cards/PaymentMeanInput";
import RenderBoleto from "./portal/components/cards/RenderBoleto";
import SuccessBoleto from "./components/checkout-1.5.4/SuccessBoleto";
import Loader from "./components/Loader";
import { Helmet } from "react-helmet";
import SelectedInput from "./components/checkout-1.5.4/forms/SelectedInput";
import { validaCNPJ, validaCPF } from "./utils/validaCPFeCNPJ";
import './layout/css/checkout.css'
import { CheckoutService } from "./core/CheckoutService";
import swal from '@sweetalert/with-react'
import ReCaptcha from 'react-google-recaptcha'
let has_document = false;
let has_address = false;

/**
 *
 * @returns {import("./locale/locale").TranslationFunction}
 */

let language = { BRA: "pt-BR", FRA: "fr" };

const execImmediate = (f) => f();

let next = {};

const checkout_config = {
	billing_address: true,
	country_code: true,
	document: true,
	email: true,
	first_name: true,
	last_name: true,
	mobile_number: true,
	shipping_address: true,
	footer_informations: {},
	captcha: {}
};
const iconsPaymentMethods = {
	boleto: barcode,
	credit_card: creditCard,
	sdd: euro,
	// pix: <Icon icon="ic:baseline-pix" style={{ fontSize: '45px' }} />

};

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9",
		// marginBottom: 12
	},
}))(Tooltip);

const CheckoutPage = () => {
	// Customer || Payment
	const { setAppState, payzenForm } = useAppContext(AppContext);
	const [step, setStep] = useState("customer");
	const [paymentMethod, setPaymentMethod] = useState("");
	const [billingDate, setBillingDate] = useState("")
	const [menuCollapse, setMenuCollapse] = useState({
		first: true,
		second: true,
		third: true,
		four: false,
		five: false,
	});
	const [enabledShippingAddress, setEnabledShippingAddress] = useState(true);
	const [company, setCompany] = useState({
		style: {
			primary_color: "",
		},
	});
	const [addressInputs, setAddressInputs] = useState({
		billingAddressInputs: ["billing_address"],
		shippingAddressInputs: ["shipping_address"],
	});
	const [checkout, setCheckout] = useState("");
	const [messageError, setMessageError] = useState("");
	const [loading, setLoading] = useState(true);
	const [planHeight, setPlanHeight] = useState("");
	const [planWidth, setPlanWidth] = useState("");
	const t = useTranslation();
	const [confirm, showConfirm] = useConfirmation();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [success, setSuccess] = useState({
		// checkoutResponse: { status: 200 },
		// userInfo: {
		// 	email: "a@a.com",
		// },
		// done: true,
	});
	const [validationSchema, setValidationSchema] = useState(() => object().shape({
		first_name: string().required("Preencha o campo nome."),
		last_name: string().required(
			"Preencha o campo sobrenome."
		),
		email: string()
			.email("Digite um e-mail válido")
			.required("Preencha o campo de e-mail"),
	}));
	const [sddInputs, setSddInputs] = useState([]);
	const theme = createTheme({
		palette: {
			primary: { main: checkout.company?.style.primary_color || "#fff" },
		},
	});
	const [TCAgreed, setTCAgreed] = useState(false)
	const [captchaChecked, setCaptchaChecked] = useState(false)
	const [defaultValues, setDefaultValues] = useState({
		disabled_input: false,
		first_name: "",
		last_name: "",
		email: "",
		mobile_phone: "",
		country_code: "",
		payment_method: "",
		document: { type: "CPF", number: "" },
		total: "0",
		shipping_address: {
			postal_code: "",
			street1: "",
			street2: "",
			number: "",
			state: "",
			district: "",
			city: "",
			country: "",
			complement: "",
		},
		billing_address: {
			postal_code: "",
			street1: "",
			street2: "",
			number: "",
			state: "",
			district: "",
			city: "",
			country: "",
			complement: "",
		},
		payment_mean: {
			card_number: "",
			expiry_date: "",
			cvv: "",
			customer_name: "",
			brand: "",
			subscription_id: "",
		},
		coupon: {
			coupon_code: "",
			coupon_enabled: false,
			coupon_error: "",
		},
		installment: 1,
	});
	const { enqueueSnackbar } = useSnackbar();

	function isBackgroundDark(hexcolor = "#000000") {
		hexcolor = hexcolor.replace("#", "");
		var r = parseInt(hexcolor.substr(0, 2), 16);
		var g = parseInt(hexcolor.substr(2, 2), 16);
		var b = parseInt(hexcolor.substr(4, 2), 16);
		var yiq = (r * 299 + g * 587 + b * 114) / 1000;
		return yiq < 128;
	}

	const customerInputs = [
		"first_name",
		"last_name",
		"email",
		"document",
		"mobile_number",
		"country_code",
	];
	const paymentInputs = ["payment"];

	const handleMenuCollapsable = (key) => {
		return setMenuCollapse({ ...menuCollapse, [key]: !menuCollapse[key] });
	};

	const renderBillingDateMessage = (billing_day, amount, currency, frequency) => {
		if (!billing_day) return (
			t(
				"^messages.trial_interval.discount",
				toCurrency(
					amount,
					currency
				),
				t.frequency_interval_unit[
				frequency
				]
			)
		)

		let date = new Date().toLocaleDateString()

		let [billingDay, billingMonth, billingYear] = date.split('/')

		billingDay = billing_day
		billingMonth = parseInt(billingMonth)
		billingYear = parseInt(billingYear)


		let today = date.split('/')[0]

		if (parseInt(today) >= billingDay) {
			++billingMonth
			if (billingMonth > 12) {
				billingMonth = 1
				++billingYear
			}
		}

		const billingDate = `${("0" + billingDay).slice(-2)}/${("0" + billingMonth).slice(-2)}/${billingYear}`

		return t(`@@ A partir do dia ${billing_day} do próximo mês, será cobrado ${toCurrency(amount, currency)}/${t.frequency_interval_unit[frequency]}`)
	}

	const checkValidationSchema = (checkout_config, country) => {
		const arrayCheckoutConfig = [
			{
				key: "first_name",
				shape: {
					first_name: string().required("Preencha o campo nome."),
					last_name: string().required("Preencha o campo sobrenome."),
					email: string()
						.email("Digite um e-mail válido")
						.required("Preencha o campo de e-mail"),
				},
			},
			// {
			// 	key: 'country_code',
			// 	shape: {
			// 		country_code: string().required('Preecha o campo país')
			// 	}
			// },
			{
				key: "document",
				shape: {
					document: object().shape({
						type: string().required("Preencha o campo tipo."),
						number: string()
							.matches(
								/^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2})$/,
								"Número de documento inválido"
							)
							.required("Documento obrigatório"),
					}),
				},
			},
			{
				key: "billing_address",
				shape: {
					billing_address: object().shape({
						postal_code: string().required("Preencha o campo cep."),
						street1: string().required("Preencha o campo rua."),
						number: string().required("Preencha o campo número."),
						state: string().required("Preencha o campo estado."),
						district: string().required("Preencha o campo bairro."),
						city: string().required("Preencha o campo cidade."),
						// country: string().required('Preencha o campo país.')
					}),
				},
			},
			{
				key: "shipping_address",
				shape: {
					shipping_address: object().shape({
						postal_code: string().required("Preencha o campo cep."),
						street1: string().required("Preencha o campo rua."),
						number: string().required("Preencha o campo número."),
						state: string().required("Preencha o campo estado."),
						district: string().required("Preencha o campo bairro."),
						city: string().required("Preencha o campo cidade."),
						// country: string().required('Preencha o campo país.')
					}),
				},
			},
			{
				key: "billing_address.fr",
				shape: {
					billing_address: object().shape({
						postal_code: string().required("Preencha o campo cep."),
						street1: string().required("Preencha o campo rua."),
						street2: string().required("Preencha o campo rua."),
						city: string().required("Preencha o campo cidade."),
						// country: string().required('Preencha o campo país.')
					}),
				},
			},
			{
				key: "shipping_address.fr",
				shape: {
					shipping_address: object().shape({
						postal_code: string().required("Preencha o campo cep."),
						street1: string().required("Preencha o campo rua."),
						street2: string().required("Preencha o campo rua."),
						city: string().required("Preencha o campo cidade."),
						// country: string().required('Preencha o campo país.')
					}),
				},
			},
			{
				key: "mobile_phone",
				shape: {
					mobile_phone: string().required(
						"Preencha o campo telefone."
					),
				},
			},
		];

		arrayCheckoutConfig.map(({ key, shape }, i) => {
			if (checkout_config[key] === true) {
				if (country === "FRA") {
					switch (key) {
						case "billing_address":
							next = { ...next, ...arrayCheckoutConfig[4].shape };
							break;

						case "shipping_address":
							next = { ...next, ...arrayCheckoutConfig[5].shape };
							break;

						default:
							next = { ...next, ...arrayCheckoutConfig[i].shape };
							break;
					}
				}
				if (has_address) {
					switch (key) {
						case "billing_address":
							next = { ...next, ...arrayCheckoutConfig[2].shape };
							break;

						case "shipping_address":
							next = { ...next, ...arrayCheckoutConfig[3].shape };
							break;

						default:
							next = { ...next, ...arrayCheckoutConfig[i].shape };
							break;
					}
				}

				if (has_document) {
					next = { ...next, ...arrayCheckoutConfig[1].shape }
				}

				if (checkout.checkout_config?.mobile_phone) {
					console.log('mobile_phone')
					next = { ...next, ...arrayCheckoutConfig[6].shape }
				}

				setValidationSchema(
					object().shape({
						first_name: string().required("Preencha o campo nome."),
						last_name: string().required(
							"Preencha o campo sobrenome."
						),
						email: string()
							.email("Digite um e-mail válido")
							.required("Preencha o campo de e-mail"),
						...next,
					})
				);
			}
		});
	};

	const checkInitialFormStep = (checkoutResponse, country) => {
		let getTotal =
			checkoutResponse.plan.trial.interval !== 0
				? checkoutResponse.plan.trial.amount
				: checkoutResponse.plan.charge_only_setup
					? 0
					: checkoutResponse.plan.amount;

		const [
			checkBoleto,
		] = checkoutResponse.plan.payment_methods.filter((payment) =>
			payment.includes("boleto") || payment.includes("pix")
		);

		if (checkoutResponse.customer) {
			// USAR ESTA VALIDAÇAO
			has_document = checkBoleto
				? !checkoutResponse.customer.document.number
				: false;
			has_address = checkBoleto
				? true
				: checkoutResponse.checkout_config.billing_address
			setDefaultValues({
				...defaultValues,
				// ...checkoutResponse.customer,
				billing_address: { ...defaultValues.billing_address },
				payment_mean: {
					...defaultValues.payment_mean,
					customer_id: checkoutResponse.customer.customer_id,
				},
				total: checkoutResponse.plan.setup_fee
					? getTotal + checkoutResponse.plan.setup_fee
					: getTotal,
			});

			if (checkBoleto) {
				setMenuCollapse({
					first: Boolean(!checkoutResponse.customer.document.number),
					second: true,
					third: true,
					four: false,
				});
				setStep("customer");
			} else {
				setMenuCollapse({
					first: false,
					second: true,
					third: true,
					four: true,
				});
				// setStep("payment");
			}

			if (checkoutResponse.plan.payment_methods.length === 2) {
				setPaymentMethod("credit_card");
				setDefaultValues({
					...defaultValues,
					...checkoutResponse.customer,
					payment_method: "credit_card",
					mobile_phone: checkoutResponse.customer.mobile_number,
					// disabled_input:
					// 	country === "FRA" ? false : checkBoleto ? false : true,
					total: checkoutResponse.plan.setup_fee
						? getTotal + checkoutResponse.plan.setup_fee
						: getTotal,
				});
			} else {
				setPaymentMethod(checkoutResponse.plan.payment_methods[0]);
				setDefaultValues({
					...defaultValues,
					...checkoutResponse.customer,
					payment_method: checkoutResponse.plan.payment_methods[0],
					total: checkoutResponse.plan.setup_fee
						? getTotal + checkoutResponse.plan.setup_fee
						: getTotal,
					mobile_phone: checkoutResponse.customer.mobile_number,
					// disabled_input:
					// 	country === "FRA" ? false : checkBoleto ? false : true,
				});
			}
		} else {
			has_document = checkBoleto
				? true
				: checkoutResponse.checkout_config.document;
			has_address = checkBoleto
				? true
				: checkoutResponse.checkout_config.billing_address;

			if (checkoutResponse.plan.payment_methods.length === 2) {
				setPaymentMethod("credit_card");
				setDefaultValues({
					...defaultValues,
					country_code: country,
					document: {
						type: country === "FRA" ? "CNI" : "CPF",
						number: "",
					},
					payment_method: "credit_card",
					// mobile_phone: checkoutResponse.customer.mobile_number,
					// disabled_input: checkBoleto ? false : true,
					total: checkoutResponse.plan.setup_fee
						? getTotal + checkoutResponse.plan.setup_fee
						: getTotal,
				});
			} else {
				setPaymentMethod(checkoutResponse.plan.payment_methods[0]);
				setDefaultValues({
					...defaultValues,
					country_code: country,
					document: {
						type: country === "FRA" ? "CNI" : "CPF",
						number: "",
					},
					payment_method: checkoutResponse.plan.payment_methods[0],
					// mobile_phone: checkoutResponse.customer.mobile_number,
					// disabled_input: checkBoleto ? false : true,
					total: checkoutResponse.plan.setup_fee
						? getTotal + checkoutResponse.plan.setup_fee
						: getTotal,
				});
			}
		}

		checkValidationSchema(
			{
				...checkoutResponse.checkout_config,
				document: has_document,
				billing_address: has_address,
			},
			country
		);
	};

	const fetchCompanyConfig = async () => {
		try {
			await api.get("/company-config").then((res) => {
				const company = res.data;

				setCompany(company);

				// setAppState({ locale: locale })
			});
		} catch (e) {
			// const company = {
			// 	address_country_code: "BRA",
			// 	city: "São Paulo",
			// 	country: "BRA",
			// 	document_type: "cnpj",
			// 	legal_name: "DEMO VERIPAG",
			// 	locale: "pt-br",
			// 	name: "DEMO",
			// 	postal_code: "05128000",
			// 	street1: "Rua Willis Roberto Banks",
			// 	style: {
			// 		background_color: "#001d7f",
			// 		checkout_style: null,
			// 		favicon: null,
			// 		primary_color: "#001d7f",
			// 		secundary_color: "#001d7f",
			// 		url_logo_default:
			// 			"https://veripagassets.blob.core.windows.net/img/logotipo-veripag@2x.png",
			// 		url_logo_white:
			// 			"https://veripagassets.blob.core.windows.net/img/logotipo-veripag3@2x.png",
			// 	},

			// 	tax_id: "87226115000187",
			// 	timezone: null,
			// 	use_form_token: false,
			// }
			// setCompany(company)
			console.log({ e });
		}
	};

	const fetchCheckoutInfo = async () => {
		try {
			setLoading(true);
			const qs = new URLSearchParams(window.location.search);
			const hash = qs.get("hash");
			await CheckoutService.Get({ hash })
				.then((data) => {
					const document = {
						type: data.customer.document.type,
						number: data.customer.document.number,
					}
					const checkout = data;
					checkout.customer.document = document
					if (checkout.plan.amount === 0) return setMessageError(t.errors["hash.disabled"]);
					if (checkout.customer && checkout.plan.payment_methods.includes('boleto')) checkout.is_payment_link = true
					if (checkout.customer) checkout.has_customer = true
					checkInitialFormStep(checkout, checkout.company.country);
					setCheckout({
						...checkout,
						customer: {
							...checkout.customer,
						},
					});
					setCompany(data.company)
					const date = new Date()
					let today = date.getDay()
					checkout.company.use_form_token = checkout.use_form_token

					return checkout.company.address.country;
				})
				.then((country) => {
					setLoading(false);
					handleLocaleChange(language[country], setAppState);
					const billingAddressInputs =
						country === "FRA"
							? ["billing_address.fr"]
							: ["billing_address"];
					const shippingAddressInputs =
						country === "FRA"
							? ["shipping_address.fr"]
							: ["shipping_address"];
					setAddressInputs({
						billingAddressInputs,
						shippingAddressInputs,
					});
				});
		} catch (e) {
			setLoading(false);

			if (!e.response) {
				return;
			}

			switch (e.response.status) {
				case 403:
					setMessageError(t.errors["hash.used"]);
					break;
				case 409:
					setMessageError(t.errors["hash.disabled"]);
					break;

				default:
					setMessageError(t.errors["hash.server_error"]);
					break;
			}
		}
	};

	const fetchSddForm = async (subscription_id) => {
		const qs = new URLSearchParams(window.location.search);
		const hash = qs.get("hash");

		try {
			billService
				.get(
					`/checkout/sdd/form?hash=${hash}&subscriptionId=${subscription_id}&isSHA256=true`
				)
				.then((res) => {
					setSddInputs(res.data);
				});
		} catch (e) {
			setSddInputs([]);
			console.log({ e });
		}
	};

	const PlanInfo = ({ planMobile, formikProps }) => {
		const { plan } = checkout;
		const coupon = formikProps.values.coupon;

		const handleChangeCupom = (values) => {
			formikProps.setFieldValue("coupon", {
				...formikProps.values.coupon,
				...values,
			});
		};

		const validateCoupon = async () => {
			if (coupon.coupon_code) {
				try {
					const qs = new URLSearchParams(window.location.search);
					const hash = qs.get("hash");
					await billService
						.get(`/checkout/coupons?code=${coupon.coupon_code}`, {
							headers: {
								hash,
							},
						})
						.then((res) => {
							const { data: coupon } = res;

							if (res.status === 200) {
								handleChangeCupom({ ...coupon });
								formikProps.setFieldValue(
									"total",
									coupon.coupon_id
										? coupon.total_amount
										: plan.setup_fee
											? plan.amount + plan.setup_fee
											: plan.amount
								);
							}
						});
				} catch (e) {
					handleChangeCupom({
						coupon_error: `^errors.${e.response.data.parameterName}`,
					});
					console.log("ERRO GET COUPOM >>> ", { e });
				}
			} else {
				handleChangeCupom({
					coupon_error: "^errors.coupons.field.coupon_code",
				});
			}
		};

		const Row = ({ label, value }) => (
			<div
				className="flex-row"
				style={{ width: "100%", padding: "8px 0" }}
			>
				<div
					className="flex-col"
					style={{ width: "40%", minWidth: 150 }}
				>
					{label}
				</div>
				<div
					className="flex-col"
					style={{ width: "60%", textAlign: "right" }}
				>
					{value}
				</div>
			</div>
		);

		const getFrequencyLabel = (frequency) => {
			switch (frequency) {
				case "DAILY":
					return "diário.";
				case "MONTHLY":
					return "mensal.";
				case "YEARLY":
					return "anual.";
				default:
					return "";
			}
		};

		const getTotal =
			plan.trial.interval !== 0
				? plan.trial.amount + plan.setup_fee
				: plan.charge_only_setup
					? plan.setup_fee
					: plan.amount + plan.setup_fee;

		if (plan)
			return (
				<SectionMain
					id={planMobile ? "plan" : "plan-card"}
					noPadding
					style={{
						padding: 20,
						width: "100%",
						// maxHeight: planHeight + 20,
						maxWidth: planWidth - 50,
						boxShadow: "12px 13px 17px -8px rgba(0,0,0,0.10)",
						border: "2px dashed #DCDCDC",
						backgroundColor: "#fff",
						height: 'fit-content',
						marginBottom: 10
					}}
				>
					{confirm}
					<div
						ref={(ref) => ref && setPlanHeight(ref.clientHeight)}
						style={{ padding: 0, width: "100%" }}
					>
						<SectionMain noPadding>
							{t.titles["summary.details"].toUpperCase()}
						</SectionMain>
						<SectionMain>
							{plan.setup_fee > 0 && (
								<React.Fragment>
									<Row
										label={
											<TitleSmall>
												{t.titles[
													"summary.initial_amount"
												].toUpperCase()}
											</TitleSmall>
										}
										value={
											<TitleSmall bold>
												{toCurrency(
													plan.setup_fee,
													plan.currency
												)}
											</TitleSmall>
										}
									/>
									<div
										className="flex-row center-a center-b"
										style={{
											height: 1,
											border: "1px dashed #DCDCDC",
											width: "100%",
										}}
									/>
								</React.Fragment>
							)}

							<Row
								label={<TitleSmall>{plan.name}</TitleSmall>}
								value={
									<React.Fragment>
										<TitleSmall bold align="right">
											{plan.trial.interval > 0
												? `${plan.trial.amount > 0
													? `${toCurrency(
														plan.trial
															.amount,
														plan.currency
													)} ${t(
														"^titles.trial",
														`${plan
															.trial
															.interval
														} ${plan
															.trial
															.interval !==
															1
															? t.frequency_quantity[
															plan.trial.interval_unit
															]
															: t
																.frequency_interval_unit[
															plan
																.trial
																.interval_unit
															]
														}`
													)}`
													: t(
														"^titles.gratuitous",
														`${plan
															.trial
															.interval
														} ${plan
															.trial
															.interval !==
															1
															? t
																.frequency_quantity[
															plan
																.trial
																.interval_unit
															]
															: t
																.frequency_interval_unit[
															plan
																.trial
																.interval_unit
															]
														}`
													)
												}.`
												: plan.charge_only_setup ?
													`${toCurrency(
														0,
														plan.currency
													)}`
													: plan.amount === 0
														? ""
														: `${toCurrency(
															plan.amount,
															plan.currency
														)}
												${plan.recurrence
															.count !== 1
															? t.frequency[
															plan
																.recurrence
																.frequency
															]
															: ""
														}
													`}
										</TitleSmall>
									</React.Fragment>
								}
							/>
							{plan.trial.interval > 0 && (
								<SectionMain position="flex-end" noPadding>
									<TitleSmall align="right">
										{t(
											"^messages.trial_interval.discount",
											toCurrency(
												plan.amount,
												plan.currency
											),
											t.frequency_interval_unit[
											plan.recurrence.frequency
											]
										)}
									</TitleSmall>
								</SectionMain>
							)}
							{plan.charge_only_setup && (
								<SectionMain position="flex-end" noPadding>
									<TitleSmall align="right">
										{renderBillingDateMessage(plan.recurrence.billing_day, plan.amount, plan.currency, plan.recurrence.frequency)}
									</TitleSmall>
								</SectionMain>
							)}

							<SectionMain style={{ paddingTop: 10 }}>
								<TitleSmall style={{ wordBreak: 'break-all' }} align="left">{`${plan.description}`}</TitleSmall>
							</SectionMain>
						</SectionMain>

						<div
							className="flex-row center-a center-b"
							style={{
								height: 1,
								border: "1px dashed #DCDCDC",
								width: "100%",
							}}
						/>
						{!payzenForm && checkout.coupon_enabled && (
							<SectionMain>
								{coupon.coupon_id ? (
									<SectionMain
										noPadding
										style={{
											padding: "0px 5px",
											boxShadow:
												"12px 13px 17px -8px rgba(0,0,0,0.10)",
											border: "2px dashed #90d10e",
											backgroundColor: "#fff",
										}}
									>
										<div
											className="flex-row center-b"
											style={{
												width: "100%",
												alignContent: "space-between",
											}}
										>
											<Tooltip
												title={t.generics.delete}
												placement="top-start"
											>
												<IconButton
													style={{
														borderRadius: 5,
														padding: 2,
													}}
													onClick={() => {
														formikProps.setFieldValue(
															"coupon",
															{
																...coupon,
																coupon_id: "",
															}
														);
														formikProps.setFieldValue(
															"total",
															plan.setup_fee
																? plan.amount +
																plan.setup_fee
																: plan.amount
														);
													}}
												>
													<DeleteIcon
														htmlColor={
															company.style
																.primary_color
																? company.style
																	.primary_color
																: backgroundColor
														}
													/>
												</IconButton>
											</Tooltip>
											<Row
												label={
													<span
														style={{ width: 185 }}
													>
														<TitleSmall>
															{coupon.coupon_code}
														</TitleSmall>
													</span>
												}
												value={
													<TitleSmall
														bold
														color="#001d7f"
													>
														-
														{toCurrency(
															coupon.discount_amount,
															coupon.currency
														)}
													</TitleSmall>
												}
											/>
										</div>
									</SectionMain>
								) : (
									<SectionMain noPadding>
										<TextInput
											placeholder={t.titles[
												"coupon.form"
											].toUpperCase()}
											fullWidth
											errorMessage={coupon.coupon_error}
											onFocus={() =>
												handleChangeCupom({
													coupon_error: "",
												})
											}
											value={coupon.coupon_code}
											onChange={(e) => {
												const value = (
													e.target.value || ""
												).replace(/[^\w\s]| /g, "");
												handleChangeCupom({
													coupon_code: value.toUpperCase(),
												});
											}}
											rigthElement={
												coupon.coupon_error ? (
													<HtmlTooltip
														open={
															coupon.coupon_error
														}
														title={
															<TitleSmall>
																{t(
																	coupon.coupon_error
																)}
															</TitleSmall>
														}
														placement="bottom"
													>
														<InfoIcon
															style={{
																color: "red",
															}}
														/>
													</HtmlTooltip>
												) : (
													<Button
														onClick={validateCoupon}
														style={{
															backgroundColor: company.style.primary_color ?
																company.style.primary_color
																: backgroundColor,
															color: isBackgroundDark(company.style.primary_color || backgroundColor) ? 'white' : 'black'
														}}
														variant="contained"
														color="primary"
														size="small"
													>
														{
															t.buttons[
															"checkout.validate"
															]
														}
													</Button>
												)
											}
										/>
									</SectionMain>
								)}
								<SectionMain
									noPadding
									style={{ paddingTop: 10 }}
								>
									<TitleSmall>
										{t.messages["coupon.discount"]}
									</TitleSmall>
								</SectionMain>
							</SectionMain>
						)}
						<span
							style={{
								display: coupon.coupon_id ? "block" : "none",
							}}
						>
							<div
								className="flex-row center-a center-b"
								style={{
									height: 1,
									border: "1px dashed #DCDCDC",
									width: "100%",
								}}
							/>
							{coupon.total_amount > 0 && (
								<Row
									label={
										<TitleSmall>
											{t.titles[
												"subtotal.details"
											].toUpperCase()}
										</TitleSmall>
									}
									value={
										<TitleSmall bold>
											{plan.setup_fee > 0
												? toCurrency(
													plan.amount +
													plan.setup_fee,
													plan.currency
												)
												: toCurrency(
													plan.amount,
													plan.currency
												)}
										</TitleSmall>
									}
								/>
							)}
							{coupon.discount_amount && (
								<Row
									label={
										<TitleSmall>
											{t.titles[
												"discount_value.details"
											].toUpperCase()}
										</TitleSmall>
									}
									value={
										<TitleSmall bold color="#001d7f">
											-{" "}
											{toCurrency(
												coupon.discount_amount,
												coupon.currency
											)}
										</TitleSmall>
									}
								/>
							)}
							{coupon.setup_amount > 0 && (
								<Row
									label={
										<TitleSmall>
											{t(
												"^titles.summary.initial_amount"
											).toUpperCase()}
										</TitleSmall>
									}
									value={
										<TitleSmall bold>
											{toCurrency(
												coupon.setup_amount,
												coupon.currency
											)}
										</TitleSmall>
									}
								/>
							)}
						</span>
						<div
							className="flex-row center-a center-b"
							style={{
								height: 1,
								border: "1px dashed #DCDCDC",
								width: "100%",
							}}
						/>

						<SectionMain
							position="space-between"
							alignItems="center"
						>
							<TitleSmall fontSize={16}>
								{t.titles["total.details"].toUpperCase()}
							</TitleSmall>
							<TitleSmall bold fontSize={20}>
								{coupon.coupon_id
									? toCurrency(
										coupon.total_amount,
										coupon.currency
									)
									: toCurrency(getTotal, plan.currency)}
							</TitleSmall>
						</SectionMain>
						{checkout.plan.enabled_installment &&
							<SectionMain noPadding position="flex-end">
								{step === 'customer'
									? <FormLabel
										component="legend"
										style={{
											padding: "0px 0px 15px",
											display: formikProps.values.installment
												? "flex"
												: "none",
										}}
									>
										Em até {checkout.plan.installments[checkout.plan.installments.length - 1]}x{" "}
									</FormLabel>
									: <FormLabel
										component="legend"
										style={{
											padding: "0px 0px 15px",
											display: formikProps.values.installment
												? "flex"
												: "none",
										}}
									>
										em {formikProps.values.installment}x{" "}
										{toCurrency(
											formikProps.values.total /
											formikProps.values.installment,
											"BRL"
										)}
									</FormLabel>}

							</SectionMain>
						}
					</div>
				</SectionMain>
			);
		return "";
	};

	const showTCmodal = async () => {
		const lines = checkout.checkout_config.terms_conditions.data.split("\n")

		const result = await swal({
			buttons: {
				cancel: 'Cancelar',
				accept: {
					text: "Aceitar",
					value: true,
				},
			},
			content: (
				<div>
					<div style={{ width: '100%', height: '50vh', backgroundColor: "#f2f2f2", overflowY: 'auto', textAlign: 'left', padding: 5 }}>
						{lines.map(line => (
							<span>
								{line}
								<br />
							</span>
						))}
					</div>
				</div>
			),

		})
		if (result) setTCAgreed(true)
	}


	const onSubmitForm = async (form, setFieldValue) => {
		const { plan } = checkout;

		if (step === "customer") {
			setIsSubmitting(true)
			const formData = {
				customer: {
					first_name: form.first_name,
					last_name: form.last_name,
					email: form.email,
					mobile_number: form.mobile_phone,
					document: form.document ? { ...form.document } : "",
					gender: "",
					country_code: form.country_code,
					billing_address: checkout_config.billing_address
						? form.billing_address
						: "",
					shipping_address: checkout_config.shipping_address
						? enabledShippingAddress === true
							? [form.billing_address]
							: [form.shipping_address]
						: "",
				},
				coupon_id: form.coupon.coupon_id,
			};

			if (!has_document && !checkout.is_payment_link) {
				delete formData.customer.document;
			}
			if (!checkout.checkout_config.country_code) {
				delete formData.customer.country_code;
			}
			if (!checkout.checkout_config.shipping_address && !checkout.is_payment_link) {
				delete formData.customer.shipping_address;
			}
			if (!has_address && !checkout.is_payment_link) {
				delete formData.customer.billing_address;
			}
			if (!checkout.coupon_enabled) {
				delete formData.coupon_id;
			}
			if (checkout.is_payment_link) {
				delete formData.customer.customer_id
			}

			try {
				setLoading(true);
				const qs = new URLSearchParams(window.location.search);
				const hash = qs.get("hash");

				await billService
					.post(
						`/checkout?hash=${hash}&step=${checkout.customer.customer_id
							? "step"
							: checkout.checkout_type
						}`,
						formData
					)
					.then((res) => {
						const {
							data: {
								subscription_id,
								customer_id,
								form_token,
								public_key,
							},
						} = res;
						// get form data from payzen
						fetchSddForm(subscription_id);
						setFieldValue("disabled_input", true);
						setFieldValue("payment_mean", {
							...form.payment_mean,
							subscription_id: subscription_id,
							customer_id: customer_id,
						});

						if (checkout.company.use_form_token) {
							setAppState({
								payzenForm: { form_token, public_key },
							});
						}
						setStep("payment");
						setLoading(false);
						setMenuCollapse({
							first: false,
							second: false,
							third: false,
							four: true,
						});
					});
			} catch (e) {
				if (
					e.response.status === 409 &&
					e.response.data.parameterName ===
					"customers.label.email_already_registered"
				) {
					enqueueSnackbar(
						t(
							"^errors.customer.email_already_registered",
							formData.customer.email
						),
						{
							variant: "error",
						}
					);
				} else {
					enqueueSnackbar(t.errors["login.server_error"], {
						variant: "error",
					});
				}
				setIsSubmitting(false)
				setLoading(false);
				console.log("err", { e });
			}
		}

		if (step === "payment") {
			//Puxando subscription direto do checkout, caso is_subscription_recovery seja true
			const subscription_id = checkout.is_subscription_recovery
				? checkout.subscription_id
				: form.payment_mean.subscription_id;
			const customer_id = checkout.customer.customer_id
				? checkout.customer.customer_id
				: form.payment_mean.customer_id;

			const formDataPayment =
				form.payment_method === "boleto"
					? {
						customer: {
							customer_id,
						},
						subscription_id,
						payment_mean: { type: form.payment_method },
					}
					: {
						customer: {
							customer_id,
						},
						subscription_id,
						installment: form.installment,
						payment_mean: {
							// ...formikProps.values.payment_mean,
							type: form.payment_method,
							number: form.payment_mean.card_number,
							expiry_date: execImmediate(() => {
								try {
									const [
										,
										month,
										year,
									] = form.payment_mean.expiry_date.match(
										/(\d\d)\/(\d\d)/
									);
									const currYearFirstTwoDigs = new Date()
										.getFullYear()
										.toString()
										.match(/^\d\d/)[0];
									return `${month}/${currYearFirstTwoDigs}${year}`;
								} catch (e) {
									return form.payment_mean.expiry_date;
								}
							}),
							cvv: form.payment_mean.cvv,
							holder: form.payment_mean.customer_name,
							brand: form.payment_mean.brand,
						},
					};
			if (paymentMethod.match("sdd")) {
				return;
			}
			const response = true

			if (response) {
				// TESTANDO POST SDD VIA AXIOS
				// const formData = new FormData()
				// setLoading(true)
				// sddInputs.map(({ name, value }) =>
				// 	formData.append(name, value)
				// )

				// try {
				// 	sddApiService
				// 		.post("/vads-payment", formData)
				// 		.then((res) => {
				// 			console.log(res.data)
				// 		})
				// } catch (e) {
				// 	console.log({ e })
				// }
				try {
					setLoading(true);

					const qs = new URLSearchParams(window.location.search);
					const hash = qs.get("hash");

					if (checkout.coupon_enabled) {
						const checkoutResponse = await billService.post(
							`/checkout?hash=${hash}`,
							{
								...formDataPayment,
								coupon_id: form.coupon.coupon_id,
							}
						);

						form.payment_method === "boleto"
							? setSuccess({
								done: true,
								checkoutResponse,
								userInfo: {
									email: "",
								},
							})
							: setSuccess({
								done: true,
								checkoutResponse,
								userInfo: {
									name: `${form.first_name} ${form.last_name}`,
									email: form.email,
									totalPaid: {
										amount: form.coupon.coupon_id
											? form.coupon.total_amount
											: defaultValues.total,
										currency: plan.currency,
										installment: form.installment,
									},
								},
							});
					} else {
						const checkoutResponse = await billService.post(
							`/checkout?hash=${hash}`,
							formDataPayment
						);

						form.payment_method === "boleto"
							? setSuccess({
								done: true,
								sending: false,
								checkoutResponse,
								userInfo: {
									email: "",
								},
							})
							: setSuccess({
								done: true,
								sending: false,
								checkoutResponse,
								userInfo: {
									name: `${form.first_name} ${form.last_name}`,
									email: form.email,
									totalPaid: {
										amount: form.coupon.coupon_id
											? form.coupon.total_amount
											: defaultValues.total,
										currency: plan.currency,
										installment: form.installment,
									},
								},
							});
					}
					setLoading(false);
				} catch (e) {
					setLoading(false);

					console.log("[ERROR SENDING CHECKOUT]", e);
					// setState({
					// 	showModal: false,
					// 	// done: true,
					// 	sending: false,
					// 	checkoutResponse: e.response || { status: 0, data: {} }
					// })

					setSuccess({
						done: false,
						sending: false,
						checkoutResponse: e.response || {
							status: 0,
							data: {},
						},
					});
				}
			}
		}
	};

	const defaultHandleErrors = () =>
		setTimeout(() => {
			const errorElement = document.getElementsByClassName(
				"error-msg"
			)[0];
			if (!errorElement) {
				return;
			}
			errorElement.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		});

	// const validationSchema = () =>
	// 	object().shape({
	// 		first_name: string().required('Preencha o campo nome.'),
	// 		last_name: string().required('Preencha o campo sobrenome.'),
	// 		email: string().email('Digite um e-mail válido').required('Preencha o campo de e-mail'),
	// 		mobile_phone: string().required('Preencha o campo telefone celular.'),

	// 		postal_code: string().required('Preencha o campo cep.'),
	// 		street1: string().required('Preencha o campo rua.'),
	// 		number: string().required('Preencha o campo número.'),

	// 		state: string().required('Preencha o campo estado.'),
	// 		district: string().required('Preencha o campo bairro.'),
	// 		city: string().required('Preencha o campo cidade.'),
	// 		country: string().required('Preencha o campo país.')

	// 		// country_code: string().required('Preencha o campo país.')
	// 	})

	const PageNotFoundCheckout = ({ company }) => {
		if (!company) {
			return "";
		}

		return (
			<SectionMain
				style={{ overflow: "hidden", height: "60vh" }}
				noPadding
				position="center"
			>
				<style>
					{`#banner {
						opacity: 0.5;
						background: ${company.style.primary_color}; /* fallback for old browsers */
						background: -webkit-linear-gradient(to right, ${company.style.primary_color}, ${company.style.primary_color}66); /* Chrome 10-25, Safari 5.1-6 */
						background: linear-gradient(to right, ${company.style.primary_color}, ${company.style.primary_color}66);
					}

					`}
				</style>

				<SectionMain
					position="center"
					noPadding
					style={{ paddingTop: 100 }}
				>
					<div
						style={{
							backgroundImage: `url(${company.style.url_logo_default})`,
							backgroundSize: "contain",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
							width: "100%",
							height: "10vw",
							maxHeight: 100,
						}}
					/>
					<SectionMain position="center" xs={10}>
						<div className="flex-col center-a center-b">
							<h2
								style={{
									color: company.style.primary_color,
									textAlign: "center",
								}}
							>
								{messageError
									? messageError
									: t.errors["generic"]}
							</h2>
						</div>
					</SectionMain>
				</SectionMain>
			</SectionMain>
		);
	};

	const CheckoutResponse = ({ response, userInfo }) => {
		const [open, setOpen] = useState(false);

		useEffect(() => {
			if (response && response.status !== 200) {
				setOpen(true);
			}
		}, []);

		const handleError = () => {
			setSuccess("");
			setOpen(false);
		};

		if (response.status === 200) {
			return userInfo.email ? (
				<SuccessPage
					checkoutInfo={checkout}
					userInfo={userInfo}
					logo={company && company.style.url_logo_default}
					primaryColor={company && company.style.primary_color}
				/>
			) : (
				<SuccessBoleto
					logo={company && company.style.url_logo_default}
					primaryColor={company && company.style.primary_color}
					boleto={response.data}
				/>
			);
		}

		return (
			response.status !== 200 && (
				<ErrorCheckoutMessage
					handleError={handleError}
					errorStatus={response.status}
				/>
			)
		);
	};

	useEffect(() => {
		fetchCheckoutInfo();
		// fetchCompanyConfig();
	}, []);

	useEffect(() => {
		if (step === "payment" && paymentMethod === "credit_card") {
			setValidationSchema(
				object().shape({
					payment_mean: object().shape({
						card_number: string().required("Preencha o campo cep."),
						cvv: string().required("Preencha o campo rua."),
						expiry_date: string().required(
							"Preencha o campo número."
						),
						customer_name: string().required(
							"Preencha o campo estado."
						),
					}),
				})
			);
		}
		if (step === "payment" && paymentMethod === "credit_card" && payzenForm) {
			setValidationSchema(
				object().shape({
					payment_mean: object().shape({
						// card_number: string().required("Preencha o campo cep."),
						// cvv: string().required("Preencha o campo rua."),
						// expiry_date: string().required("Preencha o campo número."),
						// customer_name: string().required("Preencha o campo estado."),
					}),
				})
			);
		}

		if (step === "payment" && paymentMethod === "boleto") {
			setValidationSchema(
				object().shape({
					payment_mean: object().shape({}),
				})
			);
		}

	}, [step, paymentMethod]);

	if (success && success.done) {
		return (
			<CheckoutResponse
				response={success.checkoutResponse}
				userInfo={success.userInfo}
			// userInfo={success.userInfo}
			/>
		);
	}

	if (checkout)
		return (
			<ErrorBoundary
				errorPage={<PageNotFoundCheckout company={company} />}
			>
				<Formik
					initialValues={defaultValues}
					validationSchema={() => validationSchema}
					onSubmit={(
						values,
						{ setFieldValue, setFieldError, setSubmitting }
					) => {
						if (checkout.checkout_config.document && values.document.type === "CPF") {
							if (!validaCPF(values.document.number)) {
								setFieldError("document.number", "erro");
								enqueueSnackbar("Digite um CPF válido", {
									variant: "error",
								});
								setSubmitting(false);
								return;
							}
						}
						if (checkout.checkout_config.document && values.document.type === "CNPJ") {
							if (!validaCNPJ(values.document.number)) {
								setFieldError("document.number", "erro");
								enqueueSnackbar("Digite um CNPJ válido", {
									variant: "error",
								});
								return;
							}
						}

						const [MM, YY] = values.payment_mean.expiry_date.split("/")
						const cardDate = new Date(parseInt(`20${YY}`), (parseInt(MM) - 1))
						const now = new Date()

						if (cardDate < now) {
							setFieldError("payment_mean.expiry_date", "Cartão Expirado")
							return
						}

						onSubmitForm(values, setFieldValue);

						setSubmitting(false)
					}}
				>
					{(formikProps) => (
						<WrapperLayout
							company={{ ...checkout.company, style: checkout.company.style }}
							primary_color={company.style.primary_color}
							logo_white_url={company.style.url_logo_white}
							customFooterInfo={checkout.checkout_config.footer_informations}
						>
							<Helmet title={`${company.name} - Página de pagamento`}>
								<link
									type="image/png"
									rel="shortcut icon"
									href={`${company.style.favicon}`}
								/>
							</Helmet>

							{success && success.done === false && (
								<CheckoutResponse
									response={success.checkoutResponse}
								/>
							)}
							<SectionMain position="center">
								<Title style={{ color: isBackgroundDark(checkout.company.background_color) ? 'white' : 'black' }} align="center">
									{t(
										"^titles.subscription",
										checkout.plan.name
									)}
								</Title>
							</SectionMain>
							<Grid xs={12} container justify="space-between">
								<SectionMain
									xs={12}
									sm={12}
									md={7}
									lg={7}
									xl={7}
									style={{ marginTop: 10 }}
								>
									<form
										autocomplete="new-password"
										action="javascript:void(0)"
										style={{ width: '100%' }}
										onSubmit={() => {
											defaultHandleErrors();
											if (enabledShippingAddress) {
												setTimeout(() => {
													formikProps.setFieldValue(
														"shipping_address",
														{
															...formikProps
																.values
																.billing_address,
														}
													);

													formikProps.handleSubmit();
												});
											} else {
												formikProps.handleSubmit();
											}
											console.log(formikProps.errors);
										}}
									>
										<Hidden only={["lg", "xl", "md"]}>
											<SectionMain
												xs={12}
												position="center"
											>
												<PlanInfo
													formikProps={formikProps}
													planMobile
												/>
											</SectionMain>
										</Hidden>

										<StepTitle
											iconColor={
												company.style.primary_color
											}
											handleChange={() =>
												handleMenuCollapsable("first")
											}
											defaultOpen={menuCollapse.first}
											stepNumber={
												<PersonIcon
													style={{ fontSize: 18 }}
												/>
											}
											stepTitle={
												t.titles["customer.form"]
											}
										>
											<div style={(checkout.is_payment_link || checkout.has_customer) ? { opacity: '0.5', pointerEvents: 'none' } : { opacity: '1' }}>
												<SectionMain noPadding>
													<RenderInputsV3
														checkoutConfig={{
															...checkout.checkout_config,
															first_name: true,
															last_name: true,
															email: true,
															document: false,
															mobile_number: false,
														}}
														formikProps={formikProps}
														arrayInputs={customerInputs}
													/>

												</SectionMain>
											</div>
											<div style={checkout.customer.document && checkout.customer.document.number ? { opacity: '0.5', pointerEvents: 'none', } : {}}>
												<SectionMain noPadding>
													<RenderInputsV3
														checkoutConfig={{
															document: checkout.checkout_config.document || Boolean(has_document),
														}}
														formikProps={formikProps}
														arrayInputs={customerInputs}
													/>

												</SectionMain>

											</div>
											<div style={checkout.is_payment_link || checkout.has_customer ? { opacity: '0.5', pointerEvents: 'none' } : { opacity: '1' }}>
												<SectionMain noPadding>
													<RenderInputsV3
														checkoutConfig={{
															mobile_number: checkout.checkout_config.mobile_phone,
														}}
														formikProps={formikProps}
														arrayInputs={customerInputs}
													/>

												</SectionMain>
											</div>
										</StepTitle>

										{(has_address || checkout.is_payment_link) && (
											<StepTitle
												iconColor={
													company.style.primary_color
												}
												handleChange={() =>
													handleMenuCollapsable(
														"second"
													)
												}
												defaultOpen={
													menuCollapse.second
												}
												stepNumber={
													<RoomIcon
														style={{ fontSize: 18 }}
													/>
												}
												stepTitle={
													t.titles[
													"billing_address.form"
													]
												}
											>
												<SectionMain noPadding>
													<RenderInputsV3
														checkoutConfig={{
															...checkout.checkout_config,
															billing_address: true,
														}}
														formikProps={
															formikProps
														}
														arrayInputs={
															addressInputs.billingAddressInputs
														}
													/>
												</SectionMain>
											</StepTitle>
										)}
										{((checkout.checkout_config.billing_address &&
											checkout.checkout_config.shipping_address) ||
											checkout.is_payment_link) && (
												<StepTitle
													iconColor={
														company.style
															.primary_color
													}
													handleChange={() =>
														handleMenuCollapsable(
															"third"
														)
													}
													defaultOpen={
														menuCollapse.third
													}
													stepNumber={
														<LocalShippingIcon
															style={{
																fontSize: 18,
															}}
														/>
													}
													stepTitle={
														t.titles[
														"shipping_address.form"
														]
													}
												>
													<SectionMain noPadding>
														<SectionMain>
															<FormControlLabel
																onChange={(e) =>
																	setEnabledShippingAddress(
																		e.target
																			.checked
																	)
																}
																control={
																	<Checkbox
																		color="primary"
																		checked={
																			enabledShippingAddress
																		}
																		name="enabledShippingAddress"
																	/>
																}
																label={
																	t.messages[
																	"enabled_shipping_address.subscription"
																	]
																}
															/>
														</SectionMain>
														{!enabledShippingAddress && (
															<AnimatedView>
																<RenderInputsV3
																	checkoutConfig={
																		{
																			...checkout.checkout_config,
																			shipping_address: true
																		}
																	}
																	formikProps={
																		formikProps
																	}
																	arrayInputs={
																		addressInputs.shippingAddressInputs
																	}
																/>
															</AnimatedView>
														)}
													</SectionMain>
												</StepTitle>
											)}
										<StepTitle
											iconColor={
												company.style.primary_color
											}
											hidden={
												step !== "payment"
													? true
													: false
											}
											// hidden={false}
											handleChange={() =>
												handleMenuCollapsable("four")
											}
											defaultOpen={menuCollapse.four}
											stepNumber={
												<CreditCardIcon
													style={{ fontSize: 18 }}
												/>
											}
											stepTitle={
												t.titles["payment_mean.form"]
											}
										>
											{/* Payzen form section */}
											<SectionMain position="center">
												{checkout.company
													.use_form_token ? (
													step === "payment" && (
														<SectionMain
															noPadding
															direction="column"
															alignItems="center"
														>
															<RadioGroup
																style={{
																	flexDirection:
																		"row",
																}}
																defaultValue="credit_card"
																aria-label="payment_methods"
																value={
																	formikProps
																		.values
																		.payment_method
																}

																onChange={(e) => {
																	formikProps.setFieldValue("payment_method", e.target.value); setPaymentMethod(e.target
																		.value
																	);


																	// if (
																	// 	e.target
																	// 		.value ===
																	// 	"sdd"
																	// ) {
																	// 	fetchSddForm(
																	// 		formikProps
																	// 			.values
																	// 			.payment_mean
																	// 			.subscription_id
																	// 	)
																	// }
																}}
															>
																<Hidden
																	only={[
																		"xs",
																		"sm",
																	]}
																>
																	{checkout.plan.payment_methods.map(
																		(
																			payment_method,
																			i
																		) => {
																			if (payment_method === "pix") return
																			return (<PaymentMeanInput
																				colorIcon={
																					company
																						.style
																						.primary_color
																						? company
																							.style
																							.primary_color
																						: backgroundColor
																				}
																				noLeftMargin={
																					i ===
																					0 &&
																					true
																				}
																				icon={
																					iconsPaymentMethods[
																					payment_method
																					]
																				}
																				value={
																					payment_method
																				}
																				label={
																					t
																						.titles[
																					`payment_method.${payment_method}`
																					]
																				}
																			/>)
																		}
																	)}
																</Hidden>
																<Hidden
																	only={[
																		"lg",
																		"xl",
																		"md",
																	]}
																>
																	{checkout.plan.payment_methods.map(
																		(
																			payment_method,
																			i
																		) => {
																			if (payment_method === "pix") return
																			return (
																				<PaymentMeanInput
																					colorIcon={
																						company
																							.style
																							.primary_color
																							? company
																								.style
																								.primary_color
																							: backgroundColor
																					}
																					noLeftMargin
																					icon={
																						iconsPaymentMethods[
																						payment_method
																						]
																					}
																					value={
																						payment_method
																					}
																					label={
																						t
																							.titles[
																						`payment_method.${payment_method}`
																						]
																					}
																					style={{
																						maxWidth: 170,
																					}}
																				/>
																			)
																		}
																	)}
																</Hidden>
															</RadioGroup>
															{paymentMethod ===
																"credit_card" && (
																	<SectionMain position="center">
																		{/* FORMTOKEN DO PAYZEN */}
																		<div id="form-payzen">
																			<div className="kr-smart-form" kr-card-form-expanded />
																		</div>
																		<RenderPayzenForm
																			gatewayConfig={
																				checkout.gateway_config
																			}
																			setSuccess={
																				setSuccess
																			}
																			subscriptionId={
																				formikProps
																					.values
																					.payment_mean
																					.subscription_id
																			}
																			couponId={
																				formikProps
																					.values
																					.coupon
																					.coupon_id
																			}
																		/>
																	</SectionMain>
																)}

															<SectionMain position="center">
																<form
																	id="sdd-form"
																	method="POST"
																	action="https://secure.payzen.eu/vads-payment/"
																	target="sdd"
																	autoComplete="new-password"
																	onSubmit={() => {
																		setMenuCollapse(
																			{
																				first: false,
																				second: false,
																				third: false,
																				four: false,
																				five: true,
																			}
																		);
																	}}
																>
																	{sddInputs.map(
																		({
																			name,
																			value,
																		}) => (
																			<input
																				key={
																					name
																				}
																				hidden
																				name={
																					name
																				}
																				value={
																					value
																				}
																			/>
																		)
																	)}
																	<Button
																		target="sdd-form"
																		// startIcon={<CircularProgress size={24} />}
																		type="submit"
																		variant="contained"
																		color="primary"
																		disabled={
																			formikProps.isSubmitting
																		}
																		style={{
																			marginTop: 60,
																			alignItems:
																				"flex-end",
																			backgroundColor: company.style.primary_color ?
																				company.style.primary_color
																				: backgroundColor,
																			display: paymentMethod === "sdd"
																				? "block"
																				: "none",
																			color: isBackgroundDark(company.style.primary_color || backgroundColor) ? 'white' : 'black'

																		}}
																	>
																		{loading && (
																			<CircularProgress
																				size={
																					24
																				}
																				color="inherit"
																				style={{
																					marginRight: 8,
																				}}
																			/>
																		)}
																		{
																			t
																				.buttons[
																			"checkout.continue"
																			]
																		}
																	</Button>
																</form>
															</SectionMain>
														</SectionMain>
													)
												) : (
													// Checkout form {BR}
													<>
														<SectionMain
															noPadding
															position="center"
														>
															{checkout.plan
																.payment_methods
																.length >= 2 && (
																	<SectionMain
																		direction="column"
																		position="center"
																		item
																		xs={12}
																	>
																		<RadioGroup
																			style={{
																				flexDirection:
																					"row",
																				justifyContent:
																					"center",
																			}}
																			defaultValue="boleto"
																			aria-label="payment_methods"
																			value={
																				formikProps
																					.values
																					.payment_method
																			}
																			onChange={(
																				e
																			) => {
																				formikProps.setFieldValue(
																					"payment_method",
																					e
																						.target
																						.value
																				);
																				setPaymentMethod(
																					e
																						.target
																						.value
																				);
																			}}
																		>
																			<Hidden
																				only={[
																					"xs",
																					"sm",
																				]}
																			>
																				{checkout.plan.payment_methods.map(
																					(
																						payment_method,
																						i
																					) => {
																						if (payment_method === "pix") return
																						return (
																							<PaymentMeanInput
																								colorIcon={
																									company
																										.style
																										.primary_color
																										? company
																											.style
																											.primary_color
																										: backgroundColor
																								}
																								noLeftMargin={
																									i ===
																									0 &&
																									true
																								}
																								icon={
																									iconsPaymentMethods[
																									payment_method
																									]
																								}
																								value={
																									payment_method
																								}
																								label={
																									t
																										.titles[
																									`payment_method.${payment_method}`
																									]
																								}
																							/>
																						)
																					}
																				)}
																			</Hidden>
																			<Hidden
																				only={[
																					"lg",
																					"xl",
																					"md",
																				]}
																			>
																				{checkout.plan.payment_methods.map(
																					(
																						payment_method,
																						i
																					) => {
																						if (payment_method === "pix") return
																						return (
																							<PaymentMeanInput
																								colorIcon={
																									company
																										.style
																										.primary_color
																										? company
																											.style
																											.primary_color
																										: backgroundColor
																								}
																								noLeftMargin
																								icon={
																									iconsPaymentMethods[
																									payment_method
																									]
																								}
																								value={
																									payment_method
																								}
																								label={
																									t
																										.titles[
																									`payment_method.${payment_method}`
																									]
																								}
																								style={{
																									maxWidth: 170,
																								}}
																							/>
																						)
																					}
																				)}
																			</Hidden>
																		</RadioGroup>
																	</SectionMain>
																)}
															<SectionMain
																noPadding
																position="center"
																item
																xs={12}
															>
																{paymentMethod ===
																	"credit_card" && (
																		<React.Fragment>
																			<RenderInputsV3
																				checkoutConfig={
																					checkout.checkout_config
																				}
																				formikProps={
																					formikProps
																				}
																				arrayInputs={
																					paymentInputs
																				}
																			/>
																			{checkout
																				.plan
																				.enabled_installment && (
																					<SectionMain
																						xs={12}
																						sm={12}
																						md={8}
																						lg={8}
																						xl={8}
																					>
																						<SectionMain>
																							<SelectedInput
																								defaultValue={
																									1
																								}
																								firstElement={
																									checkout.plan.installments.includes(
																										1
																									)
																										? null
																										: {
																											label: (
																												<SectionMain position="space-between">
																													<div>
																														{
																															1
																														}

																														x
																													</div>
																													<FormLabel component="legend">
																														em
																														1
																														x{" "}
																														{toCurrency(
																															formikProps
																																.values
																																.total /
																															1,
																															"BRL"
																														)}
																													</FormLabel>
																												</SectionMain>
																											),
																											value: 1,
																										}
																								}
																								value={
																									formikProps
																										.values
																										.installments
																								}
																								onChange={(
																									e
																								) => {
																									formikProps.setFieldValue(
																										`installment`,
																										e
																											.target
																											.value
																									);
																								}}
																								arrayValues={checkout.plan.installments.map(
																									(
																										label,
																										i
																									) => ({
																										label: (
																											<SectionMain position="space-between">
																												<div>
																													{`${label}x`}
																												</div>
																												<FormLabel component="legend">
																													em{" "}
																													{
																														label
																													}

																													x{" "}
																													{toCurrency(
																														formikProps
																															.values
																															.total /
																														label,
																														"BRL"
																													)}
																												</FormLabel>
																											</SectionMain>
																										),
																										value: label,
																									})
																								)}
																								label={t(
																									"^forms.installments"
																								)}
																							/>
																						</SectionMain>
																					</SectionMain>
																				)}
																		</React.Fragment>
																	)}
															</SectionMain>
															{paymentMethod ===
																"boleto" && (
																	<RenderBoleto
																		amount={
																			formikProps
																				.values
																				.total
																		}
																	/>
																)}
														</SectionMain>
													</>
												)}
											</SectionMain>
										</StepTitle>
										{menuCollapse.four === false &&
											menuCollapse.five === true && (
												<StepTitle
													iconColor={
														company.style
															.primary_color
													}
													hidden={
														step !== "payment"
															? true
															: false
													}
													// hidden={false}
													handleChange={() =>
														handleMenuCollapsable(
															"five"
														)
													}
													defaultOpen={
														menuCollapse.five
													}
													stepNumber={
														<CreditCardIcon
															style={{
																fontSize: 18,
															}}
														/>
													}
													stepTitle={
														t.titles[
														"payment_method.sdd"
														]
													}
												>
													<iframe
														id="sdd"
														name="sdd"
														style={{
															margin: 0,
															padding: 0,
															height: 600,
															width: "100%",
															display: "flex",
															border: "none",
														}}
													/>
												</StepTitle>
											)}
										<SectionMain position="flex-end">
											<div className="flex-col">
												{step === "customer" && (
													<div className='flex-row flex-end center-b'>
														{checkout.checkout_config.terms_conditions.active &&
															<>
																<ThemeProvider theme={theme}>
																	<Checkbox checked={TCAgreed} onChange={() => setTCAgreed(!TCAgreed)} color="primary" />
																</ThemeProvider>
																<span>
																	{checkout.checkout_config.terms_conditions.type === 'link' &&
																		<>
																			Declaro que li e concordo com os <a style={{ color: 'black' }} target={'_blank'} href={checkout.checkout_config.terms_conditions.data}>Termos e Condições de Uso</a>
																		</>
																	}
																	{checkout.checkout_config.terms_conditions.type === 'text' &&
																		<>
																			Declaro que li e concordo com os <a onClick={showTCmodal} style={{ textDecoration: "underline", cursor: 'pointer' }}>Termos e Condições de Uso</a>
																		</>
																	}
																	{checkout.checkout_config.terms_conditions.type === 'accept' &&
																		<>
																			Declaro que li e concordo com os Termos e Condições de Uso
																		</>
																	}


																</span>
															</>
														}
													</div>
												)}
												<div className="flex-row flex-end">
													{step === "customer" && (
														<Button
															// startIcon={<CircularProgress size={24} />}
															type="submit"
															variant="contained"
															color="primary"
															disabled={
																formikProps.isSubmitting || isSubmitting || (!TCAgreed && checkout.checkout_config.terms_conditions.active)
															}
															style={{
																color: isBackgroundDark(company.style.primary_color || backgroundColor) ? 'white' : 'black',
																alignItems: "center",
																backgroundColor: company.style.primary_color ?
																	company.style.primary_color
																	: backgroundColor,

															}}
														>
															{loading && (
																<CircularProgress
																	size={24}
																	color="inherit"
																	style={{
																		marginRight: 8,
																	}}
																/>
															)}
															{
																t.buttons[
																"checkout.continue"
																]
															}
														</Button>
													)}
												</div>

											</div>

											{!payzenForm && step === "payment" && (
												<div className="flex-col center-b">
													{checkout.checkout_config.captcha?.active && captchaChecked == false &&
														<div style={{ padding: '10px' }}>
															<ReCaptcha
																onChange={value => setCaptchaChecked(value)}
																sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
															/>
														</div>
													}
													<Button
														fullWidth
														size="large"
														disabled={loading || (!captchaChecked && checkout.checkout_config.captcha?.active)}
														type="submit"
														variant="contained"
														color="primary"
														style={{
															color: isBackgroundDark(company.style.primary_color || backgroundColor) ? 'white' : 'black',
															alignItems: "center",
															backgroundColor: company.style.primary_color
																? company.style.primary_color
																: backgroundColor,
														}}
													// disabled={formikProps.values.total === 0 && formikProps.values.payment_method === "boleto"}
													>
														{loading && (
															<CircularProgress
																size={24}
																color="inherit"
																style={{
																	marginRight: 8,
																}}
															/>
														)}

														{formikProps.values.total ==
															0
															? "assinar"
															: formikProps.values
																.payment_method ===
																"boleto"
																? t.buttons["checkout.boleto"]
																: t.buttons["checkout.paid"]}

													</Button>
													<TitleSmall style={{ color: isBackgroundDark(checkout.company.background_color) ? 'white' : 'black' }} >{t(`@@ *Ao clicar em`)}&nbsp;
														"{(formikProps.values.total ==
															0
															? "assinar"
															: formikProps.values
																.payment_method ===
																"boleto"
																? t.buttons["checkout.boleto"]
																: t.buttons["checkout.paid"]).toUpperCase()}"
														{t(`@@, você aceita que seus dados sejam armazenados para futuros pagamentos recorrentes de acordo com o plano assinado.`)}</TitleSmall>
												</div>
											)}
										</SectionMain>
									</form>
								</SectionMain>
								<Hidden only={["xs", "sm"]}>
									<SectionMain
										xs={12}
										md={5}
										lg={5}
										xl={5}
										position="center"
										noPadding
										style={{ paddingTop: 20 }}
									>
										<div
											ref={(ref) =>
												ref &&
												setPlanWidth(ref.clientWidth)
											}
											className="flex-row center-a"
											style={{
												padding: 0,
												width: "100%",
											}}
										>
											<PlanInfo
												formikProps={formikProps}
											/>
										</div>
									</SectionMain>
								</Hidden>
							</Grid>
						</WrapperLayout>
					)}
				</Formik>
			</ErrorBoundary>
		);

	if (messageError) {
		return <PageNotFoundCheckout company={company} />;
	}

	return (
		<div
			className="flex-col center-a center-b"
			style={{
				width: "100vw",
				height: "100vh",
			}}
		>
			<Loader />
		</div>
	);
};

export default CheckoutPage;
