import React from 'react'
import UserDataRow from './UserDataRow'

const CustomerInfo = ({ customer = { billing_address: {} } }) => {
	const arrayData = [
		{
			name: 'name',
			label: 'Nome'
		},
		{
			name: 'email',
			label: 'E-mail'
		},
		{
			name: 'mobile_number',
			label: 'Telefone Celular'
		},
		{
			name: 'country_code',
			label: 'País'
		},
		{
			name: 'cnpj',
			label: 'CNPJ'
		},
		{
			name: 'cpf',
			label: 'CPF'
		}
	]
	const arrayAddress = [
		{
			name: 'postal_code',
			label: 'CEP'
		},
		{
			name: 'street',
			label: 'Logradouro'
		},
		{
			name: 'number',
			label: 'Número'
		},
		{
			name: 'complement',
			label: 'Complemento'
		},
		{
			name: 'street2',
			label: 'Barro'
		},
		{
			name: 'city',
			label: 'Cidade'
		},
		{
			name: 'state',
			label: 'Estado'
		}
	]
	return (
		<div>
			<h1
				style={{
					textAlign: 'center',
					color: 'rgba(0,0,0,0.8)',
					width: 200,
					textAlign: 'left',
					whiteSpace: 'pre-wrap',
					fontSize: 16,
					wordSpacing: 4
				}}
			>
				Dados do assinante
			</h1>
			{arrayData.map(
				(item) => customer[item.name] && <UserDataRow leftValue={item.label} rightValue={customer[item.name]} />
			)}
			{/* 
			<h1
				style={{
					display: customer.billing_address ? 'block' : 'none',
					textAlign: 'center',
					color: 'rgba(0,0,0,0.8)',
					width: 200,
					textAlign: 'left',
					whiteSpace: 'pre-wrap',
					fontSize: 16,
					wordSpacing: 4
				}}
			>
				Endereço de cobrança
			</h1>
			{arrayAddress.map(
				(item) =>
					customer.billing_address[item.name] && (
						<UserDataRow leftValue={item.label} rightValue={customer.billing_address[item.name]} />
					)
			)} */}

			{/* <UserDataRow leftValue="E-mail" rightValue={customer.email} />
			<UserDataRow
				leftValue="Telefone Celular"
				rightValue={customer.mobile_phone}
			/>
			<UserDataRow leftValue="País" rightValue={customer.country_code} /> */}
		</div>
	)
}

export default CustomerInfo
