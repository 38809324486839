export const currencyLocaleTable = {
	BRL: "pt-BR",
	USD: "en-US",
	EUR: "fr",
	CLP: 'es-CL',
	CLF: 'es-CL',
	PEN: 'es-PE',
	ARS: 'es-AR',
}

const decimalRules = {
	BRL: {
		places: 2,
		conversion: 100,
		separator: ',',
		symbol: "R$"
	},
	USD: {
		places: 2,
		conversion: 100,
		separator: '.',
		symbol: "$"
	},
	EUR: {
		places: 2,
		conversion: 100,
		separator: ',',
		symbol: "€"
	},
	CLP: {
		places: 0,
		conversion: 1,
		separator: ',',
		symbol: "$"
	},
	CLF: {
		places: 4,
		conversion: 10000,
		separator: '.',
		symbol: "UF"
	},
	PEN: {
		places: 2,
		conversion: 100,
		separator: ',',
		symbol: "S/"
	},
	ARS: {
		places: 2,
		conversion: 100,
		separator: ',',
		symbol: "$"
	},
	COP: {
		places: 2,
		conversion: 100,
		separator: ',',
		symbol: "$"
	}
}

const toCurrency = (value = 0, currency = "BRL") => {
	if (currency.length <= 0) currency = 'USD'
	if (!(value || "").toString().match(/\./)) {
		value /= decimalRules[currency].conversion
	}
	const locale = currencyLocaleTable[currency] || currencyLocaleTable.BRL
	const label = new Intl.NumberFormat(locale, {
		style: "currency",
		currency,
		maximumFractionDigits: decimalRules[currency].places,
	}).format((value || 0).toString().replace(/,/g, ""))
	if (currency === 'CLP') return label.replace("$", "$ ")
	if (currency === 'CLF') return label.replace("CLF", "UF")
	return label

}

export default toCurrency