import React, { useEffect, useContext } from 'react'
import PortalWrapper from '../../components/layout/PortalWrapper'
import SectionMain from '../../../layout/SectionMain'
import { Paper, Hidden, CircularProgress, Button, IconButton, MenuItem } from '@material-ui/core'

import Title, { TitleSmall } from '../../../components/checkout-1.5.4/forms/Title'
import { toCurrency } from '../../../utils/commonMasks'
import { useState } from 'react'

import Loader from '../../../components/Loader'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import ListPaymentMeans from '../../components/list/ListPaymentMeans'
import CloseIcon from '@material-ui/icons/Close'
import AppContext from '../../../components/AppContextCheckout'

import { lightOrDark } from '../../../utils/lightOrDark'
import StatusCard from '../../components/cards/StatusCard'
import api from '../../../core/api'
import TextInput from '../../../components/checkout-1.5.4/forms/TextInput'
import ListPaymentMeanItem from '../../components/list/ListPaymentMeanItem'
import ButtonCard from '../../components/cards/ButtonCard'
import { useSnackbar } from 'notistack'
import { CheckoutService } from '../../../core/CheckoutService'
import { CustomerService } from '../../../core/CustomerService'

const freqLabels = {
	DAILY: 'dia',
	MONTHLY: 'mês',
	YEARLY: 'ano'
}

const EditPaymentMeansPage = ({ history, match }) => {
	const [state, setState] = useState({
		loading: true,
		subscription: { plan: { name: '', amount: 0, currency: '' } }
	})
	const [paymentMeans, setPaymentMeans] = useState([])
	const [paymentId, setPaymentId] = useState('')
	const [sending, setSending] = useState(null)
	const [errorSelectInput, setErrorSelectInput] = useState('')
	const id = match.params.id
	const { enqueueSnackbar } = useSnackbar()

	const { company } = useContext(AppContext)

	const handleCardChange = (id) => {
		setErrorSelectInput('')
		setPaymentId(id)
	}

	const fetchCards = async () => {
		try {
			/** @type {{ data: Veripag.Responses.CardsResult }} */
			const data = await CustomerService.Subscriptions.getPaymentMeans({ filters: { count: 20 } })
			setPaymentMeans(data.items || [])
		} catch (e) {
			console.log({ e })
		}
	}

	const fetchItem = async () => {
		try {
			/** @type {{ data: Veripag.Responses.SubscriptonsResult }} */
			const data = await CustomerService.Subscriptions.GetById({ id })


			setState({ ...state, subscription: data, loading: false })
		} catch (e) {
			console.log({ e })
			setState({ ...state, loading: false })
		}
	}

	const onFormSubmit = async () => {
		if (!paymentId) {
			enqueueSnackbar('Por favor, escolha um meio de pagamento.', {
				variant: 'error'
			})
			setErrorSelectInput('Por favor, escolha um meio de pagamento.')
			return
		}

		const form = { payment_mean_id: paymentId }
		setSending(true)

		try {
			await api
				.put(`/subscriptions/${id}/payment-means`, form)
				.then((res) => {
					if (res.status === 200) {
						enqueueSnackbar('Meio de pagamento alterado com sucesso.', {
							variant: 'success'
						})
					}
				})
				.then(fetchItem())
			setSending(null)
		} catch (e) {
			if (e.response.status === 409) {
				enqueueSnackbar('Este cartão já esta sendo utilizado na assinatura.', {
					variant: 'error'
				})
			} else {
				enqueueSnackbar('Erro interno, por favor tente novamente.', {
					variant: 'error'
				})
			}
			setSending(null)
		}
	}

	useEffect(() => {
		fetchItem()
		fetchCards()
	}, [])

	return (
		<PortalWrapper history={history}>
			<Paper style={{ padding: '0 20px' }}>
				{state.loading ? (
					<SectionMain direction="column" position="center" alignItems="center">
						<Loader />
					</SectionMain>
				) : (
					<form action="javascript:void(0)" onSubmit={() => onFormSubmit()}>
						<SectionMain noPadding>
							<SectionMain position="space-between" alignItems="flex-start">
								<div>
									<SectionMain noPadding style={{ paddingBottom: 5 }}>
										<Title style={{ paddingRight: 10 }} id="text-truncate">
											{state.subscription.plan.name}
										</Title>
										<StatusCard status={state.subscription.status} />
									</SectionMain>

									<SectionMain miniPadding>
										<TitleSmall bold>
											{`${toCurrency(
												state.subscription.plan.amount,
												state.subscription.plan.currency.alpha_code
											)}/${freqLabels[state.subscription.plan.frequency]}`}
										</TitleSmall>
									</SectionMain>
								</div>
								<IconButton size="small" onClick={() => history.replace(`/subscriptions/${id}`)}>
									<CloseIcon />
								</IconButton>
							</SectionMain>
							{state.subscription.payment_mean && (
								<SectionMain miniPadding>
									<SectionMain alignItems="bottom" noPadding>
										<CreditCardIcon /> &nbsp;
										<Title style={{ paddingRight: 10 }}>Cartão atual</Title>
									</SectionMain>
									<SectionMain noPadding xl={8} lg={8} md={8}>
										<ListPaymentMeans arrayCards={[state.subscription.payment_mean]} />
									</SectionMain>
								</SectionMain>
							)}

							<SectionMain miniPadding position="space-between">
								<Title style={{ paddingRight: 10 }}>Selecione um meio de meio de pagamento</Title>
								<ButtonCard
									style={{ padding: '0px 0px' }}
									onClick={() => history.push(`/add-payment/${state.subscription.subscription_id}`)}
								>
									Adicionar Novo Cartão
								</ButtonCard>
							</SectionMain>
							<SectionMain>
								<TextInput
									errorMessage={errorSelectInput}
									disabled={sending}
									size="small"
									select
									label="Cartão"
									value={paymentId}
									onChange={(e) => handleCardChange(e.target.value)}
								>
									{paymentMeans.map((payment_mean) => (
										<MenuItem
											style={{ paddingTop: 0, paddingBottom: 0 }}
											key={payment_mean.payment_mean_id}
											value={payment_mean.payment_mean_id}
										>
											<SectionMain noPadding>
												<ListPaymentMeanItem listView paymentMean={payment_mean} />
											</SectionMain>
										</MenuItem>
									))}
								</TextInput>
							</SectionMain>
							<SectionMain position="flex-end">
								<SectionMain noPadding xl={2} lg={2} md={2} sm={2}>
									<Button
										fullWidth
										type="submit"
										variant="contained"
										color="primary"
										disabled={sending}
										style={{
											color:
												company.primary_color && lightOrDark(company.primary_color) === 'light'
													? '#282828'
													: '#fff',
											alignItems: 'center',
											backgroundColor: company.primary_color && company.primary_color
										}}
									>
										{sending && (
											<CircularProgress size={24} color="inherit" style={{ marginRight: 8 }} />
										)}
										Salvar
									</Button>
								</SectionMain>
							</SectionMain>
						</SectionMain>
					</form>
				)}
			</Paper>
		</PortalWrapper>
	)
}

export default EditPaymentMeansPage
