import React from "react";
import { Button, Accordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails } from '@material-ui/core';
import "./randomize.css"
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles, withStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const rdmnumber = Math.floor(Math.random() * 100000)

function gerarCpf() {
    const num1 = aleatorio();
    const num2 = aleatorio();
    const num3 = aleatorio();
    const dig1 = dig(num1, num2, num3);
    const dig2 = dig(num1, num2, num3, dig1);
    return `${num1}${num2}${num3}${dig1}${dig2}`;
}

function dig(n1, n2, n3, n4) {

    const nums = n1.split("").concat(n2.split(""), n3.split(""));

    if (n4 !== undefined) {
        nums[9] = n4;
    }

    let x = 0;

    for (let i = (n4 !== undefined ? 11 : 10), j = 0; i >= 2; i--, j++) {
        x += parseInt(nums[j]) * i;
    }

    const y = x % 11;
    return y < 2 ? 0 : 11 - y;
}

function aleatorio() {
    const aleat = Math.floor(Math.random() * 999);
    return ("" + aleat).padStart(3, '0');
}

function randomizeCustomer(formikprops) {
    const values = {
        first_name: `Nome ${rdmnumber}`,
        last_name: `Sobrenome ${rdmnumber}`,
        mobile_phone: `5511111111111`,
        email: `email${rdmnumber}@email.com`,
        document_type: 'CPF',
        document_number: gerarCpf(),
        billing_address_postal_code: `01239040`,
        billing_address_street1: `Rua Mato Grosso`,
        billing_address_number: `300`,
        billing_address_state: `SP`,
        billing_address_district: `Higienópolis`,
        billing_address_city: `São Paulo`,
        billing_address_country_code: `BRA`,
        billing_address_complement: `Random`,
        shipping_address_postal_code: `01239040`,
        shipping_address_street1: `Rua Mato Grosso`,
        shipping_address_number: `300`,
        shipping_address_state: `SP`,
        shipping_address_district: `Higienópolis`,
        shipping_address_city: `São Paulo`,
        shipping_address_country_code: `BRA`,
        shipping_address_complement: ``,
    }

    return Object.keys(values).forEach(v => {
        formikprops.setFieldValue(v, values[v])
    })
}

function randomizeDocument(formikprops) {
    const values = {

        document_type: 'CPF',
        document_number: gerarCpf(),

    }

    return Object.keys(values).forEach(v => {
        formikprops.setFieldValue(v, values[v])
    })
}

function randomizeBilling(formikprops) {
    const values = {
        billing_address_postal_code: `01239040`,
        billing_address_street1: `Rua Mato Grosso`,
        billing_address_number: `300`,
        billing_address_state: `SP`,
        billing_address_district: `Higienópolis`,
        billing_address_city: `São Paulo`,
        billing_address_country_code: ``,
        billing_address_complement: ``,
        shipping_address_postal_code: `01239040`,
        shipping_address_street1: `Rua Mato Grosso`,
        shipping_address_number: `300`,
        shipping_address_state: `SP`,
        shipping_address_district: `Higienópolis`,
        shipping_address_city: `São Paulo`,
        shipping_address_country: ``,
        shipping_address_complement: ``,
    }

    return Object.keys(values).forEach(v => {
        formikprops.setFieldValue(v, values[v])
    })
}

function randomizeCC(formikprops, type) {

    const numbers = {
        'valid': "4970100000000014",
        'invalid': "4970100000000013",
        'limit_exceeded': "4970100000000071",
        'fraud': "4970100000000121",
        'invalid_cvv': "4970100000000089",
    }


    const values = {
        "cc_number": numbers[type],
        "cvv": "023",
        "cc_expiry_date": "12/30",
        "cc_cardholder": "Joao Silva"
    }

    return Object.keys(values).forEach(v => {
        formikprops.setFieldValue(v, values[v])
    })
}

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .10)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        display: 'flex',
        boxSizing: 'border-box',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
        '&:hover': {
            color: "white",
            backgroundColor: 'rgba(0, 0, 0, .50)',
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .80)',
        color: 'white',
    }
})(MuiAccordionDetails);

export default function TestForm({ formStep, formikprops, checkoutConfig, value }) {
    const [open, setOpen] = React.useState(false);

    const classes = useStyles();

    if (!(formStep !== 'customer' && value === 0)) return null
    if (['develop', 'homolog', 'sandbox'].includes(process.env.REACT_APP_ENVIRONMENT)) {
        if (checkoutConfig.company.address.country_code !== "BRA") return null
        return (
            <div className="form-test" style={{ width: '100vw' }}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <Accordion expanded={open} onClick={() => setOpen(!open)}>
                        <AccordionSummary>
                            Testes
                        </AccordionSummary>
                        <AccordionDetails >
                            <div className="flex-col" style={{ width: '100%' }}>
                                {formStep === 'customer' || formStep === "one_step" &&
                                    <>
                                        <div className="flex-col">
                                            Assinante:
                                            <Button variant="outlined" color={'primary'}onClick={() => randomizeCustomer(formikprops)}>
                                                Randomizar Assinante
                                            </Button>

                                        </div>
                                        <hr style={{ width: '100%' }} />
                                    </>
                                }

                                {checkoutConfig.plan?.coupon_enabled &&
                                    <>
                                        <div className="flex-col">
                                            Cupom:
                                            <Button variant="outlined">
                                                Testar cupom válido
                                            </Button>
                                            <Button variant="outlined">
                                                Testar cupom não encontrado
                                            </Button>
                                            <Button variant="outlined">
                                                Testar cupom expirado
                                            </Button>

                                        </div>
                                        <hr style={{ width: '100%' }} />
                                    </>
                                }

                                {((formStep !== 'customer' && value === 0) || formStep === "one_step") &&
                                    <>
                                        Cartão de crédito:
                                        <table className="testcardTable">
                                            <th>
                                                Tipo
                                            </th>
                                            <th>
                                                Número
                                            </th>
                                            <th>
                                                Bandeira
                                            </th>
                                            <th>
                                                Validade
                                            </th>
                                            <th>
                                                CVV
                                            </th>
                                            <th>
                                                Titular do cartão
                                            </th>
                                            <tr role={'button'} onClick={() => randomizeCC(formikprops, 'valid')}>
                                                <td>
                                                    Testar cartão de crédito válido
                                                </td>
                                                <td>
                                                    4970100000000014
                                                </td>
                                                <td>
                                                    VISA
                                                </td>
                                                <td>
                                                    12/30
                                                </td>
                                                <td>
                                                    023
                                                </td>
                                                <td>
                                                    Joao Silva
                                                </td>
                                            </tr>

                                            <tr role={'button'} onClick={() => randomizeCC(formikprops, 'invalid')}>
                                                <td>
                                                    Testar cartão de crédito inválido
                                                </td>
                                                <td>
                                                    4970100000000013
                                                </td>
                                                <td>
                                                    VISA
                                                </td>
                                                <td>
                                                    12/30
                                                </td>
                                                <td>
                                                    023
                                                </td>
                                                <td>
                                                    Joao Silva
                                                </td>
                                            </tr>

                                            <tr role={'button'} onClick={() => randomizeCC(formikprops, 'limit_exceeded')}>
                                                <td>
                                                    Testar cartão de crédito com limite excedido
                                                </td>
                                                <td>
                                                    4970100000000071
                                                </td>
                                                <td>
                                                    VISA
                                                </td>
                                                <td>
                                                    12/30
                                                </td>
                                                <td>
                                                    023
                                                </td>
                                                <td>
                                                    Joao Silva
                                                </td>
                                            </tr>
                                            <tr role={'button'} onClick={() => randomizeCC(formikprops, 'fraud')}>
                                                <td>
                                                    Testar cartão de crédito com suspeita de fraude
                                                </td>
                                                <td>
                                                    4970100000000121
                                                </td>
                                                <td>
                                                    VISA
                                                </td>
                                                <td>
                                                    12/30
                                                </td>
                                                <td>
                                                    023
                                                </td>
                                                <td>
                                                    Joao Silva
                                                </td>
                                            </tr>
                                            <tr role={'button'} onClick={() => randomizeCC(formikprops, 'invalid_cvv')}>
                                                <td>
                                                    Testar cartão de crédito com cvv inválido
                                                </td>
                                                <td>
                                                    4970100000000089
                                                </td>
                                                <td>
                                                    VISA
                                                </td>
                                                <td>
                                                    12/30
                                                </td>
                                                <td>
                                                    023
                                                </td>
                                                <td>
                                                    Joao Silva
                                                </td>
                                            </tr>
                                        </table>

                                        <hr style={{ width: '100%' }} />
                                    </>
                                }

                                {formStep !== 'customer' && value === 1 &&
                                    <>
                                        <div className="flex-col">
                                            Boleto:
                                            <Button variant="outlined" onClick={() => randomizeDocument(formikprops)}>
                                                Randomizar documento
                                            </Button>
                                            <Button variant="outlined" onClick={() => randomizeBilling(formikprops)}>
                                                Preencher endereços
                                            </Button>

                                        </div>
                                        <hr style={{ width: '100%' }} />
                                    </>
                                }

                            </div>
                        </AccordionDetails>
                    </Accordion>
                </ClickAwayListener>
            </div>
        )
    }
    return null

}

