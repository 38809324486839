import React from 'react'

import SectionMain from '../../../../layout/SectionMain'
import Title from '../Title'
import { Avatar, ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import { primary, backgroundColor } from '../../../../layout/color'
import Collapse from '@material-ui/core/Collapse'
import Grid from 'antd/lib/card/Grid'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

const StepTitle = ({
	stepTitle = '',
	iconColor = backgroundColor,
	stepNumber = 0,
	defaultOpen = true,
	handleChange = () => void 0,
	hidden = false,
	noArrow,
	upperCase = true,
	paperStyle,
	children
}) => {
	return (
		<SectionMain
			id="step"
			style={{
				backgroundColor: '#fff',
				boxShadow: '12px 13px 17px -8px rgba(0,0,0,0.10)',
				margin: '10px 0',
				padding: 20,
				opacity: defaultOpen ? 1 : 0.5,
				display: hidden ? 'none' : 'block',
				...paperStyle
			}}
		>
			<ListItem button onClick={handleChange}>
				<ListItemIcon>
					<Avatar style={{ backgroundColor: iconColor, width: '25px', height: '25px', fontWeight: '600' }}>
						{stepNumber}
					</Avatar>
				</ListItemIcon>
				<ListItemText primary={upperCase ? stepTitle.toUpperCase() : stepTitle} />
				{!noArrow && <span>{defaultOpen ? <ExpandLess /> : <ExpandMore />}</span>}
			</ListItem>
			<Collapse in={defaultOpen}>
				{children}

				<SectionMain noPadding style={{ paddingTop: 20 }}>
					<div
						className="flex-row center-a center-b"
						style={{ height: 1, border: '1px dashed #DCDCDC', width: '100%' }}
					/>
				</SectionMain>
			</Collapse>
		</SectionMain>
	)
}

export default StepTitle
