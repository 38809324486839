import React from "react"
import SectionMain from "../../../../../layout/SectionMain"
import { useTranslation } from "../../../../../components/IntlContext/IntlContext"
import { makeStyles, AppBar } from "@material-ui/core"
import { useAppContext } from "../../../../../components/AppContextCheckout"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles({
	appBar: {
		backgroundColor: "#fff",
		justifyContent: "center",
		flexDirection: "row",
		height: 50,
		padding: "0 20px",
	},
	container: {
		maxWidth: 1600,
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "space-between",
	},

	menu: {
		alignItems: "center",
		justifyContent: "space-between",
		maxWidth: 425,
		paddingLeft: 20,
	},
	logo: {
		// maxWidth: 100,
		maxHeight: 45,
		marginRight: 8,
	},
	avatar: {
		justifyContent: "space-between",
		alignItems: "center",
		flexDirection: "row",
	},
})

const TopMenuMobileWrapper = ({ children }) => {
	const classes = useStyles()
	const t = useTranslation()
	const { company } = useAppContext()
	const history = useHistory()

	return (
		<AppBar className={classes.appBar}>
			<SectionMain className={classes.container}>
				<img
					alt="Logo"
					className={classes.logo}
					src={company.style.url_logo_default}
					onClick={() => history.push("/")}
				/>
			</SectionMain>
		</AppBar>
	)
}

export default TopMenuMobileWrapper
