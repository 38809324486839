import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from '../components/IntlContext/IntlContext';
import FormAccordion from '../components/Accordion';
import { Formik } from 'formik';
import { FormikValidationsCustomer, FormikValidationsCCPayment, FormikValidationsBoletoPayment, FormikValidationsPixPayment } from '../components/checkout-1.5.4/forms/RenderInputsV4/FormikValidations';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { AiOutlineBarcode } from 'react-icons/ai'
import { BiRadioCircleMarked, BiRadioCircle } from 'react-icons/bi'
import RenderInputsV4 from '../components/checkout-1.5.4/forms/RenderInputsV4';
import { TextField, Button, FormControlLabel, Checkbox, Select, FormLabel, FormControl, InputLabel, Box, Accordion, AccordionSummary, AccordionDetails, ThemeProvider, createTheme } from '@material-ui/core';
import { CheckoutService } from '../core/CheckoutService';
import useMsgBox from '../components/SweetAlert';
import Loader from '../components/Loader';
import PersonIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import SectionMain from '../layout/SectionMain';
import toCurrency from '../core/toCurrency';
import TestForm from '../core/randomize';
import Typography from '@material-ui/core/Typography';
import MaskedInput from 'react-text-mask';
import { handleBrand } from '../utils/handleBrand';
import { TextInputMasked } from '../components/checkout-1.5.4/forms/TextInput';
import generateMask from '../core/generateMask';
import { flagReValidators } from '../core/cards-utils';
import CardBrand from '../portal/components/cards/CardBrand';
import ReCaptcha from 'react-google-recaptcha'
import { Icon } from '@iconify/react';
import AppContext, { useAppContext } from '../components/AppContextCheckout';
import { billService } from '../core/api';
import RenderPayzenForm from '../components/checkout-1.5.4/forms/PayzenForm';
import logoSEPA from '../assets/sepa-logo.png'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import formSchema from '../core/formSchema.js'
import moment from 'moment'
import { TrackGoogleAnalyticsEvent } from '../utils/google-analytics';

let _ = require('lodash');

const BRForm = ({
    checkoutConfig = {
        checkout_config: {
            subscription_custom_fields: [],
            customer_custom_fields: [],
        }
    },
    setSuccessPage = () => null,
    couponId = null,
    totalAmount = 0,
    initialCustomer
}) => {
    const t = useTranslation()
    const msg = useMsgBox()
    const [formStep, setFormStep] = useState('customer')
    const [customer, setCustomer] = useState({})
    const [loadingState, setLoadingState] = useState({
        customer: false,
        payment: false
    })

    const theme = createTheme({
        palette: {
            primary: { main: checkoutConfig.company?.style.primary_color },
        },
    });

    const isSubscriptionCustomEnabled = checkoutConfig.checkout_config.subscription_custom_fields?.filter(field => field.is_enabled === true).length > 0
    const isCustomerCustomEnabled = checkoutConfig.checkout_config.customer_custom_fields?.filter(field => field.is_enabled === true).length > 0

    const country = checkoutConfig?.company.address.country_code

    const [customerCountry, setCustomerCountry] = useState(country)

    const { setAppState, payzenForm, coupon } = useAppContext(AppContext);

    const [customerAddress, setCustomerAddress] = useState({})
    const [sddInputs, setSddInputs] = useState([]);
    const [showIFrame, setShowIFrame] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [useBillingAsShipping, setUseBillingAsShipping] = useState(true)

    const refCaptcha = useRef()

    function removeIframe() {
        var iframe = document.querySelector('iframe[name=sdd]');
        if (iframe) {
            iframe.remove();
        }
        return msg.warn({
            title: t.generics.attention,
            text: t.errors['sdd.session_expired']
        })
    }

    const onCustomerSubmit = async (form) => {
        setIsSubmitting(true)

        setLoadingState({ ...loadingState, customer: true })

        const fetchSddForm = async (subscription_id) => {
            const qs = new URLSearchParams(window.location.search);
            const hash = qs.get("hash");

            try {
                billService
                    .get(
                        `/checkout/sdd/form?hash=${hash}&subscriptionId=${subscription_id}&isSHA256=true`
                    )
                    .then((res) => {
                        setSddInputs(res.data);
                    });
            } catch (e) {
                setSddInputs([]);
            }
        };

        const other_documents = []

        if (form.state_tax_number) {
            other_documents.push({
                type: 'state_tax_number', number: form.state_tax_number
            })
        }
        if (form.city_tax_number) {
            other_documents.push({
                type: 'city_tax_number', number: form.city_tax_number
            })
        }


        let customerCustomFieldsValues = checkoutConfig.checkout_config.customer_custom_fields ? [
            ...checkoutConfig.checkout_config.customer_custom_fields?.map(field => field.is_enabled && form[`customer_${field.key}`] ? {
                key: field.key,
                value: (field.type === 'date') ? form[`customer_${field.key}`].format("YYYY-MM-DD[T00:00:00Z]") : form[`customer_${field.key}`]
            }
                : null)
        ] : []

        let subscriptionCustomFieldsValues = checkoutConfig.checkout_config.subscription_custom_fields ? [
            ...checkoutConfig.checkout_config.subscription_custom_fields?.map(field => field.is_enabled && form[`subscription_${field.key}`] ? {
                key: field.key,
                value: (field.type === 'date') ? form[`subscription_${field.key}`].format("YYYY-MM-DD[T00:00:00Z]") : form[`subscription_${field.key}`]
            }
                : null)
        ] : []

        customerCustomFieldsValues = customerCustomFieldsValues?.filter(v => v !== null)
        subscriptionCustomFieldsValues = subscriptionCustomFieldsValues?.filter(v => v !== null)

        const customer = {
            first_name: form.first_name,
            last_name: form.last_name,
            email: form.email,
            mobile_number: form.mobile_phone,
            gender: "",
            birth_date: form.birth_date ? moment(form.birth_date).format("YYYY/MM/DD") : null,
            country_code: checkoutConfig.checkout_config.country_code ? form.country_code : null,
            document: checkoutConfig.checkout_config.checkout_fields.document
                ? {
                    type: form.document_type,
                    number: form.document_number.match(/\d+/g).join("")
                } : null,
            billing_address: checkoutConfig.checkout_config.checkout_fields.billing_address ? {
                postal_code: form.billing_address_postal_code.replace("-", ""),
                street: form.billing_address_street1,
                number: form.billing_address_number,
                state: form.billing_address_state,
                district: form.billing_address_district,
                city: form.billing_address_city,
                country_code: form.billing_address_country_code,
                complement: form.billing_address_complement,
            } : null,
            shipping_address: checkoutConfig.checkout_config.checkout_fields.shipping_address ?
                !useBillingAsShipping ? [{
                    postal_code: form.shipping_address_postal_code.replace("-", ""),
                    street: form.shipping_address_street1,
                    number: form.shipping_address_number,
                    state: form.shipping_address_state,
                    district: form.shipping_address_district,
                    city: form.shipping_address_city,
                    country_code: form.shipping_address_country_code,
                    complement: form.shipping_address_complement,
                    is_main: false
                },
                ] : [{
                    postal_code: form.billing_address_postal_code.replace("-", ""),
                    street: form.billing_address_street1,
                    number: form.billing_address_number,
                    state: form.billing_address_state,
                    district: form.billing_address_district,
                    city: form.billing_address_city,
                    country_code: form.billing_address_country_code,
                    complement: form.billing_address_complement,
                    is_main: false
                }] : null,
            other_documents,
            custom_fields: customerCustomFieldsValues
        }
        const subscription = {
            custom_fields: subscriptionCustomFieldsValues
        }

        const qs = new URLSearchParams(window.location.search);
        const hash = qs.get("hash");

        try {
            const recaptchaToken = ['develop', 'homolog'].includes(process.env.REACT_APP_ENVIRONMENT) ? null : await refCaptcha.current.executeAsync()
            const response = await CheckoutService.Subscribe({ customer, subscription, hash, coupon_id: coupon.id, session_token: checkoutConfig.session_token, recaptchaToken })
            setCustomer({ ...response, ...customer })
            if (checkoutConfig.use_form_token) {
                setAppState({
                    payzenForm: { form_token: response.form_token, public_key: response.public_key },
                });
            }
            if (checkoutConfig.plan.payment_methods.includes("sdd")) fetchSddForm(response.subscription_id);

        } catch (e) {
            msg.err({ title: "Erro", text: t.errors[e.response.data.parameter] || t.errors['generic.post'] })
            setLoadingState({ ...loadingState, customer: false })
            setAppState({
                coupon: {
                    ...coupon,
                    enabled: false
                }
            })
            setIsSubmitting(false)
            return
        }

        setFormStep('payment')
        setIsSubmitting(false)
        setExpanded({
            ...expanded,
            customer: false,
            billing: false,
            shipping: false,
            additional_info: false,
            payment: true,
            credit_card: true,
            boleto: false,
            bolecode: false
        });

        setLoadingState({ ...loadingState, customer: false })

    }


    const onCCPaymentSubmit = async (form) => {
        setIsSubmitting(true)
        setLoadingState({ ...loadingState, payment: true })

        const [MM, YY] = form.cc_expiry_date.split("/")

        const expiry_date = `${MM}/20${YY}`

        const payment = {
            payment_mean: {
                number: form.cc_number,
                holder: form.cc_cardholder,
                expiry_date,
                cvv: form.cvv,
                brand: form.cc_brand,
                type: "credit_card"
            },
            installment: checkoutConfig.plan.installments?.length > 0 ? form.installments : null,
            customer: {
                customer_id: customer.customer_id
            },
            coupon_id: couponId || null,
            subscription_id: customer.subscription_id
        }

        try {
            const data = await CheckoutService.Pay({ payment, session_token: checkoutConfig.session_token })
            if (data.status === 'refused') {
                setIsSubmitting(false)
                setLoadingState({ ...loadingState, payment: false })
                TrackGoogleAnalyticsEvent("Payment", "cc_payment_refused", "Credit Card Payment Refused")

                return msg.err({ title: t.errors.label, text: t.errors['payments.transaction_refused'] })
            }
            TrackGoogleAnalyticsEvent("Payment", "cc_payment_successful", "Credit Card Payment Successful")
            return setSuccessPage({ type: "credit_card", customer, installment: form.installments, data })
        } catch (e) {
            TrackGoogleAnalyticsEvent("Payment", "cc_payment_error", "Credit Card Payment Error")
            msg.err({ title: t.errors.label, text: t.errors[e?.response?.data?.parameter] || t.errors['generic.post'] })
            setIsSubmitting(false)
            setLoadingState({ ...loadingState, payment: false })

        }
        setLoadingState({ ...loadingState, payment: false })
        setIsSubmitting(false)
    }

    const onBoletoPaymentSubmit = async (form) => {
        setIsSubmitting(true)


        setLoadingState({ ...loadingState, payment: true })


        const billing_address = {
            postal_code: form.billing_address_postal_code.replace("-", ""),
            street: form.billing_address_street1,
            number: form.billing_address_number,
            state: form.billing_address_state,
            district: form.billing_address_district,
            city: form.billing_address_city,
            country_code: form.billing_address_country_code,
            complement: form.billing_address_complement,
        }


        const payment = {
            payment_mean: {
                type: "boleto"
            },
            customer: {
                customer_id: customer.customer_id,
                document: !checkoutConfig.checkout_config.checkout_fields.document ? {
                    type: form.document_type,
                    number: form.document_number.match(/\d+/g).join(""),
                } : null,
                billing_address: !checkoutConfig.checkout_config.checkout_fields.billing_address ? billing_address : null,
            },
            coupon_id: couponId || null,
            subscription_id: customer.subscription_id
        }

        try {
            const data = await CheckoutService.PayBoleto({ payment, session_token: checkoutConfig.session_token })
            TrackGoogleAnalyticsEvent("Payment", "boleto_payment_successful", "Boleto Payment Successful")

            return setSuccessPage({ type: "boleto", boleto: data, customer })
        } catch (e) {
            msg.err({ title: t.errors.label, text: t.errors[e?.response?.data?.parameter] || t.errors['generic.post'] })
            setIsSubmitting(false)
            TrackGoogleAnalyticsEvent("Payment", "boleto_payment_error", "Boleto Payment Error")
            setLoadingState({ ...loadingState, payment: false })

        }
        setIsSubmitting(false)
        setLoadingState({ ...loadingState, payment: false })

    }

    const onBolecodePaymentSubmit = async (form) => {
        setIsSubmitting(true)


        setLoadingState({ ...loadingState, payment: true })


        const billing_address = {
            postal_code: form.billing_address_postal_code.replace("-", ""),
            street: form.billing_address_street1,
            number: form.billing_address_number,
            state: form.billing_address_state,
            district: form.billing_address_district,
            city: form.billing_address_city,
            country_code: form.billing_address_country_code,
            complement: form.billing_address_complement,
        }


        const payment = {
            payment_mean: {
                type: "bolecode"
            },
            customer: {
                customer_id: customer.customer_id,
                document: !checkoutConfig.checkout_config.checkout_fields.document ? {
                    type: form.document_type,
                    number: form.document_number.match(/\d+/g).join(""),
                } : null,
                billing_address: !checkoutConfig.checkout_config.checkout_fields.billing_address ? billing_address : null,
            },
            coupon_id: couponId || null,
            subscription_id: customer.subscription_id
        }

        try {
            const data = await CheckoutService.PayBoleto({ payment, session_token: checkoutConfig.session_token })
            TrackGoogleAnalyticsEvent("Payment", "bolecode_payment_successful", "Bolecode Payment Successful")

            return setSuccessPage({ type: "bolecode", bolecode: data, customer })
        } catch (e) {
            msg.err({ title: t.errors.label, text: t.errors[e?.response?.data?.parameter] || t.errors['generic.post'] })
            setIsSubmitting(false)
            TrackGoogleAnalyticsEvent("Payment", "bolecode_payment_error", "Bolecode Payment Error")
            setLoadingState({ ...loadingState, payment: false })

        }
        setIsSubmitting(false)
        setLoadingState({ ...loadingState, payment: false })

    }

    const onPixPaymentSubmit = async (form) => {
        setIsSubmitting(true)
        setLoadingState({ ...loadingState, payment: true })


        const payment = {
            payment_mean: {
                type: "pix"
            },
            customer: {
                customer_id: customer.customer_id,
                document: !checkoutConfig.checkout_config.checkout_fields.document ? {
                    type: form.document_type,
                    number: form.document_number.match(/\d+/g).join(""),
                } : null,
            },
            coupon_id: couponId || null,
            subscription_id: customer.subscription_id
        }

        try {
            const data = await CheckoutService.PayBoleto({ payment, session_token: checkoutConfig.session_token })
            TrackGoogleAnalyticsEvent("Payment", "pix_payment_successful", "Pix Payment Successful")

            return setSuccessPage({ type: "pix", pix: data, customer })
        } catch (e) {
            msg.err({ title: t.errors.label, text: t.errors[e?.response?.data?.parameter] || t.errors['generic.post'] })
            setIsSubmitting(false)
            TrackGoogleAnalyticsEvent("Payment", "pix_payment_error", "Pix Payment Error")
            setLoadingState({ ...loadingState, payment: false })

        }
        setIsSubmitting(false)
        setLoadingState({ ...loadingState, payment: false })

    }

    function isBackgroundDark(hexcolor = "#000000") {
        hexcolor = hexcolor.replace("#", "");
        var r = parseInt(hexcolor.substr(0, 2), 16);
        var g = parseInt(hexcolor.substr(2, 2), 16);
        var b = parseInt(hexcolor.substr(4, 2), 16);
        var yiq = (r * 299 + g * 587 + b * 114) / 1000;
        return yiq < 128;
    }

    const disabledInputsCustomer = () => {
        if (!checkoutConfig.customer) return {}
        let initialValues = {
            first_name: Boolean(checkoutConfig.customer.first_name),
            last_name: Boolean(checkoutConfig.customer.last_name),
            mobile_phone: Boolean(checkoutConfig.customer.mobile_number),
            email: Boolean(checkoutConfig.customer.email),
            birth_date: Boolean(checkoutConfig.customer.birth_date),
            country_code: Boolean(checkoutConfig.customer.country_code),
            document_type: Boolean(checkoutConfig.customer.document.type),
            document_number: Boolean(checkoutConfig.customer.document.number),
            billing_address_postal_code: Boolean(checkoutConfig.customer.billing_address?.postal_code),
            billing_address_street1: Boolean(checkoutConfig.customer.billing_address?.street),
            billing_address_number: Boolean(checkoutConfig.customer.billing_address?.number),
            billing_address_state: Boolean(checkoutConfig.customer.billing_address?.state),
            billing_address_district: Boolean(checkoutConfig.customer.billing_address?.district),
            billing_address_city: Boolean(checkoutConfig.customer.billing_address?.city),
            billing_address_country_code: Boolean(checkoutConfig.customer.billing_address?.country_code),
            billing_address_complement: Boolean(checkoutConfig.customer.billing_address?.complement),
            shipping_address_postal_code: Boolean(checkoutConfig.customer.shipping_address[0]?.postal_code),
            shipping_address_street1: Boolean(checkoutConfig.customer.shipping_address[0]?.street),
            shipping_address_number: Boolean(checkoutConfig.customer.shipping_address[0]?.number),
            shipping_address_state: Boolean(checkoutConfig.customer.shipping_address[0]?.state),
            shipping_address_district: Boolean(checkoutConfig.customer.shipping_address[0]?.district),
            shipping_address_city: Boolean(checkoutConfig.customer.shipping_address[0]?.city),
            shipping_address_country_code: Boolean(checkoutConfig.customer.shipping_address[0]?.country_code),
            shipping_address_complement: Boolean(checkoutConfig.customer.shipping_address[0]?.complement),
        }
        return initialValues
    }

    const disabledInputsBoleto = () => {
        if (!checkoutConfig.customer) return {}
        let initialValues = {
            document_type: Boolean(checkoutConfig.customer.document.type),
            document_number: Boolean(checkoutConfig.customer.document.number),

        }
        return initialValues
    }


    const customerInitialValues = () => {

        const date = moment("24-11-1998")

        if (checkoutConfig.customer) {
            let initialValues = {
                first_name: checkoutConfig.customer.first_name,
                last_name: checkoutConfig.customer.last_name,
                mobile_phone: checkoutConfig.customer.mobile_number || "",
                email: checkoutConfig.customer.email,
                birth_date: checkoutConfig.customer.birth_date ? moment(checkoutConfig.customer.birth_date) : null,
                country_code: checkoutConfig.customer.country_code,
                document_type: checkoutConfig.customer.document.type,
                document_number: checkoutConfig.customer.document.number,
                billing_address_postal_code: checkoutConfig.customer.billing_address?.postal_code,
                billing_address_street1: checkoutConfig.customer.billing_address?.street,
                billing_address_number: checkoutConfig.customer.billing_address?.number,
                billing_address_state: checkoutConfig.customer.billing_address?.state,
                billing_address_district: checkoutConfig.customer.billing_address?.district,
                billing_address_city: checkoutConfig.customer.billing_address?.city,
                billing_address_country_code: checkoutConfig.customer.billing_address?.country_code,
                billing_address_complement: checkoutConfig.customer.billing_address?.complement,
                shipping_address_postal_code: checkoutConfig.customer.shipping_address[0]?.postal_code,
                shipping_address_street1: checkoutConfig.customer.shipping_address[0]?.street,
                shipping_address_number: checkoutConfig.customer.shipping_address[0]?.number,
                shipping_address_state: checkoutConfig.customer.shipping_address[0]?.state,
                shipping_address_district: checkoutConfig.customer.shipping_address[0]?.district,
                shipping_address_city: checkoutConfig.customer.shipping_address[0]?.city,
                shipping_address_country_code: checkoutConfig.customer.shipping_address[0]?.country_code,
                shipping_address_complement: checkoutConfig.customer.shipping_address[0]?.complement,
            }
            return initialValues
        }
        let initialValues = {
            first_name: "",
            last_name: "",
            mobile_phone: "",
            email: "",
            birth_date: null,
            country_code: checkoutConfig?.company.address.country_code,
            document_type: checkoutConfig.checkout_config.checkout_fields.prioritize_b2b ? formSchema[customerCountry].b2bDocument : formSchema[customerCountry].documentsList[0],
            document_number: "",
            billing_address_postal_code: "",
            billing_address_street1: "",
            billing_address_number: "",
            billing_address_state: "",
            billing_address_district: "",
            billing_address_city: "",
            billing_address_country: "",
            billing_address_complement: "",
            shipping_address_postal_code: "",
            shipping_address_street1: "",
            shipping_address_number: "",
            shipping_address_state: "",
            shipping_address_district: "",
            shipping_address_city: "",
            shipping_address_country: "",
            shipping_address_complement: "",
        }
        return initialValues
    }

    const boletoInitialValues = () => {
        if (checkoutConfig.customer) {
            let initialValues = {
                first_name: checkoutConfig.customer.first_name,
                last_name: checkoutConfig.customer.last_name,
                mobile_phone: checkoutConfig.customer.mobile_number || "",
                email: checkoutConfig.customer.email,
                country_code: checkoutConfig.customer.country_code,
                document_type: checkoutConfig.customer.document.type,
                document_number: checkoutConfig.customer.document.number,
                billing_address_postal_code: checkoutConfig.customer.billing_address?.postal_code,
                billing_address_street1: checkoutConfig.customer.billing_address?.street,
                billing_address_number: checkoutConfig.customer.billing_address?.number,
                billing_address_state: checkoutConfig.customer.billing_address?.state,
                billing_address_district: checkoutConfig.customer.billing_address?.district,
                billing_address_city: checkoutConfig.customer.billing_address?.city,
                billing_address_country_code: checkoutConfig.customer.billing_address?.country_code,
                billing_address_complement: checkoutConfig.customer.billing_address?.complement
            }
            return initialValues
        }
        let initialValues = {
            document_type: 'CPF',
            document_number: "",
            billing_address_postal_code: "",
            billing_address_street1: "",
            billing_address_number: "",
            billing_address_state: "",
            billing_address_district: "",
            billing_address_city: "",
            billing_address_country_code: "",
            billing_address_complement: "",
        }
        return initialValues
    }

    const bolecodeInitialValues = () => {
        if (checkoutConfig.customer) {
            let initialValues = {
                first_name: checkoutConfig.customer.first_name,
                last_name: checkoutConfig.customer.last_name,
                mobile_phone: checkoutConfig.customer.mobile_number || "",
                email: checkoutConfig.customer.email,
                country_code: checkoutConfig.customer.country_code,
                document_type: checkoutConfig.customer.document.type,
                document_number: checkoutConfig.customer.document.number,
                billing_address_postal_code: checkoutConfig.customer.billing_address?.postal_code,
                billing_address_street1: checkoutConfig.customer.billing_address?.street,
                billing_address_number: checkoutConfig.customer.billing_address?.number,
                billing_address_state: checkoutConfig.customer.billing_address?.state,
                billing_address_district: checkoutConfig.customer.billing_address?.district,
                billing_address_city: checkoutConfig.customer.billing_address?.city,
                billing_address_country_code: checkoutConfig.customer.billing_address?.country_code,
                billing_address_complement: checkoutConfig.customer.billing_address?.complement
            }
            return initialValues
        }
        let initialValues = {
            document_type: 'CPF',
            document_number: "",
            billing_address_postal_code: "",
            billing_address_street1: "",
            billing_address_number: "",
            billing_address_state: "",
            billing_address_district: "",
            billing_address_city: "",
            billing_address_country_code: "",
            billing_address_complement: "",
        }
        return initialValues
    }

    const CCInitialValues = () => {
        let initialValues = {
            cc_number: "",
            cc_cardholder: "",
            cc_expiry_date: "",
            cvv: "",
            brand: "",
            type: "",
            installments: checkoutConfig.plan.installments && checkoutConfig.plan.installments[0]

        }
        return initialValues
    }



    const [value, setValue] = React.useState(0);

    const checkForErrors = async (form, handleSubmit, formikprops, type) => {
        const customerCustomFields = checkoutConfig.checkout_config.customer_custom_fields?.filter(field => field.is_enabled === true).map(field => `customer_${field.key}`)

        const subscriptionCustomFields = checkoutConfig.checkout_config.subscription_custom_fields?.filter(field => field.is_enabled === true).map(field => `subscription_${field.key}`)

        const values = type === 'boleto'
            ? boletoInitialValues()
            : type === 'credit_card'
                ? CCInitialValues()
                : type === 'bolecode'
                    ? bolecodeInitialValues()
                    : customerInitialValues()

        Object.keys(values).forEach(v => {
            formikprops.setFieldTouched(v, true)
        })

        if (type === 'customer') {
            customerCustomFields.forEach(v => {
                formikprops.setFieldTouched(v, true)
            })

            subscriptionCustomFields.forEach(v => {
                formikprops.setFieldTouched(v, true)
            })
        }
        if (_.isEmpty(formikprops.errors)) return handleSubmit(form)
    }

    const [expanded, setExpanded] = useState({
        customer: true,
        billing: true,
        shipping: true,
        payment: true,
        additional_info: true,
        credit_card: true,
        boleto: false
    })

    const handleExpand = (panel) => {
        if (panel === "boleto") {
            return setExpanded({
                ...expanded,
                boleto: !expanded.boleto,
                credit_card: false
            });
        }
        if (panel === "credit_card") {
            return setExpanded({
                ...expanded,
                credit_card: !expanded.credit_card,
                boleto: false,
            });
        }
        if (panel === "bolecode") {
            return setExpanded({
                ...expanded,
                bolecode: !expanded.bolecode,
                credit_card: false
            });
        }

        return setExpanded({ ...expanded, [panel]: !expanded[panel] });
    };

    const AccordionCustomerConfig = {
        title: (<span className="flex-row center-b">
            <Avatar style={{ marginRight: 10, fontSize: 45, backgroundColor: checkoutConfig.company.style.primary_color }}>
                <PersonIcon style={{ fontSize: 35, color: isBackgroundDark(checkoutConfig.company.style.primary_color) ? 'white' : 'black' }} />
            </Avatar>
            {t.titles['customer.form']}
        </span>),
        name: 'customer',
        flexRowInputs(formikProps) {
            return [
                (formikProps.values["document_type"] !== formSchema[customerCountry].b2bDocument ? ['first_name', 'last_name'] : ['first_name']),
                (checkoutConfig.checkout_config?.checkout_fields?.birth_date ? ['email', 'birth_date'] : ['email']),
                (checkoutConfig.checkout_config?.checkout_fields?.country_code ? ['country_code'] : []),
                (checkoutConfig.checkout_config?.checkout_fields?.document ? ['document_type', 'document_number'] : []),
                ((checkoutConfig.checkout_config?.checkout_fields?.state_tax_number && formikProps.values["document_type"] === "CNPJ") ? ['state_tax_number'] : []),
                ((checkoutConfig.checkout_config?.checkout_fields?.city_tax_number && formikProps.values["document_type"] === "CNPJ") ? ['city_tax_number'] : []),
                (checkoutConfig.checkout_config?.checkout_fields?.mobile_number ? ['mobile_phone'] : []),
                ['customer_custom_fields'],
            ]
        },
        expanded: expanded.customer,
        handleExpand: () => handleExpand('customer'),
        disabled: (formStep !== 'customer'),
        TCenabled: (
            checkoutConfig.checkout_config.terms_conditions?.is_enabled &&
            (!checkoutConfig.checkout_config?.checkout_fields.billing_address &&
                !isSubscriptionCustomEnabled)
        ),
        loading: loadingState.customer,
        buttons: [
            ((!checkoutConfig.checkout_config?.checkout_fields.billing_address && !isSubscriptionCustomEnabled && formStep === 'customer') && {
                text: t.buttons['checkout.continue'],
                type: 'submit',
                disabled: isSubmitting
            })
        ],
        disabledInputs: disabledInputsCustomer(),
        shadow: true
    }

    const AccordionAdditionalInfoConfig = {
        title: (<span className="flex-row center-b">
            <Avatar style={{ marginRight: 10, fontSize: 45, backgroundColor: checkoutConfig.company.style.primary_color }}>
                <PriorityHighIcon style={{ fontSize: 35, color: isBackgroundDark(checkoutConfig.company.style.primary_color) ? 'white' : 'black' }} />
            </Avatar>
            {t.titles['additional_info.form']}
        </span>),
        name: 'additional_info',
        rowInputs: [
            ['subscription_custom_fields']

        ],
        expanded: expanded.additional_info,
        handleExpand: () => handleExpand('additional_info'),
        disabled: (formStep !== 'customer'),
        disabledInputs: disabledInputsCustomer(),
        TCenabled: (checkoutConfig.checkout_config.terms_conditions?.is_enabled && (!checkoutConfig.checkout_config?.checkout_fields.billing_address)),
        loading: loadingState.customer,
        buttons: [
            ((!checkoutConfig.checkout_config?.checkout_fields.billing_address && formStep === 'customer') && {
                text: t.buttons['checkout.continue'],
                type: 'submit',
                disabled: isSubmitting
            })
        ],
        shadow: true
    }

    const AccordionBillingConfig = {
        title: (<span className="flex-row center-b">
            <AttachMoneyIcon style={{ paddingRight: 10, fontSize: 45 }} />
            {t.titles['billing_address.form']}
        </span>),
        name: 'billing',
        rowInputs: formSchema[customerCountry].billingAddressList,
        expanded: expanded.billing,
        loading: loadingState.customer,
        handleExpand: () => handleExpand('billing'),
        disabled: (formStep !== 'customer'),
        TCenabled: (checkoutConfig.checkout_config.terms_conditions?.is_enabled && !checkoutConfig.checkout_config?.checkout_fields.shipping_address),
        buttons: [
            (!checkoutConfig.checkout_config?.checkout_fields.shipping_address && formStep === 'customer' && {
                text: t.buttons['checkout.continue'],
                type: 'submit',
                disabled: isSubmitting
            })
        ],
        shadow: true
    }

    const AccordionShippingConfig = (formikprops) => ({
        title: (<span className="flex-row center-b">
            <LocalShippingIcon style={{ paddingRight: 10, fontSize: 45 }} />
            {t.titles['shipping_address.form']}
        </span>),
        name: 'shipping',
        loading: loadingState.customer,
        expanded: expanded.shipping,
        handleExpand: () => handleExpand('shipping'),
        disabled: (formStep !== 'customer'),
        TCenabled: (checkoutConfig.checkout_config.terms_conditions?.is_enabled),
        buttons: [
            (formStep === 'customer' && {
                text: t.buttons['checkout.continue'],
                type: 'submit',
                disabled: isSubmitting

            })
        ],
        shadow: true,
        customRender: (
            <div>
                <ThemeProvider theme={theme}>
                    <div className='flex-row' style={{ alignItems: 'center' }}>
                        <Checkbox aria-labelledby='use_billing_as_shipping_label' checked={useBillingAsShipping} onChange={() => setUseBillingAsShipping(!useBillingAsShipping)} color="primary" />
                        <label id="use_billing_as_shipping_label">
                            {t.buttons['checkbox.enabled_shipping_address']}
                        </label>
                    </div>
                </ThemeProvider>
                <div className={`shipping-address${!useBillingAsShipping ? 'in' : ""}`}>
                    {!useBillingAsShipping &&
                        <RenderInputsV4
                            rows={formSchema[customerCountry]?.shippingAddressList}
                            formikprops={formikprops}
                            checkoutConfig={checkoutConfig}
                        />
                    }
                </div>
            </div>
        )
    })

    const AccordionPaymentConfig = {
        title: (<span className="flex-row center-b">
            <Avatar style={{ marginRight: 10, fontSize: 45, backgroundColor: checkoutConfig.company.style.primary_color }}>
                <AttachMoneyIcon style={{ fontSize: 35, color: isBackgroundDark(checkoutConfig.company.style.primary_color) ? 'white' : 'black' }} />
            </Avatar>
            {t.titles['payment_mean.form']}
        </span>),
        name: 'payment',
        style: {
            padding: 0
        },
        customRender: (
            <div style={{ width: '100%' }}>
                <div className="flex-row" style={{ backgroundColor: "#F6F6F6", width: '100%' }}>
                    {checkoutConfig.plan.payment_methods.map((method, index) => {
                        switch (method) {
                            case 'credit_card':
                                return (
                                    <div className={`flex-col payment-button ${value === index && "button-selected"} center-b`} role="button" onClick={() => setValue(index)}>
                                        <CreditCardIcon style={{ fontSize: 45 }} />
                                        {t.titles['payment_method.credit_card']}
                                    </div>

                                )
                            case 'boleto':
                                return (
                                    <div className={`flex-col payment-button ${value === index && "button-selected"} center-b`} role="button" onClick={() => setValue(index)}>
                                        <AiOutlineBarcode style={{ fontSize: 45 }} />
                                        {t.titles['payment_method.boleto']}
                                    </div>

                                )
                            case 'bolecode':
                                return (
                                    <div className={`flex-col payment-button ${value === index && "button-selected"} center-b`} role="button" onClick={() => setValue(index)}>
                                        <AiOutlineBarcode style={{ fontSize: 45 }} />
                                        {t.titles['payment_method.bolecode']}
                                    </div>

                                )
                            case 'pix':
                                return (
                                    <div className={`flex-col payment-button ${value === index && "button-selected"} center-b`} role="button" onClick={() => setValue(index)}>
                                        <Icon icon="ic:baseline-pix" style={{ fontSize: '45px' }} />
                                        {"PIX"}
                                    </div>
                                )
                            case 'sdd':
                                return (
                                    <div className={`flex-col payment-button ${value === index && "button-selected"} center-b`} style={{ opacity: isSubmitting ? "0.8" : "1", pointerEvents: isSubmitting && "none" }} role="button" onClick={() => setValue(index)}>
                                        <img src={logoSEPA} height={60} />
                                    </div>
                                )
                            default:
                                return
                        }
                    })}


                </div>

                {checkoutConfig.plan.payment_methods.map((method, index) => {
                    switch (method) {
                        case 'credit_card':
                            return (
                                <Formik
                                    initialValues={CCInitialValues()}
                                    validate={values => FormikValidationsCCPayment(values, checkoutConfig, t)}
                                    onSubmit={props => onCCPaymentSubmit(props)}
                                >
                                    {({
                                        handleSubmit,
                                        ...formikprops
                                    }) => (
                                        <TabPanel value={value} index={index}>
                                            <Typography
                                                style={{ padding: 10 }}
                                                component="div"
                                                role="tabpanel"
                                                hidden={value !== index}
                                                id={`simple-tabpanel-${index}`}
                                                aria-labelledby={`simple-tab-${index}`}
                                            >
                                                <form onSubmit={(form) => checkForErrors(form, handleSubmit, formikprops, 'credit_card')} style={{ width: '100%' }} action="javascript:void(0)">
                                                    <div className="flex-row center-a">
                                                        <div className="payment-fields">
                                                            {checkoutConfig.use_form_token ? (
                                                                <div className="flex-row center-a">
                                                                    <div id="form-payzen">
                                                                        <div className="kr-smart-form" kr-card-form-expanded />
                                                                    </div>
                                                                    <RenderPayzenForm
                                                                        gatewayConfig={
                                                                            checkoutConfig.gateway_config
                                                                        }
                                                                        setSuccess={
                                                                            (clientAnswer) => setSuccessPage({
                                                                                type: "credit_card", customer
                                                                            })
                                                                        }
                                                                        subscriptionId={
                                                                            customer.subscription_id
                                                                        }
                                                                        couponId={
                                                                            couponId
                                                                        }
                                                                        setIsSubmitting={setIsSubmitting}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {checkoutConfig.plan.installments?.length > 0 &&
                                                                        <div key={`row-`} className="flex-row xs-wrap">
                                                                            <div key={`input`} className="flex-col" style={{ width: '100%', margin: 10 }}>
                                                                                <FormControl variant="outlined" >
                                                                                    <InputLabel id="demo-simple-select-required-label">{t.forms.installments}</InputLabel>
                                                                                    <Select label={t.forms.installments} defaultValue={checkoutConfig.plan.installments && checkoutConfig.plan.installments[0]} name="installments" value={formikprops.values.installments} onChange={formikprops.handleChange} fullWidth variant="outlined">
                                                                                        {checkoutConfig.plan.installments.map(installment => (
                                                                                            <MenuItem value={installment}>
                                                                                                <SectionMain position="space-between">
                                                                                                    <div>
                                                                                                        {`${installment}x`}
                                                                                                    </div>
                                                                                                    <FormLabel component="legend">
                                                                                                        {t.generics.in}{" "}
                                                                                                        {
                                                                                                            installment
                                                                                                        }

                                                                                                        x{" "}
                                                                                                        {toCurrency(
                                                                                                            (totalAmount / installment).toFixed(0),
                                                                                                            "BRL"
                                                                                                        )}
                                                                                                    </FormLabel>
                                                                                                </SectionMain>
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <RenderInputsV4
                                                                        formikprops={formikprops}
                                                                        checkoutConfig={checkoutConfig}
                                                                        rows={[
                                                                            ['cc_number'],
                                                                            ['cc_expiry_date', 'cvv'],
                                                                            ['cc_cardholder'],
                                                                        ]} />
                                                                    <div style={{ padding: "0 10px 0 10px" }}>
                                                                        <Button
                                                                            style={{
                                                                                backgroundColor: checkoutConfig.company?.style.primary_color ?
                                                                                    checkoutConfig.company?.style.primary_color
                                                                                    : '#fff',
                                                                                color: isBackgroundDark(checkoutConfig.company?.style.primary_color || '#fff') ? 'white' : 'black',
                                                                                width: '100%',
                                                                                height: 50,
                                                                                fontSize: 15
                                                                            }}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            size="small"
                                                                            type='submit'
                                                                        >
                                                                            {loadingState.payment &&
                                                                                <span style={{ marginRight: "10px" }}>
                                                                                    <Loader white size={30} />
                                                                                </span>
                                                                            }
                                                                            {t.buttons['checkout.paid']}
                                                                        </Button>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <TestForm checkoutConfig={checkoutConfig} value={value} formikprops={formikprops} formStep={formStep} />

                                                </form>
                                            </Typography>
                                        </TabPanel>
                                    )}
                                </Formik>

                            )
                        case 'boleto':
                            return (
                                <Formik
                                    initialValues={boletoInitialValues()}
                                    validate={values => FormikValidationsBoletoPayment(values, checkoutConfig, t)}
                                    onSubmit={props => onBoletoPaymentSubmit(props)}
                                >
                                    {({
                                        handleSubmit,
                                        ...formikprops
                                    }) => (
                                        <TabPanel value={value} index={index}>
                                            <Typography
                                                style={{ padding: 10 }}
                                                component="div"
                                                role="tabpanel"
                                                hidden={value !== index}
                                                id={`simple-tabpanel-${index}`}
                                                aria-labelledby={`simple-tab-${index}`}
                                            >
                                                <form onSubmit={(form) => checkForErrors(form, handleSubmit, formikprops, 'boleto')} style={{ width: '100%' }} action="javascript:void(0)">
                                                    {!checkoutConfig.checkout_config?.checkout_fields.document && (
                                                        <>
                                                            <span style={{ marginLeft: 10 }}>
                                                                {t.forms.document_type}:
                                                            </span>
                                                            <RenderInputsV4
                                                                checkoutConfig={checkoutConfig}
                                                                disabledInputs={disabledInputsBoleto()}
                                                                formikprops={formikprops}
                                                                rows={[
                                                                    ['document_type', 'document_number']
                                                                ]} />
                                                        </>
                                                    )}

                                                    {!checkoutConfig.checkout_config?.checkout_fields.billing_address && (
                                                        <>
                                                            <span style={{ marginLeft: 10 }}>
                                                                {t.titles['billing_address.form']}:
                                                            </span>
                                                            <RenderInputsV4
                                                                formikprops={formikprops}
                                                                rows={[
                                                                    ['billing_address_postal_code'],
                                                                    ['billing_address_street1'],
                                                                    ['billing_address_number', 'billing_address_district'],
                                                                    ['billing_address_city', 'billing_address_state_br'],
                                                                    ['billing_address_complement']
                                                                ]} />

                                                        </>

                                                    )}

                                                    <div className="flex-row center-a" style={{ margin: 20, fontWeight: 'bold' }}>
                                                        {t.titles['total.boleto']}: {(totalAmount / 100).toLocaleString('pt-BR', {
                                                            style: 'currency',
                                                            currency: 'BRL'
                                                        })}
                                                    </div>

                                                    <div style={{ padding: "0 10px 0 10px" }}>

                                                        <Button
                                                            style={{
                                                                backgroundColor: checkoutConfig.company?.style.primary_color ?
                                                                    checkoutConfig.company?.style.primary_color
                                                                    : '#fff',
                                                                color: isBackgroundDark(checkoutConfig.company?.style.primary_color || '#fff') ? 'white' : 'black',
                                                                width: '100%',
                                                                height: 50,
                                                                fontSize: 15
                                                            }}
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            type='submit'
                                                        >
                                                            {loadingState.payment &&
                                                                <span style={{ marginRight: "10px" }}>
                                                                    <Loader white size={30} />
                                                                </span>
                                                            }
                                                            {t.buttons['checkout.paid']}
                                                        </Button>
                                                    </div>
                                                    <TestForm checkoutConfig={checkoutConfig} value={value} formikprops={formikprops} formStep={formStep} />
                                                </form>
                                            </Typography>
                                        </TabPanel>
                                    )}
                                </Formik>
                            )
                        case 'bolecode':
                            return (
                                <Formik
                                    initialValues={boletoInitialValues()}
                                    validate={values => FormikValidationsBoletoPayment(values, checkoutConfig, t)}
                                    onSubmit={props => onBolecodePaymentSubmit(props)}
                                >
                                    {({
                                        handleSubmit,
                                        ...formikprops
                                    }) => (
                                        <TabPanel value={value} index={index}>
                                            <Typography
                                                style={{ padding: 10 }}
                                                component="div"
                                                role="tabpanel"
                                                hidden={value !== index}
                                                id={`simple-tabpanel-${index}`}
                                                aria-labelledby={`simple-tab-${index}`}
                                            >
                                                <form onSubmit={(form) => checkForErrors(form, handleSubmit, formikprops, 'boleto')} style={{ width: '100%' }} action="javascript:void(0)">
                                                    {!checkoutConfig.checkout_config?.checkout_fields.document && (
                                                        <>
                                                            <span style={{ marginLeft: 10 }}>
                                                                {t.forms.document_type}:
                                                            </span>
                                                            <RenderInputsV4
                                                                checkoutConfig={checkoutConfig}
                                                                disabledInputs={disabledInputsBoleto()}
                                                                formikprops={formikprops}
                                                                rows={[
                                                                    ['document_type', 'document_number']
                                                                ]} />
                                                        </>
                                                    )}

                                                    {!checkoutConfig.checkout_config?.checkout_fields.billing_address && (
                                                        <>
                                                            <span style={{ marginLeft: 10 }}>
                                                                {t.titles['billing_address.form']}:
                                                            </span>
                                                            <RenderInputsV4
                                                                formikprops={formikprops}
                                                                rows={[
                                                                    ['billing_address_postal_code'],
                                                                    ['billing_address_street1'],
                                                                    ['billing_address_number', 'billing_address_district'],
                                                                    ['billing_address_city', 'billing_address_state_br'],
                                                                    ['billing_address_complement']
                                                                ]} />

                                                        </>

                                                    )}

                                                    <div className="flex-row center-a" style={{ margin: 20, fontWeight: 'bold' }}>
                                                        {t.titles['total.boleto']}: {(totalAmount / 100).toLocaleString('pt-BR', {
                                                            style: 'currency',
                                                            currency: 'BRL'
                                                        })}
                                                    </div>

                                                    <div style={{ padding: "0 10px 0 10px" }}>

                                                        <Button
                                                            style={{
                                                                backgroundColor: checkoutConfig.company?.style.primary_color ?
                                                                    checkoutConfig.company?.style.primary_color
                                                                    : '#fff',
                                                                color: isBackgroundDark(checkoutConfig.company?.style.primary_color || '#fff') ? 'white' : 'black',
                                                                width: '100%',
                                                                height: 50,
                                                                fontSize: 15
                                                            }}
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            type='submit'
                                                        >
                                                            {loadingState.payment &&
                                                                <span style={{ marginRight: "10px" }}>
                                                                    <Loader white size={30} />
                                                                </span>
                                                            }
                                                            {t.buttons['checkout.paid']}
                                                        </Button>
                                                    </div>
                                                    <TestForm checkoutConfig={checkoutConfig} value={value} formikprops={formikprops} formStep={formStep} />
                                                </form>
                                            </Typography>
                                        </TabPanel>
                                    )}
                                </Formik>
                            )
                        case 'pix':
                            return (
                                <Formik
                                    initialValues={boletoInitialValues()}
                                    validate={values => FormikValidationsPixPayment(values, checkoutConfig, t)}
                                    onSubmit={props => onPixPaymentSubmit(props)}
                                >
                                    {({
                                        handleSubmit,
                                        ...formikprops
                                    }) => (
                                        <TabPanel value={value} index={index}>
                                            <Typography
                                                style={{ padding: 10 }}
                                                component="div"
                                                role="tabpanel"
                                                hidden={value !== index}
                                                id={`simple-tabpanel-${index}`}
                                                aria-labelledby={`simple-tab-${index}`}
                                            >
                                                <form onSubmit={(form) => checkForErrors(form, handleSubmit, formikprops, 'boleto')} style={{ width: '100%' }} action="javascript:void(0)">
                                                    {!checkoutConfig.checkout_config?.checkout_fields.document && (
                                                        <>
                                                            <span style={{ marginLeft: 10 }}>
                                                                {t.forms.document_type}:
                                                            </span>
                                                            <RenderInputsV4
                                                                checkoutConfig={checkoutConfig}
                                                                disabledInputs={disabledInputsBoleto()}
                                                                formikprops={formikprops}
                                                                rows={[
                                                                    ['document_type', 'document_number']
                                                                ]} />
                                                        </>
                                                    )}

                                                    <div className="flex-row center-a" style={{ margin: 20, fontWeight: 'bold' }}>
                                                        {t.titles['total.boleto']}: {(totalAmount / 100).toLocaleString('pt-BR', {
                                                            style: 'currency',
                                                            currency: 'BRL'
                                                        })}
                                                    </div>

                                                    <div style={{ padding: "0 10px 0 10px" }}>

                                                        <Button
                                                            style={{
                                                                backgroundColor: checkoutConfig.company?.style.primary_color ?
                                                                    checkoutConfig.company?.style.primary_color
                                                                    : '#fff',
                                                                color: isBackgroundDark(checkoutConfig.company?.style.primary_color || '#fff') ? 'white' : 'black',
                                                                width: '100%',
                                                                height: 50,
                                                                fontSize: 15
                                                            }}
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            type='submit'
                                                        >
                                                            {loadingState.payment &&
                                                                <span style={{ marginRight: "10px" }}>
                                                                    <Loader white size={30} />
                                                                </span>
                                                            }
                                                            {t.buttons['checkout.paid']}
                                                        </Button>
                                                    </div>
                                                    <TestForm checkoutConfig={checkoutConfig} value={value} formikprops={formikprops} formStep={formStep} />
                                                </form>
                                            </Typography>
                                        </TabPanel>
                                    )}
                                </Formik>
                            )
                        case 'sdd':
                            return (
                                <Typography
                                    style={{ padding: 10 }}
                                    component="div"
                                    role="tabpanel"
                                    hidden={value !== index}
                                    id={`simple-tabpanel-${index}`}
                                    aria-labelledby={`simple-tab-${index}`}
                                >
                                    <div className="flex-row center-a" style={{ width: '100%' }}>
                                        <form
                                            id="sdd-form"
                                            method="POST"
                                            action="https://secure.payzen.eu/vads-payment/"
                                            target="sdd"
                                            autoComplete="new-password"
                                            onSubmit={() => {
                                                setIsSubmitting(true)
                                                setShowIFrame(true)
                                            }}
                                            className="flex-col center-b"
                                            style={{ width: '100%' }}
                                        >
                                            {sddInputs.map(
                                                ({ name, value, }) => {
                                                    return (
                                                        <input
                                                            key={name}
                                                            hidden
                                                            name={name}
                                                            value={value}
                                                        />
                                                    )
                                                }
                                            )}
                                            <div>
                                                {!showIFrame && <Button
                                                    target="sdd-form"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"

                                                >
                                                    {t.buttons["checkout.continue"]}
                                                </Button>}
                                            </div>
                                            {showIFrame && (
                                                <>
                                                    <iframe
                                                        id="sdd"
                                                        name="sdd"
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                            height: 600,
                                                            width: "100%",
                                                            display: "flex",
                                                            border: "none",
                                                        }}
                                                    />
                                                    <span style={{ display: "none" }}>
                                                        {setTimeout(() => removeIframe(), 600000)}
                                                    </span>
                                                </>
                                            )}
                                        </form>
                                    </div>
                                </Typography>
                            )
                        default:
                            return
                    }
                })}
                {/* <FormAccordion config={AccordionCCConfig} checkoutConfig={checkoutConfig} /> */}
                {/* {checkoutConfig.plan?.payment_methods.includes('boleto') && <FormAccordion config={AccordionBoletoConfig} checkoutConfig={checkoutConfig} />} */}
            </div >
        ),

        expanded: expanded.payment,
        loading: loadingState.payment,
        handleExpand: () => handleExpand('payment'),
        shadow: true


    }

    useEffect(() => {
        if (checkoutConfig.customer) {
            setCustomer(checkoutConfig.customer)
        }
    }, [])


    return (
        <div className="flex-col" style={{ width: '100%' }}>
            <Formik
                initialValues={customerInitialValues()}
                validate={values => FormikValidationsCustomer(values, checkoutConfig, t, useBillingAsShipping)}
                onSubmit={props => onCustomerSubmit(props)}
            >
                {({
                    handleSubmit,
                    ...formikprops
                }) => (
                    <form onSubmit={(form) => checkForErrors(form, handleSubmit, formikprops, 'customer')} style={{ width: '100%' }} action="javascript:void(0)">
                        <FormAccordion config={AccordionCustomerConfig} checkoutConfig={checkoutConfig} formikprops={{ ...formikprops, setCustomerCountry }} />
                        {isSubscriptionCustomEnabled && <FormAccordion config={AccordionAdditionalInfoConfig} checkoutConfig={checkoutConfig} formikprops={formikprops} />}
                        {checkoutConfig.checkout_config?.checkout_fields.billing_address && <FormAccordion config={AccordionBillingConfig} checkoutConfig={checkoutConfig} formikprops={formikprops} />}
                        {checkoutConfig.checkout_config?.checkout_fields.shipping_address && <FormAccordion config={AccordionShippingConfig(formikprops)} checkoutConfig={checkoutConfig} formikprops={formikprops} />}
                    </form>
                )}
            </Formik>
            <div className="flex-col center-a center-b" style={{ width: '100%', wordBreak: 'break-all' }}>
                <ReCaptcha
                    size="invisible"
                    ref={refCaptcha}
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                />
            </div>
            {formStep === 'payment' && <FormAccordion config={AccordionPaymentConfig} checkoutConfig={checkoutConfig} />}
        </div>
    )
}

export default BRForm

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div className="fade-in">
                        {children}
                    </div>
                </Box>
            )}
        </div>
    );
}