import React, { useState, useEffect } from 'react'
import Title, { TitleSmall } from '../../../components/checkout-1.5.4/forms/Title'
import SectionMain from '../../../layout/SectionMain'
import api from '../../../core/api'
import Loader from '../../../components/Loader'
import ParticlesBg from 'particles-bg'
import { lightOrDark } from '../../../utils/lightOrDark'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	CircularProgress,
	InputAdornment,
	makeStyles,
	Link
} from '@material-ui/core'
import TextInput from '../../../components/checkout-1.5.4/forms/TextInput'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Formik } from 'formik'
import { string, object } from 'yup' // for only what you need
import ButtonCard from '../../components/cards/ButtonCard'
import { useSnackbar } from 'notistack'
import { canUseDOM } from 'react-helmet'
import { useTranslation } from '../../../components/IntlContext/IntlContext'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { handleLocaleChange } from '../../../locale/handleLocaleChange'
import AppContext, { useAppContext } from '../../../components/AppContextCheckout'

const useStyles = makeStyles(() => ({
	root: {
		marginTop: -100
	}
}))

/**@returns {import("../../../locale/locale").TranslationFunction} */
let language = { BRA: 'pt-BR', FRA: 'fr' }

const ResetPage = ({ history }) => {
	const { locale, setAppState } = useAppContext(AppContext)

	const [company, setCompany] = useState('')
	const [response, setResponse] = useState('')
	const [showPassword, setShowPassword] = useState({ password: false, newPassword: false })
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const t = useTranslation()

	const token = new URLSearchParams(window.location.search).get('token')

	const firstAccess = new URLSearchParams(window.location.search).get('firstAccess')

	const fetchCompanyConfig = async () => {
		try {
			await api
				.get('/company-config')
				.then((res) => {
					const company = res.data
					setCompany(company)
					return company.country
				})
				.then((country) => handleLocaleChange(language[country], setAppState))
		} catch (e) {
			if (e.message.includes('Network')) {
				setResponse(t.errors['get_company_config.network_error'])
			}
			console.log({ e })
		}
	}
	const handleClickShowPassword = (id) => {
		setShowPassword(id)
	}

	const handleMouseDownPassword = (event) => {
		event.preventDefault()
	}

	const validationSchema = () =>
		object().shape({
			password: string().min(6, t.forms_validation.password_valid).required(t.forms_validation.password_required),
			confirm_password: string()
				.min(6, t.forms_validation.password_valid)
				.required(t.forms_validation.password_required)
		})

	const onSubmit = async (values) => {
		const form = { ...values, token }
		if (form.password !== form.confirm_password) {
			enqueueSnackbar(t.errors['reset.no_equals_password'], {
				variant: 'error'
			})
			return
		}
		try {
			await api.post(`/login/reset`, form).then((res) => {
				if (res.status === 200) {
					enqueueSnackbar(t.messages['reset.success'], {
						variant: 'success'
					})
					setResponse(res)
					// history.push('/')
				} else {
					throw res
				}
			})
		} catch (e) {
			console.log({ e })

			if (e.response.status === 401) {
				enqueueSnackbar(t.errors['login.server_error'], {
					variant: 'error'
				})
			} else {
				enqueueSnackbar(t.errors['login.server_error'], {
					variant: 'error'
				})
			}
		}
	}

	useEffect(() => {
		fetchCompanyConfig()
	}, [])

	if (!company) {
		return (
			<div
				className="flex-col center-a center-b"
				style={{
					width: '100vw',
					height: '100vh'
				}}
			>
				<Loader />
				{response && <h3 style={{ color: 'red', textAlign: 'center' }}>{response}</h3>}
			</div>
		)
	}

	return (
		<SectionMain style={{ overflow: 'hidden', height: '100vh' }} noPadding position="center">
			<style>
				{`#banner {
	opacity: 0.5;
	background: ${company.style.primary_color}; /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, ${company.style.primary_color}, ${company.style
						.primary_color}66); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, ${company.style.primary_color}, ${company.style.primary_color}66);
}
.MuiBackdrop-root{
    background-color: transparent !important;
}
`}
			</style>
			<Formik
				initialValues={{ password: '', confirm_password: '' }}
				validationSchema={validationSchema}
				onSubmit={(values) => onSubmit(values)}
			>
				{(formikProps) => (
					<Dialog open={true} maxWidth="sm" PaperProps={{ elevation: 3 }} style={{ borderRadius: 12 }} className={classes.root}>
						{response.status === 200 ? (
							<SectionMain
								position="center"
								alignItems="center"
								direction="column"
								noPadding
								style={{ padding: 20 }}
							>
								<SectionMain noPadding position="center">
									<CheckCircleIcon
										style={{
											fontSize: 150,
											color: 'rgba(0, 230, 64, 0.8)'
										}}
									/>
								</SectionMain>
								<SectionMain position="center">
									<TitleSmall bold align="center" color="#282828">
										{t.messages['reset.success']}
									</TitleSmall>
								</SectionMain>
								<Link href="/login"> {t.navigation_menu['go_login']}</Link>
							</SectionMain>
						) : (
							<form action="javascript:void(0)" onSubmit={formikProps.handleSubmit}>
								<DialogTitle>
									<SectionMain position="center" noPadding>
										<div
											style={{
												backgroundImage: `url(${company.style.url_logo_default})`,
												backgroundSize: 'contain',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												width: '100%',
												height: '10vw',
												maxHeight: 100,
												marginBottom: 10
											}}
										/>
										<TitleSmall align="center" color={company.style.primary_color} fontSize={18}>
											{t.titles.customer_portal}
										</TitleSmall>
									</SectionMain>
								</DialogTitle>
								<DialogContent>
									<SectionMain position="center" noPadding>
										<SectionMain>
											<TextInput
												id="password"
												type={showPassword.password ? 'text' : 'password'}
												helperText={
													formikProps.touched.password && (
														<span style={{ color: 'red' }}>
															{formikProps.errors.password}
														</span>
													)
												}
												label={
													firstAccess ? (
														t.titles['password.reset']
													) : (
														t.titles['new_password.reset']
													)
												}
												errorMessage={
													formikProps.touched.password && formikProps.errors.password
												}
												value={formikProps.values.password}
												autoComplete="password"
												onChange={(e) => formikProps.setFieldValue('password', e.target.value)}
												autoFocus
												InputProps={{
													endAdornment: (
														<InputAdornment
															style={{ cursor: 'pointer' }}
															position="end"
															onClick={() =>
																handleClickShowPassword({
																	...showPassword,
																	password: !showPassword.password
																})}
															onMouseDown={handleMouseDownPassword}
														>
															{showPassword.password ? (
																<Visibility style={{ opacity: 1 }} />
															) : (
																<VisibilityOff style={{ opacity: 0.5 }} />
															)}
														</InputAdornment>
													)
												}}
											/>
										</SectionMain>
										<SectionMain>
											<TextInput
												id="confirm_password"
												type={showPassword.newPassword ? 'text' : 'password'}
												label={
													firstAccess ? (
														t('^titles.confirm_password.reset')
													) : (
														t('^titles.confirm_password.reset', 'a')
													)
												}
												errorMessage={
													formikProps.touched.confirm_password &&
													formikProps.errors.confirm_password
												}
												helperText={
													formikProps.touched.confirm_password && (
														<span style={{ color: 'red' }}>
															{formikProps.errors.confirm_password}
														</span>
													)
												}
												value={formikProps.values.confirm_password}
												autoComplete="confirm_password"
												onChange={(e) =>
													formikProps.setFieldValue('confirm_password', e.target.value)}
												InputProps={{
													endAdornment: (
														<InputAdornment
															style={{ cursor: 'pointer' }}
															position="end"
															onClick={() =>
																handleClickShowPassword({
																	...showPassword,
																	newPassword: !showPassword.newPassword
																})}
															onMouseDown={handleMouseDownPassword}
														>
															{showPassword.newPassword ? (
																<Visibility style={{ opacity: 1 }} />
															) : (
																<VisibilityOff style={{ opacity: 0.5 }} />
															)}
														</InputAdornment>
													)
												}}
											/>
										</SectionMain>

										<SectionMain>
											<Button
												fullWidth
												type="submit"
												variant="contained"
												color="primary"
												disabled={formikProps.isSubmitting}
												style={{
													color:
														company.style.primary_color &&
															lightOrDark(company.style.primary_color) === 'light'
															? '#282828'
															: '#fff',
													alignItems: 'center',
													backgroundColor: company.style.primary_color && company.style.primary_color
												}}
											>
												{formikProps.isSubmitting && (
													<CircularProgress
														size={24}
														color="inherit"
														style={{ marginRight: 8 }}
													/>
												)}
												{t.generics.save}
											</Button>
										</SectionMain>
									</SectionMain>
								</DialogContent>
							</form>
						)}
					</Dialog>
				)}
			</Formik>
		</SectionMain>
	)
}

export default ResetPage
