import React, { memo, useState } from 'react'
import { Paper, Button, Hidden, IconButton, Menu, MenuItem, Slide, Dialog, AppBar } from '@material-ui/core'
import SectionMain from '../../../../layout/SectionMain'
import Title, { TitleSmall } from '../../../../components/checkout-1.5.4/forms/Title'
import ButtonCard from '../../../../portal/components/cards/ButtonCard'
import StatusCard from '../../../../portal/components/cards/StatusCard'
import PricingOptionListItem from '../../../../portal/components/cards/PricingOptionListItem'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import { toCurrency } from '../../../../utils/commonMasks'
import useLocalDateFunction from '../../../../utils/useLocalDateFunction'
import moment from 'moment'
import { getBrand } from '../../../../core/cards-utils'
import { useTranslation } from '../../../../components/IntlContext/IntlContext'
import TextInput from '../../../../components/checkout-1.5.4/forms/TextInput'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CardSubscriptionWrapper from './CardSubscriptionWrapper'
import CardSubscriptionUI from './CardSubscriptionUI'

const CardSubscription = ({
	subscription,
	type = 'default',
	handleToggle,
	handleSubscription,
	history,
	onCancelSubscritption
}) => {
	return (
		<CardSubscriptionWrapper
			subscription={subscription}
			type={type}
			handleToggle={handleToggle}
			handleSubscription={handleSubscription}
			onCancelSubscritption={onCancelSubscritption}
		>
			{({ subscription, type, handleToggle, handleSubscription, sales, onCancelSubscritption }) => (
				<CardSubscriptionUI
					handleSubscription={handleSubscription}
					subscription={subscription}
					type={type}
					handleToggle={handleToggle}
					history={history}
					sales={sales}
					onCancelSubscritption={onCancelSubscritption}
				/>
			)}
		</CardSubscriptionWrapper>
	)
}

export default memo(CardSubscription)
