import getLocale from "../locale"
export const handleLocaleChange = async (language, setAppState) => {
	if (!language) {
		const lang = localStorage.getItem("locale")
		const { locale } = await getLocale(lang)
		setAppState({ language, locale })
	} else {
		const { locale } = await getLocale(language)
		localStorage.setItem("locale", language)
		setAppState({ lang: language, language, locale })
	}
}
