import React from 'react'
import { useTranslation } from '../../../../components/IntlContext/IntlContext'
import getStatusColor from '../../../../utils/getStatusColor'

const StatusCard = ({ status }) => {
	const t = useTranslation()

	return (
		<div
			className="flex-row center-a center-b"
			style={{
				padding: '0 15px',
				fontSize: 12,
				textTransform: 'uppercase',
				color: getStatusColor(status),
				border: `1px solid`,
				borderColor: getStatusColor(status),
				borderRadius: 5
			}}
		>
			{t.status_subscriptions[status]}
		</div>
	)
}

export default StatusCard
