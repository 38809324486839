import React, { useCallback, useEffect, useState } from "react"
import {
	CircularProgress,
	Container,
	Hidden,
	InputAdornment,
	makeStyles,
	MenuItem,
	Button,
} from "@material-ui/core"
import SectionMain from "../../../layout/SectionMain"
import bg_login from "../../../assets/bg_login.png"
import Title, {
	TitleSmall,
} from "../../../components/checkout-1.5.4/forms/Title"
import { Formik } from "formik"
import TextInput from "../../../components/checkout-1.5.4/forms/TextInput"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { useTranslation } from "../../../components/IntlContext/IntlContext"
import { string, object } from "yup" // for only what you need
import { useSnackbar } from "notistack"
import AppContext, {
	useAppContext,
} from "../../../components/AppContextCheckout"
import api from "../../../core/api"
import { handleLocaleChange } from "../../../locale/handleLocaleChange"
import Loader from "../../../components/Loader"
import { Helmet } from "react-helmet"
import { CustomerService } from "../../../core/CustomerService"

const useStyles = makeStyles((theme) => ({
	root: {
		overflow: "hidden",
		height: "100vh",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	wallpaper: {
		display: "flex",
		backgroundImage: `url(${bg_login})`,
		backgroundSize: "100%",
		backgroundRepeat: "no-repeat",
	},
	loginForm: {
		padding: "36px",
		justifyContent: "center",
		boxShadow: theme.shadows[3],
		borderRadius: '8px',
	},
	logo: {
		justifyContent: "center",
		width: 200,
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		height: 150,
		marginBottom: "32px"
	},
	inputs: {
		height: 200,
	},
	text: {
		height: 80,
		textAlign: "center",
	},
	title: {
		fontFamily: "Open Sans",
		paddingBottom: 16,
		backgroundColor: ({ primaryColor }) => primaryColor && primaryColor,
	},

	bgInput: {
		display: "flex",
		width: "100%",
		backgroundColor: "#fff",
		height: 56,
		borderRadius: 4,
	},
	submitButton: {
		width: 136,
		height: 46,
		borderRadius: 4,
		border: "none",
	},
}))

/**@returns {import("../../../locale/locale").TranslationFunction} */
let language = { BRA: "pt-BR", FRA: "fr" }

const LoginPage = ({ history }) => {
	const { locale, setAppState } = useAppContext(AppContext)
	const [company, setCompany] = useState("")
	const [response, setResponse] = useState("")
	const [showPassword, setShowPassword] = useState(false)
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const t = useTranslation()

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword)
	}

	const handleMouseDownPassword = (event) => {
		event.preventDefault()
	}

	const fetchCompanyConfig = useCallback(async () => {
		try {
			await api
				.get("/company-config")
				.then((res) => {
					const company = res.data
					setCompany(company)
					return company.country
				})
				.then((country) =>
					handleLocaleChange(language[country], setAppState)
				)
		} catch (e) {
			if (e.message.includes("Network")) {
				setResponse(t.errors["get_company_config.network_error"])
			}

			if (e.response && e.response.status === 406) {
				if (e.response.data.parameter === "customer_portal.not_active") {
					setResponse(t("@@ Esta página foi desativada pelo administrador."))
				}
			}
			console.log({ e })
			return "BRL"
		}
	}, [])

	const validationSchema = () =>
		object().shape({
			username: string()
				.email(t.forms_validation.email_valid)
				.required(t.forms_validation.email_required),
			password: string().required(t.forms_validation.password_required),
		})

	const onSubmit = async (values) => {
		const form = { ...values, username: values.username.toLowerCase() }

		try {
			await api.post(`/login`, form).then((res) => {
				if (res.status === 200) {
					enqueueSnackbar(t.messages["login.success"], {
						variant: "success",
					})
					localStorage.setItem("token", res.data.token)
					history.push("/")
				} else {
					throw res
				}
			})
		} catch (e) {
			console.log({ e })

			if (e.response.status === 401) {
				enqueueSnackbar(t.errors["login.invalid_user_error"], {
					variant: "error",
				})
			} else {
				enqueueSnackbar(t.errors["login.server_error"], {
					variant: "error",
				})
			}
		}
	}

	const checkAuth = async () => {
		const customer = await CustomerService.getMe()
		if (customer) {
			history.push('/')
		}
	}

	useEffect(() => {
		fetchCompanyConfig()
		checkAuth()
	}, [])

	if (!company) {
		return (
			<div
				className="flex-col center-a center-b"
				style={{
					width: "100vw",
					height: "100vh",
				}}
			>
				{!response && <Loader />}
				{response && (
					<h3 style={{ color: "red", textAlign: "center" }}>
						{response}
					</h3>
				)}
			</div>
		)
	}

	return (
		<React.Fragment>
			<Helmet>
				<title>
					Portal do Assinante
				</title>
			</Helmet>
			<Formik
				initialValues={{ username: "", password: "" }}
				validationSchema={validationSchema}
				onSubmit={(values) => onSubmit(values)}
			>

				{(formikProps) => (
					<form
						action="javascript:void(0)"
						onSubmit={formikProps.handleSubmit}
					>
						<Container maxWidth="lg">
							<SectionMain className={classes.root} noPadding>
								<SectionMain
									noPadding
									xs={12}
									lg={5}
									md={6}
									className={classes.loginForm}
									id="login-form"
								>
									<SectionMain
										noPadding
										className={classes.logo}
										style={{
											backgroundImage: `url(${company.style.url_logo_default})`,
										}}
										id="login-logo"
									></SectionMain>
									<SectionMain
										className={classes.text}
										direction="column"
									>
										<span
											id="login-title"
											className={classes.title}
											style={{
												fontSize: 28,
												color: company.style.primary_color,
											}}
											fontSize={28}
										>
											{t.titles["message_title.login"]}
										</span>
									</SectionMain>

									<SectionMain className={classes.inputs}>
										<div className={classes.bgInput}>
											<TextInput
												bgColor="#fff"
												label="E-mail"
												errorMessage={
													formikProps.touched.username &&
													formikProps.errors.username
												}
												value={formikProps.values.username}
												autoComplete="email"
												onChange={(e) =>
													formikProps.setFieldValue(
														"username",
														e.target.value
													)
												}
												autoFocus
											/>
										</div>
										<div className={classes.bgInput}>
											<TextInput
												type={
													showPassword ? "text" : "password"
												}
												label={t("^forms.password")}
												errorMessage={
													formikProps.touched.password &&
													formikProps.errors.password
												}
												value={formikProps.values.password}
												autoComplete="password"
												onChange={(e) =>
													formikProps.setFieldValue(
														"password",
														e.target.value
													)
												}
												InputProps={{
													endAdornment: (
														<InputAdornment
															position="end"
															onClick={
																handleClickShowPassword
															}
															onMouseDown={
																handleMouseDownPassword
															}
														>
															{showPassword ? (
																<Visibility
																	style={{
																		opacity: 1,
																	}}
																/>
															) : (
																<VisibilityOff
																	style={{
																		opacity: 0.5,
																	}}
																/>
															)}
														</InputAdornment>
													),
												}}
											/>
										</div>
									</SectionMain>

									<Hidden only={["xs", "sm"]}>
										<SectionMain position="space-between" noPadding>
											<MenuItem
												onClick={() => history.push(`/forgot`)}
											>
												<TitleSmall
													color={company.style.primary_color}
												>
													{t.titles["forgot_password.login"]}
												</TitleSmall>
											</MenuItem>
											<Button
												fullWidth
												type="submit"
												variant="contained"
												color="primary"
												disabled={formikProps.isSubmitting}
												className={classes.submitButton}
												style={{
													backgroundColor:
														company.style.primary_color,
												}}
											>
												{formikProps.isSubmitting && (
													<CircularProgress
														size={24}
														color="inherit"
														style={{ marginRight: 8 }}
													/>
												)}
												{t.buttons["login.enter"]}
											</Button>
										</SectionMain>
									</Hidden>
									<Hidden only={["md", "lg", "xl"]}>
										<SectionMain position="center">
											<MenuItem
												onClick={() => history.push(`/forgot`)}
												style={{
													width: "100%",
													justifyContent: "center",
												}}
											>
												<TitleSmall
													align="center"
													color={company.style.primary_color}
												>
													{t.titles["forgot_password.login"]}
												</TitleSmall>
											</MenuItem>
										</SectionMain>
										<Button
											type="submit"
											variant="contained"
											color="primary"
											disabled={formikProps.isSubmitting}
											className={classes.submitButton}
											style={{
												width: "100%",
												backgroundColor:
													company.style.primary_color,
											}}
										>
											{formikProps.isSubmitting && (
												<CircularProgress
													size={24}
													color="inherit"
													style={{ marginRight: 8 }}
												/>
											)}
											{t.buttons["login.enter"]}
										</Button>
									</Hidden>
								</SectionMain>
							</SectionMain>
						</Container>
					</form>
				)}
			</Formik>
		</React.Fragment>

	)
}

export default LoginPage
