import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from '../components/IntlContext/IntlContext';
import { Formik } from 'formik';
import { FormikValidationsCustomer, FormikValidationsCCPayment, FormikValidationsBoletoPayment, FormikValidationsCustom } from '../components/checkout-1.5.4/forms/RenderInputsV4/FormikValidations';
import RenderInputsV4 from '../components/checkout-1.5.4/forms/RenderInputsV4';
import { TextField, Button, FormControlLabel, Checkbox, Select, FormLabel, FormControl, InputLabel, Box, Accordion, AccordionSummary, AccordionDetails, IconButton, Stepper } from '@material-ui/core';
import { CheckoutService } from '../core/CheckoutService';
import useMsgBox from '../components/SweetAlert';
import Loader from '../components/Loader';
import PersonIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar'; import MenuItem from '@material-ui/core/MenuItem';
import toCurrency from '../core/toCurrency';
import './checkoutProduct.css'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { createTheme, makeStyles, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import pciLogo from "../assets/pci-logo.png"
import cycloPay from "../assets/cyclopay-blue-logo2.svg"
import cyclopayWhite from "../assets/cyclopay-white-logo2.png"
import noImgAvailable from '../assets/no-img-available.jpg'
import ProductOneStep from '../components/forms/productOneStep';
import SuccessPage from '../components/checkout-1.5.4/SuccessPage';
import SuccessBoleto from '../components/checkout-1.5.4/SuccessBoleto';
import SuccessBolecode from '../components/checkout-1.5.4/SuccessBolecode';
import SuccessPix from '../components/checkout-1.5.4/SuccessPix';
import ProductMultiStep from '../components/forms/productMultiStep';
import ReCaptcha from 'react-google-recaptcha'
import { Icon } from '@iconify/react';
import AppContext, { useAppContext } from "../components/AppContextCheckout";
import getLocale from '../locale'
import CouponForm from '../layout/coupon_form';
import { LocaleSelect } from '../components/LocaleSelect';
import moment from 'moment';
import { Animated } from "react-animated-css";


let _ = require('lodash');
function isBackgroundDark(hexcolor = "#000000") {
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq < 128;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));


export default function CheckoutProductPage({ history, match }) {
    const t = useTranslation()
    const { setAppState, payzenForm, lang, language } = useAppContext(AppContext);
    const [isLoading, setIsLoading] = useState(true)
    // const company = checkout.company
    const [quantity, setQuantity] = useState(1)
    const [checkout, setCheckout] = useState({})
    const [success, setSuccess] = useState({})
    const [messageError, setMessageError] = useState('')
    let languageLabel = { BRA: "pt-BR", FRA: "fr" };

    const hash = match.params.id
    const refCaptcha = useRef()
    const classes = useStyles()
    const [couponLoading, setCouponLoading] = useState(false)
    const [coupon, setCoupon] = useState({
        id: null,
        code: null,
        value: "",
        discount_amount: 0,
        total_amount: 0,
        tax_amount: 0
    })

    const [totalAmount, setTotalAmount] = useState(0)

    const [loadingState, setLoadingState] = useState({
        customer: false,
        payment: false
    })
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [customer, setCustomer] = useState({})
    const msg = useMsgBox()

    const handleQuantity = (type) => {
        if (type == 'add') {
            return setQuantity(quantity + 1)
        } else {
            if ((quantity - 1) <= 1) {
                return setQuantity(1)
            }
            setQuantity(quantity - 1)
        }
    }

    useEffect(() => {
        setTotalAmount(checkout.product?.amount * quantity)
    }, [quantity])

    const fetchCheckout = async () => {
        setIsLoading(true)
        try {
            const response = await CheckoutService.GetProduct({ hash })
            const list = await CheckoutService.GetDocumentTypes({ countryId: response.company.address.country_id, session_token: "" })
            response.documents_list = list

            if (!response.checkout_config.quantity_limit) response.checkout_config.quantity_limit = {
                "is_enabled": false
            }
            setCheckout(response)
            setTotalAmount(response.product.amount)
            handleLocaleChange((response.checkout_config.default_language || languageLabel[response.company.address.country_code]), setAppState);

        } catch (e) {
            switch (e.status) {
                case 403:
                    setMessageError(t.errors["hash.used"]);
                    break;
                case 409:
                    setMessageError(t.errors["hash.disabled"]);
                    break;

                default:
                    console.log({ e })
                    setMessageError(t.errors["hash.server_error"]);
                    break;
            }

        }
        return setIsLoading(false)
    }

    const CCInitialValues = () => {
        let initialValues = {
            cc_number: "",
            cc_cardholder: "",
            cc_expiry_date: "",
            cvv: "",
            brand: "",
            type: "",
            installments: checkout.installments && 1

        }
        return initialValues
    }


    const disabledInputsCustomer = () => {
        if (!checkout.customer) return {}
        let initialValues = {
            first_name: Boolean(checkout.customer.first_name),
            last_name: Boolean(checkout.customer.last_name),
            mobile_phone: Boolean(checkout.customer.mobile_number),
            email: Boolean(checkout.customer.email),
            country_code: Boolean(checkout.customer.country_code),
            document_type: Boolean(checkout.customer.document.type),
            document_number: Boolean(checkout.customer.document.number),
            billing_address_postal_code: Boolean(checkout.customer.billing_address?.postal_code),
            billing_address_street1: Boolean(checkout.customer.billing_address?.street),
            billing_address_number: Boolean(checkout.customer.billing_address?.number),
            billing_address_state: Boolean(checkout.customer.billing_address?.state),
            billing_address_district: Boolean(checkout.customer.billing_address?.district),
            billing_address_city: Boolean(checkout.customer.billing_address?.city),
            billing_address_country_code: Boolean(checkout.customer.billing_address?.country_code),
            billing_address_complement: Boolean(checkout.customer.billing_address?.complement),
            shipping_address_postal_code: Boolean(checkout.customer.shipping_address[0]?.postal_code),
            shipping_address_street1: Boolean(checkout.customer.shipping_address[0]?.street),
            shipping_address_number: Boolean(checkout.customer.shipping_address[0]?.number),
            shipping_address_state: Boolean(checkout.customer.shipping_address[0]?.state),
            shipping_address_district: Boolean(checkout.customer.shipping_address[0]?.district),
            shipping_address_city: Boolean(checkout.customer.shipping_address[0]?.city),
            shipping_address_country_code: Boolean(checkout.customer.shipping_address[0]?.country_code),
            shipping_address_complement: Boolean(checkout.customer.shipping_address[0]?.complement),
        }
        return initialValues
    }

    const customerInitialValues = () => {
        if (checkout.customer) {
            let initialValues = {
                first_name: checkout.customer.first_name,
                last_name: checkout.customer.last_name,
                mobile_phone: checkout.customer.mobile_number || "",
                email: checkout.customer.email,
                country_code: checkout.customer.country_code,
                document_type: checkout.customer.document.type,
                document_number: checkout.customer.document.number,
                billing_address_postal_code: checkout.customer.billing_address?.postal_code,
                billing_address_street1: checkout.customer.billing_address?.street,
                billing_address_number: checkout.customer.billing_address?.number,
                billing_address_state: checkout.customer.billing_address?.state,
                billing_address_district: checkout.customer.billing_address?.district,
                billing_address_city: checkout.customer.billing_address?.city,
                billing_address_country_code: checkout.customer.billing_address?.country_code,
                billing_address_complement: checkout.customer.billing_address?.complement,
                shipping_address_postal_code: checkout.customer.shipping_address[0]?.postal_code,
                shipping_address_street1: checkout.customer.shipping_address[0]?.street,
                shipping_address_number: checkout.customer.shipping_address[0]?.number,
                shipping_address_state: checkout.customer.shipping_address[0]?.state,
                shipping_address_district: checkout.customer.shipping_address[0]?.district,
                shipping_address_city: checkout.customer.shipping_address[0]?.city,
                shipping_address_country_code: checkout.customer.shipping_address[0]?.country_code,
                shipping_address_complement: checkout.customer.shipping_address[0]?.complement,
            }
            return initialValues
        }
        let initialValues = {
            first_name: "",
            last_name: "",
            mobile_phone: "",
            email: "",
            country_code: "BRA",
            document_type: checkout.checkout_config.prioritize_b2b ? "CNPJ" : "CPF",
            document_number: "",
            billing_address_postal_code: "",
            billing_address_street1: "",
            billing_address_number: "",
            billing_address_state: "",
            billing_address_district: "",
            billing_address_city: "",
            billing_address_country_code: "",
            billing_address_complement: "",
            shipping_address_postal_code: "",
            shipping_address_street1: "",
            shipping_address_number: "",
            shipping_address_state: "",
            shipping_address_district: "",
            shipping_address_city: "",
            shipping_address_country_code: "",
            shipping_address_complement: "",
        }
        return initialValues
    }

    const onOneStepSubmit = async (values) => {

        if (totalAmount === 0) { values.type = 'free' }

        const [MM, YY] = values.cc_expiry_date.split("/")

        const expiry_date = `${MM}/20${YY}`

        const billingInfo = {
            postal_code: values.billing_address_postal_code,
            street: values.billing_address_street1,
            number: values.billing_address_number,
            state: values.billing_address_state,
            district: values.billing_address_district,
            city: values.billing_address_city,
            country_code: values.billing_address_country_code,
            complement: values.billing_address_complement
        }
        const shippingInfo = [{
            postal_code: values.shipping_address_postal_code,
            street: values.shipping_address_street1,
            number: values.shipping_address_number,
            state: values.shipping_address_state,
            district: values.shipping_address_district,
            city: values.shipping_address_city,
            country_code: values.shipping_address_country_code,
            complement: values.shipping_address_complement,
            is_main: false
        }]
        const customerInfo = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            birth_date: values.birth_date ? moment(values.birth_date).format("YYYY/MM/DD") : null,
            mobile_number: values.mobile_phone,
            gender: "",
            document: (checkout.checkout_config.document_enabled || ['pix', 'boleto', 'bolecode'].includes(values.type)) ? {
                type: values.document_type,
                number: values.document_number
            } : null,
        }
        const paymentInfo = {
            number: values.cc_number,
            holder: values.cc_cardholder,
            expiry_date: expiry_date,
            cvv: values.cvv,
            type: 'credit_card'
        }

        const ccForm = {
            payment_mean: values.type === 'free' ? null : paymentInfo,
            installment: values.installments,
            quantity,
            customer: {
                ...customerInfo,
                billing_address: (checkout.checkout_config.billing_address_enabled || ['boleto', 'bolecode'].includes(values.type)) ? billingInfo : null,
                shipping_address: checkout.checkout_config.shipping_address_enabled ? shippingInfo : null
            },
            coupon_id: coupon.id
        }

        const form = {
            payment_mean: { type: values.type },
            quantity: quantity,
            customer: {
                ...customerInfo,
                billing_address: (checkout.checkout_config.billing_address_enabled || ['boleto', 'bolecode'].includes(values.type)) ? billingInfo : null,
                shipping_address: checkout.checkout_config.shipping_address_enabled ? shippingInfo : null
            },
            coupon_id: coupon.id
        }

        try {
            setLoadingState({ ...loadingState, payment: true })
            let recaptchaToken = null

            if (!['develop', 'homolog'].includes(process.env.REACT_APP_ENVIRONMENT)) {
                recaptchaToken = await refCaptcha.current.executeAsync()
            }
            const data = await CheckoutService.Products.Pay({
                form: ['credit_card', 'free'].includes(values.type)
                    ? ccForm
                    : form,
                session_token: checkout.session_token,
                hash,
                recaptchaToken
            })
            if (data.status === 'refused') {
                setLoadingState({ ...loadingState, payment: false })
                return msg.err({ title: t.errors.label, text: t.errors['payments.transaction_refused'] })
            }
            setLoadingState({ ...loadingState, payment: false })
            return setSuccess({ type: data.type || 'free', customer: customerInfo, installment: form.installments, plan: data.product, boleto: data, pix: data, successInfo: data, amount: data.amount })

        } catch (e) {
            setLoadingState({ ...loadingState, payment: false })
            return msg.err({ title: t.errors.label, text: t.errors[e?.response?.data?.parameter] || t.errors['generic.post'] })

        }
        return
    }

    const onCustomerSubmit = async (values) => {
        const billingInfo = {
            postal_code: values.billing_address_postal_code,
            street: values.billing_address_street1,
            number: values.billing_address_number,
            state: values.billing_address_state,
            district: values.billing_address_district,
            city: values.billing_address_city,
            country_code: values.billing_address_country_code,
            complement: values.billing_address_complement
        }
        const shippingInfo = [{
            postal_code: values.shipping_address_postal_code,
            street: values.shipping_address_street1,
            number: values.shipping_address_number,
            state: values.shipping_address_state,
            district: values.shipping_address_district,
            city: values.shipping_address_city,
            country_code: values.shipping_address_country_code,
            complement: values.shipping_address_complement,
            is_main: false
        }]
        const customerInfo = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            birth_date: values.birth_date ? moment(values.birth_date).format("YYYY/MM/DD") : null,
            mobile_number: values.mobile_number,
            gender: "",
            document: (checkout.checkout_config.document_enabled) ? {
                type: values.document_type,
                number: values.document_number
            } : null,
        }

        const form = {
            quantity,
            customer: {
                ...customerInfo,
                billing_address: (checkout.checkout_config.billing_address_enabled) ? billingInfo : null,
                shipping_address: (checkout.checkout_config.shipping_address_enabled) ? shippingInfo : null
            },
            coupon_id: coupon.id
        }

        try {
            setLoadingState({ ...loadingState, customer: true })
            let recaptchaToken = null

            if (!['develop', 'homolog'].includes(process.env.REACT_APP_ENVIRONMENT)) {
                recaptchaToken = await refCaptcha.current.executeAsync()
            }
            const data = await CheckoutService.Products.Subscribe({
                form,
                customer: form.customer,
                coupon_id: form.coupon_id,
                session_token: checkout.session_token,
                hash,
                recaptchaToken
            })
            setCustomer({ ...customerInfo, ...data })
            return true
            // return setSuccess({ type: values.type, customer: customerInfo, installment: form.installments, plan: data.product, boleto: data, ...data })
        } catch (e) {
            setLoadingState({ ...loadingState, customer: false })
            msg.err({ title: t.errors.label, text: t.errors[e?.response?.data?.parameter] || t.errors['generic.post'] })
            throw new Error(e)
        }
    }

    const onPaymentSubmit = async (values) => {

        if (totalAmount === 0) { values.type = 'free' }

        const billingInfo = {
            postal_code: values.billing_address_postal_code,
            street: values.billing_address_street1,
            number: values.billing_address_number,
            state: values.billing_address_state,
            district: values.billing_address_district,
            city: values.billing_address_city,
            country_code: values.billing_address_country_code,
            complement: values.billing_address_complement
        }

        const paymentInfo = {
            number: values.cc_number,
            holder: values.cc_cardholder,
            expiry_date: `${values.cc_expiry_date?.split("/")[0]}/20${values.cc_expiry_date?.split("/")[1]}`,
            cvv: values.cvv,
            type: 'credit_card'
        }

        const ccForm = {
            payment_mean: totalAmount > 0 ? paymentInfo : null,
            installment: values.installments,
            customer: {
                customer_id: customer.customer_id
            },
            sale_id: customer.sale_id
        }

        const form = {
            payment_mean: {
                type: values.type,
            },
            quantity: quantity,
            customer: {
                customer_id: customer.customer_id,
                billing_address: (!checkout.checkout_config.billing_address && ['boleto', 'bolecode'].includes(values.type)) ? billingInfo : null,
                document: (!checkout.checkout_config.document) ? { type: values.document_type, number: values.document_number } : null
            },
            sale_id: customer.sale_id
        }

        try {
            setLoadingState({ ...loadingState, payment: true })
            let recaptchaToken = null

            if (!['develop', 'homolog'].includes(process.env.REACT_APP_ENVIRONMENT)) {
                recaptchaToken = await refCaptcha.current.executeAsync()
            }
            const data = await CheckoutService.Products.Pay({
                form: ['credit_card', 'free'].includes(values.type) ? ccForm : form,
                customer: form.customer,
                coupon_id: form.coupon_id,
                session_token: checkout.session_token,
                hash,
                recaptchaToken
            })
            if (data.status === 'refused') {
                setIsSubmitting(false)
                setLoadingState({ ...loadingState, payment: false })
                return msg.err({ title: t.errors.label, text: t.errors['payments.transaction_refused'] })
            }
            setLoadingState({ ...loadingState, payment: false })
            return setSuccess({ type: data.type || 'free', customer, installment: form.installments, plan: data.product, boleto: data, pix: data, successInfo: data })
        } catch (e) {
            setLoadingState({ ...loadingState, payment: false })
            msg.err({ title: t.errors.label, text: t.errors[e?.response?.data?.parameter] || t.errors['generic.post'] })
            // throw new Error(e)

        }
    }

    const handleLocaleChange = useCallback(async lang => {
        const { locale } = await getLocale(lang)
        localStorage.setItem("locale", lang)
        setAppState({ language: lang, locale })
    }, [])


    useEffect(() => {
        fetchCheckout()
    }, [])

    if (messageError) return <div className='flex-row center-a center-b' style={{ width: '100%', height: '100vh' }}>
        {messageError}
    </div>

    if (isLoading) return <div className='flex-row center-a center-b' style={{ width: '100%', height: '100vh' }}>
        <Loader />
    </div>


    if (success) {
        if (success.type === 'credit_card') return (
            <SuccessPage
                checkoutInfo={checkout}
                isProduct
                userInfo={{ ...success.customer, totalPaid: { amount: totalAmount, currency: checkout.product?.currency, installment: success.installment } }}
                logo={checkout.company && checkout.company.style.url_logo_default}
                primaryColor={checkout.company && checkout.company.style.primary_color}
                success_info={{ ...success.data, product: checkout.product || undefined }}
            />
        )
        if (success.type === 'boleto') return (
            <SuccessBoleto
                logo={checkout.company && checkout.company.style.url_logo_default}
                primaryColor={checkout.company && checkout.company.style.primary_color}
                isProduct
                boleto={success.boleto}
                checkoutInfo={checkout}
                userInfo={{ ...success.customer, totalPaid: { amount: totalAmount, currency: checkout.product?.currency } }}
            />
        )
        if (success.type === 'bolecode') return (
            <SuccessBolecode
                logo={checkout.company && checkout.company.style.url_logo_default}
                primaryColor={checkout.company && checkout.company.style.primary_color}
                isProduct
                boleto={success.boleto}
                checkoutInfo={checkout}
                userInfo={{ ...success.customer, totalPaid: { amount: totalAmount, currency: checkout.product?.currency } }}
            />
        )
        if (success.type === 'pix') return (
            <SuccessPix
                logo={checkout.company && checkout.company.style.url_logo_default}
                primaryColor={checkout.company && checkout.company.style.primary_color}
                pixData={success.pix}
                checkoutInfo={checkout}
                isProduct
                userInfo={{ ...success.customer, totalPaid: { amount: totalAmount, currency: checkout.product?.currency } }}
            />
        )
        if (success.type === 'free') return (
            <SuccessPage
                checkoutInfo={checkout}
                isProduct
                userInfo={{ ...success.customer, totalPaid: { amount: success.amount, currency: checkout.product?.currency, installment: success.installment } }}
                logo={checkout.company && checkout.company.style.url_logo_default}
                primaryColor={checkout.company && checkout.company.style.primary_color}
                success_info={{ ...success.data, product: checkout.product || undefined }}
            />
        )
    }

    return (
        <WrapperContainer
            checkout={checkout}
        >
            <LocaleSelect />

            <div className="flex-row content" style={{ backgroundColor: checkout.company.style.background_color }}>
                <div className="left-side">
                    {/* <div className='flex-row center-a' style={{ marginBottom: 10, fontSize: 20, fontWeight: 'bold' }}>
                        <span>
                            Carrinho
                        </span>
                    </div> */}
                    <div className='product-card'>
                        <div className='product-img'>
                            <img src={checkout.product.image || noImgAvailable} alt="productpreview"></img>
                        </div>
                        <div className='flex-row' style={{ width: '100%', justifyContent: 'space-between' }}>
                            <div className='flex-col product-info'>
                                <div className='flex-row' style={{ justifyContent: 'space-between', width: '100%' }}>
                                    <span className='product-name'>
                                        {checkout.product.name}
                                    </span>
                                    <span className='product-price'>
                                        {/* {toCurrency(checkout.product.amount)} */}
                                    </span>
                                </div>
                                <span className='product-desc'>
                                    {checkout.product.description}
                                </span>
                                <div className='flex-row' style={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                    <div>
                                        <div className='flex-row' style={{ width: 170, justifyContent: 'space-between', alignItems: 'center' }}>
                                            <IconButton disabled={(Boolean(coupon.id) || Boolean(!_.isEmpty(customer)) || quantity <= 1)} onClick={() => handleQuantity('remove')}>
                                                <RemoveIcon />
                                            </IconButton>
                                            {quantity}
                                            <IconButton disabled={Boolean(coupon.id) || Boolean(!_.isEmpty(customer)) || quantity >= checkout.checkout_config.quantity_limit.maximum_quantity} onClick={() => handleQuantity('add')}>
                                                <AddIcon />
                                            </IconButton>

                                            {checkout.checkout_config.quantity_limit.maximum_quantity > 0 &&
                                                <div style={{ fontSize: 12, textAlign: "right" }}>
                                                    {`(Max.: ${checkout.checkout_config.quantity_limit.maximum_quantity})`}
                                                </div>
                                            }


                                        </div>
                                    </div>
                                    <span className='product-price flex-col' style={{ alignItems: 'flex-end' }}>
                                        <span>
                                            {toCurrency(checkout.product.amount * quantity, checkout.product.currency)}
                                        </span>
                                    </span>
                                </div>
                                <div className='flex-row' style={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>

                                    <Animated animationIn="fadeInDown" animationOut="fadeOutUp" animationInDuration={200} animationOutDuration={200} animateOnMount={false} isVisible={quantity >= checkout.checkout_config.quantity_limit.maximum_quantity}>
                                        <div style={{ fontSize: 14, color: 'red' }}>
                                            {t("@@ Quantidade máxima atingida")}
                                        </div>
                                    </Animated>

                                    <Animated animationIn="fadeInDown" animationOut="fadeOutUp" animationInDuration={200} animationOutDuration={200} animateOnMount={false} isVisible={quantity > 1}>
                                        <div style={{ fontSize: 14, textAlign: "right" }}>
                                            ({t.generics.unit_value} {toCurrency(checkout.product.amount, checkout.product.currency)})
                                        </div>
                                    </Animated>
                                </div>
                            </div>
                        </div>
                    </div>
                    {checkout.checkout_config.coupon_enabled && (
                        <>
                            <div className='subtotal'>
                                <span>
                                    {t.titles['subtotal.details']}:
                                </span>
                                <span>
                                    {toCurrency(checkout.product.amount * quantity, checkout.product.currency)}
                                </span>
                            </div>

                            <div className='coupon-section'>
                                <span>
                                    <CouponForm
                                        coupon={coupon}
                                        setCoupon={setCoupon}
                                        totalAmount={totalAmount}
                                        setTotalAmount={setTotalAmount}
                                        couponLoading={couponLoading}
                                        setCouponLoading={setCouponLoading}
                                        checkoutConfig={checkout}
                                        productHash={hash}
                                        quantity={quantity}
                                        disabled={Boolean(!_.isEmpty(customer))}
                                    />
                                </span>
                            </div>
                            {coupon.code && (
                                <div className='subtotal'>
                                    <span>
                                        <span>{t.titles["discount_value.details"]}:</span>
                                    </span>
                                    <span>
                                        <span>-{toCurrency(coupon.discount_amount, checkout.product.currency)}</span>
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                    <div className='total'>
                        <span>
                            {t.checkout.total_amount}:
                        </span>
                        <span>
                            {toCurrency(coupon.id ? coupon.total_amount : (checkout.product.amount * quantity), checkout.product.currency)}
                        </span>
                    </div>
                </div>
                <div className="right-side">
                    <ReCaptcha
                        size='invisible'
                        onChange={() => null}
                        ref={refCaptcha}
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    />
                    {checkout.checkout_config.checkout_type === 'single'
                        ? <ProductOneStep checkout={checkout} totalAmount={coupon.id ? coupon.total_amount : (checkout.product.amount * quantity)} quantity={quantity} onSubmit={(props) => onOneStepSubmit(props)} loadingState={loadingState} />
                        : <ProductMultiStep checkout={checkout} totalAmount={coupon.id ? coupon.total_amount : (checkout.product.amount * quantity)} quantity={quantity} onCustomerSubmit={(props) => onCustomerSubmit(props)} onPaymentSubmit={(props) => onPaymentSubmit(props)} loadingState={loadingState} />
                    }
                </div>
            </div>
        </WrapperContainer>
    )
}

const WrapperContainer = ({ children, checkout }) => {
    const style = checkout.company.style
    const company = checkout.company
    const classes = useStyles();
    const theme = createTheme({
        palette: {
            primary: {
                main: style.primary_color
            }
        }
    })
    return (
        <MuiThemeProvider theme={theme}>
            <div className='container'>
                <div className='header' style={{ backgroundColor: style.primary_color }}>
                    <img src={style.url_logo_default}></img>
                </div>
                <div>
                    {children}
                </div>
                <div className='footer' style={{ backgroundColor: style.secundary_color }}>
                    <div>
                        <div>
                            <div style={{ color: "white" }} noPadding>
                                <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }} className={classes.label}>{`${company.legal_name}`}</span>
                            </div>
                            {company.document_type && company.document_number &&
                                <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
                                    {`${company.document_type.toUpperCase()} ${company.document_number}`}
                                </span>
                            }
                            {company.address?.street &&
                                <div noPadding>
                                    <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
                                        {`${company.address.street} ${company.address.number ? (company.address.number) : ""}`}&nbsp;
                                    </span>
                                </div>
                            }
                            {company.address?.district &&
                                <div noPadding>
                                    <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }} noPadding>
                                        {company.address.district}
                                    </span>
                                </div>
                            }
                            {company.address?.city && company.address?.postal_code &&
                                <div noPadding>
                                    <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }} className={classes.label}>
                                        <span
                                        >{`${company.address.city} - ${company.address.postal_code}`}</span>
                                    </span>
                                </div>
                            }
                            {company.address?.second_street && (
                                <div noPadding>
                                    <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
                                        {company.address.second_street}
                                    </span>
                                </div>
                            )}
                            {company.email &&
                                <div noPadding>
                                    <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
                                        {company.email}
                                    </span>
                                </div>
                            }
                            {company.phone && company.phone.length > 5 &&
                                <div noPadding>
                                    <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
                                        {company.phone}
                                    </span>
                                </div>
                            }
                            {company.site &&
                                <div noPadding>
                                    <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
                                        {company.site}
                                    </span>
                                </div>
                            }
                        </div>

                        <div className='flex-col'>
                            <span className="flex-row">
                                <span className={classes.logosFooter}>
                                    <img alt="pci-logo" src={pciLogo} width="40px" />
                                </span>
                                <span className={classes.logosFooter}>
                                    <img
                                        onClick={() => {
                                            window.location = "https://cyclopay.com"
                                        }}
                                        alt="ciclopay-logo"
                                        src={isBackgroundDark(company.style.secundary_color) ? cyclopayWhite : cycloPay}
                                        width="70px"
                                    />
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </MuiThemeProvider>
    )
}