import React from 'react'
import PortalWrapper from '../../components/layout/PortalWrapper'
import api from '../../../core/api'
import { ListSales } from '../../components/list/ListSales'

const PaymentsPage = ({ history }) => {
	return (
		<PortalWrapper history={history}>
			<ListSales />
		</PortalWrapper>
	)
}

export default PaymentsPage
