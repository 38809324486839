import React, { useState, useContext, useEffect, useCallback } from "react"
import SectionMain from "../../../../layout/SectionMain"
import {
	AppBar,
	makeStyles,
	Button,
	Hidden,
	IconButton,
	Slide,
	Dialog,
	hexToRgb,
} from "@material-ui/core"
import UserAvatar from "../../UserAvatar"
import Title, {
	TitleSmall,
} from "../../../../components/checkout-1.5.4/forms/Title"
import ParticlesBg from "particles-bg"
import MenuIcon from "@material-ui/icons/Menu"
import ButtonCard from "../../cards/ButtonCard"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import AppContext from "../../../../components/AppContextCheckout"
import { lightOrDark } from "../../../../utils/lightOrDark"
import api from "../../../../core/api"
import { useTranslation } from "../../../../components/IntlContext/IntlContext"
import { handleLocaleChange } from "../../../../locale/handleLocaleChange"
import NavigationMenu from "../../navigation"
// const useStyles = makeStyles((theme) => ({
// 	appBar: {
// 		position: 'absolute',
// 		padding: '10px 0'
// 		// boxShadow: 'none',
// 	},
// 	title: {
// 		marginLeft: theme.spacing(2),
// 		flex: 1
// 	}
// }))

const useStyles = makeStyles({
	root: {
		maxWidth: 1024,
		padding: "0px 20px 70px",
		position: "relative",
	},
})

/**@returns {import("../../../locale/locale").TranslationFunction} */

const PortalWrapper = ({ children, history }) => {
	const { user, company, setAppState, language } = useContext(AppContext)
	const classes = useStyles()
	const t = useTranslation()
	const [open, setOpen] = useState(false)
	const navButtons = [
		{ label: t.navigation_menu.my_subscriptions, link: "/" },
		{ label: t.navigation_menu.my_payment_means, link: "/payment-means" },
		{ label: t.navigation_menu.profile, link: "/profile" },
	]
	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="right" ref={ref} {...props} />
	})

	const fetchCompanyStyle = useCallback(async () => {
		try {
			await api.get("/me").then((res) => {
				if (res.status === 200) {
					setAppState({
						user: res.data.customer,
						company: res.data.company,
					})
				}
			})
		} catch (e) {}
	}, [])

	useEffect(() => {
		fetchCompanyStyle().then(() =>
			handleLocaleChange(language, setAppState)
		)
	}, [])

	return (
		<SectionMain
			noPadding
			position="center"
			style={{ background: "#f5f9ff" }}
		>
			<style>{`body {
background-color: #f5f9ff !important;
}
`}</style>
			<NavigationMenu />
			<SectionMain noPadding className={classes.root}>
				{children}
			</SectionMain>
		</SectionMain>
	)
}

export default PortalWrapper
