import React from "react"
import RenderInputsWrapper from "./RenderInputsWrapper"
import RenderInputsUI from "./RenderInputsUI"
import AppContext, { useAppContext } from "../../../AppContextCheckout"
import { useEffect } from "react"

let lang = { "pt-BR": "BRA", fr: "FRA" }

const RenderInputsV3 = ({
	checkoutConfig = {},
	formikProps,
	arrayInputs = ["first_name", "last_name", "email", "mobile_number"],
	portalFixLayout,
}) => {
	const { language } = useAppContext(AppContext)
	const checkoutConfigWithPayment = {
		...checkoutConfig,
		payment: true,
		"billing_address.fr": checkoutConfig.billing_address,
		"shipping_address.fr": checkoutConfig.shipping_address,
	}

	// useEffect(() => {
	// 	if (!formikProps.values.country_code) {
	// 		formikProps.setFieldValue('country_code', lang[language])
	// 		if (lang[language] === 'FRA') {
	// 			formikProps.setFieldValue('document', { type: 'CNI', number: '' })
	// 		}
	// 	}
	// }, [])

	return (
		<RenderInputsWrapper
			checkoutConfig={checkoutConfigWithPayment}
			arrayInputs={arrayInputs}
		>
			{({ inputType, inputName }) => (
				<RenderInputsUI
					formikProps={formikProps}
					inputType={inputType}
					inputName={inputName}
					portalFixLayout={portalFixLayout}
					brands={checkoutConfig.brand_list}
				/>
			)}
		</RenderInputsWrapper>
	)
}

export default RenderInputsV3
