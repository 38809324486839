import React, { useState, useEffect } from 'react'
import PortalWrapper from '../../components/layout/PortalWrapper'
import { Paper, List, Hidden, IconButton } from '@material-ui/core'
import '../../../../node_modules/react-credit-cards/es/styles-compiled.css'
import api from '../../../core/api'
import SectionMain from '../../../layout/SectionMain'
import ListPaymentMeanItem from '../../../portal/components/list/ListPaymentMeanItem'
import Title from '../../../components/checkout-1.5.4/forms/Title'
import ListPaymentMeans from '../../components/list/ListPaymentMeans'
import Loader from '../../../components/Loader'
import { useTranslation } from '../../../components/IntlContext/IntlContext'
import ButtonCard from '../../components/cards/ButtonCard'
import { CustomerService } from '../../../core/CustomerService'
import InfiniteScroll from 'react-infinite-scroll-component'

const PaymentMeansPage = ({ history }) => {
	const t = useTranslation()
	const [state, setState] = useState({ cards: [], loading: true, totalPages: 0, currentPage: 1 })
	const [currentPage, setCurrentPage] = useState(1)
	const fetchCards = async () => {
		try {
			/** @type {{ data: Veripag.Responses.CardsResult }} */
			const data = await CustomerService.Subscriptions.getPaymentMeans({ filters: { count: 10, page: state.currentPage } })
			setState({ ...state, cards: data.items, totalPages: data.total_pages, loading: false })
		} catch (e) {
			setState({ ...state, loading: false })
		}
	}

	const fetchMoreData = async () => {
		console.log(state.currentPage)
		try {
			/** @type {{ data: Veripag.Responses.CardsResult }} */
			const data = await CustomerService.Subscriptions.getPaymentMeans({ filters: { count: 10, page: state.currentPage + 1 } })
			setState({ ...state, cards: [...state.cards, ...data.items], currentPage: state.currentPage + 1 })
		} catch (e) {
			setState({ ...state })
		}
	}

	useEffect(() => {
		fetchCards()
	}, [])

	return (
		<PortalWrapper history={history}>
			{state.loading ? (
				<div
					className="flex-col center-a center-b"
					style={{
						width: '100vw',
						height: '80vh'
					}}
				>
					<Loader />
				</div>
			) : state.cards.length !== 0 ? (
				<Paper style={{ padding: '10px 20px', width: '100%' }}>
					<SectionMain>
						<Title style={{ paddingBottom: 5 }} id="text-truncate">
							{t.titles['cards.form']}
						</Title>
					</SectionMain>
					<SectionMain noPadding>
						<ButtonCard textDecorationLine="none" onClick={() => history.push(`/add-payment`)}>
							{t.titles['add_payment_method.credit_card']}
						</ButtonCard>
					</SectionMain>

					<SectionMain noPadding position="center">
						<InfiniteScroll
							dataLength={state.cards.length}
							next={fetchMoreData}
							style={{ width: "100%" }}

							hasMore={state.currentPage < state.totalPages}
							loader={<div className="flex-row center-a" style={{ height: "100px", width: "100%", boxSizing: "border-box" }}>
								<Loader size={80} style={{ height: 80 }} />
							</div>}
							endMessage={
								<p style={{ textAlign: "center" }}>
									<b>{t("@@ Sem mais resultados")}</b>
								</p>
							}
						>
							<ListPaymentMeans arrayCards={state.cards} />
						</InfiniteScroll>
					</SectionMain>
				</Paper>
			) : (
				<SectionMain
					style={{
						height: '50vh'
					}}
					direction="column"
					noPadding
					position="center"
					alignItems="center"
				>
					<Title bold align="center">
						{t.titles['cards.not_found']}
					</Title>
				</SectionMain>
			)}
		</PortalWrapper>
	)
}

export default PaymentMeansPage
