import React, { useEffect, useContext } from 'react'
import PortalWrapper from '../../components/layout/PortalWrapper'
import { Formik } from 'formik'
import SectionMain from '../../../layout/SectionMain'
import { Paper, Hidden, CircularProgress, Button, IconButton, MenuItem } from '@material-ui/core'
import Title, { TitleSmall } from '../../../components/checkout-1.5.4/forms/Title'
import { toCurrency } from '../../../utils/commonMasks'
import { useState } from 'react'

import Loader from '../../../components/Loader'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import ListPaymentMeans from '../../components/list/ListPaymentMeans'
import RenderInputsV2 from '../../../components/checkout-1.5.4/forms/RenderInputsV2'
import { string, object, mixed, array } from 'yup' // for only what you need
import CloseIcon from '@material-ui/icons/Close'
import AppContext from '../../../components/AppContextCheckout'

import { lightOrDark } from '../../../utils/lightOrDark'
import StatusCard from '../../components/cards/StatusCard'
import api from '../../../core/api'
import { useSnackbar } from 'notistack'
import { CustomerService } from '../../../core/CustomerService'

const execImmediate = (f) => f()

const freqLabels = {
	DAILY: 'dia',
	MONTHLY: 'mês',
	YEARLY: 'ano'
}

const AddPaymentMeanPage = ({ history, match }) => {
	const [state, setState] = useState({ loading: true })
	const { enqueueSnackbar } = useSnackbar()
	const id = match.params.id
	const { company } = useContext(AppContext)

	const fetchItem = async () => {
		try {
			/** @type {{ data: Veripag.Responses.SubscriptonsResult }} */
			const data = await CustomerService.Subscriptions.GetById({ id })
			setState({ ...state, subscription: data, loading: false })
		} catch (e) {
			setState({ ...state, loading: false })
		}
	}

	useEffect(() => {
		fetchItem()
	}, [])

	const validationSchema = () =>
		object().shape({
			payment_mean: object().shape({
				card_number: string().required('Preencha o campo cep.'),
				cvv: string().required('Preencha o campo rua.'),
				expiry_date: string().required('Preencha o campo número.'),
				customer_name: string().required('Preencha o campo estado.')
			})
		})

	const onFormSubmit = async (values, setFieldValue) => {
		const form = {
			...values.payment_mean,
			holder: values.payment_mean.customer_name,
			number: values.payment_mean.card_number,
			expiry_date: execImmediate(() => {
				try {
					const [, month, year] = values.payment_mean.expiry_date.match(/(\d\d)\/(\d\d)/)
					const currYearFirstTwoDigs = new Date().getFullYear().toString().match(/^\d\d/)[0]
					return `${month}/${currYearFirstTwoDigs}${year}`
				} catch (e) {
					return form.payment_mean.expiry_date
				}
			})
		}
		delete form.customer_name
		try {
			let response = await api.post(`/payment-means`, form).then(
				async (res) =>
					await api.put(`/subscriptions/${id}/payment-means`, {
						payment_mean_id: res.data.payment_mean_id
					})
			)

			if (response.status === 200) {
				enqueueSnackbar('Meio de pagamento alterado com sucesso.', {
					variant: 'success'
				})
			}
			history.replace(`/subscriptions/${id}`)
		} catch (e) {
			if (e.response.status === 409) {
				enqueueSnackbar('Este cartão já esta sendo utilizado na assinatura.', {
					variant: 'error'
				})
			} else {
				if (e.response.data.message.includes('Invalid card number')) {
					enqueueSnackbar('Número de cartão invalido.', {
						variant: 'error'
					})
				} else {
					enqueueSnackbar('Erro interno, por favor tente novamente.', {
						variant: 'error'
					})
				}
			}
		}
	}

	return (
		<PortalWrapper history={history}>
			<Paper style={{ padding: '0 20px' }}>
				<Formik
					initialValues={{
						payment_mean: { card_number: '', expiry_date: '', cvv: '', customer_name: '', brand: '' }
					}}
					validationSchema={validationSchema}
					onSubmit={(values, { setFieldValue }) => onFormSubmit(values, setFieldValue)}
				>
					{(formikProps) => {
						if (state.loading) {
							return (
								<SectionMain direction="column" position="center" alignItems="center">
									<Loader />
								</SectionMain>
							)
						}
						return (
							<form action="javascript:void(0)" onSubmit={formikProps.handleSubmit}>
								<SectionMain noPadding>
									<SectionMain position="space-between" alignItems="flex-start">
										<div>
											<SectionMain noPadding style={{ paddingBottom: 5 }}>
												<Title style={{ paddingRight: 10 }} id="text-truncate">
													{state.subscription.plan.name}
												</Title>
												<StatusCard status={state.subscription.status} />
											</SectionMain>

											<SectionMain miniPadding>
												<TitleSmall bold>
													{`${toCurrency(
														state.subscription.plan.amount,
														state.subscription.plan.currency.alpha_code
													)}/${freqLabels[state.subscription.plan.frequency]}`}
												</TitleSmall>
											</SectionMain>
										</div>
										<IconButton size="small" onClick={() => history.goBack()}>
											<CloseIcon />
										</IconButton>
									</SectionMain>

									<SectionMain miniPadding>
										<Title style={{ paddingRight: 10 }}>Cadastrar novo cartão</Title>
									</SectionMain>
									<SectionMain noPadding>
										<RenderInputsV2
											portalFixLayout
											formikProps={formikProps}
											inputs={[{ type: 'payment', name: 'payment_mean' }]}
										/>
									</SectionMain>
									<SectionMain position="flex-end">
										<SectionMain noPadding xl={2} lg={2} md={2} sm={2}>
											<Button
												fullWidth
												type="submit"
												variant="contained"
												color="primary"
												disabled={formikProps.isSubmitting}
												style={{
													color:
														company.primary_color &&
															lightOrDark(company.primary_color) === 'light'
															? '#282828'
															: '#fff',
													alignItems: 'center',
													backgroundColor: company.primary_color || null
												}}
											>
												{formikProps.isSubmitting && (
													<CircularProgress
														size={24}
														color="inherit"
														style={{ marginRight: 8 }}
													/>
												)}
												Adicionar
											</Button>
										</SectionMain>
									</SectionMain>
								</SectionMain>
							</form>
						)
					}}
				</Formik>
			</Paper>
		</PortalWrapper>
	)
}

export default AddPaymentMeanPage
