import React, { useContext } from "react"
import { withRouter } from "react-router-dom"
import { ButtonConfirm } from "./Buttons"
import AppContext from "./AppContextCheckout"
import { basic_elaboration_mail_check } from "react-icons-kit/linea/basic_elaboration_mail_check"
import { arrows_square_check } from "react-icons-kit/linea/arrows_square_check"
import Icon from "react-icons-kit"
import { Md, Lg } from "./grids/Cols"
import UserDataRow from "./UserDataRow"
import CustomerInfo from "./CustomerInfo"

const Success = withRouter(
	({
		invoice,
		history,
		userData = {
			name: "",
			email: "",
			country_code: "",
			mobile_phone: "",
			postal_code: "",
		},
	}) => {
		const { radio, setAppState } = useContext(AppContext)
		// const pushInvoice = () => {
		// 	setTimeout(() => {
		// 		window.open(
		// 			`http://billing.cyclopay.com/invoice/${invoice.invoice_code}`,
		// 			"_blank"
		// 		)
		// 	}, 3000)
		// }

		// useEffect(() => {
		// 	pushInvoice()
		// }, [])

		if (radio === "boleto") {
			require("../layout/css/StyleSuccess.css")
			return (
				<div
					className="flex-col center-a center-b"
					style={{ minHeight: "80vh" }}
				>
					<div className="flex-row center-a">
						<Icon
							style={{
								marginTop: 20,
								fontStretch: "100%",
								fontWeight: "400",
								color: "rgba(0, 230, 64, 0.8)",
							}}
							size={150}
							icon={basic_elaboration_mail_check}
						/>
					</div>
					<div className="flex-col center-a">
						<br />
						<div className="flex-row center-a">
							<h1
								style={{
									textAlign: "center",
									color: "rgba(0,0,0,0.4)",

									whiteSpace: "pre-wrap",
									fontSize: 20,
									wordSpacing: 4,
								}}
							>
								Enviamos o boleto para o e-mail{" "}
								<span style={{ color: "rgba(0,0,0,0.8)" }}>
									{userData.email}
								</span>
							</h1>
						</div>
						<br />
						<div className="flex-row center-a">
							<h1
								style={{
									textAlign: "center",
									color: "rgba(0,0,0,0.8)",
									fontSize: 18,
									whiteSpace: "pre-wrap",
								}}
							>
								34191.00000 00000.000000 00000.000000 0
								00000000000101
							</h1>
						</div>
					</div>
					<br />
					<br />

					{/* <div className="flex-row center-a">
						<ButtonConfirm
							sizeWidth={200}
							backgroundColor="rgba(0, 230, 64, 0.8)">
							Copiar Código
						</ButtonConfirm>
					</div> */}
				</div>
			)
		}

		return (
			<div className="flex" style={{ padding: 20 }}>
				<div className="flex-row center-a">
					<Icon
						style={{
							marginTop: 20,
							fontStretch: "100%",
							fontWeight: "400",
							color: "rgba(0, 230, 64, 0.8)",
						}}
						size={150}
						icon={arrows_square_check}
					/>
				</div>
				<div className="flex-row center-a">
					<h1
						style={{
							textAlign: "center",
							color: "rgba(0,0,0,0.4)",
							marginTop: 40,
							whiteSpace: "pre-wrap",
							fontSize: 20,
						}}
					>
						Pagamento Realizado com sucesso!
					</h1>
				</div>
				<hr style={{ borderTop: "1px solid #0000008a" }} />
				<CustomerInfo customer={userData} />
				{/* <UserDataRow leftValue="Nome" rightValue={userData.name} />
				<UserDataRow leftValue="E-mail" rightValue={userData.email} />
				<UserDataRow
					leftValue="Telefone Celular"
					rightValue={userData.mobile_phone}
				/>
				<UserDataRow
					leftValue="País"
					rightValue={userData.country_code}
				/> */}
				<hr style={{ borderTop: "1px solid #0000008a" }} />
				<div
					className="flex-col center-a"
					style={{ paddingBottom: 20 }}
				>
					<div className="flex-row center-a">
						<h1
							style={{
								textAlign: "center",
								color: "rgba(0,0,0,0.8)",
								whiteSpace: "pre-wrap",
								fontSize: 20,
								paddingBottom: 15,
							}}
						>
							Enviamos a fatura para o seu e-mail.
						</h1>
					</div>
					{/* <div className="flex-row center-a">
						<ButtonConfirm
							backgroundColor="rgba(0, 230, 64, 0.8)"
							onClick={() =>
								window.open(
									`https://billing.cyclopay.com/invoice/${invoice.invoice_code}`,
									"_blank"
								)
							}>
							Ver comprovante
						</ButtonConfirm>
					</div> */}
				</div>
			</div>
		)
	}
)

export default Success
