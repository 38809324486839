import React from 'react'
import { Hidden, makeStyles } from '@material-ui/core'
import SectionMain from '../../../layout/SectionMain'
import TopMenu from './TopMenu'
import BottomMenu from './BottomMenu'
import TopMenuMobile from './TopMenuMobile'

const useStyles = makeStyles({
	desktop: { paddingTop: 100 },
	mobile: { paddingTop: 70 }
})

const NavigationMenu = () => {
	const classes = useStyles()
	return (
		<SectionMain noPadding position="center">
			<Hidden only={[ 'xs' ]}>
				<TopMenu />
				<SectionMain className={classes.desktop} />
			</Hidden>
			<Hidden only={[ 'xl', 'lg', 'md', 'sm' ]}>
				<TopMenuMobile />
				<SectionMain className={classes.mobile} />
				<BottomMenu />
			</Hidden>
		</SectionMain>
	)
}

export default NavigationMenu
