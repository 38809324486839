import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import 'moment/locale/pt-br';
import 'moment/locale/es';
import 'moment/locale/fr';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "./DateInput.css"
import AppContext, { useAppContext } from "../../../AppContextCheckout";
import formSchema from "../../../../core/formSchema";
import { TextField } from "@mui/material";
import moment from "moment";



const DateInput = (props) => {
    const { lang, language } = useAppContext(AppContext)

    const { country } = props
    const date = new Date()
    return <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language || lang || 'pt-BR'}>
        <DatePicker
            className={props.fullWidth ? "input-full-width" : ""}
            maxDate={moment("2100-01-01")}
            minDate={moment("1900-01-01")}
            {...props}
            slotProps={{
                textField: { 
                    name: props.name,
                    onBlur: props.onBlur,
                    error: props.error,
                 }
            }}
        />
    </LocalizationProvider>
}

export default DateInput;