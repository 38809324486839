import ReactGA4 from "react-ga4";
const InitializeGoogleAnalytics = (MEASUREMENT_ID) => {
    if (!MEASUREMENT_ID) {
        return;
    }
    try {
        ReactGA4.initialize(MEASUREMENT_ID);
        console.log("GA INITIALIZED");
    } catch (e) {
        console.log("GA INITIALIZATION ERROR:", e.message);
    }
};

const TrackGoogleAnalyticsEvent = (
    category = "",
    action = "",
    label = ""
) => {
    console.log("GA event:", category, ":", action, ":", label);
    try {
        ReactGA4.event({
            category: category,
            action: action,
            label: label,
        });
    } catch (e) {
        console.log("GA event error:", e.message)
    }
};

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent };