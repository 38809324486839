import React, { useRef, useState } from "react"
import { Formik } from "formik"

import RenderInputsV4 from "../checkout-1.5.4/forms/RenderInputsV4"
import { useTranslation } from "../IntlContext/IntlContext"
import { FormikValidationsCustom } from "../checkout-1.5.4/forms/RenderInputsV4/FormikValidations"
import _ from 'lodash'
import Loader from "../Loader"
import toCurrency from "../../core/toCurrency"
import { TextField, Button, FormControlLabel, Checkbox, Select, FormLabel, FormControl, InputLabel, Box, Accordion, AccordionSummary, AccordionDetails, IconButton, Stepper, ThemeProvider, createTheme } from '@material-ui/core';
import PaymentMethodsList from "../PaymentMethods"
import swal from "@sweetalert/with-react"
import TestForm from "../../core/randomize"




const ProductOneStep = ({ checkout, quantity, onSubmit = () => null, totalAmount = 0, loadingState = {} }) => {
    const t = useTranslation()
    const [value, setValue] = React.useState(0);


    const theme = createTheme({
        palette: {
            primary: { main: checkout.company?.style.primary_color },
        },
    });

    const [TCAgreed, setTCAgreed] = useState(!checkout.checkout_config.terms_conditions.is_enabled)

    const showTCmodal = async (t) => {
        const lines = checkout.checkout_config.terms_conditions.data.split("\n")

        const result = await swal({
            buttons: {
                cancel: t.generics.cancel,
                accept: {
                    text: t.generics.accept,
                    value: true,
                },
            },
            content: (
                <div>
                    <div style={{ width: '100%', height: '50vh', backgroundColor: "#f2f2f2", overflowY: 'auto', textAlign: 'left', padding: 5 }}>
                        {lines.map(line => (
                            <span>
                                {line}
                                <br />
                            </span>
                        ))}
                    </div>
                </div>
            ),

        })
        if (result) setTCAgreed(true)
    }

    const [selectedMethod, setSelectedMethod] = useState(checkout.checkout_config.payment_methods.includes('credit_card')
        ? 'credit_card'
        : checkout.checkout_config.payment_methods[0])



    const disabledInputsCustomer = () => {
        if (!checkout.customer) return {}
        let initialValues = {
            first_name: Boolean(checkout.customer.first_name),
            last_name: Boolean(checkout.customer.last_name),
            mobile_phone: Boolean(checkout.customer.mobile_number),
            email: Boolean(checkout.customer.email),
            country_code: Boolean(checkout.customer.country_code),
            document_type: Boolean(checkout.customer.document.type),
            document_number: Boolean(checkout.customer.document.number),
            billing_address_postal_code: Boolean(checkout.customer.billing_address?.postal_code),
            billing_address_street1: Boolean(checkout.customer.billing_address?.street),
            billing_address_number: Boolean(checkout.customer.billing_address?.number),
            billing_address_state: Boolean(checkout.customer.billing_address?.state),
            billing_address_district: Boolean(checkout.customer.billing_address?.district),
            billing_address_city: Boolean(checkout.customer.billing_address?.city),
            billing_address_country_code: Boolean(checkout.customer.billing_address?.country_code),
            billing_address_complement: Boolean(checkout.customer.billing_address?.complement),
            shipping_address_postal_code: Boolean(checkout.customer.shipping_address[0]?.postal_code),
            shipping_address_street1: Boolean(checkout.customer.shipping_address[0]?.street),
            shipping_address_number: Boolean(checkout.customer.shipping_address[0]?.number),
            shipping_address_state: Boolean(checkout.customer.shipping_address[0]?.state),
            shipping_address_district: Boolean(checkout.customer.shipping_address[0]?.district),
            shipping_address_city: Boolean(checkout.customer.shipping_address[0]?.city),
            shipping_address_country_code: Boolean(checkout.customer.shipping_address[0]?.country_code),
            shipping_address_complement: Boolean(checkout.customer.shipping_address[0]?.complement),
        }
        return initialValues
    }

    const customerInitialValues = () => {
        if (checkout.customer) {
            let initialValues = {
                first_name: checkout.customer.first_name,
                last_name: checkout.customer.last_name,
                mobile_phone: checkout.customer.mobile_number || "",
                email: checkout.customer.email,
                country_code: checkout.customer.country_code,
                document_type: checkout.customer.document.type,
                document_number: checkout.customer.document.number,
                billing_address_postal_code: checkout.customer.billing_address?.postal_code,
                billing_address_street1: checkout.customer.billing_address?.street,
                billing_address_number: checkout.customer.billing_address?.number,
                billing_address_state: checkout.customer.billing_address?.state,
                billing_address_district: checkout.customer.billing_address?.district,
                billing_address_city: checkout.customer.billing_address?.city,
                billing_address_country_code: checkout.customer.billing_address?.country_code,
                billing_address_complement: checkout.customer.billing_address?.complement,
                shipping_address_postal_code: checkout.customer.shipping_address[0]?.postal_code,
                shipping_address_street1: checkout.customer.shipping_address[0]?.street,
                shipping_address_number: checkout.customer.shipping_address[0]?.number,
                shipping_address_state: checkout.customer.shipping_address[0]?.state,
                shipping_address_district: checkout.customer.shipping_address[0]?.district,
                shipping_address_city: checkout.customer.shipping_address[0]?.city,
                shipping_address_country_code: checkout.customer.shipping_address[0]?.country_code,
                shipping_address_complement: checkout.customer.shipping_address[0]?.complement,
            }
            return initialValues
        }
        let initialValues = {
            first_name: "",
            last_name: "",
            mobile_phone: "",
            email: "",
            country_code: "BRA",
            document_type: checkout.checkout_config.prioritize_b2b ? "CNPJ" : "CPF",
            document_number: "",
            billing_address_postal_code: "",
            billing_address_street1: "",
            billing_address_number: "",
            billing_address_state: "",
            billing_address_district: "",
            billing_address_city: "",
            billing_address_country_code: "",
            billing_address_complement: "",
            shipping_address_postal_code: "",
            shipping_address_street1: "",
            shipping_address_number: "",
            shipping_address_state: "",
            shipping_address_district: "",
            shipping_address_city: "",
            shipping_address_country_code: "",
            shipping_address_complement: "",
        }
        return initialValues
    }

    const CCInitialValues = () => {
        let initialValues = {
            cc_number: "",
            cc_cardholder: "",
            cc_expiry_date: "",
            cvv: "",
            brand: "",
            type: "",
            installments: checkout.checkout_config.installment_enabled ? 1 : null

        }
        return initialValues
    }

    const customValidationConfig = {
        customer: true,
        billing_address: checkout.checkout_config.billing_address_enabled || (totalAmount > 0 && ['boleto'].includes(selectedMethod)),
        document: checkout.checkout_config.document_enabled || (totalAmount > 0 && ['pix', 'boleto'].includes(selectedMethod)),
        shipping_address: checkout.checkout_config.shipping_address_enabled,
        cc_payment: totalAmount > 0 && selectedMethod === 'credit_card'
    }

    return (
        <Formik
            initialValues={{ ...customerInitialValues(), ...CCInitialValues() }}
            validate={values => FormikValidationsCustom(values, checkout, t, customValidationConfig)}
            onSubmit={props => onSubmit({ ...props, type: selectedMethod })}
        >
            {({
                handleSubmit,
                ...formikprops
            }) => (
                <form onSubmit={(form) => handleSubmit(form)} style={{ width: '100%', height: '100%' }} action="javascript:void(0)">
                    <div className='form-container'>
                        <span style={{ padding: 10 }}>
                            {t.titles["customer.form"]}:
                        </span>
                        <RenderInputsV4 formikprops={formikprops} checkoutConfig={checkout} rows={[
                            (formikprops.values["document_type"] !== "CNPJ" ? ['first_name', 'last_name'] : ['first_name']),
                            (checkout.checkout_config?.birth_date ? ['email', 'birth_date'] : ['email']),
                            (checkout.checkout_config?.country_code ? ['country_code'] : []),
                            (checkout.checkout_config?.document_enabled ? ['document_type', 'document_number'] : []),
                            (checkout.checkout_config?.mobile_phone ? ['mobile_phone'] : [])
                        ]} />
                        {checkout.checkout_config?.billing_address_enabled &&
                            <>
                                <span style={{ padding: 10 }}>
                                    {t.titles["billing_address.form"]}:
                                </span>
                                <RenderInputsV4 formikprops={formikprops} checkoutConfig={checkout} rows={[
                                    ['billing_address_postal_code'],
                                    ['billing_address_street1'],
                                    ['billing_address_number', 'billing_address_district'],
                                    ['billing_address_city', 'billing_address_state_br'],
                                    ['billing_address_complement'],
                                ]} />
                            </>
                        }

                        {checkout.checkout_config?.shipping_address_enabled &&
                            <>
                                <span style={{ padding: 10 }}>
                                    {t.titles["shipping_address.form"]}:
                                </span>
                                <RenderInputsV4 formikprops={formikprops} checkoutConfig={checkout} rows={[
                                    ['shipping_address_postal_code'],
                                    ['shipping_address_street1'],
                                    ['shipping_address_number', 'shipping_address_district'],
                                    ['shipping_address_city', 'shipping_address_state_br'],
                                    ['shipping_address_complement'],
                                ]} />
                            </>
                        }

                        {totalAmount > 0 &&
                            <>
                                <span style={{ padding: 10 }}>
                                    {t.generics.payment}:
                                </span>
                                <PaymentMethodsList
                                    selectedMethod={selectedMethod}
                                    onSelect={(method) => setSelectedMethod(method)}
                                    availablMethods={checkout.checkout_config.payment_methods}
                                    formikprops={formikprops}
                                    totalAmount={totalAmount}
                                    checkout={checkout} />
                            </>
                        }

                        {checkout.checkout_config.terms_conditions?.is_enabled &&
                            <div className='flex-row center-b'>
                                <ThemeProvider theme={theme}>
                                    <Checkbox checked={TCAgreed} onChange={() => setTCAgreed(!TCAgreed)} color="primary" />
                                </ThemeProvider>
                                <span>
                                    {checkout.checkout_config.terms_conditions.type === 'link' &&
                                        <>
                                            {t.checkout.terms_conditions_1}{" "}
                                            <a style={{ color: 'black' }} target={'_blank'} href={checkout.checkout_config.terms_conditions.data}>
                                                {t.checkout.terms_conditions_2}
                                            </a>
                                        </>
                                    }
                                    {checkout.checkout_config.terms_conditions.type === 'text' &&
                                        <>
                                            {t.checkout.terms_conditions_1}{" "}
                                            <a onClick={() => showTCmodal(t)} style={{ textDecoration: "underline", cursor: 'pointer' }}>
                                                {t.checkout.terms_conditions_2}
                                            </a>
                                        </>
                                    }
                                    {checkout.checkout_config.terms_conditions.type === 'accept' &&
                                        <>
                                            {t.checkout.terms_conditions_full}
                                        </>
                                    }
                                </span>
                            </div>
                        }

                        <div className='flex-row' style={{ width: 'auto', padding: 10 }}>
                            <Button
                                style={{
                                    width: '100%',
                                    fontSize: '1rem'
                                }}
                                variant="contained"
                                color="primary"
                                size="small"
                                type='submit'
                                disabled={loadingState.payment || !TCAgreed}
                            >
                                {loadingState.payment &&
                                    <span style={{ marginRight: "10px" }}>
                                        <Loader white size={30} />
                                    </span>
                                }
                                {t.buttons['checkout.paid']} {toCurrency(totalAmount)}
                            </Button>
                        </div>
                    </div>
                    <TestForm checkoutConfig={checkout} value={value} formikprops={formikprops} formStep={'one_step'} />

                </form>
            )}
        </Formik>
    )
}

export default ProductOneStep