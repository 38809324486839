import React, { useMemo, useEffect } from "react"
import { createBrowserHistory } from "history"
import { Router, Route } from "react-router-dom"
// import 'antd/dist/antd.css'

import useReduxState from "./core/useReduxState"
import AppContext from "./components/AppContextCheckout"
import Loader from "./components/Loader"
import getInitialState from "./core/getInitialState"
import api from "./core/api"
import InvoiceOnlyPage from "./pages/invoice"
import LandingPage from "./pages/landing_page"
// import TestPage from './pages/dev'
import SubscriptionsPage from "./portal/screens/subscriptions"
import PaymentMeansPage from "./portal/screens/payment-means"
import ProfilePage from "./portal/screens/profile"
import EditPaymentMeansPage from "./portal/screens/edit-payment-means"
import AddPaymentMeansPage from "./portal/screens/add-payment-means"
import LoginPage from "./portal/screens/login/indexNew"
import ResetPage from "./portal/screens/reset"
import ForgotPage from "./portal/screens/forgot"

import { SnackbarProvider } from "notistack"
import UpdatePaymentMeanPage from "./portal/screens/update-payment-means"
import ChangePaymentMethodPage from "./portal/screens/change-payment-method"
import CheckoutPage from "./checkout-new.js"
import PaymentsPage from "./portal/screens/payments"
import PortalWrapper from "./portal/components/layout/PortalWrapper"
import ErrorBoundary from "./components/ErrorBoundary"
import AddCreditCardPage from "./portal/screens/add-credit-card"
import { reactPlugin } from "./AppInsights.js";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import Checkout from "./pages/checkoutV2"
import CheckoutProduct from "./pages/checkoutProductPage"
import TestPage from "./pages/dev"


const NO_AUTH_ROUTES = [
	"login",
	"reset",
	"forgot",
	"checkout",
	"landing-page",
	"edit-payment",
	"update-payment-mean",
	"change-payment-method",
	"dev",
	"dev-subscriptions",
]

const NO_AUTH_RE = new RegExp(`^/(${NO_AUTH_ROUTES.join("|")})`)

export default function App({ key }) {


	const [getState, setAppState] = useReduxState(
		AppContext.init({
			loadingAppState: true,
			radio: "credit_card",
			radioInputs: [],
		})
	)


	const history = useMemo(() => createBrowserHistory(), [key])

	useEffect(() => {
		const url = history.location.pathname
		// setAppState({ loadingAppState: false })

		getInitialState({ url }, NO_AUTH_RE).then((state) => {
			api.init(history)

			if (!NO_AUTH_RE.exec(url) && !state.user) {
				history.replace("/login")
			}

			setAppState(state)
		})

	}, [])

	const { loadingAppState } = getState()

	if (loadingAppState) {
		return (
			<div
				className="flex-col center-a center-b"
				style={{
					width: "100vw",
					height: "100vh",
				}}
			>
				<Loader />
			</div>
		)
	}

	try {
	} catch (error) {
		console.log(error)
	}

	return (
		<AppInsightsErrorBoundary onError={() => <h1>Ocorreu um erro na aplicação. </h1>} appInsights={reactPlugin}>
			<AppContext.Provider value={{ ...getState(), setAppState }}>
				<SnackbarProvider
					anchorOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
					maxSnack={3}
					autoHideDuration={3000}
				>
					<Router history={history}>
						<Route path="/" exact component={SubscriptionsPage} />

						<Route path="/subscriptions/:id" exact component={SubscriptionsPage} />

						{/* <Route path="/me" component={UserPage} /> */}
						<Route path="/profile" component={ProfilePage} />
						<Route
							path="/invoice/:id"
							exact
							component={InvoiceOnlyPage}
						/>
						<Route
							path="/payment-means"
							exact
							component={PaymentMeansPage}
						/>
						<Route path="/payments" exact component={PaymentsPage} />
						<Route
							path="/edit-payment/:id"
							exact
							component={EditPaymentMeansPage}
						/>
						<Route
							path="/add-payment"
							exact
							component={AddCreditCardPage}
						/>
						<Route
							path="/add-payment/:id"
							exact
							component={AddPaymentMeansPage}
						/>
						{/* <Route path="/login" component={LoginPage} /> */}
						<Route path="/login" component={LoginPage} />
						<Route path="/forgot" component={ForgotPage} />
						<Route path="/reset" component={ResetPage} />
						<Route path="/checkout" exact component={Checkout} />
						<Route path="/checkout/:id" exact component={Checkout} />
						<Route path="/checkout/p/:id" exact component={CheckoutProduct} />
						<Route
							exact
							path="/update-payment-mean"
							component={UpdatePaymentMeanPage}
						/>
						<Route
							exact
							path="/update-payment-mean/:id"
							component={UpdatePaymentMeanPage}
						/>
						<Route
							path="/change-payment-method"
							component={ChangePaymentMethodPage}
						/>
						<Route
							exact
							path="/change-payment-method/:id"
							component={ChangePaymentMethodPage}
						/>
						<Route path="/landing-page" component={LandingPage} />
						<Route
							path="/dev-subscriptions"
							component={SubscriptionsPage}
						/>
						{/* <Route path="/dev" component={TestPage} /> */}
					</Router>
				</SnackbarProvider>
			</AppContext.Provider>
		</AppInsightsErrorBoundary>
	)
}
