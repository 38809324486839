import React from 'react'

const UserDataRow = ({ leftValue, rightValue }) => (
	<div className="flex-row center-a" style={{ justifyContent: 'space-between', width: '100%' }}>
		<h1
			style={{
				color: 'rgba(0,0,0,0.4)',
				width: 200,
				textAlign: 'left',
				whiteSpace: 'pre-wrap',
				fontSize: 16,
				wordSpacing: 4
			}}
		>
			{leftValue}
		</h1>
		<h1
			style={{
				textAlign: 'center',
				color: 'rgba(0,0,0,0.8)',
				whiteSpace: 'pre-wrap',
				fontSize: 16,
				wordSpacing: 4
			}}
		>
			{rightValue}
		</h1>
	</div>
)
export default UserDataRow
