import request from 'superagent'

export const getviaCepUrl = (postal_code = '', handleFormikValues, inputType) => {
    if (postal_code.length === 8) {
        let next_address = request.get(`https://viacep.com.br/ws/${postal_code}/json`)
        next_address.end((err, response) => {
            if (err) {
                console.log(err)
            } else {
                const { logradouro, cep, localidade, bairro, uf } = response.body
                const address = {
                    ...handleFormikValues.values[`${inputType}`],
                    postal_code: cep,
                    street1: logradouro,
                    district: bairro,
                    state: uf,
                    city: localidade
                }
                handleFormikValues.setFieldValue(`${inputType}`, { ...address })
            }
        })
    }
}
